import React from "react";
import {
    IonCol,
    IonRow,
    IonCard,
    IonCardTitle,
    IonButton,
    IonCardSubtitle,
    IonCardHeader,
    IonCardContent,
    IonIcon
} from "@ionic/react";
import { chevronDown, trash } from "ionicons/icons";
import "../../theme/cards.css";
import EventInfoSection from "../Entry/EntryForm/EventInfoSection";
import { Event, Horse } from "../../models";
import { ExpandedCardStates } from "../../pages/dashboard/events/EventNewEntryPage";
import EntryCardChip, { ChipTitleOption } from "../Chips/EntryCardChip";
import EntryCard from "../Entry/EntryForm/EntryCard";

interface EntrySectionCardProps {
    event: Event;
    miniTitle?: string;
    title: string;
    subtitle?: string;
    chipTitle: ChipTitleOption;
    trashIcon: boolean;
    expandButtonTitle: string;
    isExpand: boolean;
    cardContent: ExpandedCardStates;
    allHorses?: Horse[]
    toggleExpansion: (isExpand: boolean) => void;
    onSubmit?: () => void;
}

const EntrySectionCard: React.FC<EntrySectionCardProps> = ({
    event,
    miniTitle = "",
    title,
    subtitle,
    chipTitle,
    trashIcon,
    expandButtonTitle,
    isExpand,
    cardContent,
    allHorses,
    toggleExpansion,
    onSubmit
}) => {
    return (
        // Card's Outer Container
        <IonRow className="p-0">
            <IonCol size="12" className="p-0 m-0">
                <IonCard mode="md" className="rounded-3 mt-2 mb-0 mx-1 bg-light">  {/* IMPORTANT!! Use this IonCard to alter parent card widths */}

                    {/* Card Header */}
                    <IonCardHeader className="pb-0">
                        <IonRow className="ion-justify-content-between">
                            {/* Left Side Header - Text and chip */}
                            <IonCol className="pt-0">
                                {miniTitle && (
                                    <IonCardSubtitle className="display-4 text-sans-serif font-weight-700 text-primary ">{miniTitle}</IonCardSubtitle>
                                )}
                                <IonCardTitle className="display-3 text-monospace font-weight-700 text-primary lh-120">{title}</IonCardTitle>
                                <IonCardSubtitle className="display-4 text-sans-serif font-weight-bold text-primary lh-120">{subtitle}</IonCardSubtitle>
                            </IonCol>
                            {/* Right Side Header - Trash and collapse chevron */}
                            {trashIcon && (
                                <IonCol size="auto" className="ion-justify-center ion-align-end">
                                    <IonIcon className="card-icon-size-1 text-danger pt-1" icon={trash} />
                                </IonCol>
                            )}
                        </IonRow>
                        <IonRow className="ion-align-items-center ion-justify-content-between pb-2">
                            <IonCol size="auto" className="pt-0">
                                <EntryCardChip chipTitle={chipTitle}>{chipTitle}</EntryCardChip>
                            </IonCol>
                            {isExpand && (
                                <IonCol size="auto" className="p-0">
                                    <IonIcon className="card-icon-size-1 text-primary pr-1" icon={chevronDown} onClick={() => toggleExpansion(false)} />
                                </IonCol>
                            )}
                        </IonRow>
                    </IonCardHeader>

                    {/* Button to Expand Card */}
                    <IonCardContent className="px-0 pt-0 pb-3">
                        {!isExpand ? 
                            // Card Start Button - is hidden when card is expanded
                            <IonRow className="ion-justify-content-center">
                                <IonCol sizeXs="12" sizeMd="4" className="ion-text-center py-0 px-3">
                                    <IonButton expand="block" className="display-4" onClick={() => toggleExpansion(true)}>
                                        {expandButtonTitle}
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                            :
                            /* Card Content */
                            <IonRow className="ion-justify-content-center">
                                <IonCol sizeXs="12" sizeMd="12" className="px-2 py-0 mx-3">
                                    {cardContent === "header" && <EventInfoSection event={event} />}
                                    {cardContent === "entry" && <EntryCard event={event} allHorses={allHorses} />}
                                    {/* Add more card content conditions as needed */}
                                    <IonRow className="ion-justify-content-center">
                                        <IonCol sizeXs="11" sizeMd="4" className="p-0">
                                            <IonButton expand="block" className="display-4 text-bold" onClick={onSubmit}>
                                                Continue
                                            </IonButton>
                                        </IonCol>
                                    </IonRow>
                                </IonCol>
                            </IonRow>
                        }
                    </IonCardContent>
                </IonCard>
            </IonCol>
        </IonRow>
    );
};

export default EntrySectionCard;
