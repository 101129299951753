import { IonButton, IonCard, IonCol, IonInput, IonItem, IonLabel, IonRow } from "@ionic/react";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { CreateAddressInput, CreateContactInput, CreatePersonalInformationInput, UpdateAddressInput, UpdateContactInput, UpdateMembershipInput, UpdatePersonalInformationInput } from "../../../API";
import { Address, Contact, Membership, MembershipTypeCategory, OrganizationMembershipType, PersonalInformation } from "../../../models";
import { createAddress, getAddressById, updateAddress } from "../../../utilities/address/Address";
import { createContact, updateContact } from "../../../utilities/contact/Contact";
import { formatAllCountryPhoneNumber } from "../../../utilities/contact/FormatPhoneNumber";
import { updateMembership } from "../../../utilities/membership/Membership";
import { createPersonalInformation, getPersonalInformationByPersonId, updatePersonalInformation } from "../../../utilities/personalInformation/PersonalInformation";
import AddressFormGroup from "../../Address/AddressFormGroup";
import PhoneNumberTypeSelect from "../../Contact/PhoneNumberTypeSelect";
import ErrorAlert from "../../Errors/ErrorAlert";
import Spinner from "../../Spinners/Spinner";
import RequiredInputIndicator from "../../Forms/RequiredInputIndicator";

interface _Props {
    membership: Membership | undefined
    membershipType: OrganizationMembershipType | undefined;
    setMembership: Function
};

const EditMembershipApplicationContact: React.FC<_Props> = ({ membership, membershipType, setMembership}) => {
    const [isDisabled, setIsDisabled] = useState(false);
    const [address, setAddress] = useState<Address>();
    const [error, setError] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [phoneType, setPhoneType] = useState<string>("");
    const [streetAddressLine1, setStreetAddressLine1] = useState("");
    const [streetAddressLine2, setStreetAddressLine2] = useState("");
    const [city, setCity] = useState("");
    const [provState, setProvState] = useState("");
    const [zip, setZip] = useState("");
    const [country, setCountry] = useState("");
    const [personInfo, setPersonInfo] = useState<PersonalInformation>();
    const [contact, setContact] = useState<Contact>();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if(membership) {
            if (membershipType?.category === MembershipTypeCategory.HORSE && membership.horse?.contact) {
                getPrePopulatedDataForHorseInfo(membership.horse.contact);
            } else {
                setEmail(membership.personEmail ?? "");
                getPrePopulatedDataForPersonalInfo(membership.contactPersonId || membership.personId);
            }
        }
    }, [membership]);

    const handlePhoneNumberTypeSelectInput = (phoneNumberTypeInput: string) => {
        setError("");
        if (phoneNumberTypeInput && phoneNumberTypeInput !== "-"){
            setPhoneType(phoneNumberTypeInput);
        } else {
            setPhoneType("");
        }
    };

    const handleAddressInputChange = (type: string, value: string) => {
        setError("");
        if (type === "streetAddressLine1") setStreetAddressLine1(value);
        if (type === "streetAddressLine2") setStreetAddressLine2(value);
        if (type === "city") setCity(value);
        if (type === "provState") setProvState(value);
        if (type === "zip") setZip(value);
        if (type === "country") setCountry(value);
    };

    const handleContactInfoSave = async () => {
        const isValid = verifyIsValid();
        if(isValid) {
            setIsLoading(true);
            if (membershipType?.category === MembershipTypeCategory.HORSE && membership?.horse?.contact) {
                const updatedAddress = await handleAddress(membership.horse.contact.mailingAddress || "");
                await handleContact(membership?.horse?.contact.id, updatedAddress);
            } else {
                // First, check that the personal info exists
                let currentPersonInfo = personInfo;
                if (!currentPersonInfo) {
                    // Create a new record for the Person Info
                    if (membership) {
                        const peronalInfoInput: CreatePersonalInformationInput = {
                            personId: (membership.contactPersonId || membership.personId)
                        };
                        const createPersonalInfoResult = await createPersonalInformation(peronalInfoInput);
                        if (createPersonalInfoResult.isSuccess) {
                            currentPersonInfo = createPersonalInfoResult.result;
                        }
                    }
                }

                // Next, update the address record or create it if needed
                const updatedAddress = await handleAddress(personInfo?.addressId || "");

                // Next, update the contact record or create it if needed
                const updatedContact = await handleContact(personInfo?.contactId || "", updatedAddress);

                await handleEmail();

                // Next, update the Personal Info record with the updated Contact and Address
                if (currentPersonInfo) { 
                    const updatePersonalInfo: UpdatePersonalInformationInput = {
                        id: currentPersonInfo?.id,
                        addressId: updatedAddress?.id || "",
                        contactId: updatedContact?.id || ""
                    };
                    await updatePersonalInformation(updatePersonalInfo);
                }
            }
            setIsLoading(false);
        }
    };

    const verifyIsValid = () => {
        if (!email) {
            setError(`Please include a value for Email Address`);
            return false;
        }
        if (!phoneType) {
            setError(`Please include a value for Phone Number Type`);
            return false;
        }
        if (!phoneNumber) {
            setError(`Please include a value for Phone Number`);
            return false;
        }
        if (!streetAddressLine1) {
            setError(`Please include a value for Street Address Line1`);
            return false;
        }
        if (!city) {
            setError(`Please include a value for City`);
            return false;
        }
        if (!provState) {
            setError(`Please include a value for State`);
            return false;
        }
        if (!zip) {
            setError(`Please include a value for Postal Code`);
            return false;
        }
        if (!country) {
            setError(`Please include a value for Country`);
            return false;
        }
        return true;
    };

    const handleAddress = async (id: string) => {
        try {
            if (id) {
                const addressInput: UpdateAddressInput = {
                    id: id,
                    streetAddress1: streetAddressLine1,
                    streetAddress2: streetAddressLine2,
                    city: city,
                    provState: provState,
                    zip: zip,
                    country: country
                };
                const updateAddressResult = await updateAddress(addressInput);
                if (updateAddressResult.isSuccess) {
                    setAddress(updateAddressResult.result);
                    return updateAddressResult.result;
                } else {
                    setError(updateAddressResult.message);
                    return null;
                }
            } else {
                const contactName = (membership?.personName ? (membership?.personName + " ") : "") + "Membership Contact";
                const addressInput: CreateAddressInput = {
                    name: contactName,
                    type: "mailing",
                    streetAddress1: streetAddressLine1,
                    streetAddress2: streetAddressLine2,
                    city: city,
                    provState: provState,
                    zip: zip,
                    country: country
                };
                const createAddressResult = await createAddress(addressInput);
                if (createAddressResult.isSuccess) {
                    setAddress(createAddressResult.result);
                    return createAddressResult.result;
                } else {
                    setError(createAddressResult.message);
                    return null;
                }
            }
        } catch (error) {
            const message = "Could not save the address information: " + error;
            setError(message);
            return null;
        }
    };

    const handleContact = async (id: string, updatedAddress?: Address) => {
        try {
            if (id) {
                let contactInput: UpdateContactInput = {
                    id: id
                };
                if (phoneType === "cell") contactInput["cell"] = formatAllCountryPhoneNumber(phoneNumber);
                else if (phoneType === "home") contactInput["home"] = formatAllCountryPhoneNumber(phoneNumber);
                else if (phoneType === "work") contactInput["work"] = formatAllCountryPhoneNumber(phoneNumber);

                if (updatedAddress) contactInput["mailingAddress"] = updatedAddress.id;

                if (membershipType?.category === MembershipTypeCategory.HORSE) {
                    contactInput["personalEmail"] = email;
                }

                const updateContactResult = await updateContact(contactInput);
                if (updateContactResult.isSuccess) {
                    setContact(updateContactResult.result);
                    return updateContactResult.result;
                } else {
                    setError(updateContactResult.message);
                    return null;
                }
            } else {
                let contactInput: CreateContactInput = {};
                if (phoneType === "cell") contactInput["cell"] = formatAllCountryPhoneNumber(phoneNumber);
                else if (phoneType === "home") contactInput["home"] = formatAllCountryPhoneNumber(phoneNumber);
                else if (phoneType === "work") contactInput["work"] = formatAllCountryPhoneNumber(phoneNumber);

                if (updatedAddress) contactInput["mailingAddress"] = updatedAddress.id;

                if (membershipType?.category === MembershipTypeCategory.HORSE) {
                    contactInput["personalEmail"] = email;
                }

                const createContactResult = await createContact(contactInput);
                if (createContactResult.isSuccess) {
                    setContact(createContactResult.result);
                    return createContactResult.result;
                } else {
                    setError(createContactResult.message);
                    return null;
                }
            }
        } catch (error) {
            const message = "Could not save the contact information: " + error;
            setError(message);
            return null;
        }
    };

    const handleEmail = async () => {
        if(membership) {
            const input: UpdateMembershipInput = {
                id: membership.id,
                personEmail: email,
            };
            const queryResult = await updateMembership(input);
            setMembership(queryResult.result);
        }
    };

    const getPrePopulatedDataForHorseInfo = async (horseContact: Contact) => {
        setEmail(horseContact.personalEmail || "");
        setPhoneType(
            horseContact && horseContact.cell
            ? "cell"
            :  horseContact && horseContact.home
            ? "home"
            :  horseContact && horseContact.work
            ? "work"
            : ""
        );
        setPhoneNumber(
            horseContact && horseContact.cell
            ?   horseContact.cell
            :   horseContact && horseContact.home
            ?   horseContact.home
            :   horseContact && horseContact.work
            ?   horseContact.work
            : ""
        );

        if(horseContact?.mailingAddress) {
            const horseAddressResult = await getAddressById(horseContact?.mailingAddress);
            const horseAddress = horseAddressResult.result;
            setAddress(horseAddress);
            setStreetAddressLine1(horseAddress.streetAddress1 ? horseAddress.streetAddress1 : "");
            setStreetAddressLine2(horseAddress.streetAddress2 ? horseAddress.streetAddress2 : "");
            setCity(horseAddress.city ? horseAddress.city : "");
            setProvState(horseAddress.provState ? horseAddress.provState : "");
            setZip(horseAddress.zip ? horseAddress.zip : "");
            setCountry(horseAddress.country ? horseAddress.country : "");
        };
    };

    const getPrePopulatedDataForPersonalInfo = async (personId: string) => {
        setIsLoading(true);
        try {
            const queryResult = await getPersonalInformationByPersonId(personId);
            if (queryResult.isSuccess) {
                const personalInformation: PersonalInformation = queryResult.result;
                setPersonInfo(personalInformation);
                setAddress(personalInformation && personalInformation.address ? personalInformation.address : undefined);
                setStreetAddressLine1(personalInformation && personalInformation.address?.streetAddress1 ? personalInformation.address.streetAddress1 : "");
                setStreetAddressLine2(personalInformation && personalInformation.address?.streetAddress2 ? personalInformation.address.streetAddress2 : "");
                setCity(personalInformation && personalInformation.address?.city ? personalInformation.address.city : "");
                setProvState(personalInformation && personalInformation.address?.provState ? personalInformation.address.provState : "");
                setZip(personalInformation && personalInformation.address?.zip ? personalInformation.address.zip : "");
                setCountry(personalInformation && personalInformation.address?.country ? personalInformation.address.country : "");
                const contactData = personalInformation.contact;
                setContact(contactData || undefined);
                if (contactData?.personalEmail) setEmail(contactData?.personalEmail);
                setPhoneType(
                    contactData && contactData.cell
                    ? "cell"
                    : contactData && contactData.home
                    ? "home"
                    : contactData && contactData.work
                    ? "work"
                    : ""
                );
                setPhoneNumber(
                    contactData && contactData.cell
                    ? contactData.cell
                    : contactData && contactData.home
                    ? contactData.home
                    : contactData && contactData.work
                    ? contactData.work
                    : ""
                );
            }
        } catch (error: any) {
            setError("Sorry, a problem occurred. Please go back and try again.");
        }
        setIsLoading(false);
    };

    return (
        <React.Fragment>
            <IonCard color="white" className="ion-padding">
                <h3>Contact Info</h3>
                {isLoading ?
                    <Spinner />
                :
                    <>
                        {error && <ErrorAlert width="12" error={error} />}
                        <IonRow>
                            <IonCol sizeXs="12" sizeMd="12">
                                <IonItem color="white">
                                    <IonLabel position="stacked">
                                        Email Address{" "}
                                        <RequiredInputIndicator />
                                    </IonLabel>
                                    <IonInput
                                        id="membership-app-edit-contact-email"
                                        type="email"
                                        required={true}
                                        aria-required={true}
                                        value={email}
                                        onIonChange={(e) => {
                                            setError("");
                                            setEmail(e.detail.value!);
                                        }}
                                    />
                                </IonItem>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol sizeXs="12" sizeMd="6">
                                <PhoneNumberTypeSelect
                                    isRequired={true}
                                    selectedType={phoneType}
                                    onChange={handlePhoneNumberTypeSelectInput}
                                />
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="6">
                                <IonLabel className="pl-3 text-darker" position="stacked">
                                    Phone Number{" "}
                                    <RequiredInputIndicator />
                                </IonLabel>
                                <PhoneInput
                                    inputProps={{
                                        name: "phone",
                                        required: true,
                                        autoFocus: true,
                                        id: "membership-app-edit-phone-number"
                                    }}
                                    placeholder="Enter phone number"
                                    country={"us"}
                                    enableSearch
                                    enableAreaCodes={false}
                                    inputStyle={{
                                        width: "100%",
                                        height: "auto",
                                    }}
                                    value={phoneNumber}
                                    onChange={(phoneNumber) => {
                                        setError("");
                                        setPhoneNumber(phoneNumber);
                                    }}
                                />
                            </IonCol>
                        </IonRow>
                        <AddressFormGroup
                            id="membership-application-contact"
                            isRequired={true}
                            isTextDarker={true}
                            onChange={handleAddressInputChange}
                            address={address}
                            setIsDisabled={setIsDisabled}
                        />
                        <IonRow className="ion-justify-content-center mb-2 mt-4">
                            <IonCol sizeXs="12" sizeMd="3" className="ion-text-center">
                                <IonButton
                                    id="membership-app-edit-save-btn"
                                    color="tertiary"
                                    expand="block"
                                    onClick={handleContactInfoSave}
                                >
                                    Save
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </>
                }
            </IonCard>
        </React.Fragment>
    );
};

export default EditMembershipApplicationContact;
