import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateHorseInput, CreateHorseMutation, DeleteHorseInput, DeleteHorseMutation, GetHorseQuery, ListHorsesQuery, UpdateHorseInput, UpdateHorseMutation } from "../../API";
import moment from "moment";
import { Horse } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: horse. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created horse.
*/
export async function createHorse(input: CreateHorseInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Horse", "No input", "Create Horse received no input.");
   try {
        const fullInput: CreateHorseInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createHorse, { input: fullInput }))) as GraphQLResult<CreateHorseMutation>;
       else result = (await API.graphql({
           query: mutations.createHorse,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreateHorseMutation>;
       const horse = result.data?.createHorse;
       return formatResult(true, "Horse", horse, "Successfully created the horse.");
   } catch (error: any) {
       return formatResult(false, "Horse", error, "Error creating record in the database for type: horse");
   }
}

/**
* Handle updating a new record in the database for type: horse. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated horse.
*/
export async function updateHorse(input: UpdateHorseInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Horse", "No input", "Update Horse received no input.");
   try {
        const fullInput: UpdateHorseInput = {
            ...input,
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateHorse, { input: fullInput }))) as GraphQLResult<UpdateHorseMutation>;
       else result = (await API.graphql({
           query: mutations.updateHorse,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdateHorseMutation>;
       const horse = result.data?.updateHorse;
       return formatResult(true, "Horse", horse, "Successfully updated the horse.");
   } catch (error: any) {
       return formatResult(false, "Horse", error, "Error updating record in the database for type: horse");
   }
}

/**
* Handle deleting a new record in the database for type: horse. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted horse.
*/
export async function deleteHorse(input: DeleteHorseInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Horse", "No input", "Delete Horse received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteHorse, { input: input }))) as GraphQLResult<DeleteHorseMutation>;
       else result = (await API.graphql({
           query: mutations.deleteHorse,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteHorseMutation>;
       const horse = result.data?.deleteHorse;
       return formatResult(true, "Horse", horse, "Successfully deleted the horse.");
   } catch (error: any) {
       return formatResult(false, "Horse", error, "Error deleting record in the database for type: horse");
   }
}

/**
* Get all records in the database for type: horse. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the horse object
*/
export async function getAllHorses(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listHorses,
            variables: {
                limit: 1000
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListHorsesQuery>;

        let items = result.data?.listHorses?.items as Horse[];
        let nextToken = result.data?.listHorses?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listHorses,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListHorsesQuery>;

            const nextItems = nextResult.data?.listHorses?.items as Horse[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listHorses?.nextToken;
        }
        return formatResult(true, "Horse", items, "Successfully got the Horses.");
    } catch (error: any) {
        return formatResult(false, "Horse", error, "Error reading record in the database for type: horses");
    }
}

/**
* Read a specific record in the database for type: horse. 
* 
* @param {string}  id                  The horse id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the horse object
*/
export async function getHorseById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getHorse,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetHorseQuery>;
       const horse = result.data?.getHorse;
       return formatResult(true, "Horse", horse, "Successfully got the horse.");
   } catch (error: any) {
       return formatResult(false, "Horse", error, "Error reading record in the database for type: horse");
   }
}

/**
* Get all records in the database that match the given criteria for type: horse. 
* 
* @param {string}  personId            The person id of the "owner" of the horse
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the horse object
*/
export async function getHorsesByPersonId(personId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.horsesByPersonIdByName,
            variables: {
                limit: 1000,
                personId: personId
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;

        let items = result.data?.horsesByPersonIdByName?.items as Horse[];
        let nextToken = result.data?.horsesByPersonIdByName?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.horsesByPersonIdByName,
                variables: {
                    limit: 100,
                    personId: personId,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.horsesByPersonIdByName?.items as Horse[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.horsesByPersonIdByName?.nextToken;
        }
        return formatResult(true, "Horse", items, "Successfully got the Horses.");
    } catch (error: any) {
        return formatResult(false, "Horse", error, "Error reading record in the database for type: horses");
    }
}

/**
* Get all records in the database that match the given criteria for type: horse. 
* 
* @param {string}  barnId              The barn id of the horse
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the horse object
*/
export async function getHorsesByBarnId(barnId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.horsesByBarnIdByName,
            variables: {
                limit: 1000,
                barnId: barnId
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;

        let items = result.data?.horsesByBarnIdByName?.items as Horse[];
        let nextToken = result.data?.horsesByBarnIdByName?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.horsesByBarnIdByName,
                variables: {
                    limit: 100,
                    barnId: barnId,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.horsesByBarnIdByName?.items as Horse[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.horsesByBarnIdByName?.nextToken;
        }
        return formatResult(true, "Horse", items, "Successfully got the Horses.");
    } catch (error: any) {
        return formatResult(false, "Horse", error, "Error reading record in the database for type: horses");
    }
}