import React, { useState } from 'react';
import { IonRow, IonCol, IonSearchbar} from '@ionic/react';

interface Props {
    onChangeSearchText: Function
}

const SelectClassOrDivisionSection: React.FC<Props> = ({ onChangeSearchText }) => {
    const [searchText, setSearchText] = useState("");

    const handleSearchInput = (input: string) => {
        onChangeSearchText(input);
    }
    return (
        <>
            {/* Section Title */}
            <IonRow>
                <IonCol className="p-0 m-0">
                    <IonSearchbar
                    className="px-0 pb-0"
                        color="white"  
                        placeholder="Search Divisions or Classes by Name or Number" 
                        value={searchText} 
                        onIonChange={e => {
                            setSearchText(e.detail.value!)
                            handleSearchInput(e.detail.value!)
                        }}
                    />
                </IonCol>
            </IonRow>
        </>
    );
};

export default SelectClassOrDivisionSection;
