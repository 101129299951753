import moment from "moment";
import { EventClass, EventDivision, EventEntry } from "../../models";
import { getAcceptedEventClassEntriesByEventClassId } from "../eventClassEntry/EventClassEntry";
import { sortEventClassEntriesByNumber, sortEventClassEntriesByTrainer } from "../eventClassEntry/SortEventClassEntries";
import { getEventEntryById } from "../eventEntry/EventEntry";
import { calculateHeightInHands } from "../horse/Height";

const { jsPDF } = require("jspdf");
require('jspdf-autotable');

export async function generateClassSheetPDF(eventClass: EventClass, eventString: string, isSortedByNumber?: boolean, includeResultsColumns?: boolean) {
    // initialize jsPDF
    const doc = new jsPDF();

    // page title. and margin-top + margin-left
    doc.text(`${eventString}`, 14, 15);
    doc.text(`Class Sheet for #${eventClass.number} - ${eventClass.name}`, 14, 25);

    // define the columns we want and their titles
    let tableColumn = [
        {header: "Back #", dataKey: "number"}, 
        {header: "Horse", dataKey: "horse"}, 
        {header: "Rider", dataKey: "rider"}
    ];

    if (includeResultsColumns) {
        tableColumn.push({header: "Class 1", dataKey: "class1"});
        tableColumn.push({header: "Class 2", dataKey: "class2"});
        tableColumn.push({header: "Class 3", dataKey: "class3"});
    }

    // define an empty array of rows
    const tableRows = [];

    const eventClassEntriesResult = await getAcceptedEventClassEntriesByEventClassId(eventClass.id);
    let eventClassEntries = eventClassEntriesResult.result;

    const sorted = isSortedByNumber ? sortEventClassEntriesByNumber(eventClassEntries) : sortEventClassEntriesByTrainer(eventClassEntries);
    if (sorted) eventClassEntries = sorted;

    if (eventClassEntries && eventClassEntries.length) {
        for (let i = 0; i < eventClassEntries.length; i++) {
            const eventClassEntry = eventClassEntries[i];
            if (eventClassEntry?.eventEntryId) {
                const queryResult = await getEventEntryById(eventClassEntry?.eventEntryId);
                if (queryResult.isSuccess) {
                    const entry: EventEntry = queryResult.result;
                    if (entry) {
                        const entryNo = entry.number || "";
                   
                        const horseName = entry.horseName;
                        const ownerName = entry.ownerName;
                        const ownerLocation = entry.owner?.location || "";
                        const trainerName = entry.trainerName;
                        let horseCol = horseName + "\nOwner: " + ownerName + " - " + ownerLocation + "\nTrainer: " + trainerName;
                        
                        const horseBreed = entry.horse?.breed || "";
                        if (horseBreed) horseCol = horseCol + "\nBreed: " + horseBreed;

                        let riderCol;
                        if (eventClassEntry.riderId && eventClassEntry.riderId === entry.rider?.id) {
                            const riderName = entry.riderName;
                            riderCol = "Rider: " + riderName;
                            let rider = entry.rider;
                            // const riderStatus = rider ? (rider.isProfessional === true ? "(Professional)" : (rider.isProfessional === false ? "(Amateur)" : "")) : "";
                            // if (riderStatus) riderCol = riderCol + "\t" + riderStatus;
                            const riderLocation = rider?.location || "";
                            if (riderLocation) riderCol = riderCol + "\n" + riderLocation;
                            const riderBirthdate = rider?.birthdate || "";
                            if (riderBirthdate) riderCol = riderCol + "\nBirthdate: " + riderBirthdate + "\t Age: " + moment().diff(riderBirthdate, 'years');
                        } else if (entry.secondRider && eventClassEntry.riderId && eventClassEntry.riderId === entry.secondRiderId) {
                            const rider2Name = entry.secondRiderName;
                            riderCol = "Rider: " + rider2Name;
                            let rider = entry.secondRider;
                            // const riderStatus = rider ? (rider.isProfessional === true ? "(Professional)" : (rider.isProfessional === false ? "(Amateur)" : "")) : "";
                            // if (riderStatus) riderCol = riderCol + "\t" + riderStatus;
                            const riderLocation = rider?.location || "";
                            if (riderLocation) riderCol = riderCol + "\n" + riderLocation;
                            const riderBirthdate = rider?.birthdate || "";
                            if (riderBirthdate) riderCol = riderCol + "\nBirthdate: " + riderBirthdate + "\t Age: " + moment().diff(riderBirthdate, 'years');
                        }
                        
                        let currentData = [
                            entryNo,
                            horseCol,
                            riderCol
                        ];

                        if (includeResultsColumns) {
                            currentData.push("");
                            currentData.push("");
                            currentData.push("");
                        }

                        tableRows.push(currentData);
                    }
                }
            }
        }
    }

    if (eventClassEntries) {
        // Display total entry count above the table
        doc.setFontSize(12);
        doc.text(`Total number of entries: ${eventClassEntries.length}`, 14, 35);
    }
    
    // startY is basically margin-top
    doc.autoTable(tableColumn, tableRows, { 
        theme: "grid",
        headStyles: {fillColor: "#73a4d3"},
        startY: 40,
        rowPageBreak: "avoid",
        columnStyles: {
            number: { cellWidth: 'auto' }, 
            horse: { cellWidth: 'auto' },
            rider: { cellWidth: 'auto' },
            class1: { cellWidth: 'auto' },
            class2: { cellWidth: 'auto' },
            class3: { cellWidth: 'auto' },
        } 
    });

    // page footer
    doc.setFontSize(10);
    doc.text("Created using RingSide Pro: www.ringsidepro.com", 14, doc.internal.pageSize.height - 10);

    // we define the name of our PDF file.
    doc.save(`Class_Sheet_${eventClass.number}_${eventClass.name}.pdf`);
}

export async function generateClassSheetGroupPDF(eventClasses: EventClass[], eventString: string, fontSize?: number, sortBy?: ("backNumber" | "trainerName" | "randomOrder"), isLandscape?: boolean, includeResultsColumns?: boolean, includeRiderStatusInfo?: boolean, includeHorseBreedInfo?: boolean, includeHorseHeightInfo?: boolean, includeTrainerInfo?: boolean, includeOwnerInfo?: boolean, callbackFunction?: Function) {
    // initialize jsPDF
    const doc = new jsPDF(isLandscape ? "l" : "p");

    if (eventClasses && eventClasses.length > 0) {
        for (let i = 0; i < eventClasses.length; i++) {
            const message = i + " of " + (eventClasses.length ? eventClasses.length : "0");
            if (callbackFunction) callbackFunction(message);
            const eventClass: EventClass = eventClasses[i];
            const eventDivison: (EventDivision | undefined | null) = eventClass.eventDivision;
            const divisionsClassesObject: any = eventClass.eventDivision?.eventClasses;
            const divisionsClasses: any[] = divisionsClassesObject?.items; 
            const divisionsNumberOfClasses = divisionsClasses?.length || 0;
            const numberOfResultsColumns = includeResultsColumns ? (divisionsNumberOfClasses ? (divisionsNumberOfClasses > 1 ? (divisionsNumberOfClasses + 1) : 1) : 1) : 0;

            doc.setFontSize(fontSize || 10);

            // Track the current y index
            let currentYIndex = 15;

            // Print the event name
            doc.text(`${eventString}`, 14, currentYIndex);
            currentYIndex = currentYIndex + ((fontSize || 20)*0.5);

            if (eventDivison) {
                // Print the division info
                const eventDivisionNumber = eventDivison.number || eventDivison.organizationDivision?.number || null;
                const eventDivisionLabel = "Division" + (eventDivisionNumber ? " #" + eventDivisionNumber + " - " : ": ") + eventDivison.name;
                doc.text(`${eventDivisionLabel}`, 14, currentYIndex);
                currentYIndex = currentYIndex + ((fontSize || 20)*0.5);
            }

            // Print the class info
            doc.text(`Class Sheet for #${eventClass.number} - ${eventClass.name}`, 14, currentYIndex);
            currentYIndex = currentYIndex + ((fontSize || 20)*0.5);

            // Define the columns we want and their titles
            const tableColumn = [
                {header: "Back #", dataKey: "number"}, 
                {header: "Horse", dataKey: "horse"}, 
                {header: "Rider", dataKey: "rider"}
            ];

            if (includeResultsColumns) {
                if (divisionsClasses && divisionsClasses.length > 0) {
                    const sortedClasses = divisionsClasses.sort((a, b) => a.number - b.number);
                    for (let i = 0; i < sortedClasses.length; i++) {
                        const eventClass = sortedClasses[i];
                        let columnTitle = "Results: \nClass " + eventClass.number;
                        let dataKey = "class" + i;
                        // Check if this is the single results column for a class without a division
                        if (divisionsClasses.length === 1) {
                            columnTitle = "Results";
                            dataKey = "results";
                        }
                        tableColumn.push({header: columnTitle, dataKey: dataKey});
                    }
                    if (divisionsClasses.length > 1) {
                        // Add an additional column to write the total of the previous columns
                        const columnTitle = "Total";
                        const dataKey = "total";
                        tableColumn.push({header: columnTitle, dataKey: dataKey});
                    }
                } else if (numberOfResultsColumns === 1) {
                    // Add a singular results column if the class does not have a division
                    const columnTitle = "Results";
                    const dataKey = "results";
                    tableColumn.push({header: columnTitle, dataKey: dataKey});
                }
            }

            // define an empty array of rows
            const tableRows = [];

            const eventClassEntriesResult = await getAcceptedEventClassEntriesByEventClassId(eventClass.id);
            let eventClassEntries = eventClassEntriesResult.result;

            const sorted = sortBy === "backNumber" ? sortEventClassEntriesByNumber(eventClassEntries) : (sortBy === "trainerName" ? sortEventClassEntriesByTrainer(eventClassEntries) : eventClassEntries.sort(() => Math.random() - 0.5));
            if (sorted) eventClassEntries = sorted;

            if (eventClassEntries && eventClassEntries.length) {
                for (let i = 0; i < eventClassEntries.length; i++) {
                    const eventClassEntry = eventClassEntries[i];
                    if (eventClassEntry?.eventEntryId) {
                        const queryResult = await getEventEntryById(eventClassEntry?.eventEntryId);
                        if (queryResult.isSuccess) {
                            const entry: EventEntry = queryResult.result;
                            if (entry) {
                                const entryNo = entry.number || "";
                        
                                const horseName = entry.horseName;
                                const horseBreed = entry.horse?.breed || "not found";
                                const horseHeight = entry.horse?.height ? calculateHeightInHands(parseInt(entry.horse.height)) : "not found";
                                const ownerName = entry.ownerName;
                                const ownerLocation = entry.owner?.location || "";
                                const trainerName = entry.trainerName;
                                let horseCol = horseName;
                                
                                if (includeHorseHeightInfo) horseCol = horseCol + "\nHorse Height: " + horseHeight;
                                if (horseBreed && includeHorseBreedInfo) horseCol = horseCol + "\nBreed: " + horseBreed;
                                if (includeOwnerInfo) {
                                    horseCol = horseCol + "\nOwner: " + ownerName + " - " + ownerLocation;
                                }
                                if (includeTrainerInfo) {
                                    horseCol = horseCol + "\nTrainer: " + trainerName;
                                }

                                let riderCol;
                                if (eventClassEntry.riderId && eventClassEntry.riderId === entry.rider?.id) {
                                    const riderName = entry.riderName;
                                    riderCol = "Rider: " + riderName;
                                    let rider = entry.rider;
                                    // const riderStatus = rider ? (rider.isProfessional === true ? "(Professional)" : (rider.isProfessional === false ? "(Amateur)" : "")) : "";
                                    // if (riderStatus) riderCol = riderCol + "\t" + riderStatus;
                                    const riderLocation = rider?.location || "";
                                    if (riderLocation) riderCol = riderCol + "\n" + riderLocation;
                                    const riderBirthdate = rider?.birthdate || "";
                                    if (riderBirthdate && includeRiderStatusInfo) riderCol = riderCol + "\nBirthdate: " + riderBirthdate + "\n Age: " + moment().diff(riderBirthdate, 'years');
                                } else if (entry.secondRider && eventClassEntry.riderId && eventClassEntry.riderId === entry.secondRiderId) {
                                    const rider2Name = entry.secondRiderName;
                                    riderCol = "Rider: " + rider2Name;
                                    let rider = entry.secondRider;
                                    // const riderStatus = rider ? (rider.isProfessional === true ? "(Professional)" : (rider.isProfessional === false ? "(Amateur)" : "")) : "";
                                    // if (riderStatus) riderCol = riderCol + "\t" + riderStatus;
                                    const riderLocation = rider?.location || "";
                                    if (riderLocation) riderCol = riderCol + "\n" + riderLocation;
                                    const riderBirthdate = rider?.birthdate || "";
                                    if (riderBirthdate && includeRiderStatusInfo) riderCol = riderCol + "\nBirthdate: " + riderBirthdate + "\n Age: " + moment().diff(riderBirthdate, 'years');
                                }
                                
                                let currentData = [
                                    entryNo,
                                    horseCol,
                                    riderCol
                                ];

                                if (includeResultsColumns) {
                                    if (numberOfResultsColumns > 0) {
                                        for (let i = 1; i <= numberOfResultsColumns; i++) {
                                            currentData.push("");
                                        }
                                    }
                                }
                                tableRows.push(currentData);
                            }
                        }
                    }
                }
            }

            if (eventClassEntries) {
                // Display total entry count above the table
                doc.setFontSize(fontSize || 10);
                doc.text(`Total number of entries: ${eventClassEntries.length}`, 14, currentYIndex);
                currentYIndex = currentYIndex + ((fontSize || 20)*0.5);
            }
            
            // startY is basically margin-top
            doc.autoTable(tableColumn, tableRows, { 
                theme: "grid",
                headStyles: {fillColor: "#73a4d3"},
                startY: currentYIndex,
                rowPageBreak: "avoid",
                columnStyles: {
                    number: { cellWidth: 'auto' }, 
                    horse: { cellWidth: 'auto' },
                    rider: { cellWidth: 'auto' },
                    class1: { cellWidth: 'auto' },
                    class2: { cellWidth: 'auto' },
                    class3: { cellWidth: 'auto' },
                },
                bodyStyles: {
                    fontSize: fontSize || 10
                },   
            });

            // page footer
            doc.setFontSize(10);
            doc.text("Created using RingSide Pro: www.ringsidepro.com", 14, doc.internal.pageSize.height - 10);

            doc.addPage();
        }
    }

    // we define the name of our PDF file.
    doc.save(`Class_Sheets.pdf`);
}