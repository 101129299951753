import React, { useState } from "react";
import {
    IonModal,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonIcon,
    IonRow,
    IonCol,
    IonContent,
} from "@ionic/react";
import { components } from "react-select";
import { close } from "ionicons/icons";
import Select from "react-select";
import Spinner from "../Spinners/Spinner";
import { formattedOption } from "./SelectOrCreateHorse";
import ErrorAlert from "../Errors/ErrorAlert";

interface VerifyHorseModalProps {
    isLoadingAllHorses: boolean;
    currentValue: any; // Define a more specific type if available
    formattedMatchedHorses: formattedOption[] | null | undefined; // Define a more specific type if available
    currentValueFromMatchedHorses: any; // Define a more specific type if available
    handleSelectFromMatchedHorses: (event: any) => void;
    handleCancel: () => void;
    handleUseMatchedHorse: (e: formattedOption) => void;
    handleCreateNewHorse: () => void;
    isHeightRequired?: boolean;
    height?: string;
}

/**
 * This function is passed into the select component to define what should be shown when an option is selected
 * Instead of showing the 3 lines from the option,
 * it will only show the 1 line defined below: horseName
 * @param props
 * @returns
 */
const SingleValue = (props: any) => (
    <components.SingleValue {...props}>{props.data.horseName || props.data.label}</components.SingleValue>
);

const VerifyHorseModal: React.FC<VerifyHorseModalProps> = ({
    isLoadingAllHorses,
    currentValue,
    formattedMatchedHorses,
    handleSelectFromMatchedHorses,
    handleCancel,
    handleUseMatchedHorse,
    handleCreateNewHorse,
    isHeightRequired = false,
    height = "auto",
}) => {
    const [selectedOptionFromMatchedHorses, setSelectedOptionFromMatchedHorses] = useState<
        formattedOption | null | undefined
    >();
    const [error, setError] = useState("");

    const handleSelection = (event: any) => {
        setSelectedOptionFromMatchedHorses(event);
        handleSelectFromMatchedHorses(event);
    };

    /**
     * User found the horse by name in the RSP Database
     * Send that horse info back to the parent component
     * And close the modal
     */
    const handleClickUseMatchedHorse = () => {
        if (selectedOptionFromMatchedHorses) handleUseMatchedHorse(selectedOptionFromMatchedHorses);
        else setError("No option was selected.");
    }

    return (
        <IonModal backdropDismiss={false} isOpen={true} id='termsAndConditionsModal'>
            {/* Modal Header */}
            <IonToolbar color='light'>
                <IonTitle className='ion-text-center'>Verify Horse</IonTitle>
                <IonButtons slot='end'>
                    <IonButton fill='clear' onClick={handleCancel}>
                        <p id='closeAddressModalBtn' className='font-weight-normal text-medium text-capitalize'>
                            <IonIcon icon={close} />
                        </p>
                    </IonButton>
                </IonButtons>
            </IonToolbar>
            {isLoadingAllHorses ? (
                <IonRow className='ion-justify-content-center'>
                    <IonCol className='text-center'>
                        <Spinner />
                    </IonCol>
                </IonRow>
            ) : (
                <IonContent className='ion-padding'>
                    {error && <ErrorAlert width="12" error={error}/>}
                    <IonRow>
                        <IonCol>
                            <p>Your Horse Name: {currentValue.horseName}</p>
                            <hr />
                        </IonCol>
                    </IonRow>
                    {formattedMatchedHorses && formattedMatchedHorses.length > 0 ? (
                        <>
                            <IonRow>
                                <IonCol>
                                    <p>We found horses in the RingSide Pro database that match this horse name.</p>
                                    <p>Please check if any of these are the correct horse.</p>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <Select
                                        id='select-from-matched-horses'
                                        inputId='select-from-matched-horses-input'
                                        placeholder='Select a Horse'
                                        styles={{
                                            control: (baseStyles) =>
                                                isHeightRequired ? { ...baseStyles, height } : { ...baseStyles },
                                            menu: (provided) => ({ ...provided, zIndex: 9999 }),
                                        }}
                                        defaultValue={selectedOptionFromMatchedHorses}
                                        value={selectedOptionFromMatchedHorses}
                                        menuPortalTarget={document.body}
                                        menuPlacement='auto'
                                        isClearable
                                        options={formattedMatchedHorses}
                                        formatOptionLabel={(option: any) =>
                                            option.horseNameLabel ? (
                                                <div className='p-0'>
                                                    <div className='font-weight-bold text-default'>
                                                        {option.horseNameLabel}
                                                    </div>
                                                    <div className='display-5 text-medium'>
                                                        Barn: {option.barnLabel}
                                                    </div>
                                                    <div className='display-5 text-medium'>
                                                        Owner: {option.ownerLabel}
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className='p-0'>
                                                    <div className='font-weight-bold text-default'>{option.value}</div>
                                                </div>
                                            )
                                        }
                                        components={{ SingleValue }}
                                        onChange={(event: any) => handleSelection(event)}
                                    />
                                </IonCol>
                            </IonRow>
                        </>
                    ) : (
                        <>
                            <IonRow>
                                <IonCol>
                                    <p>
                                        There are no matches for this horse name in the RingSide Pro database. Do you
                                        want to create a new horse?
                                    </p>
                                </IonCol>
                            </IonRow>
                        </>
                    )}
                    <IonRow className="my-3">
                        <IonCol size='5' className='text-center'>
                            <IonButton color='light' expand="block" onClick={handleCancel}>
                                <span className="ion-text-wrap font-weight-bold">Cancel</span>
                            </IonButton>
                        </IonCol>
                        <IonCol size='7' className='text-center'>
                            {selectedOptionFromMatchedHorses ? (
                                <IonButton color='success' expand="block" onClick={handleClickUseMatchedHorse}>
                                    <span className="ion-text-wrap font-weight-bold">Use Matched Horse</span>
                                </IonButton>
                            ) : (
                                <IonButton color='tertiary' expand="block" onClick={handleCreateNewHorse}>
                                    <span className="ion-text-wrap font-weight-bold">Create New Horse</span>
                                </IonButton>
                            )}
                        </IonCol>
                    </IonRow>
                </IonContent>
            )}
        </IonModal>
    );
};
export default VerifyHorseModal;
