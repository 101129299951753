import React, { useContext, useState } from 'react';
import { IonRow, IonCol, IonCard, IonCardHeader, IonCardTitle, IonCardContent } from '@ionic/react';
import { Barn, Horse, Event, Trainer } from '../../../models';
import SelectHorseSection from './SelectHorseSection';
import CogginsSection from './CogginsSection';
import SelectOwnerSection from './SelectOwnerSection';
import SelectRiderSection from './SelectRiderSection';
import SelectTrainerSection from './SelectTrainerSection';
import { PersonContext } from '../../../context/PersonContext';

interface Props {
    event: Event;
    selectedBarn?: Barn | null | undefined;
    allHorses?: Horse[]
    onSelect: Function;
}

interface CardData {
    title: 'Horse' | 'Coggins' | 'Owner' | 'Rider' | 'Trainer' | 'Primary Contact';
    content: JSX.Element;
}

const EntryInfoSection: React.FC<Props> = ({ event, selectedBarn, allHorses, onSelect }) => {
    const user = useContext(PersonContext)
    const [selectedHorse, setSelectedHorse] = useState<Horse | null | undefined>();
    const [selectedTrainer, setSelectedTrainer] = useState<Trainer | undefined>();

    const handleSelectedHorse = (horse?: Horse) => {
        setSelectedHorse(horse);
        onSelect(selectedHorse)
    };

    const handleSelectedTrainer = (trainer?: Trainer) => {
        setSelectedTrainer(trainer);
    };

    // Define each card's title and JSX content
    const cardsData: CardData[] = [
        { 
            title: 'Horse', 
            content: (
                <SelectHorseSection 
                    userId={user.id}
                    barn={selectedBarn} 
                    allHorses={allHorses}
                    selectedHorse={selectedHorse}
                    onSelectHorse={handleSelectedHorse}
                />  
            )
        },
        { 
            title: 'Coggins', 
            content: (
                <CogginsSection 
                    event={event}
                    selectedHorse={selectedHorse}
                />
            )
        },
        { 
            title: 'Owner', 
            content: (
                <SelectOwnerSection 
                    barn={selectedBarn} 
                    selectedHorse={selectedHorse}
                />            
            )
        },
        { 
            title: 'Rider', 
            content: (
                <SelectRiderSection 
                    selectedBarn={selectedBarn}
                />
            )
        },
        { 
            title: 'Trainer', 
            content: (
                <SelectTrainerSection 
                    selectedBarn={selectedBarn}
                    onSelectTrainer={handleSelectedTrainer}
                />
            )
        },
        { 
            title: 'Primary Contact', 
            content: (
                <p>Provide primary contact details...</p>
            )
        },
    ];

    return (
        <>
            {/* Section Title */}
            <IonRow>
                <IonCol className="p-0 m-0">
                    <h1 className="font-weight-bold text-info">Enter Entry Info</h1>
                </IonCol>
            </IonRow>

            {/* Entry Info Sections */}
            {cardsData.map((card) => (
                <IonCard key={card.title} className="rounded-3 p-0 mx-0 bg-white" style={{overflow: "visible"}}>
                    
                    {/* Card Header */}
                    <IonCardHeader className="px-2 py-0">
                        <IonRow className="ion-align-items-center">
                            <IonCol sizeXs="12" className="px-0 pb-0">
                                <IonCardTitle className="font-weight-bold text-primary m-1">
                                    {card.title}
                                </IonCardTitle>
                            </IonCol>
                        </IonRow>
                    </IonCardHeader>
                    
                    {/* Card Content */}
                    <IonCardContent>
                        {card.content}
                    </IonCardContent>
                </IonCard>
            ))}
        </>
    );
};

export default EntryInfoSection;
