import { 
    IonButton,
    IonButtons,
    IonCheckbox,
    IonCol, 
    IonContent, 
    IonIcon, 
    IonInput, 
    IonItem, 
    IonLabel, 
    IonModal, 
    IonRow, 
    IonTitle, 
    IonToolbar
} from "@ionic/react";
import React, { useState } from "react";
import { Barn, Event, Trainer } from "../../../models";
import EventReportCard from "../EventReportCard";
import { close, downloadOutline, printOutline } from "ionicons/icons";
import InformationBanner from "../../Banners/InformationBanner";
import EntryInvoiceReportForm from "../EntryInvoiceReport/EntryInvoiceReportForm";
import constants from "../../../constant/constant";
import { InvoicePDFSettings, generateEntryInvoicesPDF } from "../../../utilities/reports/InvoicePDF";
import { generateEntryTotalsPDF } from "../../../utilities/reports/EntryTotalsReport";
import { generateEntriesAddScratchReport } from "../../../utilities/reports/EntryReport";
import { generateAllEntriesReport } from "../../../utilities/reports/AllEntriesReport";
import { generateCogginsEquineEventReport } from "../../../utilities/reports/CogginsReport";
import { generateTIPHorseNumberReportCSV } from "../../../utilities/reports/TIPHorseNumberReport";
import { generateCrossEntryPDF } from "../../../utilities/reports/CrossEntryReport";
import SelectFromEventTrainers from "../../Trainer/SelectFromEventTrainers";
import SelectFromEventBarns from "../../Barn/SelectFromEventBarns";
import { generateMemberOneTimePassReport } from "../../../utilities/reports/MembersReport";

interface _Props {
    event: Event
}

const EventReportEntrySection: React.FC<_Props> = ({event}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [progressMessage, setProgressMessage] = useState("");
    const [error, setError] = useState("");

    const [includeClassNames, setIncludeClassNames] = useState(false);
    const [includeClassDates, setIncludeClassDates] = useState(false);

    const [isLandscape, setIsLandscape] = useState(false);
    const [showEntryInvoicesModal, setShowEntryInvoicesModal] = useState(false);
    const [showEntryAddScratchModal, setShowEntryAddScratchModal] = useState(false);
    const [entryReportSelectedOption, setEntryReportSelectedOption] = useState<"all" | "trainer" | "barn">("all");
    const [selectedTrainer, setSelectedTrainer] = useState<Trainer | null | undefined>();
    const [selectedBarn, setSelectedBarn] = useState<Barn | null | undefined>();

    const generateEntriesByTrainerReport = async (isDownload: boolean) => {
        if (event) {
            setShowEntryAddScratchModal(false);
            setIsLoading(true); 
            await generateEntriesAddScratchReport(event, setProgressMessage, isDownload, isLandscape, !!event.entryOptions?.displayRider2Input, selectedTrainer, selectedBarn, includeClassNames, includeClassDates);
            setSelectedTrainer(undefined);
            setSelectedBarn(undefined);
            setProgressMessage("");
            setIsLoading(false);
        }
        else setError("No event found. Cannot generate report.");
    }

    const generateAllEventEntriesReport = async () => {
        if (event) {
            setIsLoading(true); 
            await generateAllEntriesReport(event);
            setIsLoading(false);
        }
        else setError("No event found. Cannot generate report.");
    }
    
    const generateCogginsEventReport = async () => {
        if (event) {
            setIsLoading(true); 
            await generateCogginsEquineEventReport(event);
            setIsLoading(false);
        }
        else setError("No event found. Cannot generate report.");
    }

    const generateHorseTIPNumberReport = async () => {
        if (event) {
            setIsLoading(true); 
            await generateTIPHorseNumberReportCSV(event);
            setIsLoading(false);
        }
        else setError("No event found. Cannot generate report.");
    }

    const generateEntryBillTotalsReport = async () => {
        if (event) {
            setIsLoading(true); 
            await generateEntryTotalsPDF(event, setProgressMessage);
            setIsLoading(false);
        }
        else setError("No event found. Cannot generate report.");
    }

    const generateEntryInvoicesReport = async (settingsObject: InvoicePDFSettings) => {
        if (event) {
            setShowEntryInvoicesModal(false);
            setIsLoading(true); 
            await generateEntryInvoicesPDF(event, settingsObject, setProgressMessage);
            setProgressMessage("");
            setIsLoading(false);
        }
        else setError("No event found. Cannot generate report.");
    }

    const generateEntryCrossoverReport = async () => {
        if (event) {
            setIsLoading(true); 
            await generateCrossEntryPDF(event, setProgressMessage);
            setProgressMessage("");
            setIsLoading(false);
        }
        else setError("No event found. Cannot generate report.");
    }

    const generateOneTimePassReport = async () => {
        if (event.organizationId) {
            setIsLoading(true); 
            await generateMemberOneTimePassReport(event.organizationId, setProgressMessage);
            setProgressMessage("");
            setIsLoading(false);
        }
        else setError("No event found. Cannot generate report.");
    }


    const reportLinks = (
        <IonRow>
            <IonCol>
                <IonRow>
                    <IonCol>
                        <p onClick={generateAllEventEntriesReport} className="link ion-text-wrap">List of All Entries (CSV)</p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p onClick={() => setShowEntryAddScratchModal(true)} className="link ion-text-wrap">Entries By Trainer / Barn (with Add & Scratch Lines)</p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p onClick={generateCogginsEventReport} className="link ion-text-wrap">Coggins Equine Event Report</p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p onClick={generateHorseTIPNumberReport} className="link ion-text-wrap">Horse TIP Number Report</p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p onClick={generateEntryBillTotalsReport} className="link ion-text-wrap">All Entries - Bill Totals</p>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <p onClick={() => setShowEntryInvoicesModal(true)} className="link ion-text-wrap">Entry Invoices Report</p>
                    </IonCol>
                </IonRow>
                {(event.organizationId === constants.RHSC_ORGANIZATION.id || event.organizationId === constants.MOHSS_ORGANIZATION.id) && (
                    <>
                        <IonRow>
                            <IonCol>
                                <p onClick={() => generateEntryCrossoverReport()} className="link ion-text-wrap">Cross Entry Report</p>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <p onClick={() => generateOneTimePassReport()} className="link ion-text-wrap">Member One Time Pass Report</p>
                            </IonCol>
                        </IonRow>
                    </>
                )}
            </IonCol>
        </IonRow>
    )

    return (
        <>
            <EventReportCard title="Entry Reports" content={reportLinks} isLoading={isLoading} progressMessage={progressMessage} error={error} />
            <IonModal backdropDismiss={false} isOpen={showEntryAddScratchModal} id="entryAddScratchReportModal">
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">
                        Select Trainer or Barn
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            fill="clear"
                            onClick={() => setShowEntryAddScratchModal(false)}
                        >
                            <p id="closeModalBtn" className="font-weight-normal text-medium text-capitalize">
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    <IonRow>
                        <IonCol sizeXs="12" sizeMd="4" className="ion-text-center">
                            <IonButton color={entryReportSelectedOption === "all" ? "primary" : "light"} onClick={() => setEntryReportSelectedOption("all")}>Select All</IonButton>
                        </IonCol>
                        <IonCol sizeXs="12" sizeMd="4" className="ion-text-center">
                            <IonButton color={entryReportSelectedOption === "trainer" ? "primary" : "light"} onClick={() => setEntryReportSelectedOption("trainer")}>Select Trainer</IonButton>
                        </IonCol>
                        <IonCol sizeXs="12" sizeMd="4" className="ion-text-center">
                            <IonButton color={entryReportSelectedOption === "barn" ? "primary" : "light"} onClick={() => setEntryReportSelectedOption("barn")}>Select Barn</IonButton>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <p>Generate a report of the classes for each entry and place to write in adds and scratches.</p>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center pt-3">
                        <IonCol className="ion-text-center">
                            {entryReportSelectedOption === "all" && (
                                <p>This will download a report with all entry data sorted into sections by trainer name.</p>
                            )}
                            {(entryReportSelectedOption === "trainer" && event) && (
                                <>
                                    <p>Select the Trainer</p>
                                    <SelectFromEventTrainers event={event} onSelect={(trainer: Trainer) => setSelectedTrainer(trainer)} />
                                </>
                            )}
                            {(entryReportSelectedOption === "barn" && event) && (
                                <>
                                    <p>Select the Barn</p>
                                    <SelectFromEventBarns event={event} onSelect={(barn: Barn) => setSelectedBarn(barn)} />
                                </>
                            )}
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center pt-3">
                        <IonCol className="ion-text-center">
                            <p>Settings:</p>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol className="ion-text-center">
                            <IonItem>
                                <IonLabel><p className="ion-text-wrap">Show Class Names</p></IonLabel>
                                <IonCheckbox slot="start" value="sort" checked={includeClassNames} onIonChange={e => setIncludeClassNames(e.detail.checked)} />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol className="ion-text-center">
                            <IonItem>
                                <IonLabel><p className="ion-text-wrap">Show Class Dates</p></IonLabel>
                                <IonCheckbox slot="start" value="sort" checked={includeClassDates} onIonChange={e => setIncludeClassDates(e.detail.checked)} />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol className="ion-text-center">
                            <IonItem>
                                <IonLabel><p className="ion-text-wrap">Make orientation landscape</p></IonLabel>
                                <IonCheckbox slot="start" value="landscape" checked={isLandscape} onIonChange={e => setIsLandscape(e.detail.checked)} />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol size="6" className="ion-text-center">
                            <IonButton color="success" onClick={() => generateEntriesByTrainerReport(true)}><IonIcon icon={downloadOutline}/>Download</IonButton>
                        </IonCol>
                        <IonCol size="6" className="ion-text-center">
                            <IonButton color="success" onClick={() => generateEntriesByTrainerReport(false)}><IonIcon icon={printOutline}/>Print</IonButton>
                        </IonCol>
                    </IonRow>
                    <InformationBanner info="If using the Print button, be sure that pop-ups are allowed."/>
                </IonContent>
            </IonModal>
            <IonModal backdropDismiss={false} isOpen={showEntryInvoicesModal} id="entryInvoicesReportModal">
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">
                        Select Entry Invoices
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            fill="clear"
                            onClick={() => setShowEntryInvoicesModal(false)}
                        >
                            <p id="closeModalBtn" className="font-weight-normal text-medium text-capitalize">
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    <EntryInvoiceReportForm event={event} onSubmit={(settingsObject: InvoicePDFSettings) => generateEntryInvoicesReport(settingsObject)} />
                </IonContent>
            </IonModal>
        </>
    );
};

export default EventReportEntrySection;
