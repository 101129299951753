import React, { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import { components } from "react-select";
import { Barn, Horse } from "../../models";
import { getAllHorses, getHorsesByBarnId, getHorsesByPersonId } from "../../utilities/horse/Horse";
import Spinner from "../Spinners/Spinner";
import { IonCol } from "@ionic/react";
import ErrorAlert from "../Errors/ErrorAlert";
import { getPersonNameByPersonId } from "../../utilities/person/PersonName";
import VerifyHorseModal from "./VerifyHorseModal";
const { v4: uuidv4 } = require("uuid");

interface SelectOrCreateHorseProps {
    userId: string;
    allHorsesinDB?: Horse[];
    selectedBarn?: Barn | null;
    selectedValue?: string | null;
    onSelect: (horse?: Horse, horseName?: string) => void; //Take Horse and then Horse Name as props
    isHeightRequired?: boolean;
    height?: string;
}

export interface formattedOption {
    value: string; //Horse's id
    label: string; //Used for filtering options as a user types
    horseName: string; //Horse's Show Name - will display when item is selected
    horseNameLabel?: string; //Horse's Show Name + Nickname - will display in options
    barnLabel?: string; //Barn Name - will display in options
    ownerLabel?: string; //Date the horse was created - will display in options
    createdByLabel?: string;
    horse?: Horse;
}

interface formattedGroupOfOptions {
    label: string;
    options: formattedOption[];
}

/**
 * This function is passed into the select component to define what should be shown when an option is selected
 * Instead of showing the 3 lines from the option,
 * it will only show the 1 line defined below: horseName
 * @param props
 * @returns
 */
const SingleValue = (props: any) => <components.SingleValue {...props}>{props.data.horseName || props.data.label}</components.SingleValue>;

const SelectOrCreateHorse: React.FC<SelectOrCreateHorseProps> = ({ userId, allHorsesinDB, selectedBarn, selectedValue, onSelect, isHeightRequired, height }) => {
    // Dropdown Section Titles
    const allHorsesGroupLabel = "All Horses in RingSide Pro database";
    const createdByUserGroupLabel = "Horses created by you";
    const barnHorsesGroupLabel = "Barn Horses";

    const [allHorses, setAllHorses] = useState<Horse[]>([]);

    // We group the options into: 1-Currently Selected Barn's horses or 2-All Horses
    const [formattedHorses, setFormattedHorses] = useState<formattedOption[] | null | undefined>();
    const [formattedBarnHorses, setFormattedBarnHorses] = useState<formattedOption[] | null | undefined>();
    const [formattedHorsesCreatedByUser, setFormattedHorsesCreatedByUser] = useState<formattedOption[] | null | undefined>();

    // We then put the formatted options into defined Groups
    const [allHorsesGroup, setAllHorsesGroup] = useState<formattedGroupOfOptions | null | undefined>();
    const [barnHorsesGroup, setBarnHorsesGroup] = useState<formattedGroupOfOptions>({ label: barnHorsesGroupLabel, options: [] });
    const [horsesCreatedByUserGroup, setHorsesCreatedByUserGroup] = useState<formattedGroupOfOptions>({
        label: createdByUserGroupLabel,
        options: [],
    });

    const [currentValue, setCurrentValue] = useState<formattedOption | null | undefined>();
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingAllHorses, setIsLoadingAllHorses] = useState(false);
    const [error, setError] = useState("");

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentValueFromMatchedHorses, setCurrentValueFromMatchedHorses] = useState<formattedOption | null | undefined>();
    const [formattedMatchedHorses, setFormattedMatchedHorses] = useState<formattedOption[] | null | undefined>();

    /**
     * When the component loads, query and group horses in this order: 
     * 1 - HorsesCreatedByUser, 2 - BarnHorses, 3 - AllHorses
     */
    const getData = async () => {
        // Wrap both function calls in the same setIsLoading in order to ensure the loading spinner displays until the data has all loaded
        setIsLoading(true);

        console.log("Getting data for Select or Create Horse - allHorsesData = ", allHorsesinDB);

        // First, get horses by User
        if (userId) {
            await getHorsesCreatedByUser();
        }
        // Next, get horses by Barn
        if (selectedBarn != null && selectedBarn != undefined) {
            await getBarnHorses();
        }
        // End the Loading Spinner, all initial data is loaded
        setIsLoading(false);

        // In the background, set all horses in the DB from props or via query
        await getHorses();
    };

    useEffect(() => {
       getData()
    }, []);

    /**
     * When the selectedBarn changes, re-query barnHorses
     */
    useEffect(() => {
        if (selectedBarn) {
           getBarnHorses();
        }
    }, [selectedBarn]);

    /**
     * When horsesCreatedByUser are formatted, remove barn horses from the list
     */
    useEffect(() => {
        if (selectedBarn) {
            removeBarnHorsesFromCreatedByGroup(formattedHorsesCreatedByUser!, formattedBarnHorses!);
        } else {
            updateCreatedByGroup(formattedHorsesCreatedByUser!);
        }
    }, [formattedHorsesCreatedByUser]);

    const removeBarnHorsesFromCreatedByGroup = (createdByList: formattedOption[], barnHorsesList: formattedOption[]) => {
        // Remove all of the barnHorses from the CreatedBy list
        const filteredList = createdByList.filter(
            (createdByHorse) => !barnHorsesList.some((barnHorse) => barnHorse.value === createdByHorse.value),
        );
        // Update the HorsesCreatedByGroup with filteredList that eliminates barn horses
        updateCreatedByGroup(filteredList);
    };

    const updateCreatedByGroup = (createdByList: formattedOption[]) => {
        setHorsesCreatedByUserGroup({
            label: createdByUserGroupLabel,
            options: createdByList,
        });
    };

    // QUERIES
    const getHorses = async () => {
        setIsLoadingAllHorses(true);
        try {
            // Get the current list of horses
            let currentHorseList: Horse[] = allHorsesinDB || [];
            if (!currentHorseList || currentHorseList.length === 0) {
                const queryResult = await getAllHorses();
                if (queryResult.isSuccess) {
                    currentHorseList = queryResult.result;
                }
            }
            setAllHorses(currentHorseList)

            // Sort and format the list
            const currentFormattedHorses = await formatHorses(currentHorseList);

            // Set the formatted options and group in state
            setFormattedHorses(currentFormattedHorses);

            // If barn horses are already there, remove them from the full list and set All Horses Group
            if (formattedBarnHorses && formattedHorses) {
                removeBarnHorsesFromAllHorsesGroup(formattedBarnHorses);
            } else {
                const allGroup: formattedGroupOfOptions = {
                    label: allHorsesGroupLabel,
                    options: currentFormattedHorses,
                };
                setAllHorsesGroup(allGroup);
            }

            // If the parent component has passed in an already selected value, set it from the current options
            if (selectedValue) findValueInList(selectedValue, currentFormattedHorses);
        } catch (error) {
            console.error("Error fetching horses created by user:", error);
        } finally {
            setIsLoadingAllHorses(false);
        }
    };

    const removeBarnHorsesFromAllHorsesGroup = async (barnHorses: formattedOption[]) => {
        if (allHorsesGroup && formattedHorses) {
            const updatedFormattedHorses = formattedHorses.filter((el) => !barnHorses.find((bh) => bh.value === el.value));
            const allGroup: formattedGroupOfOptions = {
                label: allHorsesGroupLabel,
                options: updatedFormattedHorses,
            };
            setAllHorsesGroup(allGroup);
        }
    };

    const findValueInList = (value: string, horseList?: formattedOption[]) => {
        let optionArray = horseList || formattedHorses;
        if (optionArray) {
            for (var i = 0; i < optionArray.length; i++) {
                const currentOption = optionArray[i];
                if (currentOption.value === value) {
                    setCurrentValue(currentOption);
                }
            }
        }
    };

    const getBarnHorses = async () => {
        try {
            if (selectedBarn) {
                const queryHorsesByBarn = await getHorsesByBarnId(selectedBarn.id);
                if (queryHorsesByBarn.isSuccess) {
                    const result = queryHorsesByBarn.result;
                    if (result.length === 0) {
                        setFormattedBarnHorses([]);
                        const textToDisplay = "No horses are in " + (selectedBarn?.name || "barn");
                        const barnHorsesGroup: formattedGroupOfOptions = {
                            label: barnHorsesGroupLabel,
                            options: [{ value: textToDisplay, label: " ", horseName: " " }], // TODO: Only show the textToDisplay if no horses are in the barn section
                        };
                        setBarnHorsesGroup(barnHorsesGroup);
                    } else {
                        // Add CreatedByLabel populated with personName to formattedBarnHorses
                        const formattedList = await Promise.all(
                            result.map(async (horse: Horse) => {
                                const formattedHorse = await formatSingleHorse(horse);
                                const personName = await getPersonNameById(horse.personId!);

                                return {
                                    ...formattedHorse,
                                    createdByLabel: personName || "Unknown", // Ensure createdByLabel is assigned
                                } as formattedOption;
                            }),
                        );

                        setFormattedBarnHorses(formattedList);
                        setBarnHorsesGroup({
                            label: barnHorsesGroupLabel,
                            options: formattedList,
                        });
                    }
                    if (selectedValue) findValueInList(selectedValue, formattedBarnHorses!);
                }
            }
        } catch (error) {
            console.error("Error fetching barn horses:", error);
        }
    };

    const getPersonNameById = async (personId: string) => {
        const person = await getPersonNameByPersonId(personId);
        let personName = "Undefined";

        if (person.isSuccess) {
            personName = `${person.result.firstName} ${person.result.lastName}`;
        }
        return personName;
    };

    const getHorsesCreatedByUser = async () => {
        try {
            const queryHorsesByCreatedByUser = await getHorsesByPersonId(userId);
            if (queryHorsesByCreatedByUser.isSuccess) {
                const result = queryHorsesByCreatedByUser.result;
                
                if (result.length === 0) {
                    setFormattedHorsesCreatedByUser([]);
                    const textToDisplay = "You have not created any horses yet.";
                    const createdByUserGroup: formattedGroupOfOptions = {
                        label: createdByUserGroupLabel,
                        options: [{ value: textToDisplay, label: "", horseName: "" }],
                    };
                    setHorsesCreatedByUserGroup(createdByUserGroup);
                } else {
                    let formattedList = await formatHorses(result);
                    setFormattedHorsesCreatedByUser(formattedList);
                    // Triggers useEffect that runs the filtering function, this is necessary to ensure action only happens when lists are populated with current data

                    setHorsesCreatedByUserGroup({
                        label: barnHorsesGroupLabel,
                        options: formattedList,
                    });
                }
            }
        } catch (error) {
            console.error("Error fetching horses created by user:", error);
        }
    };

    // FORMATTERS
    const formatHorses = async (horseArray: Horse[]) => {
        let formattedHorses = [];

        for (var i = 0; i < horseArray.length; i++) {
            const horse = horseArray[i];
            let object: formattedOption = formatSingleHorse(horse);
            formattedHorses.push(object);
        }

        return formattedHorses;
    };

    const formatSingleHorse = (horse: Horse) => {
        const value = horse.id;
        const name = horse.name;
        const nickname = horse.nickname || "";
        const horseNameLabel = (name || "error") + (nickname ? " (" + nickname + ")" : "");
        const barnName = horse.barn?.name || horse.barnName || "unknown";
        const ownerLabel = horse.ownerName || horse.owner?.name || "unknown";

        let object: formattedOption = {
            value: value,
            label: horseNameLabel + " " + barnName,
            horseName: name,
            horseNameLabel: horseNameLabel,
            barnLabel: barnName,
            ownerLabel: ownerLabel,
            horse: horse,
        };
        return object;
    };

    const cleanHorseName = (name: string) => {
        return name.toLocaleLowerCase().trim();
    }

    const handleOnChange = async (event?: any) => {
        // Horse was selected from either the barnHorses or horsesCreatedByUser groups
        if (event && event.horse) {
            setCurrentValue(event);
            onSelect(event.horse);
        } 

        // Option from the dropdown was created by the current user with only the horse name
        else if (event && event.horseName) {
            const newFormattedOption = handleCreateNewFormattedOptionWithJustHorseName(event.horseNameLabel);
            setCurrentValue(newFormattedOption);
            onSelect(undefined, event.horseName);
        }
        
        // Here - event.value will be the value the user typed into the CreateableSelect field
        else if (event && event.value) {
            // Set the currentValue in state so that it can be used inside of the modal.
            const newFormattedOption = handleCreateNewFormattedOptionWithJustHorseName(event.value);
            setCurrentValue(newFormattedOption);

            // Verify Horse - If the horse was not in the barnHorses or horsesCreatedByUser groups
            setIsModalVisible(true)

            if (event.value) {
                // Check all horses in the DB to see if any match the given horse name
                const matchedHorses = allHorses.filter((h: Horse) => {
                    if (cleanHorseName(h.name).includes(cleanHorseName(event.value))) return true;
                    if (h.nickname && cleanHorseName(h.nickname).includes(cleanHorseName(event.value))) return true;
                    if (cleanHorseName(event.value).includes(cleanHorseName(h.name))) return true;
                    if (h.nickname && cleanHorseName(event.value).includes(cleanHorseName(h.nickname))) return true;
                    return false;
                });

                const sortedMatches = matchedHorses.sort((a,b) => a.name.localeCompare(b.name));
                const formattedMatches = await formatHorses(sortedMatches);
                setFormattedMatchedHorses(formattedMatches);
            }
                    
        } else {
            setCurrentValue(null);
            onSelect(undefined);
        }
    };

    const handleCreateNewFormattedOptionWithJustHorseName = (horseName: string) => {
        const newFormattedOption: formattedOption = {
            value: uuidv4(),
            label: horseName + " " + (selectedBarn?.name || ""),
            horseName: horseName,
            horseNameLabel: horseName,
            barnLabel: selectedBarn?.name || "",
            horse: undefined
        };
        return newFormattedOption;
    }

    const handleAddNewOptionToBarnGroup = (horseName: string, horse?: Horse) => {
        // Format the new option
        const newFormattedOption = horse ? formatSingleHorse(horse) : handleCreateNewFormattedOptionWithJustHorseName(horseName);

        if (selectedBarn) {
            // If a barn is currently selected, then add the new option to the list of barn horses
            const updatedFormattedBarnHorses = formattedBarnHorses
                ? formattedBarnHorses.concat([newFormattedOption])
                : [newFormattedOption];
            const sortedFormattedBarnHorses = updatedFormattedBarnHorses.sort((a, b) =>
                a.horseName.trim().localeCompare(b.horseName.trim()),
            );
            setFormattedBarnHorses(updatedFormattedBarnHorses);
            const barnGroup: formattedGroupOfOptions = {
                label: barnHorsesGroupLabel,
                options: sortedFormattedBarnHorses,
            };
            setBarnHorsesGroup(barnGroup);
        } else {
            // Add the horse to the horsesCreatedByUser list of options
            const updatedFormattedHorsesCreatedByUser = formattedHorses
                ? formattedHorses.concat([newFormattedOption])
                : [newFormattedOption];
            setFormattedHorsesCreatedByUser(updatedFormattedHorsesCreatedByUser);
            const horsesCreatedByUserGroup: formattedGroupOfOptions = {
                label: createdByUserGroupLabel,
                options: updatedFormattedHorsesCreatedByUser,
            };
            setHorsesCreatedByUserGroup(horsesCreatedByUserGroup);
        }
        
        // Set this option as the currently selected option
        setCurrentValue(newFormattedOption);
    }

    // MODAL Functions
    const handleSelectFromMatchedHorses = (event?: formattedOption) => {
        setCurrentValueFromMatchedHorses(event);
    }
    /**
     * User found the horse by name in the RSP Database
     * Send that horse info back to the parent component
     * And close the modal
     */
    const handleUseMatchedHorse = (event: formattedOption) => {
        if (event.horseName) {
            handleAddNewOptionToBarnGroup(event.horseName, event.horse);
            setCurrentValueFromMatchedHorses(event);
            onSelect(event.horse);
            setIsModalVisible(false);
        } else {
            setError("No horse name was found.");
        }
        
    }
    /**
     * User did not find the horse by name in the RSP Database
     * Send that horse name back to the parent component in order to create a new horse
     * And close the modal
     */
    const handleCreateNewHorse = () => {
        if (currentValue?.horseName) {
            handleAddNewOptionToBarnGroup(currentValue?.horseName);
            onSelect(undefined, currentValue?.horseName);
            setIsModalVisible(false);
        } else {
            setError("No horse name was found.");
        }
    }
    /**
    * User did not find the horse by name in the RSP Database.
    * Assume the user thought the horse existed and realized there is a typo in the horse name.
    * Send the user back to the starting point to try again.
    */
    const handleCancel = () => {
        onSelect(undefined)
        setIsModalVisible(false);
        setCurrentValue(null);
        setCurrentValueFromMatchedHorses(null);
    }

    return (
        <>
            {isLoading ? (
                <Spinner />
            ) : (
                <IonCol size="10">
                    {error && <ErrorAlert error={error} width="12" />}
                    <CreatableSelect
                        id="select-or-create-horse"
                        inputId="select-or-create-horse-input"
                        placeholder="Type the horse's name"
                        styles={{
                            // Fixes the overlapping problem of the component
                            control: (baseStyles) => (isHeightRequired ? { ...baseStyles, height } : { ...baseStyles }),
                            menu: (provided) => ({ ...provided, zIndex: 9999 }),
                            group: (base, state) => ({
                                ...base,
                                // Group background color
                                backgroundColor:
                                    state.label === barnHorsesGroupLabel ? "var(--ion-color-info)" : "var(--ion-color-secondary)",
                                padding: "10px",
                            }),
                            groupHeading: (base, state) => ({
                                ...base,
                                fontSize: "1em",
                                fontWeight: "bold",
                                // Section title color
                                color: "var(--ion-color-white)",
                            }),
                            option: (base, { isFocused, isSelected }) => ({
                                ...base,
                                // Hover vs. no hover colors for horses in list
                                backgroundColor: isFocused || isSelected ? "var(--ion-color-light)" : "var(--ion-color-white)",
                                cursor: "pointer",
                                color: "var(--ion-color-primary)",
                            }),
                        }}
                        defaultValue={currentValue}
                        value={currentValue}
                        menuPortalTarget={document.body}
                        menuPlacement={"auto"}
                        isClearable
                        options={[barnHorsesGroup, horsesCreatedByUserGroup]}
                        noOptionsMessage={({ inputValue }) => (!inputValue ? "No input found" : "No results found")}
                        formatOptionLabel={(option: any) =>
                            option.horseNameLabel ? (
                                <div className="px-10">
                                    <div className="font-weight-bold text-default">{option.horseNameLabel}</div>
                                    {barnHorsesGroup.options.includes(option) ? (
                                        <div className="display-5 text-medium">Created By: {option.createdByLabel}</div>
                                    ) : (
                                        <div className="display-5 text-medium">Barn: {option.barnLabel}</div>
                                    )}
                                    <div className="display-5 text-medium">Owner: {option.ownerLabel}</div>
                                </div>
                            ) : (
                                <div className="p-0">
                                    <div className="font-weight-bold text-default">{option.textToDisplay || "Select or Create Horse: " + option.value}</div>
                                </div>
                            )
                        }
                        components={{ SingleValue }}
                        onChange={(event: any) => handleOnChange(event)}
                        createOptionPosition={"first"}
                    />
                </IonCol>
            )}
            {isModalVisible && 
                <VerifyHorseModal 
                    isLoadingAllHorses={isLoadingAllHorses} 
                    currentValue={currentValue}
                    formattedMatchedHorses={formattedMatchedHorses} // TODO: List of horses to display that were found in the allHorsesGroup  
                    currentValueFromMatchedHorses={currentValueFromMatchedHorses} 
                    handleSelectFromMatchedHorses={handleSelectFromMatchedHorses} 
                    handleCancel={handleCancel} 
                    handleUseMatchedHorse={handleUseMatchedHorse} 
                    handleCreateNewHorse={handleCreateNewHorse} // TODO: Add horse to barnHorsesGroup if selectedBarn is true, otherwise, add to horsesCreatedByUser
                />
            }
        </>
    );
};

export default SelectOrCreateHorse;
