import React, {useMemo, useState} from "react"
import {IonItemGroup, IonLabel, IonText, IonIcon, IonCol, IonRow, IonCard, IonCardTitle} from "@ionic/react"
import {trash} from "ionicons/icons"
import {formattedOption, formattedOptionGroup, OptionGroupsSortedByDiscipline} from "./ClassInfoSection"
import {getFormattedDisciplineString} from "../../utilities/discipline/FormatDisciplines"
import {capitalizeName} from "../../utilities/person/PersonNameFormat"

type SelectedClassesProps = {
    disciplineDataArray: OptionGroupsSortedByDiscipline[] | null | undefined
    selectedClassesCost: number
    onClassRemoval: (disciplineIndex: number, formattedOptionGroups: formattedOptionGroup[]) => void
}

const SelectedClasses: React.FC<SelectedClassesProps> = ({disciplineDataArray, selectedClassesCost, onClassRemoval}) => {
    const getDivisionClassCount = (optionGroup: formattedOptionGroup) => {
        if (optionGroup.division && optionGroup.classes) {
            let counter = 0
            optionGroup.classes.forEach((element) => {
                if (element.isChecked) counter = counter + 1
            })
            return `${counter} / ${optionGroup.classes.length}`
        }
        return 0
    }

    const handleClassRemoval = (disciplineIndex: number, optionGroupIndex: number, classIndex: number) => {
        if (disciplineDataArray && disciplineDataArray.length > 0) {
            const foundOptionGroupSortedByDiscipline: OptionGroupsSortedByDiscipline = disciplineDataArray[disciplineIndex]
            const foundOptionGroupArray: formattedOptionGroup[] = foundOptionGroupSortedByDiscipline.optionGroupArray
            const foundOptionGroup: formattedOptionGroup = foundOptionGroupArray[optionGroupIndex]

            // Check what is in the optionGroup - is it a single class or a division with multiple classes
            if (foundOptionGroup.class) {
                // Simple case - Single Class within a discipline
                // Step 1: Update the class's isChecked property
                const updatedClass: formattedOption = {
                    ...foundOptionGroup.class,
                    isChecked: false,
                }

                // Step 2: Create the updated optionGroup with the updated class
                const updatedOptionGroup: formattedOptionGroup = {
                    ...foundOptionGroup,
                    class: updatedClass,
                }

                // Step 3: Replace the updated optionGroup in the array
                const updatedOptionGroupArray: formattedOptionGroup[] = [
                    ...foundOptionGroupArray.slice(0, optionGroupIndex),
                    updatedOptionGroup,
                    ...foundOptionGroupArray.slice(optionGroupIndex + 1),
                ]

                // Step 4: Send the updated option group back to the parent component
                // NOTE - the parent component handles updating the main discipline array
                onClassRemoval(disciplineIndex, updatedOptionGroupArray)
            } else if (foundOptionGroup.division && foundOptionGroup.classes) {
                // Complicated case - handle visibility of classes and divisions based on # of selected classes
                // Step 1 - Find and update class within foundOptionGroup
                //  a - find the class within the classes array + update that class to have 'isChecked = false' in the classes array
                const updatedDivisionClass: formattedOption = {
                    ...foundOptionGroup.classes[classIndex],
                    isChecked: false,
                }

                // Create a new classes array with the updated class at the specified index
                const updatedClassesArray = foundOptionGroup.classes.map((item: any, index: number) => (index === classIndex ? updatedDivisionClass : item))

                // Update the formattedOptionGroup with the new classes array
                const updatedFormattedOptionGroup: formattedOptionGroup = {
                    ...foundOptionGroup,
                    classes: updatedClassesArray,
                }

                //  b - Check if 0 classes are checked/selected. If yes, then uncheck the division.
                let counter = 0

                if (updatedFormattedOptionGroup.classes) {
                    counter = updatedFormattedOptionGroup.classes.filter((classItem: {isChecked: boolean}) => classItem.isChecked).length
                }

                if (counter === 0 && updatedFormattedOptionGroup.division) {
                    updatedFormattedOptionGroup.division.isChecked = false
                    updatedFormattedOptionGroup.division.isExpanded = false
                }

                // Step 2 - update the optionGroupArray to have the updated optionGroup
                const updatedOptionGroupArray = foundOptionGroupArray.map((group: formattedOptionGroup) => (group === foundOptionGroup ? updatedFormattedOptionGroup : group))

                // Step 3 - pass back to parent component
                onClassRemoval(disciplineIndex, updatedOptionGroupArray)
            }
        } else {
            console.log("No discipline data found.")
        }
    }

    return (
        <>
            {!disciplineDataArray || disciplineDataArray.length === 0 ? (
                <IonRow>
                    <IonCol className="text-center">
                        <IonText className="text-danger font-weight-bold">No classes selected, class list below.</IonText>
                    </IonCol>
                </IonRow>
            ) : (
                <>
                    {/* Check if any disciplines have at least one item selected */}
                    {!!disciplineDataArray.find((d) => d.includesOneOrMoreSelectedClasses) ? (
                        <>
                            {/* Iterate over each discipline group and render a separate card */}
                            {disciplineDataArray.map((disciplineData, index) => (
                                <span key={index}>
                                    {disciplineData.includesOneOrMoreSelectedClasses && (
                                        <IonRow>
                                            <IonCol className="p-0 py-1 m-0">
                                                <IonCard key={index} className="rounded-3 mx-0 bg-lighter m-0">
                                                    <IonRow className="align-items-center small-scrollable">
                                                        <IonCol className="p-0">
                                                            {/* Discipline Header */}
                                                            <IonItemGroup>
                                                                <IonRow className="bg-dark px-2 align-items-center">
                                                                    <IonCol className="px-0">
                                                                        <IonCardTitle className="font-weight-bold text-white m-1 ion-text-wrap">
                                                                            {capitalizeName(getFormattedDisciplineString(disciplineData.disciplineName))}
                                                                        </IonCardTitle>
                                                                    </IonCol>
                                                                </IonRow>

                                                                {/* Iterate over sections within the discipline */}
                                                                {disciplineData.optionGroupArray && disciplineData.optionGroupArray.length > 0 ? (
                                                                    <>
                                                                        {disciplineData.optionGroupArray.map((optionGroup, optionGroupIndex) => (
                                                                            <IonItemGroup key={optionGroupIndex}>
                                                                                {/* Header */}
                                                                                {optionGroup.division && optionGroup.division.isChecked && (
                                                                                    <>
                                                                                        <IonRow className="bg-lighter px-2 align-items-center border-top">
                                                                                            <IonCol sizeSm="9" sizeXs="9" className="pl-0">
                                                                                                <IonLabel className="text-primary font-weight-bold ion-text-wrap">
                                                                                                    Division: {optionGroup.division.name}
                                                                                                </IonLabel>
                                                                                            </IonCol>
                                                                                            <IonCol sizeSm="3" sizeXs="3" className="text-right p-0 pr-2">
                                                                                                <IonText className="text-primary ion-text-wrap">
                                                                                                    {`${getDivisionClassCount(optionGroup)} classes`}
                                                                                                </IonText>
                                                                                            </IonCol>
                                                                                        </IonRow>
                                                                                        {optionGroup.classes && optionGroup.classes.length > 0 && (
                                                                                            <>
                                                                                                {optionGroup.classes.map((cls, classIndex) => (
                                                                                                    <>
                                                                                                        {cls.isChecked && (
                                                                                                            <IonRow
                                                                                                                className="bg-lighter border-top align-items-center px-2"
                                                                                                                key={classIndex}>
                                                                                                                <IonCol sizeSm="7" sizeXs="6" className="pl-0">
                                                                                                                    <IonLabel className="px-0 ion-text-wrap">
                                                                                                                        {cls.number}. {cls.name}
                                                                                                                    </IonLabel>
                                                                                                                </IonCol>
                                                                                                                <IonCol sizeSm="3" sizeXs="3" className="p-0 text-center">
                                                                                                                    <IonText className="p-0 ion-text-wrap">DATE HOLDER</IonText>
                                                                                                                </IonCol>
                                                                                                                <IonCol sizeSm="1" sizeXs="1" className="text-center p-0 pt-1">
                                                                                                                    <IonIcon
                                                                                                                        className="card-icon-size-2 text-danger"
                                                                                                                        icon={trash}
                                                                                                                        onClick={() => {
                                                                                                                            handleClassRemoval(index, optionGroupIndex, classIndex)
                                                                                                                        }} //Class inside of a division
                                                                                                                    />
                                                                                                                </IonCol>
                                                                                                                <IonCol sizeSm="1" sizeXs="2" className="p-0 text-center">
                                                                                                                    <IonText>${cls.entryFee}</IonText>
                                                                                                                </IonCol>
                                                                                                            </IonRow>
                                                                                                        )}
                                                                                                    </>
                                                                                                ))}
                                                                                            </>
                                                                                        )}
                                                                                    </>
                                                                                )}

                                                                                {/* Stand Alone Classes */}
                                                                                {optionGroup.class && optionGroup.class.isChecked && (
                                                                                    <IonRow className="bg-white border-top align-items-center px-2">
                                                                                        <IonCol sizeSm="7" sizeXs="6" className="pl-0">
                                                                                            <IonLabel className="ion-text-wrap">
                                                                                                {optionGroup.class.number}. {optionGroup.class.name}
                                                                                            </IonLabel>
                                                                                        </IonCol>
                                                                                        <IonCol sizeSm="3" sizeXs="3" className="text-center p-0">
                                                                                            <IonText className="ion-text-wrap">DATE HOLDER</IonText>
                                                                                        </IonCol>
                                                                                        <IonCol sizeSm="1" sizeXs="1" className="text-center p-0 pt-1">
                                                                                            <IonIcon
                                                                                                className="card-icon-size-2 text-danger"
                                                                                                icon={trash}
                                                                                                onClick={() => {
                                                                                                    handleClassRemoval(index, optionGroupIndex, 0)
                                                                                                }} // Stand Alone Class
                                                                                            />
                                                                                        </IonCol>
                                                                                        <IonCol sizeSm="1" sizeXs="2" className="text-center p-0">
                                                                                            <IonText>${optionGroup.class.entryFee}</IonText>
                                                                                        </IonCol>
                                                                                    </IonRow>
                                                                                )}
                                                                            </IonItemGroup>
                                                                        ))}
                                                                    </>
                                                                ) : (
                                                                    <p>No classes found for this discipline.</p>
                                                                )}
                                                            </IonItemGroup>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonCard>
                                            </IonCol>
                                        </IonRow>
                                    )}
                                </span>
                            ))}
                        </>
                    ) : (
                        <IonRow>
                            <IonCol className="text-center">
                                <IonText className="text-danger font-weight-bold">No classes selected, class list below.</IonText>
                            </IonCol>
                        </IonRow>
                    )}
                </>
            )}
            {/* Total Amount */}
            <IonRow className="mt-2 px-2 border-top">
                <IonCol>
                    <IonText className="text-primary display-5.5 font-weight-bold">Total</IonText>
                </IonCol>
                <IonCol className="ion-text-right">
                    <IonText className="text-primary display-5.5 font-weight-bold">{selectedClassesCost ? "$" + selectedClassesCost.toFixed(2) : "$0"}</IonText>
                </IonCol>
            </IonRow>
        </>
    )
}

export default SelectedClasses
