import React, { useState } from "react";
import SelectBarnSection from "./SelectBarnSection";
import { Barn, Event, Horse } from "../../../models"; 
import EntryInfoSection from "./EntryInfoSection";
import ClassInfoSection from "../../EventClass/ClassInfoSection";

interface EntryCardProps {
    event: Event;
    allHorses?: Horse[]
}

const EntryCard: React.FC<EntryCardProps> = ({ event, allHorses }) => {
    const [selectedBarn, setSelectedBarn] = useState<Barn | undefined | null>();
    const [selectedHorse, setSelectedHorse] = useState<Horse | undefined | null>();

    return (
        <>
            <SelectBarnSection event={event} onSelect={(barn: Barn | null | undefined) => setSelectedBarn(barn)} />
            <EntryInfoSection event={event} allHorses={allHorses} selectedBarn={selectedBarn} onSelect={(horse: Horse | null | undefined) => {setSelectedHorse(horse);}} />
            <ClassInfoSection event={event} selectedHorse={selectedHorse}/>
        </>
    );
};

export default EntryCard;
