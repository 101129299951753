import React, { useEffect, useState } from "react";
import { IonCol, IonRow } from "@ionic/react";
import { Barn, Horse } from "../../../models";
import HorseHeightInput from "../../Horse/HorseHeightInput";
import { Input, Label } from "reactstrap";
import { breeds } from "../../../utilities/horse/Breed";
import SelectOrCreateHorse from "../../Horse/SelectOrCreateHorse";
import RequiredInputIndicator from "../../Forms/RequiredInputIndicator";

interface _Props {
    userId: string;
    barn?: Barn | null;
    allHorses?: Horse[];
    selectedHorse?: Horse | null;
    onSelectHorse?: (horse?: Horse) => void;
}

const SelectHorseSection: React.FC<_Props> = ({ userId, barn, allHorses, selectedHorse, onSelectHorse }) => {
    const [name, setName] = useState("");
    const [height, setHeight] = useState("");
    const [sex, setSex] = useState("");
    const [breed, setBreed] = useState("");
    const [barnName, setBarnName] = useState(barn?.name || "");

    const handleSelectedHorse = (horse?: Horse, horseName?: string) => {
        onSelectHorse?.(horse);  // Call the prop function to update the parent state
        setName(horse?.name || horseName || "");
        setHeight(horse?.height || "");
        setSex(horse?.sex || "");
        setBreed(horse?.breed || "");
        setBarnName(horse?.barnName || horse?.barn?.name || barn?.name || "");
    };

    return (
        <>
              {/* Conditional Rendering of Horse info fields based on name state */}
              {!name ? (
                 // Layout for when name is not provided
                 <>
                 <IonRow>
                     <IonCol size="12">
                         <Label>
                             Name
                             <RequiredInputIndicator />
                         </Label>
                         <SelectOrCreateHorse userId={userId} allHorsesinDB={allHorses} selectedBarn={barn} selectedValue={selectedHorse ? selectedHorse.id : null} onSelect={handleSelectedHorse} />
                     </IonCol>
                 </IonRow>
             </>
            ) : (
                // Layout for when name is provided
                <>
                    <IonRow>
                        <IonCol sizeXs="12" sizeMd="6">
                            <Label>
                                Name
                                <RequiredInputIndicator />
                            </Label>
                            <SelectOrCreateHorse userId={userId} allHorsesinDB={allHorses} selectedBarn={barn} selectedValue={selectedHorse ? selectedHorse.id : null} onSelect={handleSelectedHorse} />
                        </IonCol>
                        <IonCol sizeXs="12" sizeMd="6">
                            <HorseHeightInput 
                                isRequired 
                                height={parseFloat(height) || undefined} 
                                onChange={setHeight} 
                            />
                        </IonCol>
                        <IonCol sizeXs="12" sizeMd="6">
                            <Label for="sexSelect">Sex</Label>
                            <Input
                                type="select" 
                                name="select"
                                value={sex}
                                id="sexSelect"
                                onChange={e => setSex(e.target.value)}
                            >
                                <option>-</option>
                                <option>Gelding</option>
                                <option>Mare</option>
                                <option>Stallion</option>
                                <option>Colt</option>
                            </Input>
                        </IonCol>
                        <IonCol sizeXs="12" sizeMd="6">
                            <Label for="breedSelect">Breed</Label>
                            <Input 
                                type="select" 
                                name="select"
                                value={breed}
                                id="breedSelect"
                                onChange={e => setBreed(e.target.value)}
                            >
                                <option>-</option>
                                {breeds.map((breed, index) => (
                                    <option key={index}>{breed}</option>
                                ))}
                            </Input>
                        </IonCol>
                    </IonRow>
                </>
            )}
        </>
    );
};

export default SelectHorseSection;
