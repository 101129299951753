/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateAddress = /* GraphQL */ `
  subscription OnCreateAddress {
    onCreateAddress {
      id
      name
      type
      isDefault
      streetAddress1
      streetAddress2
      city
      provState
      zip
      country
      notes
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAddress = /* GraphQL */ `
  subscription OnUpdateAddress {
    onUpdateAddress {
      id
      name
      type
      isDefault
      streetAddress1
      streetAddress2
      city
      provState
      zip
      country
      notes
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAddress = /* GraphQL */ `
  subscription OnDeleteAddress {
    onDeleteAddress {
      id
      name
      type
      isDefault
      streetAddress1
      streetAddress2
      city
      provState
      zip
      country
      notes
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAlert = /* GraphQL */ `
  subscription OnCreateAlert {
    onCreateAlert {
      id
      type
      title
      body
      imageKey
      category
      organizationId
      eventId
      event {
        id
        name
        nickname
        createdBy
        manager
        startDate
        endDate
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contactId
        contactIds
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        type
        disciplines
        eventOptions {
          autoAcceptNewEntries
          usesTextAlerts
          acceptsIncomingTextAlerts
          hasUnlimitedCredits
          usesEventManagement
          usesStablingManagement
          usesVendorManagement
          usesMediaTools
          usesMarketingTools
          usesFinanceTools
          usesJudgeApp
        }
        paymentOptions {
          acceptsChecks
          acceptsCash
          acceptsACH
          acceptsCC
        }
        invoiceOptions {
          classFeeTax
          sendEmails
        }
        entryOptions {
          displayHorse2Input
          displayRider2Input
          displayRider3Input
          requiresCogginsDocument
          requiresCogginsData
          maximumNumberClassesOnEntry
        }
        officials {
          nextToken
        }
        status
        entriesStatus
        showStatus
        usefID
        usefSoftwareKey
        ecID
        ecPassword
        documents {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        eventAddressId
      }
      messageSegments
      subscriberCount
      totalCost
      blockIdsUsed
      fromUnlimitedEvent
      isIncoming
      sentFromPhoneNumber
      recipientPhoneNumbers
      successSIDList
      errorSIDList
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      alertEventId
    }
  }
`;
export const onUpdateAlert = /* GraphQL */ `
  subscription OnUpdateAlert {
    onUpdateAlert {
      id
      type
      title
      body
      imageKey
      category
      organizationId
      eventId
      event {
        id
        name
        nickname
        createdBy
        manager
        startDate
        endDate
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contactId
        contactIds
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        type
        disciplines
        eventOptions {
          autoAcceptNewEntries
          usesTextAlerts
          acceptsIncomingTextAlerts
          hasUnlimitedCredits
          usesEventManagement
          usesStablingManagement
          usesVendorManagement
          usesMediaTools
          usesMarketingTools
          usesFinanceTools
          usesJudgeApp
        }
        paymentOptions {
          acceptsChecks
          acceptsCash
          acceptsACH
          acceptsCC
        }
        invoiceOptions {
          classFeeTax
          sendEmails
        }
        entryOptions {
          displayHorse2Input
          displayRider2Input
          displayRider3Input
          requiresCogginsDocument
          requiresCogginsData
          maximumNumberClassesOnEntry
        }
        officials {
          nextToken
        }
        status
        entriesStatus
        showStatus
        usefID
        usefSoftwareKey
        ecID
        ecPassword
        documents {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        eventAddressId
      }
      messageSegments
      subscriberCount
      totalCost
      blockIdsUsed
      fromUnlimitedEvent
      isIncoming
      sentFromPhoneNumber
      recipientPhoneNumbers
      successSIDList
      errorSIDList
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      alertEventId
    }
  }
`;
export const onDeleteAlert = /* GraphQL */ `
  subscription OnDeleteAlert {
    onDeleteAlert {
      id
      type
      title
      body
      imageKey
      category
      organizationId
      eventId
      event {
        id
        name
        nickname
        createdBy
        manager
        startDate
        endDate
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contactId
        contactIds
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        type
        disciplines
        eventOptions {
          autoAcceptNewEntries
          usesTextAlerts
          acceptsIncomingTextAlerts
          hasUnlimitedCredits
          usesEventManagement
          usesStablingManagement
          usesVendorManagement
          usesMediaTools
          usesMarketingTools
          usesFinanceTools
          usesJudgeApp
        }
        paymentOptions {
          acceptsChecks
          acceptsCash
          acceptsACH
          acceptsCC
        }
        invoiceOptions {
          classFeeTax
          sendEmails
        }
        entryOptions {
          displayHorse2Input
          displayRider2Input
          displayRider3Input
          requiresCogginsDocument
          requiresCogginsData
          maximumNumberClassesOnEntry
        }
        officials {
          nextToken
        }
        status
        entriesStatus
        showStatus
        usefID
        usefSoftwareKey
        ecID
        ecPassword
        documents {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        eventAddressId
      }
      messageSegments
      subscriberCount
      totalCost
      blockIdsUsed
      fromUnlimitedEvent
      isIncoming
      sentFromPhoneNumber
      recipientPhoneNumbers
      successSIDList
      errorSIDList
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      alertEventId
    }
  }
`;
export const onCreateAuditor = /* GraphQL */ `
  subscription OnCreateAuditor {
    onCreateAuditor {
      id
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      name
      phoneNumber
      email
      status
      statusNote
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const onUpdateAuditor = /* GraphQL */ `
  subscription OnUpdateAuditor {
    onUpdateAuditor {
      id
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      name
      phoneNumber
      email
      status
      statusNote
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const onDeleteAuditor = /* GraphQL */ `
  subscription OnDeleteAuditor {
    onDeleteAuditor {
      id
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      name
      phoneNumber
      email
      status
      statusNote
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const onCreateBacklogEventResult = /* GraphQL */ `
  subscription OnCreateBacklogEventResult {
    onCreateBacklogEventResult {
      id
      organizationId
      organizationMemberId
      organizationResultId
      eventId
      eventName
      eventStartDate
      eventEndDate
      eventDivisionId
      eventDivisionName
      organizationDivisionId
      organizationDivision {
        id
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceDivisionsId
        }
        organizationId
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        jumpHeight
        minNumberEntries
        maxNumberEntries
        entryFee
        prizeMoney
        entryFeePerClass
        prizeMoneyPerClass
        createdBy
        lastUpdatedBy
        displayOrder
        createdOn
        updatedOn
        organizationDivisionPointTableId
        organizationDivisionPrizeMoneyTableId
      }
      eventClassId
      eventClassName
      eventClassNumber
      organizationClassId
      organizationClass {
        id
        name
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceClassesId
        }
        organizationId
        organizationDivisionId
        hasChampionship
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryFee
        prizeMoney
        minNumberEntries
        maxNumberEntries
        jumpHeight
        estimatedTimePerRound
        createdBy
        displayOrder
        lastUpdatedBy
        createdOn
        updatedOn
        organizationClassPointTableId
        organizationClassPrizeMoneyTableId
      }
      riderName
      horseName
      place
      pointsEarned
      score
      time
      didNotCompete
      prizeMoney
      prizeMoneyTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isPercentage
        totalPot
        tiers {
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      backlogEventResultPrizeMoneyTableId
    }
  }
`;
export const onUpdateBacklogEventResult = /* GraphQL */ `
  subscription OnUpdateBacklogEventResult {
    onUpdateBacklogEventResult {
      id
      organizationId
      organizationMemberId
      organizationResultId
      eventId
      eventName
      eventStartDate
      eventEndDate
      eventDivisionId
      eventDivisionName
      organizationDivisionId
      organizationDivision {
        id
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceDivisionsId
        }
        organizationId
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        jumpHeight
        minNumberEntries
        maxNumberEntries
        entryFee
        prizeMoney
        entryFeePerClass
        prizeMoneyPerClass
        createdBy
        lastUpdatedBy
        displayOrder
        createdOn
        updatedOn
        organizationDivisionPointTableId
        organizationDivisionPrizeMoneyTableId
      }
      eventClassId
      eventClassName
      eventClassNumber
      organizationClassId
      organizationClass {
        id
        name
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceClassesId
        }
        organizationId
        organizationDivisionId
        hasChampionship
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryFee
        prizeMoney
        minNumberEntries
        maxNumberEntries
        jumpHeight
        estimatedTimePerRound
        createdBy
        displayOrder
        lastUpdatedBy
        createdOn
        updatedOn
        organizationClassPointTableId
        organizationClassPrizeMoneyTableId
      }
      riderName
      horseName
      place
      pointsEarned
      score
      time
      didNotCompete
      prizeMoney
      prizeMoneyTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isPercentage
        totalPot
        tiers {
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      backlogEventResultPrizeMoneyTableId
    }
  }
`;
export const onDeleteBacklogEventResult = /* GraphQL */ `
  subscription OnDeleteBacklogEventResult {
    onDeleteBacklogEventResult {
      id
      organizationId
      organizationMemberId
      organizationResultId
      eventId
      eventName
      eventStartDate
      eventEndDate
      eventDivisionId
      eventDivisionName
      organizationDivisionId
      organizationDivision {
        id
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceDivisionsId
        }
        organizationId
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        jumpHeight
        minNumberEntries
        maxNumberEntries
        entryFee
        prizeMoney
        entryFeePerClass
        prizeMoneyPerClass
        createdBy
        lastUpdatedBy
        displayOrder
        createdOn
        updatedOn
        organizationDivisionPointTableId
        organizationDivisionPrizeMoneyTableId
      }
      eventClassId
      eventClassName
      eventClassNumber
      organizationClassId
      organizationClass {
        id
        name
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceClassesId
        }
        organizationId
        organizationDivisionId
        hasChampionship
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryFee
        prizeMoney
        minNumberEntries
        maxNumberEntries
        jumpHeight
        estimatedTimePerRound
        createdBy
        displayOrder
        lastUpdatedBy
        createdOn
        updatedOn
        organizationClassPointTableId
        organizationClassPrizeMoneyTableId
      }
      riderName
      horseName
      place
      pointsEarned
      score
      time
      didNotCompete
      prizeMoney
      prizeMoneyTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isPercentage
        totalPot
        tiers {
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      backlogEventResultPrizeMoneyTableId
    }
  }
`;
export const onCreateBarn = /* GraphQL */ `
  subscription OnCreateBarn {
    onCreateBarn {
      id
      name
      nickname
      description
      type
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      ownerName
      ownerPersonId
      secondaryOwnerName
      secondaryOwnerPersonId
      members {
        items {
          id
          name
          barnId
          personId
          permissionLevel
          status
          roles
          createdBy
          createdOn
          updatedOn
        }
        nextToken
      }
      location {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      media {
        title
        description
        originalExtension
        dateUploaded
        url
        bucket
        region
        key
      }
      lastUpdatedBy
      createdOn
      updatedOn
      barnContactId
      barnLocationId
    }
  }
`;
export const onUpdateBarn = /* GraphQL */ `
  subscription OnUpdateBarn {
    onUpdateBarn {
      id
      name
      nickname
      description
      type
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      ownerName
      ownerPersonId
      secondaryOwnerName
      secondaryOwnerPersonId
      members {
        items {
          id
          name
          barnId
          personId
          permissionLevel
          status
          roles
          createdBy
          createdOn
          updatedOn
        }
        nextToken
      }
      location {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      media {
        title
        description
        originalExtension
        dateUploaded
        url
        bucket
        region
        key
      }
      lastUpdatedBy
      createdOn
      updatedOn
      barnContactId
      barnLocationId
    }
  }
`;
export const onDeleteBarn = /* GraphQL */ `
  subscription OnDeleteBarn {
    onDeleteBarn {
      id
      name
      nickname
      description
      type
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      ownerName
      ownerPersonId
      secondaryOwnerName
      secondaryOwnerPersonId
      members {
        items {
          id
          name
          barnId
          personId
          permissionLevel
          status
          roles
          createdBy
          createdOn
          updatedOn
        }
        nextToken
      }
      location {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      media {
        title
        description
        originalExtension
        dateUploaded
        url
        bucket
        region
        key
      }
      lastUpdatedBy
      createdOn
      updatedOn
      barnContactId
      barnLocationId
    }
  }
`;
export const onCreateBarnMember = /* GraphQL */ `
  subscription OnCreateBarnMember {
    onCreateBarnMember {
      id
      name
      barnId
      barn {
        id
        name
        nickname
        description
        type
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        ownerName
        ownerPersonId
        secondaryOwnerName
        secondaryOwnerPersonId
        members {
          nextToken
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        barnContactId
        barnLocationId
      }
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      permissionLevel
      status
      roles
      createdBy
      createdOn
      updatedOn
    }
  }
`;
export const onUpdateBarnMember = /* GraphQL */ `
  subscription OnUpdateBarnMember {
    onUpdateBarnMember {
      id
      name
      barnId
      barn {
        id
        name
        nickname
        description
        type
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        ownerName
        ownerPersonId
        secondaryOwnerName
        secondaryOwnerPersonId
        members {
          nextToken
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        barnContactId
        barnLocationId
      }
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      permissionLevel
      status
      roles
      createdBy
      createdOn
      updatedOn
    }
  }
`;
export const onDeleteBarnMember = /* GraphQL */ `
  subscription OnDeleteBarnMember {
    onDeleteBarnMember {
      id
      name
      barnId
      barn {
        id
        name
        nickname
        description
        type
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        ownerName
        ownerPersonId
        secondaryOwnerName
        secondaryOwnerPersonId
        members {
          nextToken
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        barnContactId
        barnLocationId
      }
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      permissionLevel
      status
      roles
      createdBy
      createdOn
      updatedOn
    }
  }
`;
export const onCreateBlock = /* GraphQL */ `
  subscription OnCreateBlock {
    onCreateBlock {
      id
      name
      type
      personid
      organizationId
      purchasedOn
      expiresOn
      totalCredits
      usedCredits
      status
      price
      amountPaid
      isPromotion
      isFree
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const onUpdateBlock = /* GraphQL */ `
  subscription OnUpdateBlock {
    onUpdateBlock {
      id
      name
      type
      personid
      organizationId
      purchasedOn
      expiresOn
      totalCredits
      usedCredits
      status
      price
      amountPaid
      isPromotion
      isFree
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const onDeleteBlock = /* GraphQL */ `
  subscription OnDeleteBlock {
    onDeleteBlock {
      id
      name
      type
      personid
      organizationId
      purchasedOn
      expiresOn
      totalCredits
      usedCredits
      status
      price
      amountPaid
      isPromotion
      isFree
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const onCreateClass = /* GraphQL */ `
  subscription OnCreateClass {
    onCreateClass {
      id
      name
      nickname
      divisionId
      type
      description
      discipline
      ushjaName
      usefNumber
      ecNumber
      ecClassTypeNumber
      ecClassType
      code
      specs
      judgedOn
      rules {
        horseSex
        horseBreed
        horseMinHeight
        horseMaxHeight
        horseMinAge
        horseMaxAge
        riderMinAge
        riderMaxAge
        riderProfessionalStatus
        riderRecord
        horseRecord
        crossEntry
        other
      }
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventInvoiceClassesId
    }
  }
`;
export const onUpdateClass = /* GraphQL */ `
  subscription OnUpdateClass {
    onUpdateClass {
      id
      name
      nickname
      divisionId
      type
      description
      discipline
      ushjaName
      usefNumber
      ecNumber
      ecClassTypeNumber
      ecClassType
      code
      specs
      judgedOn
      rules {
        horseSex
        horseBreed
        horseMinHeight
        horseMaxHeight
        horseMinAge
        horseMaxAge
        riderMinAge
        riderMaxAge
        riderProfessionalStatus
        riderRecord
        horseRecord
        crossEntry
        other
      }
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventInvoiceClassesId
    }
  }
`;
export const onDeleteClass = /* GraphQL */ `
  subscription OnDeleteClass {
    onDeleteClass {
      id
      name
      nickname
      divisionId
      type
      description
      discipline
      ushjaName
      usefNumber
      ecNumber
      ecClassTypeNumber
      ecClassType
      code
      specs
      judgedOn
      rules {
        horseSex
        horseBreed
        horseMinHeight
        horseMaxHeight
        horseMinAge
        horseMaxAge
        riderMinAge
        riderMaxAge
        riderProfessionalStatus
        riderRecord
        horseRecord
        crossEntry
        other
      }
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventInvoiceClassesId
    }
  }
`;
export const onCreateClassOrder = /* GraphQL */ `
  subscription OnCreateClassOrder {
    onCreateClassOrder {
      id
      eventId
      eventClassId
      eventClass {
        id
        name
        eventId
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceClassesId
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        organizationClassId
        organizationClass {
          id
          name
          classId
          organizationId
          organizationDivisionId
          hasChampionship
          type
          number
          entryFee
          prizeMoney
          minNumberEntries
          maxNumberEntries
          jumpHeight
          estimatedTimePerRound
          createdBy
          displayOrder
          lastUpdatedBy
          createdOn
          updatedOn
          organizationClassPointTableId
          organizationClassPrizeMoneyTableId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryOption
        entryFee
        fees {
          nextToken
        }
        prizeMoney
        entries {
          nextToken
        }
        completedVerifyEntries
        currentNumberEntries
        numberEntriesThatCompeted
        minNumberEntries
        maxNumberEntries
        estimatedTimePerRound
        isCaliforniaSplit
        isCombined
        combinedEventClassId
        combinedEventDivisionId
        jumpHeight
        isScheduled
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventClassPointTableId
        eventClassPrizeMoneyTableId
      }
      entryId
      eventEntry {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      order
      isRandom
      createdOn
      updatedOn
    }
  }
`;
export const onUpdateClassOrder = /* GraphQL */ `
  subscription OnUpdateClassOrder {
    onUpdateClassOrder {
      id
      eventId
      eventClassId
      eventClass {
        id
        name
        eventId
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceClassesId
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        organizationClassId
        organizationClass {
          id
          name
          classId
          organizationId
          organizationDivisionId
          hasChampionship
          type
          number
          entryFee
          prizeMoney
          minNumberEntries
          maxNumberEntries
          jumpHeight
          estimatedTimePerRound
          createdBy
          displayOrder
          lastUpdatedBy
          createdOn
          updatedOn
          organizationClassPointTableId
          organizationClassPrizeMoneyTableId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryOption
        entryFee
        fees {
          nextToken
        }
        prizeMoney
        entries {
          nextToken
        }
        completedVerifyEntries
        currentNumberEntries
        numberEntriesThatCompeted
        minNumberEntries
        maxNumberEntries
        estimatedTimePerRound
        isCaliforniaSplit
        isCombined
        combinedEventClassId
        combinedEventDivisionId
        jumpHeight
        isScheduled
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventClassPointTableId
        eventClassPrizeMoneyTableId
      }
      entryId
      eventEntry {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      order
      isRandom
      createdOn
      updatedOn
    }
  }
`;
export const onDeleteClassOrder = /* GraphQL */ `
  subscription OnDeleteClassOrder {
    onDeleteClassOrder {
      id
      eventId
      eventClassId
      eventClass {
        id
        name
        eventId
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceClassesId
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        organizationClassId
        organizationClass {
          id
          name
          classId
          organizationId
          organizationDivisionId
          hasChampionship
          type
          number
          entryFee
          prizeMoney
          minNumberEntries
          maxNumberEntries
          jumpHeight
          estimatedTimePerRound
          createdBy
          displayOrder
          lastUpdatedBy
          createdOn
          updatedOn
          organizationClassPointTableId
          organizationClassPrizeMoneyTableId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryOption
        entryFee
        fees {
          nextToken
        }
        prizeMoney
        entries {
          nextToken
        }
        completedVerifyEntries
        currentNumberEntries
        numberEntriesThatCompeted
        minNumberEntries
        maxNumberEntries
        estimatedTimePerRound
        isCaliforniaSplit
        isCombined
        combinedEventClassId
        combinedEventDivisionId
        jumpHeight
        isScheduled
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventClassPointTableId
        eventClassPrizeMoneyTableId
      }
      entryId
      eventEntry {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      order
      isRandom
      createdOn
      updatedOn
    }
  }
`;
export const onCreateClinician = /* GraphQL */ `
  subscription OnCreateClinician {
    onCreateClinician {
      id
      name
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      judgeId
      contactId
      rating {
        type
        license
      }
      usefNumber
      ecNumber
      price
      status
      paymentChoice {
        id
        personId
        organizationId
        type
        stripeId
        altId
        value
        createdOn
        updatedOn
      }
      paymentVerified
      stripeAccountId
      totalEarned
      location
      disciplines
      experience
      personal
      preferences
      hasIHSAExperience
      hasIEAExperience
      createdOn
      updatedOn
      clinicianPaymentChoiceId
    }
  }
`;
export const onUpdateClinician = /* GraphQL */ `
  subscription OnUpdateClinician {
    onUpdateClinician {
      id
      name
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      judgeId
      contactId
      rating {
        type
        license
      }
      usefNumber
      ecNumber
      price
      status
      paymentChoice {
        id
        personId
        organizationId
        type
        stripeId
        altId
        value
        createdOn
        updatedOn
      }
      paymentVerified
      stripeAccountId
      totalEarned
      location
      disciplines
      experience
      personal
      preferences
      hasIHSAExperience
      hasIEAExperience
      createdOn
      updatedOn
      clinicianPaymentChoiceId
    }
  }
`;
export const onDeleteClinician = /* GraphQL */ `
  subscription OnDeleteClinician {
    onDeleteClinician {
      id
      name
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      judgeId
      contactId
      rating {
        type
        license
      }
      usefNumber
      ecNumber
      price
      status
      paymentChoice {
        id
        personId
        organizationId
        type
        stripeId
        altId
        value
        createdOn
        updatedOn
      }
      paymentVerified
      stripeAccountId
      totalEarned
      location
      disciplines
      experience
      personal
      preferences
      hasIHSAExperience
      hasIEAExperience
      createdOn
      updatedOn
      clinicianPaymentChoiceId
    }
  }
`;
export const onCreateCompetition = /* GraphQL */ `
  subscription OnCreateCompetition {
    onCreateCompetition {
      id
      name
      startDate
      endDate
      organizationId
      teamId
      addressId
      address {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      competitionOrganizerName
      competitionOrganizerContactId
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      judgeName
      secretaryName
      StewardName
      isOpenOffered
      isIntermediateOffered
      isNoviceOffered
      isAdvancedBeginnerOffered
      isBeginnerOffered
      isMinisOffered
      isAdultOffered
      isAELMedalOffered
      entryFeePerRider
      entryFeePerRiderForMedal
      feePaymentOptions
      entryClosingDate
      entryClosingTime
      isEntryLimitInPlace
      entryLimitDescription
      otherNotes
      status
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const onUpdateCompetition = /* GraphQL */ `
  subscription OnUpdateCompetition {
    onUpdateCompetition {
      id
      name
      startDate
      endDate
      organizationId
      teamId
      addressId
      address {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      competitionOrganizerName
      competitionOrganizerContactId
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      judgeName
      secretaryName
      StewardName
      isOpenOffered
      isIntermediateOffered
      isNoviceOffered
      isAdvancedBeginnerOffered
      isBeginnerOffered
      isMinisOffered
      isAdultOffered
      isAELMedalOffered
      entryFeePerRider
      entryFeePerRiderForMedal
      feePaymentOptions
      entryClosingDate
      entryClosingTime
      isEntryLimitInPlace
      entryLimitDescription
      otherNotes
      status
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const onDeleteCompetition = /* GraphQL */ `
  subscription OnDeleteCompetition {
    onDeleteCompetition {
      id
      name
      startDate
      endDate
      organizationId
      teamId
      addressId
      address {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      competitionOrganizerName
      competitionOrganizerContactId
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      judgeName
      secretaryName
      StewardName
      isOpenOffered
      isIntermediateOffered
      isNoviceOffered
      isAdvancedBeginnerOffered
      isBeginnerOffered
      isMinisOffered
      isAdultOffered
      isAELMedalOffered
      entryFeePerRider
      entryFeePerRiderForMedal
      feePaymentOptions
      entryClosingDate
      entryClosingTime
      isEntryLimitInPlace
      entryLimitDescription
      otherNotes
      status
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const onCreateContact = /* GraphQL */ `
  subscription OnCreateContact {
    onCreateContact {
      id
      name
      cell
      home
      work
      fax
      personalEmail
      personalEmailIsVerified
      workEmail
      workEmailIsVerified
      mailingAddress
      website
      facebookPage
      instagramHandle
      twitterHandle
      tiktokHandle
      emergencyContactName
      emergencyContactPhone
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateContact = /* GraphQL */ `
  subscription OnUpdateContact {
    onUpdateContact {
      id
      name
      cell
      home
      work
      fax
      personalEmail
      personalEmailIsVerified
      workEmail
      workEmailIsVerified
      mailingAddress
      website
      facebookPage
      instagramHandle
      twitterHandle
      tiktokHandle
      emergencyContactName
      emergencyContactPhone
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteContact = /* GraphQL */ `
  subscription OnDeleteContact {
    onDeleteContact {
      id
      name
      cell
      home
      work
      fax
      personalEmail
      personalEmailIsVerified
      workEmail
      workEmailIsVerified
      mailingAddress
      website
      facebookPage
      instagramHandle
      twitterHandle
      tiktokHandle
      emergencyContactName
      emergencyContactPhone
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDivision = /* GraphQL */ `
  subscription OnCreateDivision {
    onCreateDivision {
      id
      name
      nickname
      description
      discipline
      isSearchable
      ushjaName
      usefNumber
      ecNumber
      ecClassTypeNumber
      ecClassType
      code
      specs
      judgedOn
      rules {
        horseSex
        horseBreed
        horseMinHeight
        horseMaxHeight
        horseMinAge
        horseMaxAge
        riderMinAge
        riderMaxAge
        riderProfessionalStatus
        riderRecord
        horseRecord
        crossEntry
        other
      }
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventInvoiceDivisionsId
    }
  }
`;
export const onUpdateDivision = /* GraphQL */ `
  subscription OnUpdateDivision {
    onUpdateDivision {
      id
      name
      nickname
      description
      discipline
      isSearchable
      ushjaName
      usefNumber
      ecNumber
      ecClassTypeNumber
      ecClassType
      code
      specs
      judgedOn
      rules {
        horseSex
        horseBreed
        horseMinHeight
        horseMaxHeight
        horseMinAge
        horseMaxAge
        riderMinAge
        riderMaxAge
        riderProfessionalStatus
        riderRecord
        horseRecord
        crossEntry
        other
      }
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventInvoiceDivisionsId
    }
  }
`;
export const onDeleteDivision = /* GraphQL */ `
  subscription OnDeleteDivision {
    onDeleteDivision {
      id
      name
      nickname
      description
      discipline
      isSearchable
      ushjaName
      usefNumber
      ecNumber
      ecClassTypeNumber
      ecClassType
      code
      specs
      judgedOn
      rules {
        horseSex
        horseBreed
        horseMinHeight
        horseMaxHeight
        horseMinAge
        horseMaxAge
        riderMinAge
        riderMaxAge
        riderProfessionalStatus
        riderRecord
        horseRecord
        crossEntry
        other
      }
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventInvoiceDivisionsId
    }
  }
`;
export const onCreateEvent = /* GraphQL */ `
  subscription OnCreateEvent {
    onCreateEvent {
      id
      name
      nickname
      createdBy
      manager
      startDate
      endDate
      address {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      contactId
      contactIds
      organizationId
      organization {
        id
        name
        nickname
        description
        type
        industry
        addresses
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        stripeId
        stripeCustomerId
        paymentMethodId
        website
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        lastUpdatedBy
        organizationOptions {
          usesTextAlerts
          acceptsIncomingTextAlerts
        }
        urlBackHalf
        colors
        logos {
          logoURL
          key
        }
        createdOn
        updatedOn
      }
      type
      disciplines
      eventOptions {
        autoAcceptNewEntries
        usesTextAlerts
        acceptsIncomingTextAlerts
        hasUnlimitedCredits
        usesEventManagement
        usesStablingManagement
        usesVendorManagement
        usesMediaTools
        usesMarketingTools
        usesFinanceTools
        usesJudgeApp
      }
      paymentOptions {
        acceptsChecks
        acceptsCash
        acceptsACH
        acceptsCC
      }
      invoiceOptions {
        classFeeTax
        sendEmails
      }
      entryOptions {
        displayHorse2Input
        displayRider2Input
        displayRider3Input
        requiresCogginsDocument
        requiresCogginsData
        maximumNumberClassesOnEntry
      }
      officials {
        items {
          id
          name
          position
          eventId
          personId
          contactId
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      entriesStatus
      showStatus
      usefID
      usefSoftwareKey
      ecID
      ecPassword
      documents {
        title
        description
        originalExtension
        dateUploaded
        url
        bucket
        region
        key
      }
      lastUpdatedBy
      createdOn
      updatedOn
      eventAddressId
    }
  }
`;
export const onUpdateEvent = /* GraphQL */ `
  subscription OnUpdateEvent {
    onUpdateEvent {
      id
      name
      nickname
      createdBy
      manager
      startDate
      endDate
      address {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      contactId
      contactIds
      organizationId
      organization {
        id
        name
        nickname
        description
        type
        industry
        addresses
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        stripeId
        stripeCustomerId
        paymentMethodId
        website
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        lastUpdatedBy
        organizationOptions {
          usesTextAlerts
          acceptsIncomingTextAlerts
        }
        urlBackHalf
        colors
        logos {
          logoURL
          key
        }
        createdOn
        updatedOn
      }
      type
      disciplines
      eventOptions {
        autoAcceptNewEntries
        usesTextAlerts
        acceptsIncomingTextAlerts
        hasUnlimitedCredits
        usesEventManagement
        usesStablingManagement
        usesVendorManagement
        usesMediaTools
        usesMarketingTools
        usesFinanceTools
        usesJudgeApp
      }
      paymentOptions {
        acceptsChecks
        acceptsCash
        acceptsACH
        acceptsCC
      }
      invoiceOptions {
        classFeeTax
        sendEmails
      }
      entryOptions {
        displayHorse2Input
        displayRider2Input
        displayRider3Input
        requiresCogginsDocument
        requiresCogginsData
        maximumNumberClassesOnEntry
      }
      officials {
        items {
          id
          name
          position
          eventId
          personId
          contactId
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      entriesStatus
      showStatus
      usefID
      usefSoftwareKey
      ecID
      ecPassword
      documents {
        title
        description
        originalExtension
        dateUploaded
        url
        bucket
        region
        key
      }
      lastUpdatedBy
      createdOn
      updatedOn
      eventAddressId
    }
  }
`;
export const onDeleteEvent = /* GraphQL */ `
  subscription OnDeleteEvent {
    onDeleteEvent {
      id
      name
      nickname
      createdBy
      manager
      startDate
      endDate
      address {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      contactId
      contactIds
      organizationId
      organization {
        id
        name
        nickname
        description
        type
        industry
        addresses
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        stripeId
        stripeCustomerId
        paymentMethodId
        website
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        lastUpdatedBy
        organizationOptions {
          usesTextAlerts
          acceptsIncomingTextAlerts
        }
        urlBackHalf
        colors
        logos {
          logoURL
          key
        }
        createdOn
        updatedOn
      }
      type
      disciplines
      eventOptions {
        autoAcceptNewEntries
        usesTextAlerts
        acceptsIncomingTextAlerts
        hasUnlimitedCredits
        usesEventManagement
        usesStablingManagement
        usesVendorManagement
        usesMediaTools
        usesMarketingTools
        usesFinanceTools
        usesJudgeApp
      }
      paymentOptions {
        acceptsChecks
        acceptsCash
        acceptsACH
        acceptsCC
      }
      invoiceOptions {
        classFeeTax
        sendEmails
      }
      entryOptions {
        displayHorse2Input
        displayRider2Input
        displayRider3Input
        requiresCogginsDocument
        requiresCogginsData
        maximumNumberClassesOnEntry
      }
      officials {
        items {
          id
          name
          position
          eventId
          personId
          contactId
          createdAt
          updatedAt
        }
        nextToken
      }
      status
      entriesStatus
      showStatus
      usefID
      usefSoftwareKey
      ecID
      ecPassword
      documents {
        title
        description
        originalExtension
        dateUploaded
        url
        bucket
        region
        key
      }
      lastUpdatedBy
      createdOn
      updatedOn
      eventAddressId
    }
  }
`;
export const onCreateEventAdd = /* GraphQL */ `
  subscription OnCreateEventAdd {
    onCreateEventAdd {
      id
      eventId
      entryId
      entry {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      eventDivisionId
      eventDivision {
        id
        eventId
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceDivisionsId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventClasses {
          nextToken
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        entryFee
        prizeMoney
        jumpHeight
        minNumberEntriesPerClass
        maxNumberEntriesPerClass
        isCaliforniaSplit
        isCombined
        combinedEventDivisionId
        combinedEventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventDivisionOrganizationDivisionId
        eventDivisionPointTableId
        eventDivisionPrizeMoneyTableId
      }
      eventDivisionName
      eventClassId
      eventClass {
        id
        name
        eventId
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceClassesId
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        organizationClassId
        organizationClass {
          id
          name
          classId
          organizationId
          organizationDivisionId
          hasChampionship
          type
          number
          entryFee
          prizeMoney
          minNumberEntries
          maxNumberEntries
          jumpHeight
          estimatedTimePerRound
          createdBy
          displayOrder
          lastUpdatedBy
          createdOn
          updatedOn
          organizationClassPointTableId
          organizationClassPrizeMoneyTableId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryOption
        entryFee
        fees {
          nextToken
        }
        prizeMoney
        entries {
          nextToken
        }
        completedVerifyEntries
        currentNumberEntries
        numberEntriesThatCompeted
        minNumberEntries
        maxNumberEntries
        estimatedTimePerRound
        isCaliforniaSplit
        isCombined
        combinedEventClassId
        combinedEventDivisionId
        jumpHeight
        isScheduled
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventClassPointTableId
        eventClassPrizeMoneyTableId
      }
      eventClassName
      status
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const onUpdateEventAdd = /* GraphQL */ `
  subscription OnUpdateEventAdd {
    onUpdateEventAdd {
      id
      eventId
      entryId
      entry {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      eventDivisionId
      eventDivision {
        id
        eventId
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceDivisionsId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventClasses {
          nextToken
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        entryFee
        prizeMoney
        jumpHeight
        minNumberEntriesPerClass
        maxNumberEntriesPerClass
        isCaliforniaSplit
        isCombined
        combinedEventDivisionId
        combinedEventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventDivisionOrganizationDivisionId
        eventDivisionPointTableId
        eventDivisionPrizeMoneyTableId
      }
      eventDivisionName
      eventClassId
      eventClass {
        id
        name
        eventId
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceClassesId
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        organizationClassId
        organizationClass {
          id
          name
          classId
          organizationId
          organizationDivisionId
          hasChampionship
          type
          number
          entryFee
          prizeMoney
          minNumberEntries
          maxNumberEntries
          jumpHeight
          estimatedTimePerRound
          createdBy
          displayOrder
          lastUpdatedBy
          createdOn
          updatedOn
          organizationClassPointTableId
          organizationClassPrizeMoneyTableId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryOption
        entryFee
        fees {
          nextToken
        }
        prizeMoney
        entries {
          nextToken
        }
        completedVerifyEntries
        currentNumberEntries
        numberEntriesThatCompeted
        minNumberEntries
        maxNumberEntries
        estimatedTimePerRound
        isCaliforniaSplit
        isCombined
        combinedEventClassId
        combinedEventDivisionId
        jumpHeight
        isScheduled
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventClassPointTableId
        eventClassPrizeMoneyTableId
      }
      eventClassName
      status
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const onDeleteEventAdd = /* GraphQL */ `
  subscription OnDeleteEventAdd {
    onDeleteEventAdd {
      id
      eventId
      entryId
      entry {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      eventDivisionId
      eventDivision {
        id
        eventId
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceDivisionsId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventClasses {
          nextToken
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        entryFee
        prizeMoney
        jumpHeight
        minNumberEntriesPerClass
        maxNumberEntriesPerClass
        isCaliforniaSplit
        isCombined
        combinedEventDivisionId
        combinedEventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventDivisionOrganizationDivisionId
        eventDivisionPointTableId
        eventDivisionPrizeMoneyTableId
      }
      eventDivisionName
      eventClassId
      eventClass {
        id
        name
        eventId
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceClassesId
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        organizationClassId
        organizationClass {
          id
          name
          classId
          organizationId
          organizationDivisionId
          hasChampionship
          type
          number
          entryFee
          prizeMoney
          minNumberEntries
          maxNumberEntries
          jumpHeight
          estimatedTimePerRound
          createdBy
          displayOrder
          lastUpdatedBy
          createdOn
          updatedOn
          organizationClassPointTableId
          organizationClassPrizeMoneyTableId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryOption
        entryFee
        fees {
          nextToken
        }
        prizeMoney
        entries {
          nextToken
        }
        completedVerifyEntries
        currentNumberEntries
        numberEntriesThatCompeted
        minNumberEntries
        maxNumberEntries
        estimatedTimePerRound
        isCaliforniaSplit
        isCombined
        combinedEventClassId
        combinedEventDivisionId
        jumpHeight
        isScheduled
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventClassPointTableId
        eventClassPrizeMoneyTableId
      }
      eventClassName
      status
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const onCreateEventAlertSetting = /* GraphQL */ `
  subscription OnCreateEventAlertSetting {
    onCreateEventAlertSetting {
      id
      eventId
      categories
      useDescription
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const onUpdateEventAlertSetting = /* GraphQL */ `
  subscription OnUpdateEventAlertSetting {
    onUpdateEventAlertSetting {
      id
      eventId
      categories
      useDescription
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const onDeleteEventAlertSetting = /* GraphQL */ `
  subscription OnDeleteEventAlertSetting {
    onDeleteEventAlertSetting {
      id
      eventId
      categories
      useDescription
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const onCreateEventAuditor = /* GraphQL */ `
  subscription OnCreateEventAuditor {
    onCreateEventAuditor {
      id
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      eventId
      event {
        id
        name
        nickname
        createdBy
        manager
        startDate
        endDate
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contactId
        contactIds
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        type
        disciplines
        eventOptions {
          autoAcceptNewEntries
          usesTextAlerts
          acceptsIncomingTextAlerts
          hasUnlimitedCredits
          usesEventManagement
          usesStablingManagement
          usesVendorManagement
          usesMediaTools
          usesMarketingTools
          usesFinanceTools
          usesJudgeApp
        }
        paymentOptions {
          acceptsChecks
          acceptsCash
          acceptsACH
          acceptsCC
        }
        invoiceOptions {
          classFeeTax
          sendEmails
        }
        entryOptions {
          displayHorse2Input
          displayRider2Input
          displayRider3Input
          requiresCogginsDocument
          requiresCogginsData
          maximumNumberClassesOnEntry
        }
        officials {
          nextToken
        }
        status
        entriesStatus
        showStatus
        usefID
        usefSoftwareKey
        ecID
        ecPassword
        documents {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        eventAddressId
      }
      auditorId
      auditor {
        id
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        name
        phoneNumber
        email
        status
        statusNote
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      filters {
        items {
          id
          personId
          eventId
          eventauditorid
          eventAuditorId
          organizationId
          organizationAuditorId
          filterId
          options
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventAuditorFilterOrganizationId
        }
        nextToken
      }
      name
      phoneNumber
      status
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventAuditorEventId
    }
  }
`;
export const onUpdateEventAuditor = /* GraphQL */ `
  subscription OnUpdateEventAuditor {
    onUpdateEventAuditor {
      id
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      eventId
      event {
        id
        name
        nickname
        createdBy
        manager
        startDate
        endDate
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contactId
        contactIds
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        type
        disciplines
        eventOptions {
          autoAcceptNewEntries
          usesTextAlerts
          acceptsIncomingTextAlerts
          hasUnlimitedCredits
          usesEventManagement
          usesStablingManagement
          usesVendorManagement
          usesMediaTools
          usesMarketingTools
          usesFinanceTools
          usesJudgeApp
        }
        paymentOptions {
          acceptsChecks
          acceptsCash
          acceptsACH
          acceptsCC
        }
        invoiceOptions {
          classFeeTax
          sendEmails
        }
        entryOptions {
          displayHorse2Input
          displayRider2Input
          displayRider3Input
          requiresCogginsDocument
          requiresCogginsData
          maximumNumberClassesOnEntry
        }
        officials {
          nextToken
        }
        status
        entriesStatus
        showStatus
        usefID
        usefSoftwareKey
        ecID
        ecPassword
        documents {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        eventAddressId
      }
      auditorId
      auditor {
        id
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        name
        phoneNumber
        email
        status
        statusNote
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      filters {
        items {
          id
          personId
          eventId
          eventauditorid
          eventAuditorId
          organizationId
          organizationAuditorId
          filterId
          options
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventAuditorFilterOrganizationId
        }
        nextToken
      }
      name
      phoneNumber
      status
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventAuditorEventId
    }
  }
`;
export const onDeleteEventAuditor = /* GraphQL */ `
  subscription OnDeleteEventAuditor {
    onDeleteEventAuditor {
      id
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      eventId
      event {
        id
        name
        nickname
        createdBy
        manager
        startDate
        endDate
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contactId
        contactIds
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        type
        disciplines
        eventOptions {
          autoAcceptNewEntries
          usesTextAlerts
          acceptsIncomingTextAlerts
          hasUnlimitedCredits
          usesEventManagement
          usesStablingManagement
          usesVendorManagement
          usesMediaTools
          usesMarketingTools
          usesFinanceTools
          usesJudgeApp
        }
        paymentOptions {
          acceptsChecks
          acceptsCash
          acceptsACH
          acceptsCC
        }
        invoiceOptions {
          classFeeTax
          sendEmails
        }
        entryOptions {
          displayHorse2Input
          displayRider2Input
          displayRider3Input
          requiresCogginsDocument
          requiresCogginsData
          maximumNumberClassesOnEntry
        }
        officials {
          nextToken
        }
        status
        entriesStatus
        showStatus
        usefID
        usefSoftwareKey
        ecID
        ecPassword
        documents {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        eventAddressId
      }
      auditorId
      auditor {
        id
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        name
        phoneNumber
        email
        status
        statusNote
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      filters {
        items {
          id
          personId
          eventId
          eventauditorid
          eventAuditorId
          organizationId
          organizationAuditorId
          filterId
          options
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventAuditorFilterOrganizationId
        }
        nextToken
      }
      name
      phoneNumber
      status
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventAuditorEventId
    }
  }
`;
export const onCreateOrganizationAuditor = /* GraphQL */ `
  subscription OnCreateOrganizationAuditor {
    onCreateOrganizationAuditor {
      id
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      howSignedUp
      organizationId
      organization {
        id
        name
        nickname
        description
        type
        industry
        addresses
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        stripeId
        stripeCustomerId
        paymentMethodId
        website
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        lastUpdatedBy
        organizationOptions {
          usesTextAlerts
          acceptsIncomingTextAlerts
        }
        urlBackHalf
        colors
        logos {
          logoURL
          key
        }
        createdOn
        updatedOn
      }
      auditorId
      auditor {
        id
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        name
        phoneNumber
        email
        status
        statusNote
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      filters {
        items {
          id
          personId
          eventId
          eventauditorid
          eventAuditorId
          organizationId
          organizationAuditorId
          filterId
          options
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventAuditorFilterOrganizationId
        }
        nextToken
      }
      name
      phoneNumber
      status
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      organizationAuditorPersonId
      organizationAuditorOrganizationId
    }
  }
`;
export const onUpdateOrganizationAuditor = /* GraphQL */ `
  subscription OnUpdateOrganizationAuditor {
    onUpdateOrganizationAuditor {
      id
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      howSignedUp
      organizationId
      organization {
        id
        name
        nickname
        description
        type
        industry
        addresses
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        stripeId
        stripeCustomerId
        paymentMethodId
        website
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        lastUpdatedBy
        organizationOptions {
          usesTextAlerts
          acceptsIncomingTextAlerts
        }
        urlBackHalf
        colors
        logos {
          logoURL
          key
        }
        createdOn
        updatedOn
      }
      auditorId
      auditor {
        id
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        name
        phoneNumber
        email
        status
        statusNote
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      filters {
        items {
          id
          personId
          eventId
          eventauditorid
          eventAuditorId
          organizationId
          organizationAuditorId
          filterId
          options
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventAuditorFilterOrganizationId
        }
        nextToken
      }
      name
      phoneNumber
      status
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      organizationAuditorPersonId
      organizationAuditorOrganizationId
    }
  }
`;
export const onDeleteOrganizationAuditor = /* GraphQL */ `
  subscription OnDeleteOrganizationAuditor {
    onDeleteOrganizationAuditor {
      id
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      howSignedUp
      organizationId
      organization {
        id
        name
        nickname
        description
        type
        industry
        addresses
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        stripeId
        stripeCustomerId
        paymentMethodId
        website
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        lastUpdatedBy
        organizationOptions {
          usesTextAlerts
          acceptsIncomingTextAlerts
        }
        urlBackHalf
        colors
        logos {
          logoURL
          key
        }
        createdOn
        updatedOn
      }
      auditorId
      auditor {
        id
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        name
        phoneNumber
        email
        status
        statusNote
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      filters {
        items {
          id
          personId
          eventId
          eventauditorid
          eventAuditorId
          organizationId
          organizationAuditorId
          filterId
          options
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventAuditorFilterOrganizationId
        }
        nextToken
      }
      name
      phoneNumber
      status
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      organizationAuditorPersonId
      organizationAuditorOrganizationId
    }
  }
`;
export const onCreateEventAuditorFilter = /* GraphQL */ `
  subscription OnCreateEventAuditorFilter {
    onCreateEventAuditorFilter {
      id
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      eventId
      event {
        id
        name
        nickname
        createdBy
        manager
        startDate
        endDate
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contactId
        contactIds
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        type
        disciplines
        eventOptions {
          autoAcceptNewEntries
          usesTextAlerts
          acceptsIncomingTextAlerts
          hasUnlimitedCredits
          usesEventManagement
          usesStablingManagement
          usesVendorManagement
          usesMediaTools
          usesMarketingTools
          usesFinanceTools
          usesJudgeApp
        }
        paymentOptions {
          acceptsChecks
          acceptsCash
          acceptsACH
          acceptsCC
        }
        invoiceOptions {
          classFeeTax
          sendEmails
        }
        entryOptions {
          displayHorse2Input
          displayRider2Input
          displayRider3Input
          requiresCogginsDocument
          requiresCogginsData
          maximumNumberClassesOnEntry
        }
        officials {
          nextToken
        }
        status
        entriesStatus
        showStatus
        usefID
        usefSoftwareKey
        ecID
        ecPassword
        documents {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        eventAddressId
      }
      eventauditorid
      eventAuditorId
      eventAuditor {
        id
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        eventId
        event {
          id
          name
          nickname
          createdBy
          manager
          startDate
          endDate
          contactId
          contactIds
          organizationId
          type
          disciplines
          status
          entriesStatus
          showStatus
          usefID
          usefSoftwareKey
          ecID
          ecPassword
          lastUpdatedBy
          createdOn
          updatedOn
          eventAddressId
        }
        auditorId
        auditor {
          id
          personId
          name
          phoneNumber
          email
          status
          statusNote
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        filters {
          nextToken
        }
        name
        phoneNumber
        status
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventAuditorEventId
      }
      organizationId
      organization {
        id
        name
        nickname
        description
        type
        industry
        addresses
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        stripeId
        stripeCustomerId
        paymentMethodId
        website
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        lastUpdatedBy
        organizationOptions {
          usesTextAlerts
          acceptsIncomingTextAlerts
        }
        urlBackHalf
        colors
        logos {
          logoURL
          key
        }
        createdOn
        updatedOn
      }
      organizationAuditorId
      organizationAuditor {
        id
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        howSignedUp
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        auditorId
        auditor {
          id
          personId
          name
          phoneNumber
          email
          status
          statusNote
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        filters {
          nextToken
        }
        name
        phoneNumber
        status
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        organizationAuditorPersonId
        organizationAuditorOrganizationId
      }
      filterId
      filter {
        id
        eventId
        organizationID
        name
        options
        isRequired
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      options
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventAuditorFilterOrganizationId
    }
  }
`;
export const onUpdateEventAuditorFilter = /* GraphQL */ `
  subscription OnUpdateEventAuditorFilter {
    onUpdateEventAuditorFilter {
      id
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      eventId
      event {
        id
        name
        nickname
        createdBy
        manager
        startDate
        endDate
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contactId
        contactIds
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        type
        disciplines
        eventOptions {
          autoAcceptNewEntries
          usesTextAlerts
          acceptsIncomingTextAlerts
          hasUnlimitedCredits
          usesEventManagement
          usesStablingManagement
          usesVendorManagement
          usesMediaTools
          usesMarketingTools
          usesFinanceTools
          usesJudgeApp
        }
        paymentOptions {
          acceptsChecks
          acceptsCash
          acceptsACH
          acceptsCC
        }
        invoiceOptions {
          classFeeTax
          sendEmails
        }
        entryOptions {
          displayHorse2Input
          displayRider2Input
          displayRider3Input
          requiresCogginsDocument
          requiresCogginsData
          maximumNumberClassesOnEntry
        }
        officials {
          nextToken
        }
        status
        entriesStatus
        showStatus
        usefID
        usefSoftwareKey
        ecID
        ecPassword
        documents {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        eventAddressId
      }
      eventauditorid
      eventAuditorId
      eventAuditor {
        id
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        eventId
        event {
          id
          name
          nickname
          createdBy
          manager
          startDate
          endDate
          contactId
          contactIds
          organizationId
          type
          disciplines
          status
          entriesStatus
          showStatus
          usefID
          usefSoftwareKey
          ecID
          ecPassword
          lastUpdatedBy
          createdOn
          updatedOn
          eventAddressId
        }
        auditorId
        auditor {
          id
          personId
          name
          phoneNumber
          email
          status
          statusNote
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        filters {
          nextToken
        }
        name
        phoneNumber
        status
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventAuditorEventId
      }
      organizationId
      organization {
        id
        name
        nickname
        description
        type
        industry
        addresses
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        stripeId
        stripeCustomerId
        paymentMethodId
        website
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        lastUpdatedBy
        organizationOptions {
          usesTextAlerts
          acceptsIncomingTextAlerts
        }
        urlBackHalf
        colors
        logos {
          logoURL
          key
        }
        createdOn
        updatedOn
      }
      organizationAuditorId
      organizationAuditor {
        id
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        howSignedUp
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        auditorId
        auditor {
          id
          personId
          name
          phoneNumber
          email
          status
          statusNote
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        filters {
          nextToken
        }
        name
        phoneNumber
        status
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        organizationAuditorPersonId
        organizationAuditorOrganizationId
      }
      filterId
      filter {
        id
        eventId
        organizationID
        name
        options
        isRequired
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      options
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventAuditorFilterOrganizationId
    }
  }
`;
export const onDeleteEventAuditorFilter = /* GraphQL */ `
  subscription OnDeleteEventAuditorFilter {
    onDeleteEventAuditorFilter {
      id
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      eventId
      event {
        id
        name
        nickname
        createdBy
        manager
        startDate
        endDate
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contactId
        contactIds
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        type
        disciplines
        eventOptions {
          autoAcceptNewEntries
          usesTextAlerts
          acceptsIncomingTextAlerts
          hasUnlimitedCredits
          usesEventManagement
          usesStablingManagement
          usesVendorManagement
          usesMediaTools
          usesMarketingTools
          usesFinanceTools
          usesJudgeApp
        }
        paymentOptions {
          acceptsChecks
          acceptsCash
          acceptsACH
          acceptsCC
        }
        invoiceOptions {
          classFeeTax
          sendEmails
        }
        entryOptions {
          displayHorse2Input
          displayRider2Input
          displayRider3Input
          requiresCogginsDocument
          requiresCogginsData
          maximumNumberClassesOnEntry
        }
        officials {
          nextToken
        }
        status
        entriesStatus
        showStatus
        usefID
        usefSoftwareKey
        ecID
        ecPassword
        documents {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        eventAddressId
      }
      eventauditorid
      eventAuditorId
      eventAuditor {
        id
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        eventId
        event {
          id
          name
          nickname
          createdBy
          manager
          startDate
          endDate
          contactId
          contactIds
          organizationId
          type
          disciplines
          status
          entriesStatus
          showStatus
          usefID
          usefSoftwareKey
          ecID
          ecPassword
          lastUpdatedBy
          createdOn
          updatedOn
          eventAddressId
        }
        auditorId
        auditor {
          id
          personId
          name
          phoneNumber
          email
          status
          statusNote
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        filters {
          nextToken
        }
        name
        phoneNumber
        status
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventAuditorEventId
      }
      organizationId
      organization {
        id
        name
        nickname
        description
        type
        industry
        addresses
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        stripeId
        stripeCustomerId
        paymentMethodId
        website
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        lastUpdatedBy
        organizationOptions {
          usesTextAlerts
          acceptsIncomingTextAlerts
        }
        urlBackHalf
        colors
        logos {
          logoURL
          key
        }
        createdOn
        updatedOn
      }
      organizationAuditorId
      organizationAuditor {
        id
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        howSignedUp
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        auditorId
        auditor {
          id
          personId
          name
          phoneNumber
          email
          status
          statusNote
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        filters {
          nextToken
        }
        name
        phoneNumber
        status
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        organizationAuditorPersonId
        organizationAuditorOrganizationId
      }
      filterId
      filter {
        id
        eventId
        organizationID
        name
        options
        isRequired
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      options
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventAuditorFilterOrganizationId
    }
  }
`;
export const onCreateEventBeddingType = /* GraphQL */ `
  subscription OnCreateEventBeddingType {
    onCreateEventBeddingType {
      id
      eventId
      organizationId
      organizationBeddingTypeId
      name
      description
      type
      pricePerBag
      taxPerBag
      createdOn
      updatedOn
    }
  }
`;
export const onUpdateEventBeddingType = /* GraphQL */ `
  subscription OnUpdateEventBeddingType {
    onUpdateEventBeddingType {
      id
      eventId
      organizationId
      organizationBeddingTypeId
      name
      description
      type
      pricePerBag
      taxPerBag
      createdOn
      updatedOn
    }
  }
`;
export const onDeleteEventBeddingType = /* GraphQL */ `
  subscription OnDeleteEventBeddingType {
    onDeleteEventBeddingType {
      id
      eventId
      organizationId
      organizationBeddingTypeId
      name
      description
      type
      pricePerBag
      taxPerBag
      createdOn
      updatedOn
    }
  }
`;
export const onCreateEventBreak = /* GraphQL */ `
  subscription OnCreateEventBreak {
    onCreateEventBreak {
      id
      eventId
      name
      time
      isDisplayedOnSchedule
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const onUpdateEventBreak = /* GraphQL */ `
  subscription OnUpdateEventBreak {
    onUpdateEventBreak {
      id
      eventId
      name
      time
      isDisplayedOnSchedule
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const onDeleteEventBreak = /* GraphQL */ `
  subscription OnDeleteEventBreak {
    onDeleteEventBreak {
      id
      eventId
      name
      time
      isDisplayedOnSchedule
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const onCreateEventClass = /* GraphQL */ `
  subscription OnCreateEventClass {
    onCreateEventClass {
      id
      name
      eventId
      classId
      class {
        id
        name
        nickname
        divisionId
        type
        description
        discipline
        ushjaName
        usefNumber
        ecNumber
        ecClassTypeNumber
        ecClassType
        code
        specs
        judgedOn
        rules {
          horseSex
          horseBreed
          horseMinHeight
          horseMaxHeight
          horseMinAge
          horseMaxAge
          riderMinAge
          riderMaxAge
          riderProfessionalStatus
          riderRecord
          horseRecord
          crossEntry
          other
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventInvoiceClassesId
      }
      eventDivisionId
      eventDivision {
        id
        eventId
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceDivisionsId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventClasses {
          nextToken
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        entryFee
        prizeMoney
        jumpHeight
        minNumberEntriesPerClass
        maxNumberEntriesPerClass
        isCaliforniaSplit
        isCombined
        combinedEventDivisionId
        combinedEventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventDivisionOrganizationDivisionId
        eventDivisionPointTableId
        eventDivisionPrizeMoneyTableId
      }
      organizationClassId
      organizationClass {
        id
        name
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceClassesId
        }
        organizationId
        organizationDivisionId
        hasChampionship
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryFee
        prizeMoney
        minNumberEntries
        maxNumberEntries
        jumpHeight
        estimatedTimePerRound
        createdBy
        displayOrder
        lastUpdatedBy
        createdOn
        updatedOn
        organizationClassPointTableId
        organizationClassPrizeMoneyTableId
      }
      organizationDivisionId
      organizationDivision {
        id
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceDivisionsId
        }
        organizationId
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        jumpHeight
        minNumberEntries
        maxNumberEntries
        entryFee
        prizeMoney
        entryFeePerClass
        prizeMoneyPerClass
        createdBy
        lastUpdatedBy
        displayOrder
        createdOn
        updatedOn
        organizationDivisionPointTableId
        organizationDivisionPrizeMoneyTableId
      }
      eventResults {
        items {
          id
          eventId
          entryId
          eventClassEntryId
          eventDivisionId
          eventDivisionName
          eventClassId
          eventClassName
          resultSet
          place
          score
          time
          didNotCompete
          prizeMoney
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultEntryId
          eventResultEventClassEntryId
          eventResultPrizeMoneyTableId
        }
        nextToken
      }
      pointTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isAddValueToRegularFromEntryCount
        isAddValueToChampionshipFromEntryCount
        isUseChampionRule
        isUseReserveRule
        tiers {
          lowEntryCount
          highEntryCount
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        numberEntriesToReceiveSpecialClassPoints
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventResultPointTablesId
      }
      prizeMoneyTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isPercentage
        totalPot
        tiers {
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      type
      number
      entryOption
      entryFee
      fees {
        items {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        nextToken
      }
      prizeMoney
      entries {
        items {
          id
          eventId
          eventClassId
          eventEntryId
          riderId
          status
          selectedEntryOption
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        nextToken
      }
      completedVerifyEntries
      currentNumberEntries
      numberEntriesThatCompeted
      minNumberEntries
      maxNumberEntries
      estimatedTimePerRound
      isCaliforniaSplit
      isCombined
      combinedEventClassId
      combinedEventDivisionId
      jumpHeight
      isScheduled
      note
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventClassPointTableId
      eventClassPrizeMoneyTableId
    }
  }
`;
export const onUpdateEventClass = /* GraphQL */ `
  subscription OnUpdateEventClass {
    onUpdateEventClass {
      id
      name
      eventId
      classId
      class {
        id
        name
        nickname
        divisionId
        type
        description
        discipline
        ushjaName
        usefNumber
        ecNumber
        ecClassTypeNumber
        ecClassType
        code
        specs
        judgedOn
        rules {
          horseSex
          horseBreed
          horseMinHeight
          horseMaxHeight
          horseMinAge
          horseMaxAge
          riderMinAge
          riderMaxAge
          riderProfessionalStatus
          riderRecord
          horseRecord
          crossEntry
          other
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventInvoiceClassesId
      }
      eventDivisionId
      eventDivision {
        id
        eventId
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceDivisionsId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventClasses {
          nextToken
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        entryFee
        prizeMoney
        jumpHeight
        minNumberEntriesPerClass
        maxNumberEntriesPerClass
        isCaliforniaSplit
        isCombined
        combinedEventDivisionId
        combinedEventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventDivisionOrganizationDivisionId
        eventDivisionPointTableId
        eventDivisionPrizeMoneyTableId
      }
      organizationClassId
      organizationClass {
        id
        name
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceClassesId
        }
        organizationId
        organizationDivisionId
        hasChampionship
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryFee
        prizeMoney
        minNumberEntries
        maxNumberEntries
        jumpHeight
        estimatedTimePerRound
        createdBy
        displayOrder
        lastUpdatedBy
        createdOn
        updatedOn
        organizationClassPointTableId
        organizationClassPrizeMoneyTableId
      }
      organizationDivisionId
      organizationDivision {
        id
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceDivisionsId
        }
        organizationId
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        jumpHeight
        minNumberEntries
        maxNumberEntries
        entryFee
        prizeMoney
        entryFeePerClass
        prizeMoneyPerClass
        createdBy
        lastUpdatedBy
        displayOrder
        createdOn
        updatedOn
        organizationDivisionPointTableId
        organizationDivisionPrizeMoneyTableId
      }
      eventResults {
        items {
          id
          eventId
          entryId
          eventClassEntryId
          eventDivisionId
          eventDivisionName
          eventClassId
          eventClassName
          resultSet
          place
          score
          time
          didNotCompete
          prizeMoney
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultEntryId
          eventResultEventClassEntryId
          eventResultPrizeMoneyTableId
        }
        nextToken
      }
      pointTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isAddValueToRegularFromEntryCount
        isAddValueToChampionshipFromEntryCount
        isUseChampionRule
        isUseReserveRule
        tiers {
          lowEntryCount
          highEntryCount
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        numberEntriesToReceiveSpecialClassPoints
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventResultPointTablesId
      }
      prizeMoneyTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isPercentage
        totalPot
        tiers {
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      type
      number
      entryOption
      entryFee
      fees {
        items {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        nextToken
      }
      prizeMoney
      entries {
        items {
          id
          eventId
          eventClassId
          eventEntryId
          riderId
          status
          selectedEntryOption
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        nextToken
      }
      completedVerifyEntries
      currentNumberEntries
      numberEntriesThatCompeted
      minNumberEntries
      maxNumberEntries
      estimatedTimePerRound
      isCaliforniaSplit
      isCombined
      combinedEventClassId
      combinedEventDivisionId
      jumpHeight
      isScheduled
      note
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventClassPointTableId
      eventClassPrizeMoneyTableId
    }
  }
`;
export const onDeleteEventClass = /* GraphQL */ `
  subscription OnDeleteEventClass {
    onDeleteEventClass {
      id
      name
      eventId
      classId
      class {
        id
        name
        nickname
        divisionId
        type
        description
        discipline
        ushjaName
        usefNumber
        ecNumber
        ecClassTypeNumber
        ecClassType
        code
        specs
        judgedOn
        rules {
          horseSex
          horseBreed
          horseMinHeight
          horseMaxHeight
          horseMinAge
          horseMaxAge
          riderMinAge
          riderMaxAge
          riderProfessionalStatus
          riderRecord
          horseRecord
          crossEntry
          other
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventInvoiceClassesId
      }
      eventDivisionId
      eventDivision {
        id
        eventId
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceDivisionsId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventClasses {
          nextToken
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        entryFee
        prizeMoney
        jumpHeight
        minNumberEntriesPerClass
        maxNumberEntriesPerClass
        isCaliforniaSplit
        isCombined
        combinedEventDivisionId
        combinedEventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventDivisionOrganizationDivisionId
        eventDivisionPointTableId
        eventDivisionPrizeMoneyTableId
      }
      organizationClassId
      organizationClass {
        id
        name
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceClassesId
        }
        organizationId
        organizationDivisionId
        hasChampionship
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryFee
        prizeMoney
        minNumberEntries
        maxNumberEntries
        jumpHeight
        estimatedTimePerRound
        createdBy
        displayOrder
        lastUpdatedBy
        createdOn
        updatedOn
        organizationClassPointTableId
        organizationClassPrizeMoneyTableId
      }
      organizationDivisionId
      organizationDivision {
        id
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceDivisionsId
        }
        organizationId
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        jumpHeight
        minNumberEntries
        maxNumberEntries
        entryFee
        prizeMoney
        entryFeePerClass
        prizeMoneyPerClass
        createdBy
        lastUpdatedBy
        displayOrder
        createdOn
        updatedOn
        organizationDivisionPointTableId
        organizationDivisionPrizeMoneyTableId
      }
      eventResults {
        items {
          id
          eventId
          entryId
          eventClassEntryId
          eventDivisionId
          eventDivisionName
          eventClassId
          eventClassName
          resultSet
          place
          score
          time
          didNotCompete
          prizeMoney
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultEntryId
          eventResultEventClassEntryId
          eventResultPrizeMoneyTableId
        }
        nextToken
      }
      pointTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isAddValueToRegularFromEntryCount
        isAddValueToChampionshipFromEntryCount
        isUseChampionRule
        isUseReserveRule
        tiers {
          lowEntryCount
          highEntryCount
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        numberEntriesToReceiveSpecialClassPoints
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventResultPointTablesId
      }
      prizeMoneyTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isPercentage
        totalPot
        tiers {
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      type
      number
      entryOption
      entryFee
      fees {
        items {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        nextToken
      }
      prizeMoney
      entries {
        items {
          id
          eventId
          eventClassId
          eventEntryId
          riderId
          status
          selectedEntryOption
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        nextToken
      }
      completedVerifyEntries
      currentNumberEntries
      numberEntriesThatCompeted
      minNumberEntries
      maxNumberEntries
      estimatedTimePerRound
      isCaliforniaSplit
      isCombined
      combinedEventClassId
      combinedEventDivisionId
      jumpHeight
      isScheduled
      note
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventClassPointTableId
      eventClassPrizeMoneyTableId
    }
  }
`;
export const onCreateEventClassEntry = /* GraphQL */ `
  subscription OnCreateEventClassEntry {
    onCreateEventClassEntry {
      id
      eventId
      eventClassId
      eventClass {
        id
        name
        eventId
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceClassesId
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        organizationClassId
        organizationClass {
          id
          name
          classId
          organizationId
          organizationDivisionId
          hasChampionship
          type
          number
          entryFee
          prizeMoney
          minNumberEntries
          maxNumberEntries
          jumpHeight
          estimatedTimePerRound
          createdBy
          displayOrder
          lastUpdatedBy
          createdOn
          updatedOn
          organizationClassPointTableId
          organizationClassPrizeMoneyTableId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryOption
        entryFee
        fees {
          nextToken
        }
        prizeMoney
        entries {
          nextToken
        }
        completedVerifyEntries
        currentNumberEntries
        numberEntriesThatCompeted
        minNumberEntries
        maxNumberEntries
        estimatedTimePerRound
        isCaliforniaSplit
        isCombined
        combinedEventClassId
        combinedEventDivisionId
        jumpHeight
        isScheduled
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventClassPointTableId
        eventClassPrizeMoneyTableId
      }
      eventEntryId
      eventEntry {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      riderId
      rider {
        id
        name
        location
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        personId
        age
        birthdate
        isProfessional
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        createdAt
        updatedAt
        riderContactId
      }
      status
      selectedEntryOption
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const onUpdateEventClassEntry = /* GraphQL */ `
  subscription OnUpdateEventClassEntry {
    onUpdateEventClassEntry {
      id
      eventId
      eventClassId
      eventClass {
        id
        name
        eventId
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceClassesId
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        organizationClassId
        organizationClass {
          id
          name
          classId
          organizationId
          organizationDivisionId
          hasChampionship
          type
          number
          entryFee
          prizeMoney
          minNumberEntries
          maxNumberEntries
          jumpHeight
          estimatedTimePerRound
          createdBy
          displayOrder
          lastUpdatedBy
          createdOn
          updatedOn
          organizationClassPointTableId
          organizationClassPrizeMoneyTableId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryOption
        entryFee
        fees {
          nextToken
        }
        prizeMoney
        entries {
          nextToken
        }
        completedVerifyEntries
        currentNumberEntries
        numberEntriesThatCompeted
        minNumberEntries
        maxNumberEntries
        estimatedTimePerRound
        isCaliforniaSplit
        isCombined
        combinedEventClassId
        combinedEventDivisionId
        jumpHeight
        isScheduled
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventClassPointTableId
        eventClassPrizeMoneyTableId
      }
      eventEntryId
      eventEntry {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      riderId
      rider {
        id
        name
        location
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        personId
        age
        birthdate
        isProfessional
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        createdAt
        updatedAt
        riderContactId
      }
      status
      selectedEntryOption
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const onDeleteEventClassEntry = /* GraphQL */ `
  subscription OnDeleteEventClassEntry {
    onDeleteEventClassEntry {
      id
      eventId
      eventClassId
      eventClass {
        id
        name
        eventId
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceClassesId
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        organizationClassId
        organizationClass {
          id
          name
          classId
          organizationId
          organizationDivisionId
          hasChampionship
          type
          number
          entryFee
          prizeMoney
          minNumberEntries
          maxNumberEntries
          jumpHeight
          estimatedTimePerRound
          createdBy
          displayOrder
          lastUpdatedBy
          createdOn
          updatedOn
          organizationClassPointTableId
          organizationClassPrizeMoneyTableId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryOption
        entryFee
        fees {
          nextToken
        }
        prizeMoney
        entries {
          nextToken
        }
        completedVerifyEntries
        currentNumberEntries
        numberEntriesThatCompeted
        minNumberEntries
        maxNumberEntries
        estimatedTimePerRound
        isCaliforniaSplit
        isCombined
        combinedEventClassId
        combinedEventDivisionId
        jumpHeight
        isScheduled
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventClassPointTableId
        eventClassPrizeMoneyTableId
      }
      eventEntryId
      eventEntry {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      riderId
      rider {
        id
        name
        location
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        personId
        age
        birthdate
        isProfessional
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        createdAt
        updatedAt
        riderContactId
      }
      status
      selectedEntryOption
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const onCreateEventDay = /* GraphQL */ `
  subscription OnCreateEventDay {
    onCreateEventDay {
      id
      eventId
      awsdate
      dayofweek
      date
      month
      year
      createdOn
      updatedOn
    }
  }
`;
export const onUpdateEventDay = /* GraphQL */ `
  subscription OnUpdateEventDay {
    onUpdateEventDay {
      id
      eventId
      awsdate
      dayofweek
      date
      month
      year
      createdOn
      updatedOn
    }
  }
`;
export const onDeleteEventDay = /* GraphQL */ `
  subscription OnDeleteEventDay {
    onDeleteEventDay {
      id
      eventId
      awsdate
      dayofweek
      date
      month
      year
      createdOn
      updatedOn
    }
  }
`;
export const onCreateEventDivision = /* GraphQL */ `
  subscription OnCreateEventDivision {
    onCreateEventDivision {
      id
      eventId
      name
      number
      divisionId
      division {
        id
        name
        nickname
        description
        discipline
        isSearchable
        ushjaName
        usefNumber
        ecNumber
        ecClassTypeNumber
        ecClassType
        code
        specs
        judgedOn
        rules {
          horseSex
          horseBreed
          horseMinHeight
          horseMaxHeight
          horseMinAge
          horseMaxAge
          riderMinAge
          riderMaxAge
          riderProfessionalStatus
          riderRecord
          horseRecord
          crossEntry
          other
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventInvoiceDivisionsId
      }
      organizationDivisionId
      organizationDivision {
        id
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceDivisionsId
        }
        organizationId
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        jumpHeight
        minNumberEntries
        maxNumberEntries
        entryFee
        prizeMoney
        entryFeePerClass
        prizeMoneyPerClass
        createdBy
        lastUpdatedBy
        displayOrder
        createdOn
        updatedOn
        organizationDivisionPointTableId
        organizationDivisionPrizeMoneyTableId
      }
      eventClasses {
        items {
          id
          name
          eventId
          classId
          eventDivisionId
          organizationClassId
          organizationDivisionId
          type
          number
          entryOption
          entryFee
          prizeMoney
          completedVerifyEntries
          currentNumberEntries
          numberEntriesThatCompeted
          minNumberEntries
          maxNumberEntries
          estimatedTimePerRound
          isCaliforniaSplit
          isCombined
          combinedEventClassId
          combinedEventDivisionId
          jumpHeight
          isScheduled
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventClassPointTableId
          eventClassPrizeMoneyTableId
        }
        nextToken
      }
      eventResults {
        items {
          id
          eventId
          entryId
          eventClassEntryId
          eventDivisionId
          eventDivisionName
          eventClassId
          eventClassName
          resultSet
          place
          score
          time
          didNotCompete
          prizeMoney
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultEntryId
          eventResultEventClassEntryId
          eventResultPrizeMoneyTableId
        }
        nextToken
      }
      pointTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isAddValueToRegularFromEntryCount
        isAddValueToChampionshipFromEntryCount
        isUseChampionRule
        isUseReserveRule
        tiers {
          lowEntryCount
          highEntryCount
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        numberEntriesToReceiveSpecialClassPoints
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventResultPointTablesId
      }
      prizeMoneyTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isPercentage
        totalPot
        tiers {
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      entryFee
      prizeMoney
      jumpHeight
      minNumberEntriesPerClass
      maxNumberEntriesPerClass
      isCaliforniaSplit
      isCombined
      combinedEventDivisionId
      combinedEventDivision {
        id
        eventId
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceDivisionsId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventClasses {
          nextToken
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        entryFee
        prizeMoney
        jumpHeight
        minNumberEntriesPerClass
        maxNumberEntriesPerClass
        isCaliforniaSplit
        isCombined
        combinedEventDivisionId
        combinedEventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventDivisionOrganizationDivisionId
        eventDivisionPointTableId
        eventDivisionPrizeMoneyTableId
      }
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventDivisionOrganizationDivisionId
      eventDivisionPointTableId
      eventDivisionPrizeMoneyTableId
    }
  }
`;
export const onUpdateEventDivision = /* GraphQL */ `
  subscription OnUpdateEventDivision {
    onUpdateEventDivision {
      id
      eventId
      name
      number
      divisionId
      division {
        id
        name
        nickname
        description
        discipline
        isSearchable
        ushjaName
        usefNumber
        ecNumber
        ecClassTypeNumber
        ecClassType
        code
        specs
        judgedOn
        rules {
          horseSex
          horseBreed
          horseMinHeight
          horseMaxHeight
          horseMinAge
          horseMaxAge
          riderMinAge
          riderMaxAge
          riderProfessionalStatus
          riderRecord
          horseRecord
          crossEntry
          other
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventInvoiceDivisionsId
      }
      organizationDivisionId
      organizationDivision {
        id
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceDivisionsId
        }
        organizationId
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        jumpHeight
        minNumberEntries
        maxNumberEntries
        entryFee
        prizeMoney
        entryFeePerClass
        prizeMoneyPerClass
        createdBy
        lastUpdatedBy
        displayOrder
        createdOn
        updatedOn
        organizationDivisionPointTableId
        organizationDivisionPrizeMoneyTableId
      }
      eventClasses {
        items {
          id
          name
          eventId
          classId
          eventDivisionId
          organizationClassId
          organizationDivisionId
          type
          number
          entryOption
          entryFee
          prizeMoney
          completedVerifyEntries
          currentNumberEntries
          numberEntriesThatCompeted
          minNumberEntries
          maxNumberEntries
          estimatedTimePerRound
          isCaliforniaSplit
          isCombined
          combinedEventClassId
          combinedEventDivisionId
          jumpHeight
          isScheduled
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventClassPointTableId
          eventClassPrizeMoneyTableId
        }
        nextToken
      }
      eventResults {
        items {
          id
          eventId
          entryId
          eventClassEntryId
          eventDivisionId
          eventDivisionName
          eventClassId
          eventClassName
          resultSet
          place
          score
          time
          didNotCompete
          prizeMoney
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultEntryId
          eventResultEventClassEntryId
          eventResultPrizeMoneyTableId
        }
        nextToken
      }
      pointTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isAddValueToRegularFromEntryCount
        isAddValueToChampionshipFromEntryCount
        isUseChampionRule
        isUseReserveRule
        tiers {
          lowEntryCount
          highEntryCount
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        numberEntriesToReceiveSpecialClassPoints
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventResultPointTablesId
      }
      prizeMoneyTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isPercentage
        totalPot
        tiers {
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      entryFee
      prizeMoney
      jumpHeight
      minNumberEntriesPerClass
      maxNumberEntriesPerClass
      isCaliforniaSplit
      isCombined
      combinedEventDivisionId
      combinedEventDivision {
        id
        eventId
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceDivisionsId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventClasses {
          nextToken
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        entryFee
        prizeMoney
        jumpHeight
        minNumberEntriesPerClass
        maxNumberEntriesPerClass
        isCaliforniaSplit
        isCombined
        combinedEventDivisionId
        combinedEventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventDivisionOrganizationDivisionId
        eventDivisionPointTableId
        eventDivisionPrizeMoneyTableId
      }
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventDivisionOrganizationDivisionId
      eventDivisionPointTableId
      eventDivisionPrizeMoneyTableId
    }
  }
`;
export const onDeleteEventDivision = /* GraphQL */ `
  subscription OnDeleteEventDivision {
    onDeleteEventDivision {
      id
      eventId
      name
      number
      divisionId
      division {
        id
        name
        nickname
        description
        discipline
        isSearchable
        ushjaName
        usefNumber
        ecNumber
        ecClassTypeNumber
        ecClassType
        code
        specs
        judgedOn
        rules {
          horseSex
          horseBreed
          horseMinHeight
          horseMaxHeight
          horseMinAge
          horseMaxAge
          riderMinAge
          riderMaxAge
          riderProfessionalStatus
          riderRecord
          horseRecord
          crossEntry
          other
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventInvoiceDivisionsId
      }
      organizationDivisionId
      organizationDivision {
        id
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceDivisionsId
        }
        organizationId
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        jumpHeight
        minNumberEntries
        maxNumberEntries
        entryFee
        prizeMoney
        entryFeePerClass
        prizeMoneyPerClass
        createdBy
        lastUpdatedBy
        displayOrder
        createdOn
        updatedOn
        organizationDivisionPointTableId
        organizationDivisionPrizeMoneyTableId
      }
      eventClasses {
        items {
          id
          name
          eventId
          classId
          eventDivisionId
          organizationClassId
          organizationDivisionId
          type
          number
          entryOption
          entryFee
          prizeMoney
          completedVerifyEntries
          currentNumberEntries
          numberEntriesThatCompeted
          minNumberEntries
          maxNumberEntries
          estimatedTimePerRound
          isCaliforniaSplit
          isCombined
          combinedEventClassId
          combinedEventDivisionId
          jumpHeight
          isScheduled
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventClassPointTableId
          eventClassPrizeMoneyTableId
        }
        nextToken
      }
      eventResults {
        items {
          id
          eventId
          entryId
          eventClassEntryId
          eventDivisionId
          eventDivisionName
          eventClassId
          eventClassName
          resultSet
          place
          score
          time
          didNotCompete
          prizeMoney
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultEntryId
          eventResultEventClassEntryId
          eventResultPrizeMoneyTableId
        }
        nextToken
      }
      pointTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isAddValueToRegularFromEntryCount
        isAddValueToChampionshipFromEntryCount
        isUseChampionRule
        isUseReserveRule
        tiers {
          lowEntryCount
          highEntryCount
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        numberEntriesToReceiveSpecialClassPoints
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventResultPointTablesId
      }
      prizeMoneyTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isPercentage
        totalPot
        tiers {
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      entryFee
      prizeMoney
      jumpHeight
      minNumberEntriesPerClass
      maxNumberEntriesPerClass
      isCaliforniaSplit
      isCombined
      combinedEventDivisionId
      combinedEventDivision {
        id
        eventId
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceDivisionsId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventClasses {
          nextToken
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        entryFee
        prizeMoney
        jumpHeight
        minNumberEntriesPerClass
        maxNumberEntriesPerClass
        isCaliforniaSplit
        isCombined
        combinedEventDivisionId
        combinedEventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventDivisionOrganizationDivisionId
        eventDivisionPointTableId
        eventDivisionPrizeMoneyTableId
      }
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventDivisionOrganizationDivisionId
      eventDivisionPointTableId
      eventDivisionPrizeMoneyTableId
    }
  }
`;
export const onCreateEventEntry = /* GraphQL */ `
  subscription OnCreateEventEntry {
    onCreateEventEntry {
      id
      eventId
      status
      statusNote
      personId
      number
      isCheckedIn
      isCheckedOut
      contactId
      primarycontact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      riderName
      riderId
      rider {
        id
        name
        location
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        personId
        age
        birthdate
        isProfessional
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        createdAt
        updatedAt
        riderContactId
      }
      secondRiderName
      secondRiderId
      secondRider {
        id
        name
        location
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        personId
        age
        birthdate
        isProfessional
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        createdAt
        updatedAt
        riderContactId
      }
      thirdRiderName
      thirdRiderId
      thirdRider {
        id
        name
        location
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        personId
        age
        birthdate
        isProfessional
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        createdAt
        updatedAt
        riderContactId
      }
      horseName
      horseId
      horse {
        id
        name
        nickname
        previousNames
        personId
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        ownerName
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        microchipNumber
        passportNumber
        dateOfBirth
        breed
        sex
        color
        markings
        height
        foalState
        cogginsNumber
        cogginsDate
        cogginsState
        coggins {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        memberships {
          nextToken
        }
        highestLevelCompeted
        greenEligibility
        createdOn
        updatedOn
      }
      ownerName
      ownerId
      owner {
        id
        name
        type
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnId
        createdBy
        createdOn
        updatedOn
        ownerContactId
      }
      secondOwnerName
      secondOwnerId
      secondOwner {
        id
        name
        type
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnId
        createdBy
        createdOn
        updatedOn
        ownerContactId
      }
      thirdOwnerName
      thirdOwnerId
      thirdOwner {
        id
        name
        type
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnId
        createdBy
        createdOn
        updatedOn
        ownerContactId
      }
      trainerName
      trainerId
      trainer {
        id
        name
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        trainerContactId
      }
      secondTrainerName
      secondTrainerId
      secondTrainer {
        id
        name
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        trainerContactId
      }
      thirdTrainerName
      thirdTrainerId
      thirdTrainer {
        id
        name
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        trainerContactId
      }
      barnName
      barnId
      barn {
        id
        name
        nickname
        description
        type
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        ownerName
        ownerPersonId
        secondaryOwnerName
        secondaryOwnerPersonId
        members {
          nextToken
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        barnContactId
        barnLocationId
      }
      stablingRequestId
      stablingRequest {
        id
        eventId
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        arrivalDateTime
        departureDateTime
        stallTypeId
        stallType {
          id
          eventId
          organizationId
          organizationStallTypeId
          name
          description
          type
          pricePerStall
          taxPerStall
          createdOn
          updatedOn
        }
        quantityNeeded
        basePrice
        taxA
        taxB
        totalPrice
        status
        statusNote
        requestNote
        stallSetId
        stallSet {
          id
          eventId
          organizationId
          organizationStallSetId
          name
          location
          available
          totalAvailable
          used
          totalUsed
          createdOn
          updatedOn
        }
        stallLocation
        stallNumbers
        payerId
        payerName
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        splitAcrossEntries
        entries {
          nextToken
        }
        entryIds
        submittedEntry
        lastUpdatedBy
        createdOn
        updatedOn
      }
      beddingRequestId
      beddingRequest {
        id
        eventId
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        arrivalDateTime
        dateTimeNeeded
        deliveryInfo
        beddingType
        quantityNeeded
        basePrice
        taxA {
          id
          name
          createdBy
          organizationId
          stripeTaxRateId
          percentage
          createdOn
          updatedOn
        }
        taxB {
          id
          name
          createdBy
          organizationId
          stripeTaxRateId
          percentage
          createdOn
          updatedOn
        }
        totalPrice
        status
        statusNote
        payerId
        payerName
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        splitAcrossEntries
        entries {
          nextToken
        }
        entryIds
        submittedEntry
        lastUpdatedBy
        createdOn
        updatedOn
        beddingRequestTaxAId
        beddingRequestTaxBId
      }
      markedDoesNotNeedStalls
      payerName
      payerId
      payer {
        id
        name
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        paymentMethod {
          id
          personId
          organizationId
          type
          stripeId
          altId
          value
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
        payerContactId
        payerPaymentMethodId
      }
      prizeMoneyRecipientName
      prizeMoneyRecipientId
      prizeMoneyRecipient {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      prizeMoneyRecipientContact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      prizeMoneyRecipientType
      eventRulesAgreements {
        items {
          id
          eventId
          eventRuleId
          eventEntryId
          name
          personId
          personType
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        nextToken
      }
      note
      divisionList
      classList {
        items {
          id
          eventId
          eventClassId
          eventEntryId
          riderId
          status
          selectedEntryOption
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        nextToken
      }
      fees {
        id
        name
        amount
        description
        eventId
        createdBy
        eventClassId
        eventClass {
          id
          name
          eventId
          classId
          eventDivisionId
          organizationClassId
          organizationDivisionId
          type
          number
          entryOption
          entryFee
          prizeMoney
          completedVerifyEntries
          currentNumberEntries
          numberEntriesThatCompeted
          minNumberEntries
          maxNumberEntries
          estimatedTimePerRound
          isCaliforniaSplit
          isCombined
          combinedEventClassId
          combinedEventDivisionId
          jumpHeight
          isScheduled
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventClassPointTableId
          eventClassPrizeMoneyTableId
        }
        feeId
        fee {
          id
          name
          description
          createdBy
          organizationId
          stripeId
          altId
          isFixed
          isPercentage
          totalPrice
          quantity
          isPerEntry
          isPerHorse
          isPerRider
          isPerClass
          isPerTrainer
          isRefundable
          isChargedAtTimeOfEntry
          isStartedOnEntryDate
          isHiddenFromEntryForm
          startDate
          startTime
          taxA
          taxB
          createdOn
          updatedOn
          eventInvoiceFeesId
          feeTaxRateAId
          feeTaxRateBId
        }
        splitAcrossEntries
        entries {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        createdOn
        updatedOn
      }
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventEntryPrizeMoneyRecipientContactId
    }
  }
`;
export const onUpdateEventEntry = /* GraphQL */ `
  subscription OnUpdateEventEntry {
    onUpdateEventEntry {
      id
      eventId
      status
      statusNote
      personId
      number
      isCheckedIn
      isCheckedOut
      contactId
      primarycontact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      riderName
      riderId
      rider {
        id
        name
        location
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        personId
        age
        birthdate
        isProfessional
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        createdAt
        updatedAt
        riderContactId
      }
      secondRiderName
      secondRiderId
      secondRider {
        id
        name
        location
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        personId
        age
        birthdate
        isProfessional
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        createdAt
        updatedAt
        riderContactId
      }
      thirdRiderName
      thirdRiderId
      thirdRider {
        id
        name
        location
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        personId
        age
        birthdate
        isProfessional
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        createdAt
        updatedAt
        riderContactId
      }
      horseName
      horseId
      horse {
        id
        name
        nickname
        previousNames
        personId
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        ownerName
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        microchipNumber
        passportNumber
        dateOfBirth
        breed
        sex
        color
        markings
        height
        foalState
        cogginsNumber
        cogginsDate
        cogginsState
        coggins {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        memberships {
          nextToken
        }
        highestLevelCompeted
        greenEligibility
        createdOn
        updatedOn
      }
      ownerName
      ownerId
      owner {
        id
        name
        type
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnId
        createdBy
        createdOn
        updatedOn
        ownerContactId
      }
      secondOwnerName
      secondOwnerId
      secondOwner {
        id
        name
        type
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnId
        createdBy
        createdOn
        updatedOn
        ownerContactId
      }
      thirdOwnerName
      thirdOwnerId
      thirdOwner {
        id
        name
        type
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnId
        createdBy
        createdOn
        updatedOn
        ownerContactId
      }
      trainerName
      trainerId
      trainer {
        id
        name
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        trainerContactId
      }
      secondTrainerName
      secondTrainerId
      secondTrainer {
        id
        name
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        trainerContactId
      }
      thirdTrainerName
      thirdTrainerId
      thirdTrainer {
        id
        name
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        trainerContactId
      }
      barnName
      barnId
      barn {
        id
        name
        nickname
        description
        type
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        ownerName
        ownerPersonId
        secondaryOwnerName
        secondaryOwnerPersonId
        members {
          nextToken
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        barnContactId
        barnLocationId
      }
      stablingRequestId
      stablingRequest {
        id
        eventId
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        arrivalDateTime
        departureDateTime
        stallTypeId
        stallType {
          id
          eventId
          organizationId
          organizationStallTypeId
          name
          description
          type
          pricePerStall
          taxPerStall
          createdOn
          updatedOn
        }
        quantityNeeded
        basePrice
        taxA
        taxB
        totalPrice
        status
        statusNote
        requestNote
        stallSetId
        stallSet {
          id
          eventId
          organizationId
          organizationStallSetId
          name
          location
          available
          totalAvailable
          used
          totalUsed
          createdOn
          updatedOn
        }
        stallLocation
        stallNumbers
        payerId
        payerName
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        splitAcrossEntries
        entries {
          nextToken
        }
        entryIds
        submittedEntry
        lastUpdatedBy
        createdOn
        updatedOn
      }
      beddingRequestId
      beddingRequest {
        id
        eventId
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        arrivalDateTime
        dateTimeNeeded
        deliveryInfo
        beddingType
        quantityNeeded
        basePrice
        taxA {
          id
          name
          createdBy
          organizationId
          stripeTaxRateId
          percentage
          createdOn
          updatedOn
        }
        taxB {
          id
          name
          createdBy
          organizationId
          stripeTaxRateId
          percentage
          createdOn
          updatedOn
        }
        totalPrice
        status
        statusNote
        payerId
        payerName
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        splitAcrossEntries
        entries {
          nextToken
        }
        entryIds
        submittedEntry
        lastUpdatedBy
        createdOn
        updatedOn
        beddingRequestTaxAId
        beddingRequestTaxBId
      }
      markedDoesNotNeedStalls
      payerName
      payerId
      payer {
        id
        name
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        paymentMethod {
          id
          personId
          organizationId
          type
          stripeId
          altId
          value
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
        payerContactId
        payerPaymentMethodId
      }
      prizeMoneyRecipientName
      prizeMoneyRecipientId
      prizeMoneyRecipient {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      prizeMoneyRecipientContact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      prizeMoneyRecipientType
      eventRulesAgreements {
        items {
          id
          eventId
          eventRuleId
          eventEntryId
          name
          personId
          personType
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        nextToken
      }
      note
      divisionList
      classList {
        items {
          id
          eventId
          eventClassId
          eventEntryId
          riderId
          status
          selectedEntryOption
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        nextToken
      }
      fees {
        id
        name
        amount
        description
        eventId
        createdBy
        eventClassId
        eventClass {
          id
          name
          eventId
          classId
          eventDivisionId
          organizationClassId
          organizationDivisionId
          type
          number
          entryOption
          entryFee
          prizeMoney
          completedVerifyEntries
          currentNumberEntries
          numberEntriesThatCompeted
          minNumberEntries
          maxNumberEntries
          estimatedTimePerRound
          isCaliforniaSplit
          isCombined
          combinedEventClassId
          combinedEventDivisionId
          jumpHeight
          isScheduled
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventClassPointTableId
          eventClassPrizeMoneyTableId
        }
        feeId
        fee {
          id
          name
          description
          createdBy
          organizationId
          stripeId
          altId
          isFixed
          isPercentage
          totalPrice
          quantity
          isPerEntry
          isPerHorse
          isPerRider
          isPerClass
          isPerTrainer
          isRefundable
          isChargedAtTimeOfEntry
          isStartedOnEntryDate
          isHiddenFromEntryForm
          startDate
          startTime
          taxA
          taxB
          createdOn
          updatedOn
          eventInvoiceFeesId
          feeTaxRateAId
          feeTaxRateBId
        }
        splitAcrossEntries
        entries {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        createdOn
        updatedOn
      }
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventEntryPrizeMoneyRecipientContactId
    }
  }
`;
export const onDeleteEventEntry = /* GraphQL */ `
  subscription OnDeleteEventEntry {
    onDeleteEventEntry {
      id
      eventId
      status
      statusNote
      personId
      number
      isCheckedIn
      isCheckedOut
      contactId
      primarycontact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      riderName
      riderId
      rider {
        id
        name
        location
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        personId
        age
        birthdate
        isProfessional
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        createdAt
        updatedAt
        riderContactId
      }
      secondRiderName
      secondRiderId
      secondRider {
        id
        name
        location
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        personId
        age
        birthdate
        isProfessional
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        createdAt
        updatedAt
        riderContactId
      }
      thirdRiderName
      thirdRiderId
      thirdRider {
        id
        name
        location
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        personId
        age
        birthdate
        isProfessional
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        createdAt
        updatedAt
        riderContactId
      }
      horseName
      horseId
      horse {
        id
        name
        nickname
        previousNames
        personId
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        ownerName
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        microchipNumber
        passportNumber
        dateOfBirth
        breed
        sex
        color
        markings
        height
        foalState
        cogginsNumber
        cogginsDate
        cogginsState
        coggins {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        memberships {
          nextToken
        }
        highestLevelCompeted
        greenEligibility
        createdOn
        updatedOn
      }
      ownerName
      ownerId
      owner {
        id
        name
        type
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnId
        createdBy
        createdOn
        updatedOn
        ownerContactId
      }
      secondOwnerName
      secondOwnerId
      secondOwner {
        id
        name
        type
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnId
        createdBy
        createdOn
        updatedOn
        ownerContactId
      }
      thirdOwnerName
      thirdOwnerId
      thirdOwner {
        id
        name
        type
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnId
        createdBy
        createdOn
        updatedOn
        ownerContactId
      }
      trainerName
      trainerId
      trainer {
        id
        name
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        trainerContactId
      }
      secondTrainerName
      secondTrainerId
      secondTrainer {
        id
        name
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        trainerContactId
      }
      thirdTrainerName
      thirdTrainerId
      thirdTrainer {
        id
        name
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        trainerContactId
      }
      barnName
      barnId
      barn {
        id
        name
        nickname
        description
        type
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        ownerName
        ownerPersonId
        secondaryOwnerName
        secondaryOwnerPersonId
        members {
          nextToken
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        barnContactId
        barnLocationId
      }
      stablingRequestId
      stablingRequest {
        id
        eventId
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        arrivalDateTime
        departureDateTime
        stallTypeId
        stallType {
          id
          eventId
          organizationId
          organizationStallTypeId
          name
          description
          type
          pricePerStall
          taxPerStall
          createdOn
          updatedOn
        }
        quantityNeeded
        basePrice
        taxA
        taxB
        totalPrice
        status
        statusNote
        requestNote
        stallSetId
        stallSet {
          id
          eventId
          organizationId
          organizationStallSetId
          name
          location
          available
          totalAvailable
          used
          totalUsed
          createdOn
          updatedOn
        }
        stallLocation
        stallNumbers
        payerId
        payerName
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        splitAcrossEntries
        entries {
          nextToken
        }
        entryIds
        submittedEntry
        lastUpdatedBy
        createdOn
        updatedOn
      }
      beddingRequestId
      beddingRequest {
        id
        eventId
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        arrivalDateTime
        dateTimeNeeded
        deliveryInfo
        beddingType
        quantityNeeded
        basePrice
        taxA {
          id
          name
          createdBy
          organizationId
          stripeTaxRateId
          percentage
          createdOn
          updatedOn
        }
        taxB {
          id
          name
          createdBy
          organizationId
          stripeTaxRateId
          percentage
          createdOn
          updatedOn
        }
        totalPrice
        status
        statusNote
        payerId
        payerName
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        splitAcrossEntries
        entries {
          nextToken
        }
        entryIds
        submittedEntry
        lastUpdatedBy
        createdOn
        updatedOn
        beddingRequestTaxAId
        beddingRequestTaxBId
      }
      markedDoesNotNeedStalls
      payerName
      payerId
      payer {
        id
        name
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        paymentMethod {
          id
          personId
          organizationId
          type
          stripeId
          altId
          value
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
        payerContactId
        payerPaymentMethodId
      }
      prizeMoneyRecipientName
      prizeMoneyRecipientId
      prizeMoneyRecipient {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      prizeMoneyRecipientContact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      prizeMoneyRecipientType
      eventRulesAgreements {
        items {
          id
          eventId
          eventRuleId
          eventEntryId
          name
          personId
          personType
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        nextToken
      }
      note
      divisionList
      classList {
        items {
          id
          eventId
          eventClassId
          eventEntryId
          riderId
          status
          selectedEntryOption
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        nextToken
      }
      fees {
        id
        name
        amount
        description
        eventId
        createdBy
        eventClassId
        eventClass {
          id
          name
          eventId
          classId
          eventDivisionId
          organizationClassId
          organizationDivisionId
          type
          number
          entryOption
          entryFee
          prizeMoney
          completedVerifyEntries
          currentNumberEntries
          numberEntriesThatCompeted
          minNumberEntries
          maxNumberEntries
          estimatedTimePerRound
          isCaliforniaSplit
          isCombined
          combinedEventClassId
          combinedEventDivisionId
          jumpHeight
          isScheduled
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventClassPointTableId
          eventClassPrizeMoneyTableId
        }
        feeId
        fee {
          id
          name
          description
          createdBy
          organizationId
          stripeId
          altId
          isFixed
          isPercentage
          totalPrice
          quantity
          isPerEntry
          isPerHorse
          isPerRider
          isPerClass
          isPerTrainer
          isRefundable
          isChargedAtTimeOfEntry
          isStartedOnEntryDate
          isHiddenFromEntryForm
          startDate
          startTime
          taxA
          taxB
          createdOn
          updatedOn
          eventInvoiceFeesId
          feeTaxRateAId
          feeTaxRateBId
        }
        splitAcrossEntries
        entries {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        createdOn
        updatedOn
      }
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventEntryPrizeMoneyRecipientContactId
    }
  }
`;
export const onCreateEventEntryFee = /* GraphQL */ `
  subscription OnCreateEventEntryFee {
    onCreateEventEntryFee {
      id
      name
      description
      status
      amount
      quantity
      taxA
      taxB
      eventId
      feeId
      fee {
        id
        name
        description
        createdBy
        organizationId
        stripeId
        altId
        isFixed
        isPercentage
        totalPrice
        quantity
        isPerEntry
        isPerHorse
        isPerRider
        isPerClass
        isPerTrainer
        isRefundable
        isChargedAtTimeOfEntry
        isStartedOnEntryDate
        isHiddenFromEntryForm
        startDate
        startTime
        taxA
        taxB
        taxRateA {
          id
          name
          createdBy
          organizationId
          stripeTaxRateId
          percentage
          createdOn
          updatedOn
        }
        taxRateB {
          id
          name
          createdBy
          organizationId
          stripeTaxRateId
          percentage
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
        eventInvoiceFeesId
        feeTaxRateAId
        feeTaxRateBId
      }
      eventFeeId
      eventFee {
        id
        name
        amount
        description
        eventId
        createdBy
        eventClassId
        eventClass {
          id
          name
          eventId
          classId
          eventDivisionId
          organizationClassId
          organizationDivisionId
          type
          number
          entryOption
          entryFee
          prizeMoney
          completedVerifyEntries
          currentNumberEntries
          numberEntriesThatCompeted
          minNumberEntries
          maxNumberEntries
          estimatedTimePerRound
          isCaliforniaSplit
          isCombined
          combinedEventClassId
          combinedEventDivisionId
          jumpHeight
          isScheduled
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventClassPointTableId
          eventClassPrizeMoneyTableId
        }
        feeId
        fee {
          id
          name
          description
          createdBy
          organizationId
          stripeId
          altId
          isFixed
          isPercentage
          totalPrice
          quantity
          isPerEntry
          isPerHorse
          isPerRider
          isPerClass
          isPerTrainer
          isRefundable
          isChargedAtTimeOfEntry
          isStartedOnEntryDate
          isHiddenFromEntryForm
          startDate
          startTime
          taxA
          taxB
          createdOn
          updatedOn
          eventInvoiceFeesId
          feeTaxRateAId
          feeTaxRateBId
        }
        splitAcrossEntries
        entries {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        createdOn
        updatedOn
      }
      entryId
      entry {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      createdBy
      createdOn
      updatedOn
    }
  }
`;
export const onUpdateEventEntryFee = /* GraphQL */ `
  subscription OnUpdateEventEntryFee {
    onUpdateEventEntryFee {
      id
      name
      description
      status
      amount
      quantity
      taxA
      taxB
      eventId
      feeId
      fee {
        id
        name
        description
        createdBy
        organizationId
        stripeId
        altId
        isFixed
        isPercentage
        totalPrice
        quantity
        isPerEntry
        isPerHorse
        isPerRider
        isPerClass
        isPerTrainer
        isRefundable
        isChargedAtTimeOfEntry
        isStartedOnEntryDate
        isHiddenFromEntryForm
        startDate
        startTime
        taxA
        taxB
        taxRateA {
          id
          name
          createdBy
          organizationId
          stripeTaxRateId
          percentage
          createdOn
          updatedOn
        }
        taxRateB {
          id
          name
          createdBy
          organizationId
          stripeTaxRateId
          percentage
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
        eventInvoiceFeesId
        feeTaxRateAId
        feeTaxRateBId
      }
      eventFeeId
      eventFee {
        id
        name
        amount
        description
        eventId
        createdBy
        eventClassId
        eventClass {
          id
          name
          eventId
          classId
          eventDivisionId
          organizationClassId
          organizationDivisionId
          type
          number
          entryOption
          entryFee
          prizeMoney
          completedVerifyEntries
          currentNumberEntries
          numberEntriesThatCompeted
          minNumberEntries
          maxNumberEntries
          estimatedTimePerRound
          isCaliforniaSplit
          isCombined
          combinedEventClassId
          combinedEventDivisionId
          jumpHeight
          isScheduled
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventClassPointTableId
          eventClassPrizeMoneyTableId
        }
        feeId
        fee {
          id
          name
          description
          createdBy
          organizationId
          stripeId
          altId
          isFixed
          isPercentage
          totalPrice
          quantity
          isPerEntry
          isPerHorse
          isPerRider
          isPerClass
          isPerTrainer
          isRefundable
          isChargedAtTimeOfEntry
          isStartedOnEntryDate
          isHiddenFromEntryForm
          startDate
          startTime
          taxA
          taxB
          createdOn
          updatedOn
          eventInvoiceFeesId
          feeTaxRateAId
          feeTaxRateBId
        }
        splitAcrossEntries
        entries {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        createdOn
        updatedOn
      }
      entryId
      entry {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      createdBy
      createdOn
      updatedOn
    }
  }
`;
export const onDeleteEventEntryFee = /* GraphQL */ `
  subscription OnDeleteEventEntryFee {
    onDeleteEventEntryFee {
      id
      name
      description
      status
      amount
      quantity
      taxA
      taxB
      eventId
      feeId
      fee {
        id
        name
        description
        createdBy
        organizationId
        stripeId
        altId
        isFixed
        isPercentage
        totalPrice
        quantity
        isPerEntry
        isPerHorse
        isPerRider
        isPerClass
        isPerTrainer
        isRefundable
        isChargedAtTimeOfEntry
        isStartedOnEntryDate
        isHiddenFromEntryForm
        startDate
        startTime
        taxA
        taxB
        taxRateA {
          id
          name
          createdBy
          organizationId
          stripeTaxRateId
          percentage
          createdOn
          updatedOn
        }
        taxRateB {
          id
          name
          createdBy
          organizationId
          stripeTaxRateId
          percentage
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
        eventInvoiceFeesId
        feeTaxRateAId
        feeTaxRateBId
      }
      eventFeeId
      eventFee {
        id
        name
        amount
        description
        eventId
        createdBy
        eventClassId
        eventClass {
          id
          name
          eventId
          classId
          eventDivisionId
          organizationClassId
          organizationDivisionId
          type
          number
          entryOption
          entryFee
          prizeMoney
          completedVerifyEntries
          currentNumberEntries
          numberEntriesThatCompeted
          minNumberEntries
          maxNumberEntries
          estimatedTimePerRound
          isCaliforniaSplit
          isCombined
          combinedEventClassId
          combinedEventDivisionId
          jumpHeight
          isScheduled
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventClassPointTableId
          eventClassPrizeMoneyTableId
        }
        feeId
        fee {
          id
          name
          description
          createdBy
          organizationId
          stripeId
          altId
          isFixed
          isPercentage
          totalPrice
          quantity
          isPerEntry
          isPerHorse
          isPerRider
          isPerClass
          isPerTrainer
          isRefundable
          isChargedAtTimeOfEntry
          isStartedOnEntryDate
          isHiddenFromEntryForm
          startDate
          startTime
          taxA
          taxB
          createdOn
          updatedOn
          eventInvoiceFeesId
          feeTaxRateAId
          feeTaxRateBId
        }
        splitAcrossEntries
        entries {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        createdOn
        updatedOn
      }
      entryId
      entry {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      createdBy
      createdOn
      updatedOn
    }
  }
`;
export const onCreateEventEntryNumbers = /* GraphQL */ `
  subscription OnCreateEventEntryNumbers {
    onCreateEventEntryNumbers {
      id
      eventId
      name
      available
      totalAvailable
      used
      totalUsed
      createdOn
      updatedOn
    }
  }
`;
export const onUpdateEventEntryNumbers = /* GraphQL */ `
  subscription OnUpdateEventEntryNumbers {
    onUpdateEventEntryNumbers {
      id
      eventId
      name
      available
      totalAvailable
      used
      totalUsed
      createdOn
      updatedOn
    }
  }
`;
export const onDeleteEventEntryNumbers = /* GraphQL */ `
  subscription OnDeleteEventEntryNumbers {
    onDeleteEventEntryNumbers {
      id
      eventId
      name
      available
      totalAvailable
      used
      totalUsed
      createdOn
      updatedOn
    }
  }
`;
export const onCreateEventFee = /* GraphQL */ `
  subscription OnCreateEventFee {
    onCreateEventFee {
      id
      name
      amount
      description
      eventId
      createdBy
      eventClassId
      eventClass {
        id
        name
        eventId
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceClassesId
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        organizationClassId
        organizationClass {
          id
          name
          classId
          organizationId
          organizationDivisionId
          hasChampionship
          type
          number
          entryFee
          prizeMoney
          minNumberEntries
          maxNumberEntries
          jumpHeight
          estimatedTimePerRound
          createdBy
          displayOrder
          lastUpdatedBy
          createdOn
          updatedOn
          organizationClassPointTableId
          organizationClassPrizeMoneyTableId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryOption
        entryFee
        fees {
          nextToken
        }
        prizeMoney
        entries {
          nextToken
        }
        completedVerifyEntries
        currentNumberEntries
        numberEntriesThatCompeted
        minNumberEntries
        maxNumberEntries
        estimatedTimePerRound
        isCaliforniaSplit
        isCombined
        combinedEventClassId
        combinedEventDivisionId
        jumpHeight
        isScheduled
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventClassPointTableId
        eventClassPrizeMoneyTableId
      }
      feeId
      fee {
        id
        name
        description
        createdBy
        organizationId
        stripeId
        altId
        isFixed
        isPercentage
        totalPrice
        quantity
        isPerEntry
        isPerHorse
        isPerRider
        isPerClass
        isPerTrainer
        isRefundable
        isChargedAtTimeOfEntry
        isStartedOnEntryDate
        isHiddenFromEntryForm
        startDate
        startTime
        taxA
        taxB
        taxRateA {
          id
          name
          createdBy
          organizationId
          stripeTaxRateId
          percentage
          createdOn
          updatedOn
        }
        taxRateB {
          id
          name
          createdBy
          organizationId
          stripeTaxRateId
          percentage
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
        eventInvoiceFeesId
        feeTaxRateAId
        feeTaxRateBId
      }
      splitAcrossEntries
      entries {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      createdOn
      updatedOn
    }
  }
`;
export const onUpdateEventFee = /* GraphQL */ `
  subscription OnUpdateEventFee {
    onUpdateEventFee {
      id
      name
      amount
      description
      eventId
      createdBy
      eventClassId
      eventClass {
        id
        name
        eventId
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceClassesId
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        organizationClassId
        organizationClass {
          id
          name
          classId
          organizationId
          organizationDivisionId
          hasChampionship
          type
          number
          entryFee
          prizeMoney
          minNumberEntries
          maxNumberEntries
          jumpHeight
          estimatedTimePerRound
          createdBy
          displayOrder
          lastUpdatedBy
          createdOn
          updatedOn
          organizationClassPointTableId
          organizationClassPrizeMoneyTableId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryOption
        entryFee
        fees {
          nextToken
        }
        prizeMoney
        entries {
          nextToken
        }
        completedVerifyEntries
        currentNumberEntries
        numberEntriesThatCompeted
        minNumberEntries
        maxNumberEntries
        estimatedTimePerRound
        isCaliforniaSplit
        isCombined
        combinedEventClassId
        combinedEventDivisionId
        jumpHeight
        isScheduled
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventClassPointTableId
        eventClassPrizeMoneyTableId
      }
      feeId
      fee {
        id
        name
        description
        createdBy
        organizationId
        stripeId
        altId
        isFixed
        isPercentage
        totalPrice
        quantity
        isPerEntry
        isPerHorse
        isPerRider
        isPerClass
        isPerTrainer
        isRefundable
        isChargedAtTimeOfEntry
        isStartedOnEntryDate
        isHiddenFromEntryForm
        startDate
        startTime
        taxA
        taxB
        taxRateA {
          id
          name
          createdBy
          organizationId
          stripeTaxRateId
          percentage
          createdOn
          updatedOn
        }
        taxRateB {
          id
          name
          createdBy
          organizationId
          stripeTaxRateId
          percentage
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
        eventInvoiceFeesId
        feeTaxRateAId
        feeTaxRateBId
      }
      splitAcrossEntries
      entries {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      createdOn
      updatedOn
    }
  }
`;
export const onDeleteEventFee = /* GraphQL */ `
  subscription OnDeleteEventFee {
    onDeleteEventFee {
      id
      name
      amount
      description
      eventId
      createdBy
      eventClassId
      eventClass {
        id
        name
        eventId
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceClassesId
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        organizationClassId
        organizationClass {
          id
          name
          classId
          organizationId
          organizationDivisionId
          hasChampionship
          type
          number
          entryFee
          prizeMoney
          minNumberEntries
          maxNumberEntries
          jumpHeight
          estimatedTimePerRound
          createdBy
          displayOrder
          lastUpdatedBy
          createdOn
          updatedOn
          organizationClassPointTableId
          organizationClassPrizeMoneyTableId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryOption
        entryFee
        fees {
          nextToken
        }
        prizeMoney
        entries {
          nextToken
        }
        completedVerifyEntries
        currentNumberEntries
        numberEntriesThatCompeted
        minNumberEntries
        maxNumberEntries
        estimatedTimePerRound
        isCaliforniaSplit
        isCombined
        combinedEventClassId
        combinedEventDivisionId
        jumpHeight
        isScheduled
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventClassPointTableId
        eventClassPrizeMoneyTableId
      }
      feeId
      fee {
        id
        name
        description
        createdBy
        organizationId
        stripeId
        altId
        isFixed
        isPercentage
        totalPrice
        quantity
        isPerEntry
        isPerHorse
        isPerRider
        isPerClass
        isPerTrainer
        isRefundable
        isChargedAtTimeOfEntry
        isStartedOnEntryDate
        isHiddenFromEntryForm
        startDate
        startTime
        taxA
        taxB
        taxRateA {
          id
          name
          createdBy
          organizationId
          stripeTaxRateId
          percentage
          createdOn
          updatedOn
        }
        taxRateB {
          id
          name
          createdBy
          organizationId
          stripeTaxRateId
          percentage
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
        eventInvoiceFeesId
        feeTaxRateAId
        feeTaxRateBId
      }
      splitAcrossEntries
      entries {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      createdOn
      updatedOn
    }
  }
`;
export const onCreateEventFilter = /* GraphQL */ `
  subscription OnCreateEventFilter {
    onCreateEventFilter {
      id
      eventId
      organizationID
      name
      options
      isRequired
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const onUpdateEventFilter = /* GraphQL */ `
  subscription OnUpdateEventFilter {
    onUpdateEventFilter {
      id
      eventId
      organizationID
      name
      options
      isRequired
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const onDeleteEventFilter = /* GraphQL */ `
  subscription OnDeleteEventFilter {
    onDeleteEventFilter {
      id
      eventId
      organizationID
      name
      options
      isRequired
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const onCreateEventInvoice = /* GraphQL */ `
  subscription OnCreateEventInvoice {
    onCreateEventInvoice {
      id
      eventId
      status
      statusNote
      number
      stripeInvoiceNumber
      entryNumber
      entryId
      entry {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      payerName
      payerId
      payer {
        id
        name
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        paymentMethod {
          id
          personId
          organizationId
          type
          stripeId
          altId
          value
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
        payerContactId
        payerPaymentMethodId
      }
      payerType
      payerStripeId
      payerContact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      prizeMoneyRecipientName
      prizeMoneyRecipientId
      prizeMoneyRecipientStripeId
      prizeMoneyRecipient {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      prizeMoneyRecipientContact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      prizeMoneyRecipientType
      divisions {
        items {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceDivisionsId
        }
        nextToken
      }
      classes {
        items {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceClassesId
        }
        nextToken
      }
      fees {
        items {
          id
          name
          description
          createdBy
          organizationId
          stripeId
          altId
          isFixed
          isPercentage
          totalPrice
          quantity
          isPerEntry
          isPerHorse
          isPerRider
          isPerClass
          isPerTrainer
          isRefundable
          isChargedAtTimeOfEntry
          isStartedOnEntryDate
          isHiddenFromEntryForm
          startDate
          startTime
          taxA
          taxB
          createdOn
          updatedOn
          eventInvoiceFeesId
          feeTaxRateAId
          feeTaxRateBId
        }
        nextToken
      }
      entryFeeTotalAmount
      otherFeeTotalAmount
      stablingAmount
      beddingAmount
      feedAmount
      results {
        id
        eventId
        entryId
        entry {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        eventClassEntryId
        eventClassEntry {
          id
          eventId
          eventClassId
          eventEntryId
          riderId
          status
          selectedEntryOption
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        eventDivisionName
        eventClassId
        eventClass {
          id
          name
          eventId
          classId
          eventDivisionId
          organizationClassId
          organizationDivisionId
          type
          number
          entryOption
          entryFee
          prizeMoney
          completedVerifyEntries
          currentNumberEntries
          numberEntriesThatCompeted
          minNumberEntries
          maxNumberEntries
          estimatedTimePerRound
          isCaliforniaSplit
          isCombined
          combinedEventClassId
          combinedEventDivisionId
          jumpHeight
          isScheduled
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventClassPointTableId
          eventClassPrizeMoneyTableId
        }
        eventClassName
        resultSet
        place
        score
        time
        didNotCompete
        prizeMoney
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        pointTables {
          nextToken
        }
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventResultEntryId
        eventResultEventClassEntryId
        eventResultPrizeMoneyTableId
      }
      prizeMoneyAmount
      amountToDeductFromInvoice
      amountToPayRecipientViaCheck
      totalDue
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventInvoicePayerContactId
      eventInvoicePrizeMoneyRecipientId
      eventInvoicePrizeMoneyRecipientContactId
    }
  }
`;
export const onUpdateEventInvoice = /* GraphQL */ `
  subscription OnUpdateEventInvoice {
    onUpdateEventInvoice {
      id
      eventId
      status
      statusNote
      number
      stripeInvoiceNumber
      entryNumber
      entryId
      entry {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      payerName
      payerId
      payer {
        id
        name
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        paymentMethod {
          id
          personId
          organizationId
          type
          stripeId
          altId
          value
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
        payerContactId
        payerPaymentMethodId
      }
      payerType
      payerStripeId
      payerContact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      prizeMoneyRecipientName
      prizeMoneyRecipientId
      prizeMoneyRecipientStripeId
      prizeMoneyRecipient {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      prizeMoneyRecipientContact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      prizeMoneyRecipientType
      divisions {
        items {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceDivisionsId
        }
        nextToken
      }
      classes {
        items {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceClassesId
        }
        nextToken
      }
      fees {
        items {
          id
          name
          description
          createdBy
          organizationId
          stripeId
          altId
          isFixed
          isPercentage
          totalPrice
          quantity
          isPerEntry
          isPerHorse
          isPerRider
          isPerClass
          isPerTrainer
          isRefundable
          isChargedAtTimeOfEntry
          isStartedOnEntryDate
          isHiddenFromEntryForm
          startDate
          startTime
          taxA
          taxB
          createdOn
          updatedOn
          eventInvoiceFeesId
          feeTaxRateAId
          feeTaxRateBId
        }
        nextToken
      }
      entryFeeTotalAmount
      otherFeeTotalAmount
      stablingAmount
      beddingAmount
      feedAmount
      results {
        id
        eventId
        entryId
        entry {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        eventClassEntryId
        eventClassEntry {
          id
          eventId
          eventClassId
          eventEntryId
          riderId
          status
          selectedEntryOption
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        eventDivisionName
        eventClassId
        eventClass {
          id
          name
          eventId
          classId
          eventDivisionId
          organizationClassId
          organizationDivisionId
          type
          number
          entryOption
          entryFee
          prizeMoney
          completedVerifyEntries
          currentNumberEntries
          numberEntriesThatCompeted
          minNumberEntries
          maxNumberEntries
          estimatedTimePerRound
          isCaliforniaSplit
          isCombined
          combinedEventClassId
          combinedEventDivisionId
          jumpHeight
          isScheduled
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventClassPointTableId
          eventClassPrizeMoneyTableId
        }
        eventClassName
        resultSet
        place
        score
        time
        didNotCompete
        prizeMoney
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        pointTables {
          nextToken
        }
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventResultEntryId
        eventResultEventClassEntryId
        eventResultPrizeMoneyTableId
      }
      prizeMoneyAmount
      amountToDeductFromInvoice
      amountToPayRecipientViaCheck
      totalDue
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventInvoicePayerContactId
      eventInvoicePrizeMoneyRecipientId
      eventInvoicePrizeMoneyRecipientContactId
    }
  }
`;
export const onDeleteEventInvoice = /* GraphQL */ `
  subscription OnDeleteEventInvoice {
    onDeleteEventInvoice {
      id
      eventId
      status
      statusNote
      number
      stripeInvoiceNumber
      entryNumber
      entryId
      entry {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      payerName
      payerId
      payer {
        id
        name
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        paymentMethod {
          id
          personId
          organizationId
          type
          stripeId
          altId
          value
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
        payerContactId
        payerPaymentMethodId
      }
      payerType
      payerStripeId
      payerContact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      prizeMoneyRecipientName
      prizeMoneyRecipientId
      prizeMoneyRecipientStripeId
      prizeMoneyRecipient {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      prizeMoneyRecipientContact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      prizeMoneyRecipientType
      divisions {
        items {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceDivisionsId
        }
        nextToken
      }
      classes {
        items {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceClassesId
        }
        nextToken
      }
      fees {
        items {
          id
          name
          description
          createdBy
          organizationId
          stripeId
          altId
          isFixed
          isPercentage
          totalPrice
          quantity
          isPerEntry
          isPerHorse
          isPerRider
          isPerClass
          isPerTrainer
          isRefundable
          isChargedAtTimeOfEntry
          isStartedOnEntryDate
          isHiddenFromEntryForm
          startDate
          startTime
          taxA
          taxB
          createdOn
          updatedOn
          eventInvoiceFeesId
          feeTaxRateAId
          feeTaxRateBId
        }
        nextToken
      }
      entryFeeTotalAmount
      otherFeeTotalAmount
      stablingAmount
      beddingAmount
      feedAmount
      results {
        id
        eventId
        entryId
        entry {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        eventClassEntryId
        eventClassEntry {
          id
          eventId
          eventClassId
          eventEntryId
          riderId
          status
          selectedEntryOption
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        eventDivisionName
        eventClassId
        eventClass {
          id
          name
          eventId
          classId
          eventDivisionId
          organizationClassId
          organizationDivisionId
          type
          number
          entryOption
          entryFee
          prizeMoney
          completedVerifyEntries
          currentNumberEntries
          numberEntriesThatCompeted
          minNumberEntries
          maxNumberEntries
          estimatedTimePerRound
          isCaliforniaSplit
          isCombined
          combinedEventClassId
          combinedEventDivisionId
          jumpHeight
          isScheduled
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventClassPointTableId
          eventClassPrizeMoneyTableId
        }
        eventClassName
        resultSet
        place
        score
        time
        didNotCompete
        prizeMoney
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        pointTables {
          nextToken
        }
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventResultEntryId
        eventResultEventClassEntryId
        eventResultPrizeMoneyTableId
      }
      prizeMoneyAmount
      amountToDeductFromInvoice
      amountToPayRecipientViaCheck
      totalDue
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventInvoicePayerContactId
      eventInvoicePrizeMoneyRecipientId
      eventInvoicePrizeMoneyRecipientContactId
    }
  }
`;
export const onCreateEventJudge = /* GraphQL */ `
  subscription OnCreateEventJudge {
    onCreateEventJudge {
      id
      name
      eventId
      personId
      judgeId
      contactId
      rating {
        type
        license
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateEventJudge = /* GraphQL */ `
  subscription OnUpdateEventJudge {
    onUpdateEventJudge {
      id
      name
      eventId
      personId
      judgeId
      contactId
      rating {
        type
        license
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteEventJudge = /* GraphQL */ `
  subscription OnDeleteEventJudge {
    onDeleteEventJudge {
      id
      name
      eventId
      personId
      judgeId
      contactId
      rating {
        type
        license
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateEventOfficial = /* GraphQL */ `
  subscription OnCreateEventOfficial {
    onCreateEventOfficial {
      id
      name
      position
      eventId
      event {
        id
        name
        nickname
        createdBy
        manager
        startDate
        endDate
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contactId
        contactIds
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        type
        disciplines
        eventOptions {
          autoAcceptNewEntries
          usesTextAlerts
          acceptsIncomingTextAlerts
          hasUnlimitedCredits
          usesEventManagement
          usesStablingManagement
          usesVendorManagement
          usesMediaTools
          usesMarketingTools
          usesFinanceTools
          usesJudgeApp
        }
        paymentOptions {
          acceptsChecks
          acceptsCash
          acceptsACH
          acceptsCC
        }
        invoiceOptions {
          classFeeTax
          sendEmails
        }
        entryOptions {
          displayHorse2Input
          displayRider2Input
          displayRider3Input
          requiresCogginsDocument
          requiresCogginsData
          maximumNumberClassesOnEntry
        }
        officials {
          nextToken
        }
        status
        entriesStatus
        showStatus
        usefID
        usefSoftwareKey
        ecID
        ecPassword
        documents {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        eventAddressId
      }
      personId
      contactId
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateEventOfficial = /* GraphQL */ `
  subscription OnUpdateEventOfficial {
    onUpdateEventOfficial {
      id
      name
      position
      eventId
      event {
        id
        name
        nickname
        createdBy
        manager
        startDate
        endDate
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contactId
        contactIds
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        type
        disciplines
        eventOptions {
          autoAcceptNewEntries
          usesTextAlerts
          acceptsIncomingTextAlerts
          hasUnlimitedCredits
          usesEventManagement
          usesStablingManagement
          usesVendorManagement
          usesMediaTools
          usesMarketingTools
          usesFinanceTools
          usesJudgeApp
        }
        paymentOptions {
          acceptsChecks
          acceptsCash
          acceptsACH
          acceptsCC
        }
        invoiceOptions {
          classFeeTax
          sendEmails
        }
        entryOptions {
          displayHorse2Input
          displayRider2Input
          displayRider3Input
          requiresCogginsDocument
          requiresCogginsData
          maximumNumberClassesOnEntry
        }
        officials {
          nextToken
        }
        status
        entriesStatus
        showStatus
        usefID
        usefSoftwareKey
        ecID
        ecPassword
        documents {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        eventAddressId
      }
      personId
      contactId
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteEventOfficial = /* GraphQL */ `
  subscription OnDeleteEventOfficial {
    onDeleteEventOfficial {
      id
      name
      position
      eventId
      event {
        id
        name
        nickname
        createdBy
        manager
        startDate
        endDate
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contactId
        contactIds
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        type
        disciplines
        eventOptions {
          autoAcceptNewEntries
          usesTextAlerts
          acceptsIncomingTextAlerts
          hasUnlimitedCredits
          usesEventManagement
          usesStablingManagement
          usesVendorManagement
          usesMediaTools
          usesMarketingTools
          usesFinanceTools
          usesJudgeApp
        }
        paymentOptions {
          acceptsChecks
          acceptsCash
          acceptsACH
          acceptsCC
        }
        invoiceOptions {
          classFeeTax
          sendEmails
        }
        entryOptions {
          displayHorse2Input
          displayRider2Input
          displayRider3Input
          requiresCogginsDocument
          requiresCogginsData
          maximumNumberClassesOnEntry
        }
        officials {
          nextToken
        }
        status
        entriesStatus
        showStatus
        usefID
        usefSoftwareKey
        ecID
        ecPassword
        documents {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        eventAddressId
      }
      personId
      contactId
      createdAt
      updatedAt
    }
  }
`;
export const onCreateEventResult = /* GraphQL */ `
  subscription OnCreateEventResult {
    onCreateEventResult {
      id
      eventId
      entryId
      entry {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      eventClassEntryId
      eventClassEntry {
        id
        eventId
        eventClassId
        eventClass {
          id
          name
          eventId
          classId
          eventDivisionId
          organizationClassId
          organizationDivisionId
          type
          number
          entryOption
          entryFee
          prizeMoney
          completedVerifyEntries
          currentNumberEntries
          numberEntriesThatCompeted
          minNumberEntries
          maxNumberEntries
          estimatedTimePerRound
          isCaliforniaSplit
          isCombined
          combinedEventClassId
          combinedEventDivisionId
          jumpHeight
          isScheduled
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventClassPointTableId
          eventClassPrizeMoneyTableId
        }
        eventEntryId
        eventEntry {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        status
        selectedEntryOption
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      eventDivisionId
      eventDivision {
        id
        eventId
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceDivisionsId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventClasses {
          nextToken
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        entryFee
        prizeMoney
        jumpHeight
        minNumberEntriesPerClass
        maxNumberEntriesPerClass
        isCaliforniaSplit
        isCombined
        combinedEventDivisionId
        combinedEventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventDivisionOrganizationDivisionId
        eventDivisionPointTableId
        eventDivisionPrizeMoneyTableId
      }
      eventDivisionName
      eventClassId
      eventClass {
        id
        name
        eventId
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceClassesId
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        organizationClassId
        organizationClass {
          id
          name
          classId
          organizationId
          organizationDivisionId
          hasChampionship
          type
          number
          entryFee
          prizeMoney
          minNumberEntries
          maxNumberEntries
          jumpHeight
          estimatedTimePerRound
          createdBy
          displayOrder
          lastUpdatedBy
          createdOn
          updatedOn
          organizationClassPointTableId
          organizationClassPrizeMoneyTableId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryOption
        entryFee
        fees {
          nextToken
        }
        prizeMoney
        entries {
          nextToken
        }
        completedVerifyEntries
        currentNumberEntries
        numberEntriesThatCompeted
        minNumberEntries
        maxNumberEntries
        estimatedTimePerRound
        isCaliforniaSplit
        isCombined
        combinedEventClassId
        combinedEventDivisionId
        jumpHeight
        isScheduled
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventClassPointTableId
        eventClassPrizeMoneyTableId
      }
      eventClassName
      resultSet
      place
      score
      time
      didNotCompete
      prizeMoney
      prizeMoneyTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isPercentage
        totalPot
        tiers {
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      pointTables {
        items {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        nextToken
      }
      note
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventResultEntryId
      eventResultEventClassEntryId
      eventResultPrizeMoneyTableId
    }
  }
`;
export const onUpdateEventResult = /* GraphQL */ `
  subscription OnUpdateEventResult {
    onUpdateEventResult {
      id
      eventId
      entryId
      entry {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      eventClassEntryId
      eventClassEntry {
        id
        eventId
        eventClassId
        eventClass {
          id
          name
          eventId
          classId
          eventDivisionId
          organizationClassId
          organizationDivisionId
          type
          number
          entryOption
          entryFee
          prizeMoney
          completedVerifyEntries
          currentNumberEntries
          numberEntriesThatCompeted
          minNumberEntries
          maxNumberEntries
          estimatedTimePerRound
          isCaliforniaSplit
          isCombined
          combinedEventClassId
          combinedEventDivisionId
          jumpHeight
          isScheduled
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventClassPointTableId
          eventClassPrizeMoneyTableId
        }
        eventEntryId
        eventEntry {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        status
        selectedEntryOption
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      eventDivisionId
      eventDivision {
        id
        eventId
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceDivisionsId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventClasses {
          nextToken
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        entryFee
        prizeMoney
        jumpHeight
        minNumberEntriesPerClass
        maxNumberEntriesPerClass
        isCaliforniaSplit
        isCombined
        combinedEventDivisionId
        combinedEventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventDivisionOrganizationDivisionId
        eventDivisionPointTableId
        eventDivisionPrizeMoneyTableId
      }
      eventDivisionName
      eventClassId
      eventClass {
        id
        name
        eventId
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceClassesId
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        organizationClassId
        organizationClass {
          id
          name
          classId
          organizationId
          organizationDivisionId
          hasChampionship
          type
          number
          entryFee
          prizeMoney
          minNumberEntries
          maxNumberEntries
          jumpHeight
          estimatedTimePerRound
          createdBy
          displayOrder
          lastUpdatedBy
          createdOn
          updatedOn
          organizationClassPointTableId
          organizationClassPrizeMoneyTableId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryOption
        entryFee
        fees {
          nextToken
        }
        prizeMoney
        entries {
          nextToken
        }
        completedVerifyEntries
        currentNumberEntries
        numberEntriesThatCompeted
        minNumberEntries
        maxNumberEntries
        estimatedTimePerRound
        isCaliforniaSplit
        isCombined
        combinedEventClassId
        combinedEventDivisionId
        jumpHeight
        isScheduled
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventClassPointTableId
        eventClassPrizeMoneyTableId
      }
      eventClassName
      resultSet
      place
      score
      time
      didNotCompete
      prizeMoney
      prizeMoneyTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isPercentage
        totalPot
        tiers {
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      pointTables {
        items {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        nextToken
      }
      note
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventResultEntryId
      eventResultEventClassEntryId
      eventResultPrizeMoneyTableId
    }
  }
`;
export const onDeleteEventResult = /* GraphQL */ `
  subscription OnDeleteEventResult {
    onDeleteEventResult {
      id
      eventId
      entryId
      entry {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      eventClassEntryId
      eventClassEntry {
        id
        eventId
        eventClassId
        eventClass {
          id
          name
          eventId
          classId
          eventDivisionId
          organizationClassId
          organizationDivisionId
          type
          number
          entryOption
          entryFee
          prizeMoney
          completedVerifyEntries
          currentNumberEntries
          numberEntriesThatCompeted
          minNumberEntries
          maxNumberEntries
          estimatedTimePerRound
          isCaliforniaSplit
          isCombined
          combinedEventClassId
          combinedEventDivisionId
          jumpHeight
          isScheduled
          note
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventClassPointTableId
          eventClassPrizeMoneyTableId
        }
        eventEntryId
        eventEntry {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        status
        selectedEntryOption
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      eventDivisionId
      eventDivision {
        id
        eventId
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceDivisionsId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventClasses {
          nextToken
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        entryFee
        prizeMoney
        jumpHeight
        minNumberEntriesPerClass
        maxNumberEntriesPerClass
        isCaliforniaSplit
        isCombined
        combinedEventDivisionId
        combinedEventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventDivisionOrganizationDivisionId
        eventDivisionPointTableId
        eventDivisionPrizeMoneyTableId
      }
      eventDivisionName
      eventClassId
      eventClass {
        id
        name
        eventId
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceClassesId
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        organizationClassId
        organizationClass {
          id
          name
          classId
          organizationId
          organizationDivisionId
          hasChampionship
          type
          number
          entryFee
          prizeMoney
          minNumberEntries
          maxNumberEntries
          jumpHeight
          estimatedTimePerRound
          createdBy
          displayOrder
          lastUpdatedBy
          createdOn
          updatedOn
          organizationClassPointTableId
          organizationClassPrizeMoneyTableId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryOption
        entryFee
        fees {
          nextToken
        }
        prizeMoney
        entries {
          nextToken
        }
        completedVerifyEntries
        currentNumberEntries
        numberEntriesThatCompeted
        minNumberEntries
        maxNumberEntries
        estimatedTimePerRound
        isCaliforniaSplit
        isCombined
        combinedEventClassId
        combinedEventDivisionId
        jumpHeight
        isScheduled
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventClassPointTableId
        eventClassPrizeMoneyTableId
      }
      eventClassName
      resultSet
      place
      score
      time
      didNotCompete
      prizeMoney
      prizeMoneyTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isPercentage
        totalPot
        tiers {
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      pointTables {
        items {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        nextToken
      }
      note
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventResultEntryId
      eventResultEventClassEntryId
      eventResultPrizeMoneyTableId
    }
  }
`;
export const onCreateEventRing = /* GraphQL */ `
  subscription OnCreateEventRing {
    onCreateEventRing {
      id
      name
      eventId
      ringId
      event {
        id
        name
        nickname
        createdBy
        manager
        startDate
        endDate
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contactId
        contactIds
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        type
        disciplines
        eventOptions {
          autoAcceptNewEntries
          usesTextAlerts
          acceptsIncomingTextAlerts
          hasUnlimitedCredits
          usesEventManagement
          usesStablingManagement
          usesVendorManagement
          usesMediaTools
          usesMarketingTools
          usesFinanceTools
          usesJudgeApp
        }
        paymentOptions {
          acceptsChecks
          acceptsCash
          acceptsACH
          acceptsCC
        }
        invoiceOptions {
          classFeeTax
          sendEmails
        }
        entryOptions {
          displayHorse2Input
          displayRider2Input
          displayRider3Input
          requiresCogginsDocument
          requiresCogginsData
          maximumNumberClassesOnEntry
        }
        officials {
          nextToken
        }
        status
        entriesStatus
        showStatus
        usefID
        usefSoftwareKey
        ecID
        ecPassword
        documents {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        eventAddressId
      }
      ring {
        id
        name
        displayOrder
        organizationId
        createdOn
        updatedOn
      }
      createdOn
      updatedOn
    }
  }
`;
export const onUpdateEventRing = /* GraphQL */ `
  subscription OnUpdateEventRing {
    onUpdateEventRing {
      id
      name
      eventId
      ringId
      event {
        id
        name
        nickname
        createdBy
        manager
        startDate
        endDate
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contactId
        contactIds
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        type
        disciplines
        eventOptions {
          autoAcceptNewEntries
          usesTextAlerts
          acceptsIncomingTextAlerts
          hasUnlimitedCredits
          usesEventManagement
          usesStablingManagement
          usesVendorManagement
          usesMediaTools
          usesMarketingTools
          usesFinanceTools
          usesJudgeApp
        }
        paymentOptions {
          acceptsChecks
          acceptsCash
          acceptsACH
          acceptsCC
        }
        invoiceOptions {
          classFeeTax
          sendEmails
        }
        entryOptions {
          displayHorse2Input
          displayRider2Input
          displayRider3Input
          requiresCogginsDocument
          requiresCogginsData
          maximumNumberClassesOnEntry
        }
        officials {
          nextToken
        }
        status
        entriesStatus
        showStatus
        usefID
        usefSoftwareKey
        ecID
        ecPassword
        documents {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        eventAddressId
      }
      ring {
        id
        name
        displayOrder
        organizationId
        createdOn
        updatedOn
      }
      createdOn
      updatedOn
    }
  }
`;
export const onDeleteEventRing = /* GraphQL */ `
  subscription OnDeleteEventRing {
    onDeleteEventRing {
      id
      name
      eventId
      ringId
      event {
        id
        name
        nickname
        createdBy
        manager
        startDate
        endDate
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contactId
        contactIds
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        type
        disciplines
        eventOptions {
          autoAcceptNewEntries
          usesTextAlerts
          acceptsIncomingTextAlerts
          hasUnlimitedCredits
          usesEventManagement
          usesStablingManagement
          usesVendorManagement
          usesMediaTools
          usesMarketingTools
          usesFinanceTools
          usesJudgeApp
        }
        paymentOptions {
          acceptsChecks
          acceptsCash
          acceptsACH
          acceptsCC
        }
        invoiceOptions {
          classFeeTax
          sendEmails
        }
        entryOptions {
          displayHorse2Input
          displayRider2Input
          displayRider3Input
          requiresCogginsDocument
          requiresCogginsData
          maximumNumberClassesOnEntry
        }
        officials {
          nextToken
        }
        status
        entriesStatus
        showStatus
        usefID
        usefSoftwareKey
        ecID
        ecPassword
        documents {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        eventAddressId
      }
      ring {
        id
        name
        displayOrder
        organizationId
        createdOn
        updatedOn
      }
      createdOn
      updatedOn
    }
  }
`;
export const onCreateEventRules = /* GraphQL */ `
  subscription OnCreateEventRules {
    onCreateEventRules {
      id
      eventId
      title
      text
      requiresAgreementPerEntry
      agreements {
        items {
          id
          eventId
          eventRuleId
          eventEntryId
          name
          personId
          personType
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        nextToken
      }
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const onUpdateEventRules = /* GraphQL */ `
  subscription OnUpdateEventRules {
    onUpdateEventRules {
      id
      eventId
      title
      text
      requiresAgreementPerEntry
      agreements {
        items {
          id
          eventId
          eventRuleId
          eventEntryId
          name
          personId
          personType
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        nextToken
      }
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const onDeleteEventRules = /* GraphQL */ `
  subscription OnDeleteEventRules {
    onDeleteEventRules {
      id
      eventId
      title
      text
      requiresAgreementPerEntry
      agreements {
        items {
          id
          eventId
          eventRuleId
          eventEntryId
          name
          personId
          personType
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        nextToken
      }
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const onCreateEventScratch = /* GraphQL */ `
  subscription OnCreateEventScratch {
    onCreateEventScratch {
      id
      eventId
      entryId
      entry {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      eventDivisionId
      eventDivision {
        id
        eventId
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceDivisionsId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventClasses {
          nextToken
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        entryFee
        prizeMoney
        jumpHeight
        minNumberEntriesPerClass
        maxNumberEntriesPerClass
        isCaliforniaSplit
        isCombined
        combinedEventDivisionId
        combinedEventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventDivisionOrganizationDivisionId
        eventDivisionPointTableId
        eventDivisionPrizeMoneyTableId
      }
      eventDivisionName
      eventClassId
      eventClass {
        id
        name
        eventId
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceClassesId
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        organizationClassId
        organizationClass {
          id
          name
          classId
          organizationId
          organizationDivisionId
          hasChampionship
          type
          number
          entryFee
          prizeMoney
          minNumberEntries
          maxNumberEntries
          jumpHeight
          estimatedTimePerRound
          createdBy
          displayOrder
          lastUpdatedBy
          createdOn
          updatedOn
          organizationClassPointTableId
          organizationClassPrizeMoneyTableId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryOption
        entryFee
        fees {
          nextToken
        }
        prizeMoney
        entries {
          nextToken
        }
        completedVerifyEntries
        currentNumberEntries
        numberEntriesThatCompeted
        minNumberEntries
        maxNumberEntries
        estimatedTimePerRound
        isCaliforniaSplit
        isCombined
        combinedEventClassId
        combinedEventDivisionId
        jumpHeight
        isScheduled
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventClassPointTableId
        eventClassPrizeMoneyTableId
      }
      eventClassName
      status
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const onUpdateEventScratch = /* GraphQL */ `
  subscription OnUpdateEventScratch {
    onUpdateEventScratch {
      id
      eventId
      entryId
      entry {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      eventDivisionId
      eventDivision {
        id
        eventId
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceDivisionsId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventClasses {
          nextToken
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        entryFee
        prizeMoney
        jumpHeight
        minNumberEntriesPerClass
        maxNumberEntriesPerClass
        isCaliforniaSplit
        isCombined
        combinedEventDivisionId
        combinedEventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventDivisionOrganizationDivisionId
        eventDivisionPointTableId
        eventDivisionPrizeMoneyTableId
      }
      eventDivisionName
      eventClassId
      eventClass {
        id
        name
        eventId
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceClassesId
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        organizationClassId
        organizationClass {
          id
          name
          classId
          organizationId
          organizationDivisionId
          hasChampionship
          type
          number
          entryFee
          prizeMoney
          minNumberEntries
          maxNumberEntries
          jumpHeight
          estimatedTimePerRound
          createdBy
          displayOrder
          lastUpdatedBy
          createdOn
          updatedOn
          organizationClassPointTableId
          organizationClassPrizeMoneyTableId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryOption
        entryFee
        fees {
          nextToken
        }
        prizeMoney
        entries {
          nextToken
        }
        completedVerifyEntries
        currentNumberEntries
        numberEntriesThatCompeted
        minNumberEntries
        maxNumberEntries
        estimatedTimePerRound
        isCaliforniaSplit
        isCombined
        combinedEventClassId
        combinedEventDivisionId
        jumpHeight
        isScheduled
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventClassPointTableId
        eventClassPrizeMoneyTableId
      }
      eventClassName
      status
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const onDeleteEventScratch = /* GraphQL */ `
  subscription OnDeleteEventScratch {
    onDeleteEventScratch {
      id
      eventId
      entryId
      entry {
        id
        eventId
        status
        statusNote
        personId
        number
        isCheckedIn
        isCheckedOut
        contactId
        primarycontact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        riderName
        riderId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        secondRiderName
        secondRiderId
        secondRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        thirdRiderName
        thirdRiderId
        thirdRider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        horseName
        horseId
        horse {
          id
          name
          nickname
          previousNames
          personId
          ownerId
          ownerName
          barnName
          barnId
          contactId
          microchipNumber
          passportNumber
          dateOfBirth
          breed
          sex
          color
          markings
          height
          foalState
          cogginsNumber
          cogginsDate
          cogginsState
          highestLevelCompeted
          greenEligibility
          createdOn
          updatedOn
        }
        ownerName
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        secondOwnerName
        secondOwnerId
        secondOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        thirdOwnerName
        thirdOwnerId
        thirdOwner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        trainerName
        trainerId
        trainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        secondTrainerName
        secondTrainerId
        secondTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        thirdTrainerName
        thirdTrainerId
        thirdTrainer {
          id
          name
          location
          addressId
          personId
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          trainerContactId
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        stablingRequestId
        stablingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          departureDateTime
          stallTypeId
          quantityNeeded
          basePrice
          taxA
          taxB
          totalPrice
          status
          statusNote
          requestNote
          stallSetId
          stallLocation
          stallNumbers
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
        }
        beddingRequestId
        beddingRequest {
          id
          eventId
          trainerId
          barnId
          personId
          arrivalDateTime
          dateTimeNeeded
          deliveryInfo
          beddingType
          quantityNeeded
          basePrice
          totalPrice
          status
          statusNote
          payerId
          payerName
          splitAcrossEntries
          entryIds
          submittedEntry
          lastUpdatedBy
          createdOn
          updatedOn
          beddingRequestTaxAId
          beddingRequestTaxBId
        }
        markedDoesNotNeedStalls
        payerName
        payerId
        payer {
          id
          name
          personId
          createdOn
          updatedOn
          payerContactId
          payerPaymentMethodId
        }
        prizeMoneyRecipientName
        prizeMoneyRecipientId
        prizeMoneyRecipient {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        prizeMoneyRecipientContact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        prizeMoneyRecipientType
        eventRulesAgreements {
          nextToken
        }
        note
        divisionList
        classList {
          nextToken
        }
        fees {
          id
          name
          amount
          description
          eventId
          createdBy
          eventClassId
          feeId
          splitAcrossEntries
          createdOn
          updatedOn
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventEntryPrizeMoneyRecipientContactId
      }
      eventDivisionId
      eventDivision {
        id
        eventId
        name
        number
        divisionId
        division {
          id
          name
          nickname
          description
          discipline
          isSearchable
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceDivisionsId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventClasses {
          nextToken
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        entryFee
        prizeMoney
        jumpHeight
        minNumberEntriesPerClass
        maxNumberEntriesPerClass
        isCaliforniaSplit
        isCombined
        combinedEventDivisionId
        combinedEventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventDivisionOrganizationDivisionId
        eventDivisionPointTableId
        eventDivisionPrizeMoneyTableId
      }
      eventDivisionName
      eventClassId
      eventClass {
        id
        name
        eventId
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceClassesId
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        organizationClassId
        organizationClass {
          id
          name
          classId
          organizationId
          organizationDivisionId
          hasChampionship
          type
          number
          entryFee
          prizeMoney
          minNumberEntries
          maxNumberEntries
          jumpHeight
          estimatedTimePerRound
          createdBy
          displayOrder
          lastUpdatedBy
          createdOn
          updatedOn
          organizationClassPointTableId
          organizationClassPrizeMoneyTableId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryOption
        entryFee
        fees {
          nextToken
        }
        prizeMoney
        entries {
          nextToken
        }
        completedVerifyEntries
        currentNumberEntries
        numberEntriesThatCompeted
        minNumberEntries
        maxNumberEntries
        estimatedTimePerRound
        isCaliforniaSplit
        isCombined
        combinedEventClassId
        combinedEventDivisionId
        jumpHeight
        isScheduled
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventClassPointTableId
        eventClassPrizeMoneyTableId
      }
      eventClassName
      status
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const onCreateEventStallSet = /* GraphQL */ `
  subscription OnCreateEventStallSet {
    onCreateEventStallSet {
      id
      eventId
      organizationId
      organizationStallSetId
      name
      location
      available
      totalAvailable
      used
      totalUsed
      createdOn
      updatedOn
    }
  }
`;
export const onUpdateEventStallSet = /* GraphQL */ `
  subscription OnUpdateEventStallSet {
    onUpdateEventStallSet {
      id
      eventId
      organizationId
      organizationStallSetId
      name
      location
      available
      totalAvailable
      used
      totalUsed
      createdOn
      updatedOn
    }
  }
`;
export const onDeleteEventStallSet = /* GraphQL */ `
  subscription OnDeleteEventStallSet {
    onDeleteEventStallSet {
      id
      eventId
      organizationId
      organizationStallSetId
      name
      location
      available
      totalAvailable
      used
      totalUsed
      createdOn
      updatedOn
    }
  }
`;
export const onCreateEventStallType = /* GraphQL */ `
  subscription OnCreateEventStallType {
    onCreateEventStallType {
      id
      eventId
      organizationId
      organizationStallTypeId
      name
      description
      type
      pricePerStall
      taxPerStall
      createdOn
      updatedOn
    }
  }
`;
export const onUpdateEventStallType = /* GraphQL */ `
  subscription OnUpdateEventStallType {
    onUpdateEventStallType {
      id
      eventId
      organizationId
      organizationStallTypeId
      name
      description
      type
      pricePerStall
      taxPerStall
      createdOn
      updatedOn
    }
  }
`;
export const onDeleteEventStallType = /* GraphQL */ `
  subscription OnDeleteEventStallType {
    onDeleteEventStallType {
      id
      eventId
      organizationId
      organizationStallTypeId
      name
      description
      type
      pricePerStall
      taxPerStall
      createdOn
      updatedOn
    }
  }
`;
export const onCreateFee = /* GraphQL */ `
  subscription OnCreateFee {
    onCreateFee {
      id
      name
      description
      createdBy
      organizationId
      stripeId
      altId
      isFixed
      isPercentage
      totalPrice
      quantity
      isPerEntry
      isPerHorse
      isPerRider
      isPerClass
      isPerTrainer
      isRefundable
      isChargedAtTimeOfEntry
      isStartedOnEntryDate
      isHiddenFromEntryForm
      startDate
      startTime
      taxA
      taxB
      taxRateA {
        id
        name
        createdBy
        organizationId
        stripeTaxRateId
        percentage
        createdOn
        updatedOn
      }
      taxRateB {
        id
        name
        createdBy
        organizationId
        stripeTaxRateId
        percentage
        createdOn
        updatedOn
      }
      createdOn
      updatedOn
      eventInvoiceFeesId
      feeTaxRateAId
      feeTaxRateBId
    }
  }
`;
export const onUpdateFee = /* GraphQL */ `
  subscription OnUpdateFee {
    onUpdateFee {
      id
      name
      description
      createdBy
      organizationId
      stripeId
      altId
      isFixed
      isPercentage
      totalPrice
      quantity
      isPerEntry
      isPerHorse
      isPerRider
      isPerClass
      isPerTrainer
      isRefundable
      isChargedAtTimeOfEntry
      isStartedOnEntryDate
      isHiddenFromEntryForm
      startDate
      startTime
      taxA
      taxB
      taxRateA {
        id
        name
        createdBy
        organizationId
        stripeTaxRateId
        percentage
        createdOn
        updatedOn
      }
      taxRateB {
        id
        name
        createdBy
        organizationId
        stripeTaxRateId
        percentage
        createdOn
        updatedOn
      }
      createdOn
      updatedOn
      eventInvoiceFeesId
      feeTaxRateAId
      feeTaxRateBId
    }
  }
`;
export const onDeleteFee = /* GraphQL */ `
  subscription OnDeleteFee {
    onDeleteFee {
      id
      name
      description
      createdBy
      organizationId
      stripeId
      altId
      isFixed
      isPercentage
      totalPrice
      quantity
      isPerEntry
      isPerHorse
      isPerRider
      isPerClass
      isPerTrainer
      isRefundable
      isChargedAtTimeOfEntry
      isStartedOnEntryDate
      isHiddenFromEntryForm
      startDate
      startTime
      taxA
      taxB
      taxRateA {
        id
        name
        createdBy
        organizationId
        stripeTaxRateId
        percentage
        createdOn
        updatedOn
      }
      taxRateB {
        id
        name
        createdBy
        organizationId
        stripeTaxRateId
        percentage
        createdOn
        updatedOn
      }
      createdOn
      updatedOn
      eventInvoiceFeesId
      feeTaxRateAId
      feeTaxRateBId
    }
  }
`;
export const onCreateFeedback = /* GraphQL */ `
  subscription OnCreateFeedback {
    onCreateFeedback {
      id
      clinicianId
      personId
      technical
      style
      turnout
      general
      score
      status
      submissionFeedback {
        id
        type
        submission {
          id
          title
          description
          videoLength
          riderId
          personId
          totalPrice
          status
          createdOn
          updatedOn
        }
        submissionId
        feedback {
          id
          clinicianId
          personId
          technical
          style
          turnout
          general
          score
          status
          createdOn
          updatedOn
          feedbackSubmissionFeedbackId
        }
        feedbackId
        personId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        riderId
        clinician {
          id
          name
          personId
          judgeId
          contactId
          usefNumber
          ecNumber
          price
          status
          paymentVerified
          stripeAccountId
          totalEarned
          location
          disciplines
          experience
          personal
          preferences
          hasIHSAExperience
          hasIEAExperience
          createdOn
          updatedOn
          clinicianPaymentChoiceId
        }
        clinicianId
        submissionStatus
        feedbackStatus
        clinicianAmount
        declineReason
        permissionToPost
        dateSubmitted
        dateCompleted
        createdOn
        updatedOn
        submissionFeedbackClinicianId
      }
      createdOn
      updatedOn
      feedbackSubmissionFeedbackId
    }
  }
`;
export const onUpdateFeedback = /* GraphQL */ `
  subscription OnUpdateFeedback {
    onUpdateFeedback {
      id
      clinicianId
      personId
      technical
      style
      turnout
      general
      score
      status
      submissionFeedback {
        id
        type
        submission {
          id
          title
          description
          videoLength
          riderId
          personId
          totalPrice
          status
          createdOn
          updatedOn
        }
        submissionId
        feedback {
          id
          clinicianId
          personId
          technical
          style
          turnout
          general
          score
          status
          createdOn
          updatedOn
          feedbackSubmissionFeedbackId
        }
        feedbackId
        personId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        riderId
        clinician {
          id
          name
          personId
          judgeId
          contactId
          usefNumber
          ecNumber
          price
          status
          paymentVerified
          stripeAccountId
          totalEarned
          location
          disciplines
          experience
          personal
          preferences
          hasIHSAExperience
          hasIEAExperience
          createdOn
          updatedOn
          clinicianPaymentChoiceId
        }
        clinicianId
        submissionStatus
        feedbackStatus
        clinicianAmount
        declineReason
        permissionToPost
        dateSubmitted
        dateCompleted
        createdOn
        updatedOn
        submissionFeedbackClinicianId
      }
      createdOn
      updatedOn
      feedbackSubmissionFeedbackId
    }
  }
`;
export const onDeleteFeedback = /* GraphQL */ `
  subscription OnDeleteFeedback {
    onDeleteFeedback {
      id
      clinicianId
      personId
      technical
      style
      turnout
      general
      score
      status
      submissionFeedback {
        id
        type
        submission {
          id
          title
          description
          videoLength
          riderId
          personId
          totalPrice
          status
          createdOn
          updatedOn
        }
        submissionId
        feedback {
          id
          clinicianId
          personId
          technical
          style
          turnout
          general
          score
          status
          createdOn
          updatedOn
          feedbackSubmissionFeedbackId
        }
        feedbackId
        personId
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        riderId
        clinician {
          id
          name
          personId
          judgeId
          contactId
          usefNumber
          ecNumber
          price
          status
          paymentVerified
          stripeAccountId
          totalEarned
          location
          disciplines
          experience
          personal
          preferences
          hasIHSAExperience
          hasIEAExperience
          createdOn
          updatedOn
          clinicianPaymentChoiceId
        }
        clinicianId
        submissionStatus
        feedbackStatus
        clinicianAmount
        declineReason
        permissionToPost
        dateSubmitted
        dateCompleted
        createdOn
        updatedOn
        submissionFeedbackClinicianId
      }
      createdOn
      updatedOn
      feedbackSubmissionFeedbackId
    }
  }
`;
export const onCreateGameInput = /* GraphQL */ `
  subscription OnCreateGameInput {
    onCreateGameInput {
      id
      eventId
      eventClassId
      backNumber
      round
      score
      isMasterRecord
      createdBy
      createdOn
      updatedOn
    }
  }
`;
export const onUpdateGameInput = /* GraphQL */ `
  subscription OnUpdateGameInput {
    onUpdateGameInput {
      id
      eventId
      eventClassId
      backNumber
      round
      score
      isMasterRecord
      createdBy
      createdOn
      updatedOn
    }
  }
`;
export const onDeleteGameInput = /* GraphQL */ `
  subscription OnDeleteGameInput {
    onDeleteGameInput {
      id
      eventId
      eventClassId
      backNumber
      round
      score
      isMasterRecord
      createdBy
      createdOn
      updatedOn
    }
  }
`;
export const onCreateHorse = /* GraphQL */ `
  subscription OnCreateHorse {
    onCreateHorse {
      id
      name
      nickname
      previousNames
      personId
      ownerId
      owner {
        id
        name
        type
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnId
        createdBy
        createdOn
        updatedOn
        ownerContactId
      }
      ownerName
      barnName
      barnId
      barn {
        id
        name
        nickname
        description
        type
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        ownerName
        ownerPersonId
        secondaryOwnerName
        secondaryOwnerPersonId
        members {
          nextToken
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        barnContactId
        barnLocationId
      }
      contactId
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      microchipNumber
      passportNumber
      dateOfBirth
      breed
      sex
      color
      markings
      height
      foalState
      cogginsNumber
      cogginsDate
      cogginsState
      coggins {
        title
        description
        originalExtension
        dateUploaded
        url
        bucket
        region
        key
      }
      memberships {
        items {
          id
          name
          description
          membershipId
          backNumber
          membershipStatus
          amountPaid
          organizationMembershipTypeId
          type
          personName
          personId
          riderId
          horseId
          ownerId
          trainerId
          barnId
          teamId
          organizationId
          organizationName
          isJunior
          isProfessional
          dateMembershipEnds
          volunteerHours
          meetingsAttended
          showsAttended
          oneTimePassUseDate
          personEmail
          gradeLevel
          aelDivision
          aelTeamRole
          contactPersonId
          groupContactId
          groupLeaderContactId
          createdBy
          createdOn
          updatedOn
          personMembershipsId
          membershipOrganizationId
        }
        nextToken
      }
      highestLevelCompeted
      greenEligibility
      createdOn
      updatedOn
    }
  }
`;
export const onUpdateHorse = /* GraphQL */ `
  subscription OnUpdateHorse {
    onUpdateHorse {
      id
      name
      nickname
      previousNames
      personId
      ownerId
      owner {
        id
        name
        type
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnId
        createdBy
        createdOn
        updatedOn
        ownerContactId
      }
      ownerName
      barnName
      barnId
      barn {
        id
        name
        nickname
        description
        type
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        ownerName
        ownerPersonId
        secondaryOwnerName
        secondaryOwnerPersonId
        members {
          nextToken
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        barnContactId
        barnLocationId
      }
      contactId
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      microchipNumber
      passportNumber
      dateOfBirth
      breed
      sex
      color
      markings
      height
      foalState
      cogginsNumber
      cogginsDate
      cogginsState
      coggins {
        title
        description
        originalExtension
        dateUploaded
        url
        bucket
        region
        key
      }
      memberships {
        items {
          id
          name
          description
          membershipId
          backNumber
          membershipStatus
          amountPaid
          organizationMembershipTypeId
          type
          personName
          personId
          riderId
          horseId
          ownerId
          trainerId
          barnId
          teamId
          organizationId
          organizationName
          isJunior
          isProfessional
          dateMembershipEnds
          volunteerHours
          meetingsAttended
          showsAttended
          oneTimePassUseDate
          personEmail
          gradeLevel
          aelDivision
          aelTeamRole
          contactPersonId
          groupContactId
          groupLeaderContactId
          createdBy
          createdOn
          updatedOn
          personMembershipsId
          membershipOrganizationId
        }
        nextToken
      }
      highestLevelCompeted
      greenEligibility
      createdOn
      updatedOn
    }
  }
`;
export const onDeleteHorse = /* GraphQL */ `
  subscription OnDeleteHorse {
    onDeleteHorse {
      id
      name
      nickname
      previousNames
      personId
      ownerId
      owner {
        id
        name
        type
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnId
        createdBy
        createdOn
        updatedOn
        ownerContactId
      }
      ownerName
      barnName
      barnId
      barn {
        id
        name
        nickname
        description
        type
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        ownerName
        ownerPersonId
        secondaryOwnerName
        secondaryOwnerPersonId
        members {
          nextToken
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        barnContactId
        barnLocationId
      }
      contactId
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      microchipNumber
      passportNumber
      dateOfBirth
      breed
      sex
      color
      markings
      height
      foalState
      cogginsNumber
      cogginsDate
      cogginsState
      coggins {
        title
        description
        originalExtension
        dateUploaded
        url
        bucket
        region
        key
      }
      memberships {
        items {
          id
          name
          description
          membershipId
          backNumber
          membershipStatus
          amountPaid
          organizationMembershipTypeId
          type
          personName
          personId
          riderId
          horseId
          ownerId
          trainerId
          barnId
          teamId
          organizationId
          organizationName
          isJunior
          isProfessional
          dateMembershipEnds
          volunteerHours
          meetingsAttended
          showsAttended
          oneTimePassUseDate
          personEmail
          gradeLevel
          aelDivision
          aelTeamRole
          contactPersonId
          groupContactId
          groupLeaderContactId
          createdBy
          createdOn
          updatedOn
          personMembershipsId
          membershipOrganizationId
        }
        nextToken
      }
      highestLevelCompeted
      greenEligibility
      createdOn
      updatedOn
    }
  }
`;
export const onCreateHorseShow = /* GraphQL */ `
  subscription OnCreateHorseShow {
    onCreateHorseShow {
      id
      name
      nickname
      eventId
      ratings {
        id
        eventId
        governingBody
        rating
        createdAt
        updatedAt
      }
      judges {
        id
        name
        eventId
        personId
        judgeId
        contactId
        rating {
          type
          license
        }
        createdAt
        updatedAt
      }
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const onUpdateHorseShow = /* GraphQL */ `
  subscription OnUpdateHorseShow {
    onUpdateHorseShow {
      id
      name
      nickname
      eventId
      ratings {
        id
        eventId
        governingBody
        rating
        createdAt
        updatedAt
      }
      judges {
        id
        name
        eventId
        personId
        judgeId
        contactId
        rating {
          type
          license
        }
        createdAt
        updatedAt
      }
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const onDeleteHorseShow = /* GraphQL */ `
  subscription OnDeleteHorseShow {
    onDeleteHorseShow {
      id
      name
      nickname
      eventId
      ratings {
        id
        eventId
        governingBody
        rating
        createdAt
        updatedAt
      }
      judges {
        id
        name
        eventId
        personId
        judgeId
        contactId
        rating {
          type
          license
        }
        createdAt
        updatedAt
      }
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const onCreateHorseShowRating = /* GraphQL */ `
  subscription OnCreateHorseShowRating {
    onCreateHorseShowRating {
      id
      eventId
      governingBody
      rating
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateHorseShowRating = /* GraphQL */ `
  subscription OnUpdateHorseShowRating {
    onUpdateHorseShowRating {
      id
      eventId
      governingBody
      rating
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteHorseShowRating = /* GraphQL */ `
  subscription OnDeleteHorseShowRating {
    onDeleteHorseShowRating {
      id
      eventId
      governingBody
      rating
      createdAt
      updatedAt
    }
  }
`;
export const onCreateInvitation = /* GraphQL */ `
  subscription OnCreateInvitation {
    onCreateInvitation {
      id
      createdBy
      creatorEmail
      invitee
      inviteeEmail
      status
      organizationId
      barnId
      permissionLevel
      roles
      createdOn
      updatedOn
    }
  }
`;
export const onUpdateInvitation = /* GraphQL */ `
  subscription OnUpdateInvitation {
    onUpdateInvitation {
      id
      createdBy
      creatorEmail
      invitee
      inviteeEmail
      status
      organizationId
      barnId
      permissionLevel
      roles
      createdOn
      updatedOn
    }
  }
`;
export const onDeleteInvitation = /* GraphQL */ `
  subscription OnDeleteInvitation {
    onDeleteInvitation {
      id
      createdBy
      creatorEmail
      invitee
      inviteeEmail
      status
      organizationId
      barnId
      permissionLevel
      roles
      createdOn
      updatedOn
    }
  }
`;
export const onCreateMembership = /* GraphQL */ `
  subscription OnCreateMembership {
    onCreateMembership {
      id
      name
      description
      membershipId
      backNumber
      membershipStatus
      amountPaid
      organizationMembershipTypeId
      organizationMembershipType {
        id
        name
        description
        order
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        membershipIdValues
        nextAvailableMembershipId
        price
        category
        schedule
        isStartAtTimeOfPurchase
        setStartDate
        acceptOnlineApplications
        applicationFields {
          firstName
          lastName
          dateOfBirth
          contact
          gradeLevel
          barnName
          teamName
          backNumber
          groupName
          groupLeader
          groupLeaderContactInfo
          collectMemberDetailsTogether
          maxNumberOfGroupMembers
          isTeam
          horseName
          horseHeight
          horseOwnerName
          cogginsInfo
          cogginsUpload
        }
        applicationOptions {
          requireApproval
          autoAssignIds
          acceptRSPPayment
          acceptOtherPayment
        }
        organizationDocuments {
          id
          name
        }
        organizationRules {
          id
          title
          body
          isRequired
        }
        seasons {
          nextToken
        }
        createdOn
        updatedOn
        organizationMembershipTypeOrganizationId
      }
      type
      personName
      personId
      riderId
      rider {
        id
        name
        location
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        personId
        age
        birthdate
        isProfessional
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        createdAt
        updatedAt
        riderContactId
      }
      horseId
      horse {
        id
        name
        nickname
        previousNames
        personId
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        ownerName
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        microchipNumber
        passportNumber
        dateOfBirth
        breed
        sex
        color
        markings
        height
        foalState
        cogginsNumber
        cogginsDate
        cogginsState
        coggins {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        memberships {
          nextToken
        }
        highestLevelCompeted
        greenEligibility
        createdOn
        updatedOn
      }
      ownerId
      owner {
        id
        name
        type
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnId
        createdBy
        createdOn
        updatedOn
        ownerContactId
      }
      trainerId
      trainer {
        id
        name
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        trainerContactId
      }
      barnId
      barn {
        id
        name
        nickname
        description
        type
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        ownerName
        ownerPersonId
        secondaryOwnerName
        secondaryOwnerPersonId
        members {
          nextToken
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        barnContactId
        barnLocationId
      }
      teamId
      organizationId
      organization {
        id
        name
        nickname
        description
        type
        industry
        addresses
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        stripeId
        stripeCustomerId
        paymentMethodId
        website
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        lastUpdatedBy
        organizationOptions {
          usesTextAlerts
          acceptsIncomingTextAlerts
        }
        urlBackHalf
        colors
        logos {
          logoURL
          key
        }
        createdOn
        updatedOn
      }
      organizationName
      isJunior
      isProfessional
      dateMembershipEnds
      volunteerHours
      meetingsAttended
      showsAttended
      oneTimePassUseDate
      personEmail
      gradeLevel
      aelDivision
      aelTeamRole
      documents {
        title
        description
        originalExtension
        dateUploaded
        url
        bucket
        region
        key
      }
      organizationAgreements {
        organizationRuleId
        organizationRuleTitle
        firstName
        lastName
        personId
        createdOn
        updatedOn
      }
      contactPersonId
      groupContactId
      groupContact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      groupLeaderContactId
      groupLeaderContact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      createdBy
      createdOn
      updatedOn
      personMembershipsId
      membershipOrganizationId
    }
  }
`;
export const onUpdateMembership = /* GraphQL */ `
  subscription OnUpdateMembership {
    onUpdateMembership {
      id
      name
      description
      membershipId
      backNumber
      membershipStatus
      amountPaid
      organizationMembershipTypeId
      organizationMembershipType {
        id
        name
        description
        order
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        membershipIdValues
        nextAvailableMembershipId
        price
        category
        schedule
        isStartAtTimeOfPurchase
        setStartDate
        acceptOnlineApplications
        applicationFields {
          firstName
          lastName
          dateOfBirth
          contact
          gradeLevel
          barnName
          teamName
          backNumber
          groupName
          groupLeader
          groupLeaderContactInfo
          collectMemberDetailsTogether
          maxNumberOfGroupMembers
          isTeam
          horseName
          horseHeight
          horseOwnerName
          cogginsInfo
          cogginsUpload
        }
        applicationOptions {
          requireApproval
          autoAssignIds
          acceptRSPPayment
          acceptOtherPayment
        }
        organizationDocuments {
          id
          name
        }
        organizationRules {
          id
          title
          body
          isRequired
        }
        seasons {
          nextToken
        }
        createdOn
        updatedOn
        organizationMembershipTypeOrganizationId
      }
      type
      personName
      personId
      riderId
      rider {
        id
        name
        location
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        personId
        age
        birthdate
        isProfessional
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        createdAt
        updatedAt
        riderContactId
      }
      horseId
      horse {
        id
        name
        nickname
        previousNames
        personId
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        ownerName
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        microchipNumber
        passportNumber
        dateOfBirth
        breed
        sex
        color
        markings
        height
        foalState
        cogginsNumber
        cogginsDate
        cogginsState
        coggins {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        memberships {
          nextToken
        }
        highestLevelCompeted
        greenEligibility
        createdOn
        updatedOn
      }
      ownerId
      owner {
        id
        name
        type
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnId
        createdBy
        createdOn
        updatedOn
        ownerContactId
      }
      trainerId
      trainer {
        id
        name
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        trainerContactId
      }
      barnId
      barn {
        id
        name
        nickname
        description
        type
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        ownerName
        ownerPersonId
        secondaryOwnerName
        secondaryOwnerPersonId
        members {
          nextToken
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        barnContactId
        barnLocationId
      }
      teamId
      organizationId
      organization {
        id
        name
        nickname
        description
        type
        industry
        addresses
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        stripeId
        stripeCustomerId
        paymentMethodId
        website
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        lastUpdatedBy
        organizationOptions {
          usesTextAlerts
          acceptsIncomingTextAlerts
        }
        urlBackHalf
        colors
        logos {
          logoURL
          key
        }
        createdOn
        updatedOn
      }
      organizationName
      isJunior
      isProfessional
      dateMembershipEnds
      volunteerHours
      meetingsAttended
      showsAttended
      oneTimePassUseDate
      personEmail
      gradeLevel
      aelDivision
      aelTeamRole
      documents {
        title
        description
        originalExtension
        dateUploaded
        url
        bucket
        region
        key
      }
      organizationAgreements {
        organizationRuleId
        organizationRuleTitle
        firstName
        lastName
        personId
        createdOn
        updatedOn
      }
      contactPersonId
      groupContactId
      groupContact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      groupLeaderContactId
      groupLeaderContact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      createdBy
      createdOn
      updatedOn
      personMembershipsId
      membershipOrganizationId
    }
  }
`;
export const onDeleteMembership = /* GraphQL */ `
  subscription OnDeleteMembership {
    onDeleteMembership {
      id
      name
      description
      membershipId
      backNumber
      membershipStatus
      amountPaid
      organizationMembershipTypeId
      organizationMembershipType {
        id
        name
        description
        order
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        membershipIdValues
        nextAvailableMembershipId
        price
        category
        schedule
        isStartAtTimeOfPurchase
        setStartDate
        acceptOnlineApplications
        applicationFields {
          firstName
          lastName
          dateOfBirth
          contact
          gradeLevel
          barnName
          teamName
          backNumber
          groupName
          groupLeader
          groupLeaderContactInfo
          collectMemberDetailsTogether
          maxNumberOfGroupMembers
          isTeam
          horseName
          horseHeight
          horseOwnerName
          cogginsInfo
          cogginsUpload
        }
        applicationOptions {
          requireApproval
          autoAssignIds
          acceptRSPPayment
          acceptOtherPayment
        }
        organizationDocuments {
          id
          name
        }
        organizationRules {
          id
          title
          body
          isRequired
        }
        seasons {
          nextToken
        }
        createdOn
        updatedOn
        organizationMembershipTypeOrganizationId
      }
      type
      personName
      personId
      riderId
      rider {
        id
        name
        location
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        personId
        age
        birthdate
        isProfessional
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        createdAt
        updatedAt
        riderContactId
      }
      horseId
      horse {
        id
        name
        nickname
        previousNames
        personId
        ownerId
        owner {
          id
          name
          type
          location
          addressId
          personId
          barnId
          createdBy
          createdOn
          updatedOn
          ownerContactId
        }
        ownerName
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        microchipNumber
        passportNumber
        dateOfBirth
        breed
        sex
        color
        markings
        height
        foalState
        cogginsNumber
        cogginsDate
        cogginsState
        coggins {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        memberships {
          nextToken
        }
        highestLevelCompeted
        greenEligibility
        createdOn
        updatedOn
      }
      ownerId
      owner {
        id
        name
        type
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnId
        createdBy
        createdOn
        updatedOn
        ownerContactId
      }
      trainerId
      trainer {
        id
        name
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        trainerContactId
      }
      barnId
      barn {
        id
        name
        nickname
        description
        type
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        ownerName
        ownerPersonId
        secondaryOwnerName
        secondaryOwnerPersonId
        members {
          nextToken
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        barnContactId
        barnLocationId
      }
      teamId
      organizationId
      organization {
        id
        name
        nickname
        description
        type
        industry
        addresses
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        stripeId
        stripeCustomerId
        paymentMethodId
        website
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        lastUpdatedBy
        organizationOptions {
          usesTextAlerts
          acceptsIncomingTextAlerts
        }
        urlBackHalf
        colors
        logos {
          logoURL
          key
        }
        createdOn
        updatedOn
      }
      organizationName
      isJunior
      isProfessional
      dateMembershipEnds
      volunteerHours
      meetingsAttended
      showsAttended
      oneTimePassUseDate
      personEmail
      gradeLevel
      aelDivision
      aelTeamRole
      documents {
        title
        description
        originalExtension
        dateUploaded
        url
        bucket
        region
        key
      }
      organizationAgreements {
        organizationRuleId
        organizationRuleTitle
        firstName
        lastName
        personId
        createdOn
        updatedOn
      }
      contactPersonId
      groupContactId
      groupContact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      groupLeaderContactId
      groupLeaderContact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      createdBy
      createdOn
      updatedOn
      personMembershipsId
      membershipOrganizationId
    }
  }
`;
export const onCreateOrganizationMembershipType = /* GraphQL */ `
  subscription OnCreateOrganizationMembershipType {
    onCreateOrganizationMembershipType {
      id
      name
      description
      order
      organizationId
      organization {
        id
        name
        nickname
        description
        type
        industry
        addresses
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        stripeId
        stripeCustomerId
        paymentMethodId
        website
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        lastUpdatedBy
        organizationOptions {
          usesTextAlerts
          acceptsIncomingTextAlerts
        }
        urlBackHalf
        colors
        logos {
          logoURL
          key
        }
        createdOn
        updatedOn
      }
      membershipIdValues
      nextAvailableMembershipId
      price
      category
      schedule
      isStartAtTimeOfPurchase
      setStartDate
      acceptOnlineApplications
      applicationFields {
        firstName
        lastName
        dateOfBirth
        contact
        gradeLevel
        barnName
        teamName
        backNumber
        groupName
        groupLeader
        groupLeaderContactInfo
        collectMemberDetailsTogether
        maxNumberOfGroupMembers
        isTeam
        horseName
        horseHeight
        horseOwnerName
        cogginsInfo
        cogginsUpload
      }
      applicationOptions {
        requireApproval
        autoAssignIds
        acceptRSPPayment
        acceptOtherPayment
      }
      organizationDocuments {
        id
        name
      }
      organizationRules {
        id
        title
        body
        isRequired
      }
      seasons {
        items {
          id
          organizationMembershipTypeID
          seasonID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdOn
      updatedOn
      organizationMembershipTypeOrganizationId
    }
  }
`;
export const onUpdateOrganizationMembershipType = /* GraphQL */ `
  subscription OnUpdateOrganizationMembershipType {
    onUpdateOrganizationMembershipType {
      id
      name
      description
      order
      organizationId
      organization {
        id
        name
        nickname
        description
        type
        industry
        addresses
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        stripeId
        stripeCustomerId
        paymentMethodId
        website
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        lastUpdatedBy
        organizationOptions {
          usesTextAlerts
          acceptsIncomingTextAlerts
        }
        urlBackHalf
        colors
        logos {
          logoURL
          key
        }
        createdOn
        updatedOn
      }
      membershipIdValues
      nextAvailableMembershipId
      price
      category
      schedule
      isStartAtTimeOfPurchase
      setStartDate
      acceptOnlineApplications
      applicationFields {
        firstName
        lastName
        dateOfBirth
        contact
        gradeLevel
        barnName
        teamName
        backNumber
        groupName
        groupLeader
        groupLeaderContactInfo
        collectMemberDetailsTogether
        maxNumberOfGroupMembers
        isTeam
        horseName
        horseHeight
        horseOwnerName
        cogginsInfo
        cogginsUpload
      }
      applicationOptions {
        requireApproval
        autoAssignIds
        acceptRSPPayment
        acceptOtherPayment
      }
      organizationDocuments {
        id
        name
      }
      organizationRules {
        id
        title
        body
        isRequired
      }
      seasons {
        items {
          id
          organizationMembershipTypeID
          seasonID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdOn
      updatedOn
      organizationMembershipTypeOrganizationId
    }
  }
`;
export const onDeleteOrganizationMembershipType = /* GraphQL */ `
  subscription OnDeleteOrganizationMembershipType {
    onDeleteOrganizationMembershipType {
      id
      name
      description
      order
      organizationId
      organization {
        id
        name
        nickname
        description
        type
        industry
        addresses
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        stripeId
        stripeCustomerId
        paymentMethodId
        website
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        lastUpdatedBy
        organizationOptions {
          usesTextAlerts
          acceptsIncomingTextAlerts
        }
        urlBackHalf
        colors
        logos {
          logoURL
          key
        }
        createdOn
        updatedOn
      }
      membershipIdValues
      nextAvailableMembershipId
      price
      category
      schedule
      isStartAtTimeOfPurchase
      setStartDate
      acceptOnlineApplications
      applicationFields {
        firstName
        lastName
        dateOfBirth
        contact
        gradeLevel
        barnName
        teamName
        backNumber
        groupName
        groupLeader
        groupLeaderContactInfo
        collectMemberDetailsTogether
        maxNumberOfGroupMembers
        isTeam
        horseName
        horseHeight
        horseOwnerName
        cogginsInfo
        cogginsUpload
      }
      applicationOptions {
        requireApproval
        autoAssignIds
        acceptRSPPayment
        acceptOtherPayment
      }
      organizationDocuments {
        id
        name
      }
      organizationRules {
        id
        title
        body
        isRequired
      }
      seasons {
        items {
          id
          organizationMembershipTypeID
          seasonID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdOn
      updatedOn
      organizationMembershipTypeOrganizationId
    }
  }
`;
export const onCreateOrganization = /* GraphQL */ `
  subscription OnCreateOrganization {
    onCreateOrganization {
      id
      name
      nickname
      description
      type
      industry
      addresses
      contactId
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      stripeId
      stripeCustomerId
      paymentMethodId
      website
      media {
        title
        description
        originalExtension
        dateUploaded
        url
        bucket
        region
        key
      }
      createdBy
      lastUpdatedBy
      organizationOptions {
        usesTextAlerts
        acceptsIncomingTextAlerts
      }
      urlBackHalf
      colors
      logos {
        logoURL
        key
        logo {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
      }
      createdOn
      updatedOn
    }
  }
`;
export const onUpdateOrganization = /* GraphQL */ `
  subscription OnUpdateOrganization {
    onUpdateOrganization {
      id
      name
      nickname
      description
      type
      industry
      addresses
      contactId
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      stripeId
      stripeCustomerId
      paymentMethodId
      website
      media {
        title
        description
        originalExtension
        dateUploaded
        url
        bucket
        region
        key
      }
      createdBy
      lastUpdatedBy
      organizationOptions {
        usesTextAlerts
        acceptsIncomingTextAlerts
      }
      urlBackHalf
      colors
      logos {
        logoURL
        key
        logo {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
      }
      createdOn
      updatedOn
    }
  }
`;
export const onDeleteOrganization = /* GraphQL */ `
  subscription OnDeleteOrganization {
    onDeleteOrganization {
      id
      name
      nickname
      description
      type
      industry
      addresses
      contactId
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      stripeId
      stripeCustomerId
      paymentMethodId
      website
      media {
        title
        description
        originalExtension
        dateUploaded
        url
        bucket
        region
        key
      }
      createdBy
      lastUpdatedBy
      organizationOptions {
        usesTextAlerts
        acceptsIncomingTextAlerts
      }
      urlBackHalf
      colors
      logos {
        logoURL
        key
        logo {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
      }
      createdOn
      updatedOn
    }
  }
`;
export const onCreateOrganizationBeddingType = /* GraphQL */ `
  subscription OnCreateOrganizationBeddingType {
    onCreateOrganizationBeddingType {
      id
      organizationId
      name
      description
      type
      pricePerBag
      taxA
      taxB
      taxRateIdA
      taxRateIdB
      taxRateA {
        id
        name
        createdBy
        organizationId
        stripeTaxRateId
        percentage
        createdOn
        updatedOn
      }
      taxRateB {
        id
        name
        createdBy
        organizationId
        stripeTaxRateId
        percentage
        createdOn
        updatedOn
      }
      createdOn
      updatedOn
    }
  }
`;
export const onUpdateOrganizationBeddingType = /* GraphQL */ `
  subscription OnUpdateOrganizationBeddingType {
    onUpdateOrganizationBeddingType {
      id
      organizationId
      name
      description
      type
      pricePerBag
      taxA
      taxB
      taxRateIdA
      taxRateIdB
      taxRateA {
        id
        name
        createdBy
        organizationId
        stripeTaxRateId
        percentage
        createdOn
        updatedOn
      }
      taxRateB {
        id
        name
        createdBy
        organizationId
        stripeTaxRateId
        percentage
        createdOn
        updatedOn
      }
      createdOn
      updatedOn
    }
  }
`;
export const onDeleteOrganizationBeddingType = /* GraphQL */ `
  subscription OnDeleteOrganizationBeddingType {
    onDeleteOrganizationBeddingType {
      id
      organizationId
      name
      description
      type
      pricePerBag
      taxA
      taxB
      taxRateIdA
      taxRateIdB
      taxRateA {
        id
        name
        createdBy
        organizationId
        stripeTaxRateId
        percentage
        createdOn
        updatedOn
      }
      taxRateB {
        id
        name
        createdBy
        organizationId
        stripeTaxRateId
        percentage
        createdOn
        updatedOn
      }
      createdOn
      updatedOn
    }
  }
`;
export const onCreateOrganizationClass = /* GraphQL */ `
  subscription OnCreateOrganizationClass {
    onCreateOrganizationClass {
      id
      name
      classId
      class {
        id
        name
        nickname
        divisionId
        type
        description
        discipline
        ushjaName
        usefNumber
        ecNumber
        ecClassTypeNumber
        ecClassType
        code
        specs
        judgedOn
        rules {
          horseSex
          horseBreed
          horseMinHeight
          horseMaxHeight
          horseMinAge
          horseMaxAge
          riderMinAge
          riderMaxAge
          riderProfessionalStatus
          riderRecord
          horseRecord
          crossEntry
          other
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventInvoiceClassesId
      }
      organizationId
      organizationDivisionId
      hasChampionship
      pointTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isAddValueToRegularFromEntryCount
        isAddValueToChampionshipFromEntryCount
        isUseChampionRule
        isUseReserveRule
        tiers {
          lowEntryCount
          highEntryCount
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        numberEntriesToReceiveSpecialClassPoints
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventResultPointTablesId
      }
      prizeMoneyTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isPercentage
        totalPot
        tiers {
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      type
      number
      entryFee
      prizeMoney
      minNumberEntries
      maxNumberEntries
      jumpHeight
      estimatedTimePerRound
      createdBy
      displayOrder
      lastUpdatedBy
      createdOn
      updatedOn
      organizationClassPointTableId
      organizationClassPrizeMoneyTableId
    }
  }
`;
export const onUpdateOrganizationClass = /* GraphQL */ `
  subscription OnUpdateOrganizationClass {
    onUpdateOrganizationClass {
      id
      name
      classId
      class {
        id
        name
        nickname
        divisionId
        type
        description
        discipline
        ushjaName
        usefNumber
        ecNumber
        ecClassTypeNumber
        ecClassType
        code
        specs
        judgedOn
        rules {
          horseSex
          horseBreed
          horseMinHeight
          horseMaxHeight
          horseMinAge
          horseMaxAge
          riderMinAge
          riderMaxAge
          riderProfessionalStatus
          riderRecord
          horseRecord
          crossEntry
          other
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventInvoiceClassesId
      }
      organizationId
      organizationDivisionId
      hasChampionship
      pointTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isAddValueToRegularFromEntryCount
        isAddValueToChampionshipFromEntryCount
        isUseChampionRule
        isUseReserveRule
        tiers {
          lowEntryCount
          highEntryCount
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        numberEntriesToReceiveSpecialClassPoints
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventResultPointTablesId
      }
      prizeMoneyTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isPercentage
        totalPot
        tiers {
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      type
      number
      entryFee
      prizeMoney
      minNumberEntries
      maxNumberEntries
      jumpHeight
      estimatedTimePerRound
      createdBy
      displayOrder
      lastUpdatedBy
      createdOn
      updatedOn
      organizationClassPointTableId
      organizationClassPrizeMoneyTableId
    }
  }
`;
export const onDeleteOrganizationClass = /* GraphQL */ `
  subscription OnDeleteOrganizationClass {
    onDeleteOrganizationClass {
      id
      name
      classId
      class {
        id
        name
        nickname
        divisionId
        type
        description
        discipline
        ushjaName
        usefNumber
        ecNumber
        ecClassTypeNumber
        ecClassType
        code
        specs
        judgedOn
        rules {
          horseSex
          horseBreed
          horseMinHeight
          horseMaxHeight
          horseMinAge
          horseMaxAge
          riderMinAge
          riderMaxAge
          riderProfessionalStatus
          riderRecord
          horseRecord
          crossEntry
          other
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventInvoiceClassesId
      }
      organizationId
      organizationDivisionId
      hasChampionship
      pointTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isAddValueToRegularFromEntryCount
        isAddValueToChampionshipFromEntryCount
        isUseChampionRule
        isUseReserveRule
        tiers {
          lowEntryCount
          highEntryCount
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        numberEntriesToReceiveSpecialClassPoints
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventResultPointTablesId
      }
      prizeMoneyTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isPercentage
        totalPot
        tiers {
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      type
      number
      entryFee
      prizeMoney
      minNumberEntries
      maxNumberEntries
      jumpHeight
      estimatedTimePerRound
      createdBy
      displayOrder
      lastUpdatedBy
      createdOn
      updatedOn
      organizationClassPointTableId
      organizationClassPrizeMoneyTableId
    }
  }
`;
export const onCreateOrganizationDivision = /* GraphQL */ `
  subscription OnCreateOrganizationDivision {
    onCreateOrganizationDivision {
      id
      name
      number
      divisionId
      division {
        id
        name
        nickname
        description
        discipline
        isSearchable
        ushjaName
        usefNumber
        ecNumber
        ecClassTypeNumber
        ecClassType
        code
        specs
        judgedOn
        rules {
          horseSex
          horseBreed
          horseMinHeight
          horseMaxHeight
          horseMinAge
          horseMaxAge
          riderMinAge
          riderMaxAge
          riderProfessionalStatus
          riderRecord
          horseRecord
          crossEntry
          other
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventInvoiceDivisionsId
      }
      organizationId
      pointTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isAddValueToRegularFromEntryCount
        isAddValueToChampionshipFromEntryCount
        isUseChampionRule
        isUseReserveRule
        tiers {
          lowEntryCount
          highEntryCount
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        numberEntriesToReceiveSpecialClassPoints
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventResultPointTablesId
      }
      prizeMoneyTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isPercentage
        totalPot
        tiers {
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      jumpHeight
      minNumberEntries
      maxNumberEntries
      entryFee
      prizeMoney
      entryFeePerClass
      prizeMoneyPerClass
      createdBy
      lastUpdatedBy
      displayOrder
      createdOn
      updatedOn
      organizationDivisionPointTableId
      organizationDivisionPrizeMoneyTableId
    }
  }
`;
export const onUpdateOrganizationDivision = /* GraphQL */ `
  subscription OnUpdateOrganizationDivision {
    onUpdateOrganizationDivision {
      id
      name
      number
      divisionId
      division {
        id
        name
        nickname
        description
        discipline
        isSearchable
        ushjaName
        usefNumber
        ecNumber
        ecClassTypeNumber
        ecClassType
        code
        specs
        judgedOn
        rules {
          horseSex
          horseBreed
          horseMinHeight
          horseMaxHeight
          horseMinAge
          horseMaxAge
          riderMinAge
          riderMaxAge
          riderProfessionalStatus
          riderRecord
          horseRecord
          crossEntry
          other
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventInvoiceDivisionsId
      }
      organizationId
      pointTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isAddValueToRegularFromEntryCount
        isAddValueToChampionshipFromEntryCount
        isUseChampionRule
        isUseReserveRule
        tiers {
          lowEntryCount
          highEntryCount
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        numberEntriesToReceiveSpecialClassPoints
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventResultPointTablesId
      }
      prizeMoneyTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isPercentage
        totalPot
        tiers {
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      jumpHeight
      minNumberEntries
      maxNumberEntries
      entryFee
      prizeMoney
      entryFeePerClass
      prizeMoneyPerClass
      createdBy
      lastUpdatedBy
      displayOrder
      createdOn
      updatedOn
      organizationDivisionPointTableId
      organizationDivisionPrizeMoneyTableId
    }
  }
`;
export const onDeleteOrganizationDivision = /* GraphQL */ `
  subscription OnDeleteOrganizationDivision {
    onDeleteOrganizationDivision {
      id
      name
      number
      divisionId
      division {
        id
        name
        nickname
        description
        discipline
        isSearchable
        ushjaName
        usefNumber
        ecNumber
        ecClassTypeNumber
        ecClassType
        code
        specs
        judgedOn
        rules {
          horseSex
          horseBreed
          horseMinHeight
          horseMaxHeight
          horseMinAge
          horseMaxAge
          riderMinAge
          riderMaxAge
          riderProfessionalStatus
          riderRecord
          horseRecord
          crossEntry
          other
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventInvoiceDivisionsId
      }
      organizationId
      pointTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isAddValueToRegularFromEntryCount
        isAddValueToChampionshipFromEntryCount
        isUseChampionRule
        isUseReserveRule
        tiers {
          lowEntryCount
          highEntryCount
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        numberEntriesToReceiveSpecialClassPoints
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventResultPointTablesId
      }
      prizeMoneyTable {
        id
        name
        description
        displayOrder
        organizationId
        discipline
        isPercentage
        totalPot
        tiers {
          first
          second
          third
          fourth
          fifth
          sixth
          seventh
          eighth
          ninth
          tenth
          eleventh
          twelfth
          champion
          reserve
        }
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      jumpHeight
      minNumberEntries
      maxNumberEntries
      entryFee
      prizeMoney
      entryFeePerClass
      prizeMoneyPerClass
      createdBy
      lastUpdatedBy
      displayOrder
      createdOn
      updatedOn
      organizationDivisionPointTableId
      organizationDivisionPrizeMoneyTableId
    }
  }
`;
export const onCreateOrganizationStallSet = /* GraphQL */ `
  subscription OnCreateOrganizationStallSet {
    onCreateOrganizationStallSet {
      id
      organizationId
      name
      location
      available
      createdOn
      updatedOn
    }
  }
`;
export const onUpdateOrganizationStallSet = /* GraphQL */ `
  subscription OnUpdateOrganizationStallSet {
    onUpdateOrganizationStallSet {
      id
      organizationId
      name
      location
      available
      createdOn
      updatedOn
    }
  }
`;
export const onDeleteOrganizationStallSet = /* GraphQL */ `
  subscription OnDeleteOrganizationStallSet {
    onDeleteOrganizationStallSet {
      id
      organizationId
      name
      location
      available
      createdOn
      updatedOn
    }
  }
`;
export const onCreateOrganizationStallType = /* GraphQL */ `
  subscription OnCreateOrganizationStallType {
    onCreateOrganizationStallType {
      id
      organizationId
      name
      description
      type
      pricePerStall
      taxA
      taxB
      taxRateIdA
      taxRateIdB
      taxRateA {
        id
        name
        createdBy
        organizationId
        stripeTaxRateId
        percentage
        createdOn
        updatedOn
      }
      taxRateB {
        id
        name
        createdBy
        organizationId
        stripeTaxRateId
        percentage
        createdOn
        updatedOn
      }
      createdOn
      updatedOn
    }
  }
`;
export const onUpdateOrganizationStallType = /* GraphQL */ `
  subscription OnUpdateOrganizationStallType {
    onUpdateOrganizationStallType {
      id
      organizationId
      name
      description
      type
      pricePerStall
      taxA
      taxB
      taxRateIdA
      taxRateIdB
      taxRateA {
        id
        name
        createdBy
        organizationId
        stripeTaxRateId
        percentage
        createdOn
        updatedOn
      }
      taxRateB {
        id
        name
        createdBy
        organizationId
        stripeTaxRateId
        percentage
        createdOn
        updatedOn
      }
      createdOn
      updatedOn
    }
  }
`;
export const onDeleteOrganizationStallType = /* GraphQL */ `
  subscription OnDeleteOrganizationStallType {
    onDeleteOrganizationStallType {
      id
      organizationId
      name
      description
      type
      pricePerStall
      taxA
      taxB
      taxRateIdA
      taxRateIdB
      taxRateA {
        id
        name
        createdBy
        organizationId
        stripeTaxRateId
        percentage
        createdOn
        updatedOn
      }
      taxRateB {
        id
        name
        createdBy
        organizationId
        stripeTaxRateId
        percentage
        createdOn
        updatedOn
      }
      createdOn
      updatedOn
    }
  }
`;
export const onCreateOwner = /* GraphQL */ `
  subscription OnCreateOwner {
    onCreateOwner {
      id
      name
      type
      location
      addressId
      address {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      personId
      memberships {
        items {
          id
          name
          description
          membershipId
          backNumber
          membershipStatus
          amountPaid
          organizationMembershipTypeId
          type
          personName
          personId
          riderId
          horseId
          ownerId
          trainerId
          barnId
          teamId
          organizationId
          organizationName
          isJunior
          isProfessional
          dateMembershipEnds
          volunteerHours
          meetingsAttended
          showsAttended
          oneTimePassUseDate
          personEmail
          gradeLevel
          aelDivision
          aelTeamRole
          contactPersonId
          groupContactId
          groupLeaderContactId
          createdBy
          createdOn
          updatedOn
          personMembershipsId
          membershipOrganizationId
        }
        nextToken
      }
      barnId
      createdBy
      createdOn
      updatedOn
      ownerContactId
    }
  }
`;
export const onUpdateOwner = /* GraphQL */ `
  subscription OnUpdateOwner {
    onUpdateOwner {
      id
      name
      type
      location
      addressId
      address {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      personId
      memberships {
        items {
          id
          name
          description
          membershipId
          backNumber
          membershipStatus
          amountPaid
          organizationMembershipTypeId
          type
          personName
          personId
          riderId
          horseId
          ownerId
          trainerId
          barnId
          teamId
          organizationId
          organizationName
          isJunior
          isProfessional
          dateMembershipEnds
          volunteerHours
          meetingsAttended
          showsAttended
          oneTimePassUseDate
          personEmail
          gradeLevel
          aelDivision
          aelTeamRole
          contactPersonId
          groupContactId
          groupLeaderContactId
          createdBy
          createdOn
          updatedOn
          personMembershipsId
          membershipOrganizationId
        }
        nextToken
      }
      barnId
      createdBy
      createdOn
      updatedOn
      ownerContactId
    }
  }
`;
export const onDeleteOwner = /* GraphQL */ `
  subscription OnDeleteOwner {
    onDeleteOwner {
      id
      name
      type
      location
      addressId
      address {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      personId
      memberships {
        items {
          id
          name
          description
          membershipId
          backNumber
          membershipStatus
          amountPaid
          organizationMembershipTypeId
          type
          personName
          personId
          riderId
          horseId
          ownerId
          trainerId
          barnId
          teamId
          organizationId
          organizationName
          isJunior
          isProfessional
          dateMembershipEnds
          volunteerHours
          meetingsAttended
          showsAttended
          oneTimePassUseDate
          personEmail
          gradeLevel
          aelDivision
          aelTeamRole
          contactPersonId
          groupContactId
          groupLeaderContactId
          createdBy
          createdOn
          updatedOn
          personMembershipsId
          membershipOrganizationId
        }
        nextToken
      }
      barnId
      createdBy
      createdOn
      updatedOn
      ownerContactId
    }
  }
`;
export const onCreatePayer = /* GraphQL */ `
  subscription OnCreatePayer {
    onCreatePayer {
      id
      name
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      personId
      paymentMethod {
        id
        personId
        organizationId
        type
        stripeId
        altId
        value
        createdOn
        updatedOn
      }
      createdOn
      updatedOn
      payerContactId
      payerPaymentMethodId
    }
  }
`;
export const onUpdatePayer = /* GraphQL */ `
  subscription OnUpdatePayer {
    onUpdatePayer {
      id
      name
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      personId
      paymentMethod {
        id
        personId
        organizationId
        type
        stripeId
        altId
        value
        createdOn
        updatedOn
      }
      createdOn
      updatedOn
      payerContactId
      payerPaymentMethodId
    }
  }
`;
export const onDeletePayer = /* GraphQL */ `
  subscription OnDeletePayer {
    onDeletePayer {
      id
      name
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      personId
      paymentMethod {
        id
        personId
        organizationId
        type
        stripeId
        altId
        value
        createdOn
        updatedOn
      }
      createdOn
      updatedOn
      payerContactId
      payerPaymentMethodId
    }
  }
`;
export const onCreatePaymentMethod = /* GraphQL */ `
  subscription OnCreatePaymentMethod {
    onCreatePaymentMethod {
      id
      personId
      organizationId
      type
      stripeId
      altId
      value
      createdOn
      updatedOn
    }
  }
`;
export const onUpdatePaymentMethod = /* GraphQL */ `
  subscription OnUpdatePaymentMethod {
    onUpdatePaymentMethod {
      id
      personId
      organizationId
      type
      stripeId
      altId
      value
      createdOn
      updatedOn
    }
  }
`;
export const onDeletePaymentMethod = /* GraphQL */ `
  subscription OnDeletePaymentMethod {
    onDeletePaymentMethod {
      id
      personId
      organizationId
      type
      stripeId
      altId
      value
      createdOn
      updatedOn
    }
  }
`;
export const onCreatePerson = /* GraphQL */ `
  subscription OnCreatePerson {
    onCreatePerson {
      id
      email
      firstName
      lastName
      isVerified
      roles
      memberships {
        items {
          id
          name
          description
          membershipId
          backNumber
          membershipStatus
          amountPaid
          organizationMembershipTypeId
          type
          personName
          personId
          riderId
          horseId
          ownerId
          trainerId
          barnId
          teamId
          organizationId
          organizationName
          isJunior
          isProfessional
          dateMembershipEnds
          volunteerHours
          meetingsAttended
          showsAttended
          oneTimePassUseDate
          personEmail
          gradeLevel
          aelDivision
          aelTeamRole
          contactPersonId
          groupContactId
          groupLeaderContactId
          createdBy
          createdOn
          updatedOn
          personMembershipsId
          membershipOrganizationId
        }
        nextToken
      }
      personalInformationId
      personalInformation {
        id
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        dateOfBirth
        gender
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      pictureURL
      picture {
        title
        description
        originalExtension
        dateUploaded
        url
        bucket
        region
        key
      }
      disciplines
      stripeId
      media {
        title
        description
        originalExtension
        dateUploaded
        url
        bucket
        region
        key
      }
      createdBy
      createdOn
      updatedOn
    }
  }
`;
export const onUpdatePerson = /* GraphQL */ `
  subscription OnUpdatePerson {
    onUpdatePerson {
      id
      email
      firstName
      lastName
      isVerified
      roles
      memberships {
        items {
          id
          name
          description
          membershipId
          backNumber
          membershipStatus
          amountPaid
          organizationMembershipTypeId
          type
          personName
          personId
          riderId
          horseId
          ownerId
          trainerId
          barnId
          teamId
          organizationId
          organizationName
          isJunior
          isProfessional
          dateMembershipEnds
          volunteerHours
          meetingsAttended
          showsAttended
          oneTimePassUseDate
          personEmail
          gradeLevel
          aelDivision
          aelTeamRole
          contactPersonId
          groupContactId
          groupLeaderContactId
          createdBy
          createdOn
          updatedOn
          personMembershipsId
          membershipOrganizationId
        }
        nextToken
      }
      personalInformationId
      personalInformation {
        id
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        dateOfBirth
        gender
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      pictureURL
      picture {
        title
        description
        originalExtension
        dateUploaded
        url
        bucket
        region
        key
      }
      disciplines
      stripeId
      media {
        title
        description
        originalExtension
        dateUploaded
        url
        bucket
        region
        key
      }
      createdBy
      createdOn
      updatedOn
    }
  }
`;
export const onDeletePerson = /* GraphQL */ `
  subscription OnDeletePerson {
    onDeletePerson {
      id
      email
      firstName
      lastName
      isVerified
      roles
      memberships {
        items {
          id
          name
          description
          membershipId
          backNumber
          membershipStatus
          amountPaid
          organizationMembershipTypeId
          type
          personName
          personId
          riderId
          horseId
          ownerId
          trainerId
          barnId
          teamId
          organizationId
          organizationName
          isJunior
          isProfessional
          dateMembershipEnds
          volunteerHours
          meetingsAttended
          showsAttended
          oneTimePassUseDate
          personEmail
          gradeLevel
          aelDivision
          aelTeamRole
          contactPersonId
          groupContactId
          groupLeaderContactId
          createdBy
          createdOn
          updatedOn
          personMembershipsId
          membershipOrganizationId
        }
        nextToken
      }
      personalInformationId
      personalInformation {
        id
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        dateOfBirth
        gender
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      pictureURL
      picture {
        title
        description
        originalExtension
        dateUploaded
        url
        bucket
        region
        key
      }
      disciplines
      stripeId
      media {
        title
        description
        originalExtension
        dateUploaded
        url
        bucket
        region
        key
      }
      createdBy
      createdOn
      updatedOn
    }
  }
`;
export const onCreatePersonName = /* GraphQL */ `
  subscription OnCreatePersonName {
    onCreatePersonName {
      id
      personId
      title
      firstName
      middleName
      maidenName
      lastName
      suffix
      legalName
      displayName
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePersonName = /* GraphQL */ `
  subscription OnUpdatePersonName {
    onUpdatePersonName {
      id
      personId
      title
      firstName
      middleName
      maidenName
      lastName
      suffix
      legalName
      displayName
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePersonName = /* GraphQL */ `
  subscription OnDeletePersonName {
    onDeletePersonName {
      id
      personId
      title
      firstName
      middleName
      maidenName
      lastName
      suffix
      legalName
      displayName
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePersonalInformation = /* GraphQL */ `
  subscription OnCreatePersonalInformation {
    onCreatePersonalInformation {
      id
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      dateOfBirth
      gender
      contactId
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      addressId
      address {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePersonalInformation = /* GraphQL */ `
  subscription OnUpdatePersonalInformation {
    onUpdatePersonalInformation {
      id
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      dateOfBirth
      gender
      contactId
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      addressId
      address {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePersonalInformation = /* GraphQL */ `
  subscription OnDeletePersonalInformation {
    onDeletePersonalInformation {
      id
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      dateOfBirth
      gender
      contactId
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      addressId
      address {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePointTable = /* GraphQL */ `
  subscription OnCreatePointTable {
    onCreatePointTable {
      id
      name
      description
      displayOrder
      organizationId
      discipline
      isAddValueToRegularFromEntryCount
      isAddValueToChampionshipFromEntryCount
      isUseChampionRule
      isUseReserveRule
      tiers {
        lowEntryCount
        highEntryCount
        first
        second
        third
        fourth
        fifth
        sixth
        seventh
        eighth
        ninth
        tenth
        eleventh
        twelfth
        champion
        reserve
      }
      numberEntriesToReceiveSpecialClassPoints
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventResultPointTablesId
    }
  }
`;
export const onUpdatePointTable = /* GraphQL */ `
  subscription OnUpdatePointTable {
    onUpdatePointTable {
      id
      name
      description
      displayOrder
      organizationId
      discipline
      isAddValueToRegularFromEntryCount
      isAddValueToChampionshipFromEntryCount
      isUseChampionRule
      isUseReserveRule
      tiers {
        lowEntryCount
        highEntryCount
        first
        second
        third
        fourth
        fifth
        sixth
        seventh
        eighth
        ninth
        tenth
        eleventh
        twelfth
        champion
        reserve
      }
      numberEntriesToReceiveSpecialClassPoints
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventResultPointTablesId
    }
  }
`;
export const onDeletePointTable = /* GraphQL */ `
  subscription OnDeletePointTable {
    onDeletePointTable {
      id
      name
      description
      displayOrder
      organizationId
      discipline
      isAddValueToRegularFromEntryCount
      isAddValueToChampionshipFromEntryCount
      isUseChampionRule
      isUseReserveRule
      tiers {
        lowEntryCount
        highEntryCount
        first
        second
        third
        fourth
        fifth
        sixth
        seventh
        eighth
        ninth
        tenth
        eleventh
        twelfth
        champion
        reserve
      }
      numberEntriesToReceiveSpecialClassPoints
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
      eventResultPointTablesId
    }
  }
`;
export const onCreatePoll = /* GraphQL */ `
  subscription OnCreatePoll {
    onCreatePoll {
      id
      title
      description
      responses {
        items {
          id
          pollId
          personId
          value
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePoll = /* GraphQL */ `
  subscription OnUpdatePoll {
    onUpdatePoll {
      id
      title
      description
      responses {
        items {
          id
          pollId
          personId
          value
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePoll = /* GraphQL */ `
  subscription OnDeletePoll {
    onDeletePoll {
      id
      title
      description
      responses {
        items {
          id
          pollId
          personId
          value
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePost = /* GraphQL */ `
  subscription OnCreatePost {
    onCreatePost {
      id
      title
      status
      rating
      content
      organizationId
      createdBy
      createdOn
      updatedOn
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePost = /* GraphQL */ `
  subscription OnUpdatePost {
    onUpdatePost {
      id
      title
      status
      rating
      content
      organizationId
      createdBy
      createdOn
      updatedOn
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePost = /* GraphQL */ `
  subscription OnDeletePost {
    onDeletePost {
      id
      title
      status
      rating
      content
      organizationId
      createdBy
      createdOn
      updatedOn
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePrizeMoneyTable = /* GraphQL */ `
  subscription OnCreatePrizeMoneyTable {
    onCreatePrizeMoneyTable {
      id
      name
      description
      displayOrder
      organizationId
      discipline
      isPercentage
      totalPot
      tiers {
        first
        second
        third
        fourth
        fifth
        sixth
        seventh
        eighth
        ninth
        tenth
        eleventh
        twelfth
        champion
        reserve
      }
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const onUpdatePrizeMoneyTable = /* GraphQL */ `
  subscription OnUpdatePrizeMoneyTable {
    onUpdatePrizeMoneyTable {
      id
      name
      description
      displayOrder
      organizationId
      discipline
      isPercentage
      totalPot
      tiers {
        first
        second
        third
        fourth
        fifth
        sixth
        seventh
        eighth
        ninth
        tenth
        eleventh
        twelfth
        champion
        reserve
      }
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const onDeletePrizeMoneyTable = /* GraphQL */ `
  subscription OnDeletePrizeMoneyTable {
    onDeletePrizeMoneyTable {
      id
      name
      description
      displayOrder
      organizationId
      discipline
      isPercentage
      totalPot
      tiers {
        first
        second
        third
        fourth
        fifth
        sixth
        seventh
        eighth
        ninth
        tenth
        eleventh
        twelfth
        champion
        reserve
      }
      createdBy
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const onCreatePromoCode = /* GraphQL */ `
  subscription OnCreatePromoCode {
    onCreatePromoCode {
      id
      name
      stripeId
      isvalid
      isfundraiser
      ispercentdiscount
      isfixeddiscount
      percent
      fixed
      minamount
      startdate
      enddate
      createdOn
      updatedOn
    }
  }
`;
export const onUpdatePromoCode = /* GraphQL */ `
  subscription OnUpdatePromoCode {
    onUpdatePromoCode {
      id
      name
      stripeId
      isvalid
      isfundraiser
      ispercentdiscount
      isfixeddiscount
      percent
      fixed
      minamount
      startdate
      enddate
      createdOn
      updatedOn
    }
  }
`;
export const onDeletePromoCode = /* GraphQL */ `
  subscription OnDeletePromoCode {
    onDeletePromoCode {
      id
      name
      stripeId
      isvalid
      isfundraiser
      ispercentdiscount
      isfixeddiscount
      percent
      fixed
      minamount
      startdate
      enddate
      createdOn
      updatedOn
    }
  }
`;
export const onCreateResponse = /* GraphQL */ `
  subscription OnCreateResponse {
    onCreateResponse {
      id
      pollId
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      value
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateResponse = /* GraphQL */ `
  subscription OnUpdateResponse {
    onUpdateResponse {
      id
      pollId
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      value
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteResponse = /* GraphQL */ `
  subscription OnDeleteResponse {
    onDeleteResponse {
      id
      pollId
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      value
      createdAt
      updatedAt
    }
  }
`;
export const onCreateRider = /* GraphQL */ `
  subscription OnCreateRider {
    onCreateRider {
      id
      name
      location
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      addressId
      address {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      personId
      age
      birthdate
      isProfessional
      memberships {
        items {
          id
          name
          description
          membershipId
          backNumber
          membershipStatus
          amountPaid
          organizationMembershipTypeId
          type
          personName
          personId
          riderId
          horseId
          ownerId
          trainerId
          barnId
          teamId
          organizationId
          organizationName
          isJunior
          isProfessional
          dateMembershipEnds
          volunteerHours
          meetingsAttended
          showsAttended
          oneTimePassUseDate
          personEmail
          gradeLevel
          aelDivision
          aelTeamRole
          contactPersonId
          groupContactId
          groupLeaderContactId
          createdBy
          createdOn
          updatedOn
          personMembershipsId
          membershipOrganizationId
        }
        nextToken
      }
      barnName
      barnId
      barn {
        id
        name
        nickname
        description
        type
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        ownerName
        ownerPersonId
        secondaryOwnerName
        secondaryOwnerPersonId
        members {
          nextToken
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        barnContactId
        barnLocationId
      }
      createdBy
      createdOn
      updatedOn
      createdAt
      updatedAt
      riderContactId
    }
  }
`;
export const onUpdateRider = /* GraphQL */ `
  subscription OnUpdateRider {
    onUpdateRider {
      id
      name
      location
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      addressId
      address {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      personId
      age
      birthdate
      isProfessional
      memberships {
        items {
          id
          name
          description
          membershipId
          backNumber
          membershipStatus
          amountPaid
          organizationMembershipTypeId
          type
          personName
          personId
          riderId
          horseId
          ownerId
          trainerId
          barnId
          teamId
          organizationId
          organizationName
          isJunior
          isProfessional
          dateMembershipEnds
          volunteerHours
          meetingsAttended
          showsAttended
          oneTimePassUseDate
          personEmail
          gradeLevel
          aelDivision
          aelTeamRole
          contactPersonId
          groupContactId
          groupLeaderContactId
          createdBy
          createdOn
          updatedOn
          personMembershipsId
          membershipOrganizationId
        }
        nextToken
      }
      barnName
      barnId
      barn {
        id
        name
        nickname
        description
        type
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        ownerName
        ownerPersonId
        secondaryOwnerName
        secondaryOwnerPersonId
        members {
          nextToken
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        barnContactId
        barnLocationId
      }
      createdBy
      createdOn
      updatedOn
      createdAt
      updatedAt
      riderContactId
    }
  }
`;
export const onDeleteRider = /* GraphQL */ `
  subscription OnDeleteRider {
    onDeleteRider {
      id
      name
      location
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      addressId
      address {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      personId
      age
      birthdate
      isProfessional
      memberships {
        items {
          id
          name
          description
          membershipId
          backNumber
          membershipStatus
          amountPaid
          organizationMembershipTypeId
          type
          personName
          personId
          riderId
          horseId
          ownerId
          trainerId
          barnId
          teamId
          organizationId
          organizationName
          isJunior
          isProfessional
          dateMembershipEnds
          volunteerHours
          meetingsAttended
          showsAttended
          oneTimePassUseDate
          personEmail
          gradeLevel
          aelDivision
          aelTeamRole
          contactPersonId
          groupContactId
          groupLeaderContactId
          createdBy
          createdOn
          updatedOn
          personMembershipsId
          membershipOrganizationId
        }
        nextToken
      }
      barnName
      barnId
      barn {
        id
        name
        nickname
        description
        type
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        ownerName
        ownerPersonId
        secondaryOwnerName
        secondaryOwnerPersonId
        members {
          nextToken
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        barnContactId
        barnLocationId
      }
      createdBy
      createdOn
      updatedOn
      createdAt
      updatedAt
      riderContactId
    }
  }
`;
export const onCreateRing = /* GraphQL */ `
  subscription OnCreateRing {
    onCreateRing {
      id
      name
      displayOrder
      organizationId
      createdOn
      updatedOn
    }
  }
`;
export const onUpdateRing = /* GraphQL */ `
  subscription OnUpdateRing {
    onUpdateRing {
      id
      name
      displayOrder
      organizationId
      createdOn
      updatedOn
    }
  }
`;
export const onDeleteRing = /* GraphQL */ `
  subscription OnDeleteRing {
    onDeleteRing {
      id
      name
      displayOrder
      organizationId
      createdOn
      updatedOn
    }
  }
`;
export const onCreateScheduleItem = /* GraphQL */ `
  subscription OnCreateScheduleItem {
    onCreateScheduleItem {
      id
      eventId
      eventRingId
      eventDayId
      eventRing {
        id
        name
        eventId
        ringId
        event {
          id
          name
          nickname
          createdBy
          manager
          startDate
          endDate
          contactId
          contactIds
          organizationId
          type
          disciplines
          status
          entriesStatus
          showStatus
          usefID
          usefSoftwareKey
          ecID
          ecPassword
          lastUpdatedBy
          createdOn
          updatedOn
          eventAddressId
        }
        ring {
          id
          name
          displayOrder
          organizationId
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
      }
      eventDay {
        id
        eventId
        awsdate
        dayofweek
        date
        month
        year
        createdOn
        updatedOn
      }
      displayOrder
      classId
      eventClass {
        id
        name
        eventId
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceClassesId
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        organizationClassId
        organizationClass {
          id
          name
          classId
          organizationId
          organizationDivisionId
          hasChampionship
          type
          number
          entryFee
          prizeMoney
          minNumberEntries
          maxNumberEntries
          jumpHeight
          estimatedTimePerRound
          createdBy
          displayOrder
          lastUpdatedBy
          createdOn
          updatedOn
          organizationClassPointTableId
          organizationClassPrizeMoneyTableId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryOption
        entryFee
        fees {
          nextToken
        }
        prizeMoney
        entries {
          nextToken
        }
        completedVerifyEntries
        currentNumberEntries
        numberEntriesThatCompeted
        minNumberEntries
        maxNumberEntries
        estimatedTimePerRound
        isCaliforniaSplit
        isCombined
        combinedEventClassId
        combinedEventDivisionId
        jumpHeight
        isScheduled
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventClassPointTableId
        eventClassPrizeMoneyTableId
      }
      breakId
      eventBreak {
        id
        eventId
        name
        time
        isDisplayedOnSchedule
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      startTime
      createdOn
      updatedOn
    }
  }
`;
export const onUpdateScheduleItem = /* GraphQL */ `
  subscription OnUpdateScheduleItem {
    onUpdateScheduleItem {
      id
      eventId
      eventRingId
      eventDayId
      eventRing {
        id
        name
        eventId
        ringId
        event {
          id
          name
          nickname
          createdBy
          manager
          startDate
          endDate
          contactId
          contactIds
          organizationId
          type
          disciplines
          status
          entriesStatus
          showStatus
          usefID
          usefSoftwareKey
          ecID
          ecPassword
          lastUpdatedBy
          createdOn
          updatedOn
          eventAddressId
        }
        ring {
          id
          name
          displayOrder
          organizationId
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
      }
      eventDay {
        id
        eventId
        awsdate
        dayofweek
        date
        month
        year
        createdOn
        updatedOn
      }
      displayOrder
      classId
      eventClass {
        id
        name
        eventId
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceClassesId
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        organizationClassId
        organizationClass {
          id
          name
          classId
          organizationId
          organizationDivisionId
          hasChampionship
          type
          number
          entryFee
          prizeMoney
          minNumberEntries
          maxNumberEntries
          jumpHeight
          estimatedTimePerRound
          createdBy
          displayOrder
          lastUpdatedBy
          createdOn
          updatedOn
          organizationClassPointTableId
          organizationClassPrizeMoneyTableId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryOption
        entryFee
        fees {
          nextToken
        }
        prizeMoney
        entries {
          nextToken
        }
        completedVerifyEntries
        currentNumberEntries
        numberEntriesThatCompeted
        minNumberEntries
        maxNumberEntries
        estimatedTimePerRound
        isCaliforniaSplit
        isCombined
        combinedEventClassId
        combinedEventDivisionId
        jumpHeight
        isScheduled
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventClassPointTableId
        eventClassPrizeMoneyTableId
      }
      breakId
      eventBreak {
        id
        eventId
        name
        time
        isDisplayedOnSchedule
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      startTime
      createdOn
      updatedOn
    }
  }
`;
export const onDeleteScheduleItem = /* GraphQL */ `
  subscription OnDeleteScheduleItem {
    onDeleteScheduleItem {
      id
      eventId
      eventRingId
      eventDayId
      eventRing {
        id
        name
        eventId
        ringId
        event {
          id
          name
          nickname
          createdBy
          manager
          startDate
          endDate
          contactId
          contactIds
          organizationId
          type
          disciplines
          status
          entriesStatus
          showStatus
          usefID
          usefSoftwareKey
          ecID
          ecPassword
          lastUpdatedBy
          createdOn
          updatedOn
          eventAddressId
        }
        ring {
          id
          name
          displayOrder
          organizationId
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
      }
      eventDay {
        id
        eventId
        awsdate
        dayofweek
        date
        month
        year
        createdOn
        updatedOn
      }
      displayOrder
      classId
      eventClass {
        id
        name
        eventId
        classId
        class {
          id
          name
          nickname
          divisionId
          type
          description
          discipline
          ushjaName
          usefNumber
          ecNumber
          ecClassTypeNumber
          ecClassType
          code
          specs
          judgedOn
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventInvoiceClassesId
        }
        eventDivisionId
        eventDivision {
          id
          eventId
          name
          number
          divisionId
          organizationDivisionId
          entryFee
          prizeMoney
          jumpHeight
          minNumberEntriesPerClass
          maxNumberEntriesPerClass
          isCaliforniaSplit
          isCombined
          combinedEventDivisionId
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventDivisionOrganizationDivisionId
          eventDivisionPointTableId
          eventDivisionPrizeMoneyTableId
        }
        organizationClassId
        organizationClass {
          id
          name
          classId
          organizationId
          organizationDivisionId
          hasChampionship
          type
          number
          entryFee
          prizeMoney
          minNumberEntries
          maxNumberEntries
          jumpHeight
          estimatedTimePerRound
          createdBy
          displayOrder
          lastUpdatedBy
          createdOn
          updatedOn
          organizationClassPointTableId
          organizationClassPrizeMoneyTableId
        }
        organizationDivisionId
        organizationDivision {
          id
          name
          number
          divisionId
          organizationId
          jumpHeight
          minNumberEntries
          maxNumberEntries
          entryFee
          prizeMoney
          entryFeePerClass
          prizeMoneyPerClass
          createdBy
          lastUpdatedBy
          displayOrder
          createdOn
          updatedOn
          organizationDivisionPointTableId
          organizationDivisionPrizeMoneyTableId
        }
        eventResults {
          nextToken
        }
        pointTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isAddValueToRegularFromEntryCount
          isAddValueToChampionshipFromEntryCount
          isUseChampionRule
          isUseReserveRule
          numberEntriesToReceiveSpecialClassPoints
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventResultPointTablesId
        }
        prizeMoneyTable {
          id
          name
          description
          displayOrder
          organizationId
          discipline
          isPercentage
          totalPot
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        type
        number
        entryOption
        entryFee
        fees {
          nextToken
        }
        prizeMoney
        entries {
          nextToken
        }
        completedVerifyEntries
        currentNumberEntries
        numberEntriesThatCompeted
        minNumberEntries
        maxNumberEntries
        estimatedTimePerRound
        isCaliforniaSplit
        isCombined
        combinedEventClassId
        combinedEventDivisionId
        jumpHeight
        isScheduled
        note
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventClassPointTableId
        eventClassPrizeMoneyTableId
      }
      breakId
      eventBreak {
        id
        eventId
        name
        time
        isDisplayedOnSchedule
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
      }
      startTime
      createdOn
      updatedOn
    }
  }
`;
export const onCreateSeason = /* GraphQL */ `
  subscription OnCreateSeason {
    onCreateSeason {
      id
      name
      description
      organizationId
      organization {
        id
        name
        nickname
        description
        type
        industry
        addresses
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        stripeId
        stripeCustomerId
        paymentMethodId
        website
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        lastUpdatedBy
        organizationOptions {
          usesTextAlerts
          acceptsIncomingTextAlerts
        }
        urlBackHalf
        colors
        logos {
          logoURL
          key
        }
        createdOn
        updatedOn
      }
      organizationMembershipTypes {
        items {
          id
          organizationMembershipTypeID
          seasonID
          createdAt
          updatedAt
        }
        nextToken
      }
      isActive
      startDate
      endDate
      disciplines
      createdOn
      updatedOn
      createdAt
      updatedAt
      seasonOrganizationId
    }
  }
`;
export const onUpdateSeason = /* GraphQL */ `
  subscription OnUpdateSeason {
    onUpdateSeason {
      id
      name
      description
      organizationId
      organization {
        id
        name
        nickname
        description
        type
        industry
        addresses
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        stripeId
        stripeCustomerId
        paymentMethodId
        website
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        lastUpdatedBy
        organizationOptions {
          usesTextAlerts
          acceptsIncomingTextAlerts
        }
        urlBackHalf
        colors
        logos {
          logoURL
          key
        }
        createdOn
        updatedOn
      }
      organizationMembershipTypes {
        items {
          id
          organizationMembershipTypeID
          seasonID
          createdAt
          updatedAt
        }
        nextToken
      }
      isActive
      startDate
      endDate
      disciplines
      createdOn
      updatedOn
      createdAt
      updatedAt
      seasonOrganizationId
    }
  }
`;
export const onDeleteSeason = /* GraphQL */ `
  subscription OnDeleteSeason {
    onDeleteSeason {
      id
      name
      description
      organizationId
      organization {
        id
        name
        nickname
        description
        type
        industry
        addresses
        contactId
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        stripeId
        stripeCustomerId
        paymentMethodId
        website
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        lastUpdatedBy
        organizationOptions {
          usesTextAlerts
          acceptsIncomingTextAlerts
        }
        urlBackHalf
        colors
        logos {
          logoURL
          key
        }
        createdOn
        updatedOn
      }
      organizationMembershipTypes {
        items {
          id
          organizationMembershipTypeID
          seasonID
          createdAt
          updatedAt
        }
        nextToken
      }
      isActive
      startDate
      endDate
      disciplines
      createdOn
      updatedOn
      createdAt
      updatedAt
      seasonOrganizationId
    }
  }
`;
export const onCreateStablingRequest = /* GraphQL */ `
  subscription OnCreateStablingRequest {
    onCreateStablingRequest {
      id
      eventId
      trainerId
      trainer {
        id
        name
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        trainerContactId
      }
      barnId
      barn {
        id
        name
        nickname
        description
        type
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        ownerName
        ownerPersonId
        secondaryOwnerName
        secondaryOwnerPersonId
        members {
          nextToken
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        barnContactId
        barnLocationId
      }
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      arrivalDateTime
      departureDateTime
      stallTypeId
      stallType {
        id
        eventId
        organizationId
        organizationStallTypeId
        name
        description
        type
        pricePerStall
        taxPerStall
        createdOn
        updatedOn
      }
      quantityNeeded
      basePrice
      taxA
      taxB
      totalPrice
      status
      statusNote
      requestNote
      stallSetId
      stallSet {
        id
        eventId
        organizationId
        organizationStallSetId
        name
        location
        available
        totalAvailable
        used
        totalUsed
        createdOn
        updatedOn
      }
      stallLocation
      stallNumbers
      payerId
      payerName
      payer {
        id
        name
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        paymentMethod {
          id
          personId
          organizationId
          type
          stripeId
          altId
          value
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
        payerContactId
        payerPaymentMethodId
      }
      splitAcrossEntries
      entries {
        items {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        nextToken
      }
      entryIds
      submittedEntry
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const onUpdateStablingRequest = /* GraphQL */ `
  subscription OnUpdateStablingRequest {
    onUpdateStablingRequest {
      id
      eventId
      trainerId
      trainer {
        id
        name
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        trainerContactId
      }
      barnId
      barn {
        id
        name
        nickname
        description
        type
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        ownerName
        ownerPersonId
        secondaryOwnerName
        secondaryOwnerPersonId
        members {
          nextToken
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        barnContactId
        barnLocationId
      }
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      arrivalDateTime
      departureDateTime
      stallTypeId
      stallType {
        id
        eventId
        organizationId
        organizationStallTypeId
        name
        description
        type
        pricePerStall
        taxPerStall
        createdOn
        updatedOn
      }
      quantityNeeded
      basePrice
      taxA
      taxB
      totalPrice
      status
      statusNote
      requestNote
      stallSetId
      stallSet {
        id
        eventId
        organizationId
        organizationStallSetId
        name
        location
        available
        totalAvailable
        used
        totalUsed
        createdOn
        updatedOn
      }
      stallLocation
      stallNumbers
      payerId
      payerName
      payer {
        id
        name
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        paymentMethod {
          id
          personId
          organizationId
          type
          stripeId
          altId
          value
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
        payerContactId
        payerPaymentMethodId
      }
      splitAcrossEntries
      entries {
        items {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        nextToken
      }
      entryIds
      submittedEntry
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const onDeleteStablingRequest = /* GraphQL */ `
  subscription OnDeleteStablingRequest {
    onDeleteStablingRequest {
      id
      eventId
      trainerId
      trainer {
        id
        name
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        trainerContactId
      }
      barnId
      barn {
        id
        name
        nickname
        description
        type
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        ownerName
        ownerPersonId
        secondaryOwnerName
        secondaryOwnerPersonId
        members {
          nextToken
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        barnContactId
        barnLocationId
      }
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      arrivalDateTime
      departureDateTime
      stallTypeId
      stallType {
        id
        eventId
        organizationId
        organizationStallTypeId
        name
        description
        type
        pricePerStall
        taxPerStall
        createdOn
        updatedOn
      }
      quantityNeeded
      basePrice
      taxA
      taxB
      totalPrice
      status
      statusNote
      requestNote
      stallSetId
      stallSet {
        id
        eventId
        organizationId
        organizationStallSetId
        name
        location
        available
        totalAvailable
        used
        totalUsed
        createdOn
        updatedOn
      }
      stallLocation
      stallNumbers
      payerId
      payerName
      payer {
        id
        name
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        paymentMethod {
          id
          personId
          organizationId
          type
          stripeId
          altId
          value
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
        payerContactId
        payerPaymentMethodId
      }
      splitAcrossEntries
      entries {
        items {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        nextToken
      }
      entryIds
      submittedEntry
      lastUpdatedBy
      createdOn
      updatedOn
    }
  }
`;
export const onCreateBeddingRequest = /* GraphQL */ `
  subscription OnCreateBeddingRequest {
    onCreateBeddingRequest {
      id
      eventId
      trainerId
      trainer {
        id
        name
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        trainerContactId
      }
      barnId
      barn {
        id
        name
        nickname
        description
        type
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        ownerName
        ownerPersonId
        secondaryOwnerName
        secondaryOwnerPersonId
        members {
          nextToken
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        barnContactId
        barnLocationId
      }
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      arrivalDateTime
      dateTimeNeeded
      deliveryInfo
      beddingType
      quantityNeeded
      basePrice
      taxA {
        id
        name
        createdBy
        organizationId
        stripeTaxRateId
        percentage
        createdOn
        updatedOn
      }
      taxB {
        id
        name
        createdBy
        organizationId
        stripeTaxRateId
        percentage
        createdOn
        updatedOn
      }
      totalPrice
      status
      statusNote
      payerId
      payerName
      payer {
        id
        name
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        paymentMethod {
          id
          personId
          organizationId
          type
          stripeId
          altId
          value
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
        payerContactId
        payerPaymentMethodId
      }
      splitAcrossEntries
      entries {
        items {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        nextToken
      }
      entryIds
      submittedEntry
      lastUpdatedBy
      createdOn
      updatedOn
      beddingRequestTaxAId
      beddingRequestTaxBId
    }
  }
`;
export const onUpdateBeddingRequest = /* GraphQL */ `
  subscription OnUpdateBeddingRequest {
    onUpdateBeddingRequest {
      id
      eventId
      trainerId
      trainer {
        id
        name
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        trainerContactId
      }
      barnId
      barn {
        id
        name
        nickname
        description
        type
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        ownerName
        ownerPersonId
        secondaryOwnerName
        secondaryOwnerPersonId
        members {
          nextToken
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        barnContactId
        barnLocationId
      }
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      arrivalDateTime
      dateTimeNeeded
      deliveryInfo
      beddingType
      quantityNeeded
      basePrice
      taxA {
        id
        name
        createdBy
        organizationId
        stripeTaxRateId
        percentage
        createdOn
        updatedOn
      }
      taxB {
        id
        name
        createdBy
        organizationId
        stripeTaxRateId
        percentage
        createdOn
        updatedOn
      }
      totalPrice
      status
      statusNote
      payerId
      payerName
      payer {
        id
        name
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        paymentMethod {
          id
          personId
          organizationId
          type
          stripeId
          altId
          value
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
        payerContactId
        payerPaymentMethodId
      }
      splitAcrossEntries
      entries {
        items {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        nextToken
      }
      entryIds
      submittedEntry
      lastUpdatedBy
      createdOn
      updatedOn
      beddingRequestTaxAId
      beddingRequestTaxBId
    }
  }
`;
export const onDeleteBeddingRequest = /* GraphQL */ `
  subscription OnDeleteBeddingRequest {
    onDeleteBeddingRequest {
      id
      eventId
      trainerId
      trainer {
        id
        name
        location
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        trainerContactId
      }
      barnId
      barn {
        id
        name
        nickname
        description
        type
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        ownerName
        ownerPersonId
        secondaryOwnerName
        secondaryOwnerPersonId
        members {
          nextToken
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        barnContactId
        barnLocationId
      }
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      arrivalDateTime
      dateTimeNeeded
      deliveryInfo
      beddingType
      quantityNeeded
      basePrice
      taxA {
        id
        name
        createdBy
        organizationId
        stripeTaxRateId
        percentage
        createdOn
        updatedOn
      }
      taxB {
        id
        name
        createdBy
        organizationId
        stripeTaxRateId
        percentage
        createdOn
        updatedOn
      }
      totalPrice
      status
      statusNote
      payerId
      payerName
      payer {
        id
        name
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        personId
        paymentMethod {
          id
          personId
          organizationId
          type
          stripeId
          altId
          value
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
        payerContactId
        payerPaymentMethodId
      }
      splitAcrossEntries
      entries {
        items {
          id
          eventId
          status
          statusNote
          personId
          number
          isCheckedIn
          isCheckedOut
          contactId
          riderName
          riderId
          secondRiderName
          secondRiderId
          thirdRiderName
          thirdRiderId
          horseName
          horseId
          ownerName
          ownerId
          secondOwnerName
          secondOwnerId
          thirdOwnerName
          thirdOwnerId
          trainerName
          trainerId
          secondTrainerName
          secondTrainerId
          thirdTrainerName
          thirdTrainerId
          barnName
          barnId
          stablingRequestId
          beddingRequestId
          markedDoesNotNeedStalls
          payerName
          payerId
          prizeMoneyRecipientName
          prizeMoneyRecipientId
          prizeMoneyRecipientType
          note
          divisionList
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
          eventEntryPrizeMoneyRecipientContactId
        }
        nextToken
      }
      entryIds
      submittedEntry
      lastUpdatedBy
      createdOn
      updatedOn
      beddingRequestTaxAId
      beddingRequestTaxBId
    }
  }
`;
export const onCreateSubmission = /* GraphQL */ `
  subscription OnCreateSubmission {
    onCreateSubmission {
      id
      title
      description
      video {
        title
        description
        originalExtension
        dateUploaded
        url
        bucket
        region
        key
      }
      videoLength
      rider {
        id
        name
        location
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        personId
        age
        birthdate
        isProfessional
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        createdAt
        updatedAt
        riderContactId
      }
      riderId
      personId
      clinicians {
        id
        name
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        judgeId
        contactId
        rating {
          type
          license
        }
        usefNumber
        ecNumber
        price
        status
        paymentChoice {
          id
          personId
          organizationId
          type
          stripeId
          altId
          value
          createdOn
          updatedOn
        }
        paymentVerified
        stripeAccountId
        totalEarned
        location
        disciplines
        experience
        personal
        preferences
        hasIHSAExperience
        hasIEAExperience
        createdOn
        updatedOn
        clinicianPaymentChoiceId
      }
      totalPrice
      status
      submissionFeedback {
        items {
          id
          type
          submissionId
          feedbackId
          personId
          riderId
          clinicianId
          submissionStatus
          feedbackStatus
          clinicianAmount
          declineReason
          permissionToPost
          dateSubmitted
          dateCompleted
          createdOn
          updatedOn
          submissionFeedbackClinicianId
        }
        nextToken
      }
      createdOn
      updatedOn
    }
  }
`;
export const onUpdateSubmission = /* GraphQL */ `
  subscription OnUpdateSubmission {
    onUpdateSubmission {
      id
      title
      description
      video {
        title
        description
        originalExtension
        dateUploaded
        url
        bucket
        region
        key
      }
      videoLength
      rider {
        id
        name
        location
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        personId
        age
        birthdate
        isProfessional
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        createdAt
        updatedAt
        riderContactId
      }
      riderId
      personId
      clinicians {
        id
        name
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        judgeId
        contactId
        rating {
          type
          license
        }
        usefNumber
        ecNumber
        price
        status
        paymentChoice {
          id
          personId
          organizationId
          type
          stripeId
          altId
          value
          createdOn
          updatedOn
        }
        paymentVerified
        stripeAccountId
        totalEarned
        location
        disciplines
        experience
        personal
        preferences
        hasIHSAExperience
        hasIEAExperience
        createdOn
        updatedOn
        clinicianPaymentChoiceId
      }
      totalPrice
      status
      submissionFeedback {
        items {
          id
          type
          submissionId
          feedbackId
          personId
          riderId
          clinicianId
          submissionStatus
          feedbackStatus
          clinicianAmount
          declineReason
          permissionToPost
          dateSubmitted
          dateCompleted
          createdOn
          updatedOn
          submissionFeedbackClinicianId
        }
        nextToken
      }
      createdOn
      updatedOn
    }
  }
`;
export const onDeleteSubmission = /* GraphQL */ `
  subscription OnDeleteSubmission {
    onDeleteSubmission {
      id
      title
      description
      video {
        title
        description
        originalExtension
        dateUploaded
        url
        bucket
        region
        key
      }
      videoLength
      rider {
        id
        name
        location
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        personId
        age
        birthdate
        isProfessional
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        createdAt
        updatedAt
        riderContactId
      }
      riderId
      personId
      clinicians {
        id
        name
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        judgeId
        contactId
        rating {
          type
          license
        }
        usefNumber
        ecNumber
        price
        status
        paymentChoice {
          id
          personId
          organizationId
          type
          stripeId
          altId
          value
          createdOn
          updatedOn
        }
        paymentVerified
        stripeAccountId
        totalEarned
        location
        disciplines
        experience
        personal
        preferences
        hasIHSAExperience
        hasIEAExperience
        createdOn
        updatedOn
        clinicianPaymentChoiceId
      }
      totalPrice
      status
      submissionFeedback {
        items {
          id
          type
          submissionId
          feedbackId
          personId
          riderId
          clinicianId
          submissionStatus
          feedbackStatus
          clinicianAmount
          declineReason
          permissionToPost
          dateSubmitted
          dateCompleted
          createdOn
          updatedOn
          submissionFeedbackClinicianId
        }
        nextToken
      }
      createdOn
      updatedOn
    }
  }
`;
export const onCreateSubmissionFeedback = /* GraphQL */ `
  subscription OnCreateSubmissionFeedback {
    onCreateSubmissionFeedback {
      id
      type
      submission {
        id
        title
        description
        video {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        videoLength
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        riderId
        personId
        clinicians {
          id
          name
          personId
          judgeId
          contactId
          usefNumber
          ecNumber
          price
          status
          paymentVerified
          stripeAccountId
          totalEarned
          location
          disciplines
          experience
          personal
          preferences
          hasIHSAExperience
          hasIEAExperience
          createdOn
          updatedOn
          clinicianPaymentChoiceId
        }
        totalPrice
        status
        submissionFeedback {
          nextToken
        }
        createdOn
        updatedOn
      }
      submissionId
      feedback {
        id
        clinicianId
        personId
        technical
        style
        turnout
        general
        score
        status
        submissionFeedback {
          id
          type
          submissionId
          feedbackId
          personId
          riderId
          clinicianId
          submissionStatus
          feedbackStatus
          clinicianAmount
          declineReason
          permissionToPost
          dateSubmitted
          dateCompleted
          createdOn
          updatedOn
          submissionFeedbackClinicianId
        }
        createdOn
        updatedOn
        feedbackSubmissionFeedbackId
      }
      feedbackId
      personId
      rider {
        id
        name
        location
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        personId
        age
        birthdate
        isProfessional
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        createdAt
        updatedAt
        riderContactId
      }
      riderId
      clinician {
        id
        name
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        judgeId
        contactId
        rating {
          type
          license
        }
        usefNumber
        ecNumber
        price
        status
        paymentChoice {
          id
          personId
          organizationId
          type
          stripeId
          altId
          value
          createdOn
          updatedOn
        }
        paymentVerified
        stripeAccountId
        totalEarned
        location
        disciplines
        experience
        personal
        preferences
        hasIHSAExperience
        hasIEAExperience
        createdOn
        updatedOn
        clinicianPaymentChoiceId
      }
      clinicianId
      submissionStatus
      feedbackStatus
      clinicianAmount
      declineReason
      permissionToPost
      dateSubmitted
      dateCompleted
      createdOn
      updatedOn
      submissionFeedbackClinicianId
    }
  }
`;
export const onUpdateSubmissionFeedback = /* GraphQL */ `
  subscription OnUpdateSubmissionFeedback {
    onUpdateSubmissionFeedback {
      id
      type
      submission {
        id
        title
        description
        video {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        videoLength
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        riderId
        personId
        clinicians {
          id
          name
          personId
          judgeId
          contactId
          usefNumber
          ecNumber
          price
          status
          paymentVerified
          stripeAccountId
          totalEarned
          location
          disciplines
          experience
          personal
          preferences
          hasIHSAExperience
          hasIEAExperience
          createdOn
          updatedOn
          clinicianPaymentChoiceId
        }
        totalPrice
        status
        submissionFeedback {
          nextToken
        }
        createdOn
        updatedOn
      }
      submissionId
      feedback {
        id
        clinicianId
        personId
        technical
        style
        turnout
        general
        score
        status
        submissionFeedback {
          id
          type
          submissionId
          feedbackId
          personId
          riderId
          clinicianId
          submissionStatus
          feedbackStatus
          clinicianAmount
          declineReason
          permissionToPost
          dateSubmitted
          dateCompleted
          createdOn
          updatedOn
          submissionFeedbackClinicianId
        }
        createdOn
        updatedOn
        feedbackSubmissionFeedbackId
      }
      feedbackId
      personId
      rider {
        id
        name
        location
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        personId
        age
        birthdate
        isProfessional
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        createdAt
        updatedAt
        riderContactId
      }
      riderId
      clinician {
        id
        name
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        judgeId
        contactId
        rating {
          type
          license
        }
        usefNumber
        ecNumber
        price
        status
        paymentChoice {
          id
          personId
          organizationId
          type
          stripeId
          altId
          value
          createdOn
          updatedOn
        }
        paymentVerified
        stripeAccountId
        totalEarned
        location
        disciplines
        experience
        personal
        preferences
        hasIHSAExperience
        hasIEAExperience
        createdOn
        updatedOn
        clinicianPaymentChoiceId
      }
      clinicianId
      submissionStatus
      feedbackStatus
      clinicianAmount
      declineReason
      permissionToPost
      dateSubmitted
      dateCompleted
      createdOn
      updatedOn
      submissionFeedbackClinicianId
    }
  }
`;
export const onDeleteSubmissionFeedback = /* GraphQL */ `
  subscription OnDeleteSubmissionFeedback {
    onDeleteSubmissionFeedback {
      id
      type
      submission {
        id
        title
        description
        video {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        videoLength
        rider {
          id
          name
          location
          addressId
          personId
          age
          birthdate
          isProfessional
          barnName
          barnId
          createdBy
          createdOn
          updatedOn
          createdAt
          updatedAt
          riderContactId
        }
        riderId
        personId
        clinicians {
          id
          name
          personId
          judgeId
          contactId
          usefNumber
          ecNumber
          price
          status
          paymentVerified
          stripeAccountId
          totalEarned
          location
          disciplines
          experience
          personal
          preferences
          hasIHSAExperience
          hasIEAExperience
          createdOn
          updatedOn
          clinicianPaymentChoiceId
        }
        totalPrice
        status
        submissionFeedback {
          nextToken
        }
        createdOn
        updatedOn
      }
      submissionId
      feedback {
        id
        clinicianId
        personId
        technical
        style
        turnout
        general
        score
        status
        submissionFeedback {
          id
          type
          submissionId
          feedbackId
          personId
          riderId
          clinicianId
          submissionStatus
          feedbackStatus
          clinicianAmount
          declineReason
          permissionToPost
          dateSubmitted
          dateCompleted
          createdOn
          updatedOn
          submissionFeedbackClinicianId
        }
        createdOn
        updatedOn
        feedbackSubmissionFeedbackId
      }
      feedbackId
      personId
      rider {
        id
        name
        location
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        addressId
        address {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        personId
        age
        birthdate
        isProfessional
        memberships {
          nextToken
        }
        barnName
        barnId
        barn {
          id
          name
          nickname
          description
          type
          ownerName
          ownerPersonId
          secondaryOwnerName
          secondaryOwnerPersonId
          lastUpdatedBy
          createdOn
          updatedOn
          barnContactId
          barnLocationId
        }
        createdBy
        createdOn
        updatedOn
        createdAt
        updatedAt
        riderContactId
      }
      riderId
      clinician {
        id
        name
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        judgeId
        contactId
        rating {
          type
          license
        }
        usefNumber
        ecNumber
        price
        status
        paymentChoice {
          id
          personId
          organizationId
          type
          stripeId
          altId
          value
          createdOn
          updatedOn
        }
        paymentVerified
        stripeAccountId
        totalEarned
        location
        disciplines
        experience
        personal
        preferences
        hasIHSAExperience
        hasIEAExperience
        createdOn
        updatedOn
        clinicianPaymentChoiceId
      }
      clinicianId
      submissionStatus
      feedbackStatus
      clinicianAmount
      declineReason
      permissionToPost
      dateSubmitted
      dateCompleted
      createdOn
      updatedOn
      submissionFeedbackClinicianId
    }
  }
`;
export const onCreateTaxRate = /* GraphQL */ `
  subscription OnCreateTaxRate {
    onCreateTaxRate {
      id
      name
      createdBy
      organizationId
      stripeTaxRateId
      percentage
      createdOn
      updatedOn
    }
  }
`;
export const onUpdateTaxRate = /* GraphQL */ `
  subscription OnUpdateTaxRate {
    onUpdateTaxRate {
      id
      name
      createdBy
      organizationId
      stripeTaxRateId
      percentage
      createdOn
      updatedOn
    }
  }
`;
export const onDeleteTaxRate = /* GraphQL */ `
  subscription OnDeleteTaxRate {
    onDeleteTaxRate {
      id
      name
      createdBy
      organizationId
      stripeTaxRateId
      percentage
      createdOn
      updatedOn
    }
  }
`;
export const onCreateTeam = /* GraphQL */ `
  subscription OnCreateTeam {
    onCreateTeam {
      id
      name
      nickname
      description
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      location {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      members {
        items {
          id
          teamId
          personId
          status
          role
          permissionLevel
          creatorId
          createdOn
          updatedOn
          teamMemberCreatorId
        }
        nextToken
      }
      creatorId
      creator {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      createdOn
      updatedOn
      teamContactId
      teamLocationId
      teamCreatorId
    }
  }
`;
export const onUpdateTeam = /* GraphQL */ `
  subscription OnUpdateTeam {
    onUpdateTeam {
      id
      name
      nickname
      description
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      location {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      members {
        items {
          id
          teamId
          personId
          status
          role
          permissionLevel
          creatorId
          createdOn
          updatedOn
          teamMemberCreatorId
        }
        nextToken
      }
      creatorId
      creator {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      createdOn
      updatedOn
      teamContactId
      teamLocationId
      teamCreatorId
    }
  }
`;
export const onDeleteTeam = /* GraphQL */ `
  subscription OnDeleteTeam {
    onDeleteTeam {
      id
      name
      nickname
      description
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      location {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      members {
        items {
          id
          teamId
          personId
          status
          role
          permissionLevel
          creatorId
          createdOn
          updatedOn
          teamMemberCreatorId
        }
        nextToken
      }
      creatorId
      creator {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      createdOn
      updatedOn
      teamContactId
      teamLocationId
      teamCreatorId
    }
  }
`;
export const onCreateTeamMember = /* GraphQL */ `
  subscription OnCreateTeamMember {
    onCreateTeamMember {
      id
      teamId
      team {
        id
        name
        nickname
        description
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        members {
          nextToken
        }
        creatorId
        creator {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
        teamContactId
        teamLocationId
        teamCreatorId
      }
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      status
      role
      permissionLevel
      creatorId
      creator {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      createdOn
      updatedOn
      teamMemberCreatorId
    }
  }
`;
export const onUpdateTeamMember = /* GraphQL */ `
  subscription OnUpdateTeamMember {
    onUpdateTeamMember {
      id
      teamId
      team {
        id
        name
        nickname
        description
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        members {
          nextToken
        }
        creatorId
        creator {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
        teamContactId
        teamLocationId
        teamCreatorId
      }
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      status
      role
      permissionLevel
      creatorId
      creator {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      createdOn
      updatedOn
      teamMemberCreatorId
    }
  }
`;
export const onDeleteTeamMember = /* GraphQL */ `
  subscription OnDeleteTeamMember {
    onDeleteTeamMember {
      id
      teamId
      team {
        id
        name
        nickname
        description
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        members {
          nextToken
        }
        creatorId
        creator {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        createdOn
        updatedOn
        teamContactId
        teamLocationId
        teamCreatorId
      }
      personId
      person {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      status
      role
      permissionLevel
      creatorId
      creator {
        id
        email
        firstName
        lastName
        isVerified
        roles
        memberships {
          nextToken
        }
        personalInformationId
        personalInformation {
          id
          personId
          dateOfBirth
          gender
          contactId
          addressId
          createdAt
          updatedAt
        }
        pictureURL
        picture {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        disciplines
        stripeId
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        createdBy
        createdOn
        updatedOn
      }
      createdOn
      updatedOn
      teamMemberCreatorId
    }
  }
`;
export const onCreateText = /* GraphQL */ `
  subscription OnCreateText {
    onCreateText {
      id
      eventId
      blockId
      block {
        id
        name
        type
        personid
        organizationId
        purchasedOn
        expiresOn
        totalCredits
        usedCredits
        status
        price
        amountPaid
        isPromotion
        isFree
        lastUpdatedBy
        createdOn
        updatedOn
      }
      alertId
      alert {
        id
        type
        title
        body
        imageKey
        category
        organizationId
        eventId
        event {
          id
          name
          nickname
          createdBy
          manager
          startDate
          endDate
          contactId
          contactIds
          organizationId
          type
          disciplines
          status
          entriesStatus
          showStatus
          usefID
          usefSoftwareKey
          ecID
          ecPassword
          lastUpdatedBy
          createdOn
          updatedOn
          eventAddressId
        }
        messageSegments
        subscriberCount
        totalCost
        blockIdsUsed
        fromUnlimitedEvent
        isIncoming
        sentFromPhoneNumber
        recipientPhoneNumbers
        successSIDList
        errorSIDList
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        alertEventId
      }
      auditorId
      auditor {
        id
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        eventId
        event {
          id
          name
          nickname
          createdBy
          manager
          startDate
          endDate
          contactId
          contactIds
          organizationId
          type
          disciplines
          status
          entriesStatus
          showStatus
          usefID
          usefSoftwareKey
          ecID
          ecPassword
          lastUpdatedBy
          createdOn
          updatedOn
          eventAddressId
        }
        auditorId
        auditor {
          id
          personId
          name
          phoneNumber
          email
          status
          statusNote
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        filters {
          nextToken
        }
        name
        phoneNumber
        status
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventAuditorEventId
      }
      status
      statusNote
      errorCode
      twilioSID
      twilioMessagingServiceSID
      twilioNote
      createdBy
      lastUpdatedBy
      timeSent
      timeDelivered
      createdOn
      updatedOn
    }
  }
`;
export const onUpdateText = /* GraphQL */ `
  subscription OnUpdateText {
    onUpdateText {
      id
      eventId
      blockId
      block {
        id
        name
        type
        personid
        organizationId
        purchasedOn
        expiresOn
        totalCredits
        usedCredits
        status
        price
        amountPaid
        isPromotion
        isFree
        lastUpdatedBy
        createdOn
        updatedOn
      }
      alertId
      alert {
        id
        type
        title
        body
        imageKey
        category
        organizationId
        eventId
        event {
          id
          name
          nickname
          createdBy
          manager
          startDate
          endDate
          contactId
          contactIds
          organizationId
          type
          disciplines
          status
          entriesStatus
          showStatus
          usefID
          usefSoftwareKey
          ecID
          ecPassword
          lastUpdatedBy
          createdOn
          updatedOn
          eventAddressId
        }
        messageSegments
        subscriberCount
        totalCost
        blockIdsUsed
        fromUnlimitedEvent
        isIncoming
        sentFromPhoneNumber
        recipientPhoneNumbers
        successSIDList
        errorSIDList
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        alertEventId
      }
      auditorId
      auditor {
        id
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        eventId
        event {
          id
          name
          nickname
          createdBy
          manager
          startDate
          endDate
          contactId
          contactIds
          organizationId
          type
          disciplines
          status
          entriesStatus
          showStatus
          usefID
          usefSoftwareKey
          ecID
          ecPassword
          lastUpdatedBy
          createdOn
          updatedOn
          eventAddressId
        }
        auditorId
        auditor {
          id
          personId
          name
          phoneNumber
          email
          status
          statusNote
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        filters {
          nextToken
        }
        name
        phoneNumber
        status
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventAuditorEventId
      }
      status
      statusNote
      errorCode
      twilioSID
      twilioMessagingServiceSID
      twilioNote
      createdBy
      lastUpdatedBy
      timeSent
      timeDelivered
      createdOn
      updatedOn
    }
  }
`;
export const onDeleteText = /* GraphQL */ `
  subscription OnDeleteText {
    onDeleteText {
      id
      eventId
      blockId
      block {
        id
        name
        type
        personid
        organizationId
        purchasedOn
        expiresOn
        totalCredits
        usedCredits
        status
        price
        amountPaid
        isPromotion
        isFree
        lastUpdatedBy
        createdOn
        updatedOn
      }
      alertId
      alert {
        id
        type
        title
        body
        imageKey
        category
        organizationId
        eventId
        event {
          id
          name
          nickname
          createdBy
          manager
          startDate
          endDate
          contactId
          contactIds
          organizationId
          type
          disciplines
          status
          entriesStatus
          showStatus
          usefID
          usefSoftwareKey
          ecID
          ecPassword
          lastUpdatedBy
          createdOn
          updatedOn
          eventAddressId
        }
        messageSegments
        subscriberCount
        totalCost
        blockIdsUsed
        fromUnlimitedEvent
        isIncoming
        sentFromPhoneNumber
        recipientPhoneNumbers
        successSIDList
        errorSIDList
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        alertEventId
      }
      auditorId
      auditor {
        id
        personId
        person {
          id
          email
          firstName
          lastName
          isVerified
          roles
          personalInformationId
          pictureURL
          disciplines
          stripeId
          createdBy
          createdOn
          updatedOn
        }
        eventId
        event {
          id
          name
          nickname
          createdBy
          manager
          startDate
          endDate
          contactId
          contactIds
          organizationId
          type
          disciplines
          status
          entriesStatus
          showStatus
          usefID
          usefSoftwareKey
          ecID
          ecPassword
          lastUpdatedBy
          createdOn
          updatedOn
          eventAddressId
        }
        auditorId
        auditor {
          id
          personId
          name
          phoneNumber
          email
          status
          statusNote
          createdBy
          lastUpdatedBy
          createdOn
          updatedOn
        }
        filters {
          nextToken
        }
        name
        phoneNumber
        status
        createdBy
        lastUpdatedBy
        createdOn
        updatedOn
        eventAuditorEventId
      }
      status
      statusNote
      errorCode
      twilioSID
      twilioMessagingServiceSID
      twilioNote
      createdBy
      lastUpdatedBy
      timeSent
      timeDelivered
      createdOn
      updatedOn
    }
  }
`;
export const onCreateTrainer = /* GraphQL */ `
  subscription OnCreateTrainer {
    onCreateTrainer {
      id
      name
      location
      addressId
      address {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      personId
      memberships {
        items {
          id
          name
          description
          membershipId
          backNumber
          membershipStatus
          amountPaid
          organizationMembershipTypeId
          type
          personName
          personId
          riderId
          horseId
          ownerId
          trainerId
          barnId
          teamId
          organizationId
          organizationName
          isJunior
          isProfessional
          dateMembershipEnds
          volunteerHours
          meetingsAttended
          showsAttended
          oneTimePassUseDate
          personEmail
          gradeLevel
          aelDivision
          aelTeamRole
          contactPersonId
          groupContactId
          groupLeaderContactId
          createdBy
          createdOn
          updatedOn
          personMembershipsId
          membershipOrganizationId
        }
        nextToken
      }
      barnName
      barnId
      barn {
        id
        name
        nickname
        description
        type
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        ownerName
        ownerPersonId
        secondaryOwnerName
        secondaryOwnerPersonId
        members {
          nextToken
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        barnContactId
        barnLocationId
      }
      createdBy
      createdOn
      updatedOn
      trainerContactId
    }
  }
`;
export const onUpdateTrainer = /* GraphQL */ `
  subscription OnUpdateTrainer {
    onUpdateTrainer {
      id
      name
      location
      addressId
      address {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      personId
      memberships {
        items {
          id
          name
          description
          membershipId
          backNumber
          membershipStatus
          amountPaid
          organizationMembershipTypeId
          type
          personName
          personId
          riderId
          horseId
          ownerId
          trainerId
          barnId
          teamId
          organizationId
          organizationName
          isJunior
          isProfessional
          dateMembershipEnds
          volunteerHours
          meetingsAttended
          showsAttended
          oneTimePassUseDate
          personEmail
          gradeLevel
          aelDivision
          aelTeamRole
          contactPersonId
          groupContactId
          groupLeaderContactId
          createdBy
          createdOn
          updatedOn
          personMembershipsId
          membershipOrganizationId
        }
        nextToken
      }
      barnName
      barnId
      barn {
        id
        name
        nickname
        description
        type
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        ownerName
        ownerPersonId
        secondaryOwnerName
        secondaryOwnerPersonId
        members {
          nextToken
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        barnContactId
        barnLocationId
      }
      createdBy
      createdOn
      updatedOn
      trainerContactId
    }
  }
`;
export const onDeleteTrainer = /* GraphQL */ `
  subscription OnDeleteTrainer {
    onDeleteTrainer {
      id
      name
      location
      addressId
      address {
        id
        name
        type
        isDefault
        streetAddress1
        streetAddress2
        city
        provState
        zip
        country
        notes
        createdAt
        updatedAt
      }
      contact {
        id
        name
        cell
        home
        work
        fax
        personalEmail
        personalEmailIsVerified
        workEmail
        workEmailIsVerified
        mailingAddress
        website
        facebookPage
        instagramHandle
        twitterHandle
        tiktokHandle
        emergencyContactName
        emergencyContactPhone
        createdBy
        createdAt
        updatedAt
      }
      personId
      memberships {
        items {
          id
          name
          description
          membershipId
          backNumber
          membershipStatus
          amountPaid
          organizationMembershipTypeId
          type
          personName
          personId
          riderId
          horseId
          ownerId
          trainerId
          barnId
          teamId
          organizationId
          organizationName
          isJunior
          isProfessional
          dateMembershipEnds
          volunteerHours
          meetingsAttended
          showsAttended
          oneTimePassUseDate
          personEmail
          gradeLevel
          aelDivision
          aelTeamRole
          contactPersonId
          groupContactId
          groupLeaderContactId
          createdBy
          createdOn
          updatedOn
          personMembershipsId
          membershipOrganizationId
        }
        nextToken
      }
      barnName
      barnId
      barn {
        id
        name
        nickname
        description
        type
        contact {
          id
          name
          cell
          home
          work
          fax
          personalEmail
          personalEmailIsVerified
          workEmail
          workEmailIsVerified
          mailingAddress
          website
          facebookPage
          instagramHandle
          twitterHandle
          tiktokHandle
          emergencyContactName
          emergencyContactPhone
          createdBy
          createdAt
          updatedAt
        }
        ownerName
        ownerPersonId
        secondaryOwnerName
        secondaryOwnerPersonId
        members {
          nextToken
        }
        location {
          id
          name
          type
          isDefault
          streetAddress1
          streetAddress2
          city
          provState
          zip
          country
          notes
          createdAt
          updatedAt
        }
        media {
          title
          description
          originalExtension
          dateUploaded
          url
          bucket
          region
          key
        }
        lastUpdatedBy
        createdOn
        updatedOn
        barnContactId
        barnLocationId
      }
      createdBy
      createdOn
      updatedOn
      trainerContactId
    }
  }
`;
export const onCreateOrganizationMembershipTypeSeasons = /* GraphQL */ `
  subscription OnCreateOrganizationMembershipTypeSeasons {
    onCreateOrganizationMembershipTypeSeasons {
      id
      organizationMembershipTypeID
      seasonID
      organizationMembershipType {
        id
        name
        description
        order
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        membershipIdValues
        nextAvailableMembershipId
        price
        category
        schedule
        isStartAtTimeOfPurchase
        setStartDate
        acceptOnlineApplications
        applicationFields {
          firstName
          lastName
          dateOfBirth
          contact
          gradeLevel
          barnName
          teamName
          backNumber
          groupName
          groupLeader
          groupLeaderContactInfo
          collectMemberDetailsTogether
          maxNumberOfGroupMembers
          isTeam
          horseName
          horseHeight
          horseOwnerName
          cogginsInfo
          cogginsUpload
        }
        applicationOptions {
          requireApproval
          autoAssignIds
          acceptRSPPayment
          acceptOtherPayment
        }
        organizationDocuments {
          id
          name
        }
        organizationRules {
          id
          title
          body
          isRequired
        }
        seasons {
          nextToken
        }
        createdOn
        updatedOn
        organizationMembershipTypeOrganizationId
      }
      season {
        id
        name
        description
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        organizationMembershipTypes {
          nextToken
        }
        isActive
        startDate
        endDate
        disciplines
        createdOn
        updatedOn
        createdAt
        updatedAt
        seasonOrganizationId
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateOrganizationMembershipTypeSeasons = /* GraphQL */ `
  subscription OnUpdateOrganizationMembershipTypeSeasons {
    onUpdateOrganizationMembershipTypeSeasons {
      id
      organizationMembershipTypeID
      seasonID
      organizationMembershipType {
        id
        name
        description
        order
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        membershipIdValues
        nextAvailableMembershipId
        price
        category
        schedule
        isStartAtTimeOfPurchase
        setStartDate
        acceptOnlineApplications
        applicationFields {
          firstName
          lastName
          dateOfBirth
          contact
          gradeLevel
          barnName
          teamName
          backNumber
          groupName
          groupLeader
          groupLeaderContactInfo
          collectMemberDetailsTogether
          maxNumberOfGroupMembers
          isTeam
          horseName
          horseHeight
          horseOwnerName
          cogginsInfo
          cogginsUpload
        }
        applicationOptions {
          requireApproval
          autoAssignIds
          acceptRSPPayment
          acceptOtherPayment
        }
        organizationDocuments {
          id
          name
        }
        organizationRules {
          id
          title
          body
          isRequired
        }
        seasons {
          nextToken
        }
        createdOn
        updatedOn
        organizationMembershipTypeOrganizationId
      }
      season {
        id
        name
        description
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        organizationMembershipTypes {
          nextToken
        }
        isActive
        startDate
        endDate
        disciplines
        createdOn
        updatedOn
        createdAt
        updatedAt
        seasonOrganizationId
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteOrganizationMembershipTypeSeasons = /* GraphQL */ `
  subscription OnDeleteOrganizationMembershipTypeSeasons {
    onDeleteOrganizationMembershipTypeSeasons {
      id
      organizationMembershipTypeID
      seasonID
      organizationMembershipType {
        id
        name
        description
        order
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        membershipIdValues
        nextAvailableMembershipId
        price
        category
        schedule
        isStartAtTimeOfPurchase
        setStartDate
        acceptOnlineApplications
        applicationFields {
          firstName
          lastName
          dateOfBirth
          contact
          gradeLevel
          barnName
          teamName
          backNumber
          groupName
          groupLeader
          groupLeaderContactInfo
          collectMemberDetailsTogether
          maxNumberOfGroupMembers
          isTeam
          horseName
          horseHeight
          horseOwnerName
          cogginsInfo
          cogginsUpload
        }
        applicationOptions {
          requireApproval
          autoAssignIds
          acceptRSPPayment
          acceptOtherPayment
        }
        organizationDocuments {
          id
          name
        }
        organizationRules {
          id
          title
          body
          isRequired
        }
        seasons {
          nextToken
        }
        createdOn
        updatedOn
        organizationMembershipTypeOrganizationId
      }
      season {
        id
        name
        description
        organizationId
        organization {
          id
          name
          nickname
          description
          type
          industry
          addresses
          contactId
          stripeId
          stripeCustomerId
          paymentMethodId
          website
          createdBy
          lastUpdatedBy
          urlBackHalf
          colors
          createdOn
          updatedOn
        }
        organizationMembershipTypes {
          nextToken
        }
        isActive
        startDate
        endDate
        disciplines
        createdOn
        updatedOn
        createdAt
        updatedAt
        seasonOrganizationId
      }
      createdAt
      updatedAt
    }
  }
`;
