import {
    IonButton,
    IonCard,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonIcon,
    IonRow,
    IonSearchbar,
    IonText,
    isPlatform
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Input } from "reactstrap";
import DataTable from "react-data-table-component";
import moment from "moment";
import { useHistory } from "react-router";
import { createOutline, caretUpOutline, caretDownOutline, add } from "ionicons/icons";
import Spinner from "../Spinners/Spinner";
import { onCreateMembership, onDeleteMembership, onUpdateMembership } from "../../graphql/subscriptions";
import { useSubscriptionByItself } from "../../utilities/subscription/Subscription";
import { isWindows } from "../../utilities/platform/Platform";
import { UpdateMembershipInput } from "../../API";
import { generateOrgMemberContactReport, generateOrgMemberReport } from "../../utilities/reports/MembersReport";
import { Membership, Team } from "../../models";
import { getMembershipById, getMembershipsByOrganizationIdByActiveStatus, updateMembership } from "../../utilities/membership/Membership";
import CONSTANT from "../../constant/constant";
import { getTeamById } from "../../utilities/team/Team";

interface _Props {
    organizationId: string
    isViewOnly?: boolean
}

export interface FormattedMembership {
    id: string
    personName: string
    membershipNumber: string
    backNumber: string
    status: string
    horseName: string
    barnName: string
    barnId: string
    volunteerHours: string
    meetingsAttended: string
    showsAttended: string
    oneTimePass?: (string | null | undefined)
    membership: Membership
    membershipStatus: string
    type: string
    dateCreated: string
    hasBeenChanged?: boolean
}

enum sortOptions {
    number1 = "SortByNumberSmallToLarge",
    number2 = "SortByNumberLargeToSmall",
    type1 = "SortByTypeNameAtoZ",
    type2 = "SortByTypeNameZtoA",
    hours1 = "SortByHoursSmallToLarge",
    hours2 = "SortByHoursLargeToSmall",
    meeting1 = "SortByMeetingSmallToLarge",
    meeting2 = "SortByMeetingLargeToSmall",
    shows1 = "SortByShowsSmallToLarge",
    shows2 = "SortByShowsLargeToSmall",
    memberId1 = "SortByMemberIdSmallToLarge",
    memberId2 = "SortByMemberIdLargeToSmall",
    rider1 = "SortByRiderNameAtoZ",
    rider2 = "SortByRiderNameZtoA",
    horse1 = "SortByHorseNameAtoZ",
    horse2 = "SortByHorseNameZtoA",
    barn1 = "SortByBarnNameAtoZ",
    barn2 = "SortByBarnNameZtoA",
    status1 = "SortByStatusAtoZ",
    status2 = "SortByStatusZtoA",
    dateCreated1 = "SortByDateCreatedSmallToLarge",
    dateCreated2 = "SortByDateCreatedLargeToSmall",
}

const columnHeaders = {
    backNumber: "Back #",
    membershipId: "Member Id",
    dateCreated: "Date Created",
    rider: "Rider",
    horse: "Horse",
    barn: "Barn",
    status: "Status",
    type: "Type",
    hours: "Hours",
    meetings: "Meetings",
    shows: "Shows"
}

const OrganizationMembershipsTable: React.FC<_Props> = ({organizationId, isViewOnly}) => {

    const history = useHistory();

    const createSubscription = useSubscriptionByItself({
        config: {
            query: onCreateMembership,
            key: "onCreateMembership"
        }
    });

    const updateSubscription = useSubscriptionByItself({
        config: {
            query: onUpdateMembership,
            key: "onUpdateMembership"
        }
    });

    const deleteSubscription = useSubscriptionByItself({
        config: {
            query: onDeleteMembership,
            key: "onDeleteMembership"
        }
    });

    const [currentCreateSubscriptionItem, setCreateCurrentSubscriptionItem] = useState<any>();
    const [currentUpdateSubscriptionItem, setUpdateCurrentSubscriptionItem] = useState<any>();
    const [currentDeleteSubscriptionItem, setDeleteCurrentSubscriptionItem] = useState<any>();

    useEffect(() => {
        if (createSubscription && createSubscription[0] !== undefined && createSubscription[0] !== currentCreateSubscriptionItem) {
            const item = createSubscription[0];
            setCreateCurrentSubscriptionItem(item);
            if (item && item.id) handleCreateMembership(item.id);
        }
    }, [createSubscription]);

    useEffect(() => {
        if (updateSubscription && updateSubscription[0] !== undefined && updateSubscription[0] !== currentUpdateSubscriptionItem) {
            const item = updateSubscription[0];
            setUpdateCurrentSubscriptionItem(item);
            if (item) handleUpdateMembership(item.id);
        }
    }, [updateSubscription]);

    useEffect(() => {
        if (deleteSubscription && deleteSubscription[0] !== undefined && deleteSubscription[0] !== currentDeleteSubscriptionItem) {
            const item = deleteSubscription[0];
            setDeleteCurrentSubscriptionItem(deleteSubscription[0]);
            if (item) handleDeleteMembership(item.id);
        }
    }, [deleteSubscription]);

    const [isEditing, setIsEditing] = useState(false);
    const [formattedMemberships, setFormattedMemberships] = useState<FormattedMembership[] | null | undefined>();
    const [filteredMemberships, setFilteredMemberships] = useState<FormattedMembership[] | null | undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [membershipStatus, setMembershipStatus] = useState<("active" | "expired")>("active");
    const [currentSortOption, setCurrentSortOption] = useState(sortOptions.number1);
    const [sortOption, setSortOption] = useState<string>(columnHeaders.backNumber);
    const [filterText, setFilterText] = useState<string>('');
    const [isAELOrganization, setIsAELOrganization] = useState(false);

    const handleCreateMembership = async (membershipId: string) => {
        const queryResult = await getMembershipById(membershipId);
        if (queryResult.isSuccess) {
            const currentMembership = queryResult.result;
            const formattedMembership = await formatSingleMembership(currentMembership);
            const newFormattedList = formattedMemberships?.concat([formattedMembership]);
            const sortedFormattedList = newFormattedList?.sort((a: FormattedMembership, b: FormattedMembership) => parseInt(a.backNumber) - parseInt(b.backNumber));
            setFormattedMemberships(sortedFormattedList);
            setFilteredMemberships(sortedFormattedList);
        }
    }

    const handleUpdateMembership = async (membershipId: string) => {
        const queryResult = await getMembershipById(membershipId);
        if (queryResult.isSuccess) {
            const currentMembership = queryResult.result;
            const formattedMembership = await formatSingleMembership(currentMembership);
            if (formattedMemberships) {
                const index = formattedMemberships?.findIndex(formattedMembership => formattedMembership.id === membershipId);
                const newFormattedList = [
                    ...formattedMemberships.slice(0, index),
                    formattedMembership,
                    ...formattedMemberships.slice(index + 1)
                ];
                setFormattedMemberships(newFormattedList);
                setFilteredMemberships(newFormattedList);
            }
        }
    }

    const handleDeleteMembership = async (membershipId: string) => {
        if (formattedMemberships) {
            const index = formattedMemberships?.findIndex(formattedMembership => formattedMembership.id === membershipId);
            const newFormattedList = [
                ...formattedMemberships.slice(0, index),
                ...formattedMemberships.slice(index + 1)
            ];
            setFormattedMemberships(newFormattedList);
            setFilteredMemberships(newFormattedList);
        }
    }

    const getMembershipStatus = (currentMembership: Membership) => {
        let status = "";
        // A membership is active if the today's date has not hit the date the membership ends OR if the membership is lifetime (aka no dateMembershipEnds)
        const isActive = (currentMembership.dateMembershipEnds) ? moment(new Date()).isSameOrBefore(moment(currentMembership.dateMembershipEnds)) : false;
        if (isActive) {
            status = currentMembership.membershipStatus === CONSTANT.MEMBERSHIP.APPLICATION_STATUS.COMPLETE ? 
                CONSTANT.MEMBERSHIP.APPLICATION_STATUS.ACTIVE : CONSTANT.MEMBERSHIP.APPLICATION_STATUS.APPLICATION_INCOMPLETE;
        } else {
            status = CONSTANT.MEMBERSHIP.APPLICATION_STATUS.EXPIRED;
        }
        return status;
    }

    const formatSingleMembership = async (currentMembership: Membership, isAEL?: boolean) => {
        let personName = "";
        let barnName = "";
        if (isAELOrganization || isAEL) {
            // If this is NOT a team membership, include the person name
            if (!(currentMembership.type?.toLowerCase().includes("team"))) {
                personName = currentMembership.personName || "";
            }

            // For AEL, use team instead of barn
            if (currentMembership.groupContact?.name) {
                barnName = currentMembership.groupContact?.name;
            }
            // If team id is available, show the team name instead of the barn name
            else if (currentMembership.teamId) {
                const teamQuery = await getTeamById(currentMembership.teamId);
                if (teamQuery.isSuccess) {
                    const team: Team = teamQuery.result;
                    barnName = team?.name;
                }
            }
        } else {
            personName = currentMembership.personName || "";
            barnName = currentMembership?.barn?.name || "";
        }

        let object: FormattedMembership = {
            id: currentMembership.id,
            personName: personName || "",
            membershipNumber: currentMembership.membershipId || "",
            backNumber: currentMembership.backNumber?.toString() || "",
            status: getMembershipStatus(currentMembership),
            type: currentMembership.type || currentMembership.organizationMembershipType?.name || "", 
            horseName: currentMembership.horse?.name || "",
            barnName: barnName || "",
            membershipStatus: currentMembership.membershipStatus || "",
            barnId: currentMembership.barnId || "",
            volunteerHours: currentMembership.volunteerHours ? currentMembership.volunteerHours.toString() : "0",
            meetingsAttended: currentMembership.meetingsAttended ? currentMembership.meetingsAttended.toString() : "0",
            membership: currentMembership,
            showsAttended: currentMembership.showsAttended ? currentMembership.showsAttended.toString() : "0",
            oneTimePass: currentMembership.oneTimePassUseDate,
            dateCreated: currentMembership.createdOn ? moment(currentMembership.createdOn).format("MM-DD-YY") : ""
        };
        return object;
    }

    const formatMemberships = async (memberships: Membership[], isAEL?: boolean) => {
        let formattedMemberships = [];
        for (var i = 0; i < memberships.length; i++) {
            const currentMembership = memberships[i];
            const formattedMembership: FormattedMembership = await formatSingleMembership(currentMembership, isAEL);
            formattedMemberships.push(formattedMembership);
        }
        const sorted = formattedMemberships.sort((a, b) => parseInt(a.backNumber) - parseInt(b.backNumber));
        setFormattedMemberships(sorted);
        setFilteredMemberships(sorted);
        setIsLoading(false);
    }

    async function getMemberships(organizationId: string, status: "active" | "expired", isAEL?: boolean) {
        setIsLoading(true);
        const queryResult = await getMembershipsByOrganizationIdByActiveStatus(organizationId, status);
        if (queryResult.isSuccess) {
            await formatMemberships(queryResult.result, isAEL);
        } else {
            setIsLoading(false);
        }
    }

    async function getData(organizationId: string) {
        if (organizationId === "e05fc919-2793-4ead-acef-3f6efb584b67") {
            await setIsAELOrganization(true);
            await getMemberships(organizationId, "active", true); 
        } else {
            await getMemberships(organizationId, "active", false); 
        }
    }

    useEffect(() => {
        if (organizationId) getData(organizationId); 
    }, [organizationId]);

    const sortEntries = (currentSortOption: sortOptions) => {
        if (formattedMemberships) {
            if (currentSortOption === sortOptions.number1) {
                const sorted = formattedMemberships.sort((a: FormattedMembership, b: FormattedMembership) => {
                    const numberResult = (parseInt(a.backNumber || "0") || 0) - (parseInt(b.backNumber || "0") || 0);
                    return numberResult;
                });
                setFilteredMemberships([...sorted]);
            } else if (currentSortOption === sortOptions.number2) {
                const sorted = formattedMemberships.sort(function(a: FormattedMembership, b: FormattedMembership): number {
                    const numberResult = (parseInt(b.backNumber || "0") || 0) - (parseInt(a.backNumber || "0") || 0);
                    return numberResult;
                });
                setFilteredMemberships([...sorted]);
            } else if (currentSortOption === sortOptions.memberId1) {
                const sorted = formattedMemberships.sort((a: FormattedMembership, b: FormattedMembership) => {
                    const numberResult = (parseInt(b.membershipNumber || "0") || 0) - (parseInt(a.membershipNumber || "0") || 0);
                    return numberResult;
                });
                setFilteredMemberships([...sorted]);
            } else if (currentSortOption === sortOptions.memberId2) {
                const sorted = formattedMemberships.sort(function(a: FormattedMembership, b: FormattedMembership): number {
                    const numberResult = (parseInt(a.membershipNumber || "0") || 0) - (parseInt(b.membershipNumber || "0") || 0);
                    return numberResult;
                });
                setFilteredMemberships([...sorted]);
            } else if (currentSortOption === sortOptions.type1) {
                const sorted = formattedMemberships.sort(function(a: FormattedMembership, b: FormattedMembership): number {
                    const nameResult = (b.type || "").localeCompare(a.type || "");
                    return nameResult;
                });
                setFilteredMemberships([...sorted]);
            } else if (currentSortOption === sortOptions.type2) {
                const sorted = formattedMemberships.sort(function(a: FormattedMembership, b: FormattedMembership): number {
                    const nameResult = (a.type || "").localeCompare(b.type || "");
                    return nameResult;
                });
                setFilteredMemberships([...sorted]);
            } else if (currentSortOption === sortOptions.hours1) {
                const sorted = formattedMemberships.sort(function(a: FormattedMembership, b: FormattedMembership): number {
                    const numberResult = (parseInt(b.volunteerHours || "0") || 0) - (parseInt(a.volunteerHours || "0") || 0);
                    return numberResult;
                });
                setFilteredMemberships([...sorted]);
            } else if (currentSortOption === sortOptions.hours2) {
                const sorted = formattedMemberships.sort(function(a: FormattedMembership, b: FormattedMembership): number {
                    const numberResult = (parseInt(a.volunteerHours || "0") || 0) - (parseInt(b.volunteerHours || "0") || 0);
                    return numberResult;
                });
                setFilteredMemberships([...sorted]);
            } else if (currentSortOption === sortOptions.meeting1) {
                const sorted = formattedMemberships.sort(function(a: FormattedMembership, b: FormattedMembership): number {
                    const numberResult = (parseInt(b.meetingsAttended || "0") || 0) - (parseInt(a.meetingsAttended || "0") || 0);
                    return numberResult;
                });
                setFilteredMemberships([...sorted]);
            } else if (currentSortOption === sortOptions.meeting2) {
                const sorted = formattedMemberships.sort(function(a: FormattedMembership, b: FormattedMembership): number {
                    const numberResult = (parseInt(a.meetingsAttended || "0") || 0) - (parseInt(b.meetingsAttended || "0") || 0);
                    return numberResult;
                });
                setFilteredMemberships([...sorted]);
            } else if (currentSortOption === sortOptions.shows1) {
                const sorted = formattedMemberships.sort(function(a: FormattedMembership, b: FormattedMembership): number {
                    const numberResult = (parseInt(b.showsAttended || "0") || 0) - (parseInt(a.showsAttended || "0") || 0);
                    return numberResult;
                });
                setFilteredMemberships([...sorted]);
            } else if (currentSortOption === sortOptions.shows2) {
                const sorted = formattedMemberships.sort(function(a: FormattedMembership, b: FormattedMembership): number {
                    const numberResult = (parseInt(a.showsAttended || "0") || 0) - (parseInt(b.showsAttended || "0") || 0);
                    return numberResult;
                });
                setFilteredMemberships([...sorted]);
            } else if (currentSortOption === sortOptions.rider1) {
                const sorted = formattedMemberships.sort(function(a: FormattedMembership, b: FormattedMembership): number {
                    const nameResult = (b.personName || "").localeCompare(a.personName || "");
                    return nameResult;
                });
                setFilteredMemberships([...sorted]);
            } else if (currentSortOption === sortOptions.rider2) {
                const sorted = formattedMemberships.sort(function(a: FormattedMembership, b: FormattedMembership): number {
                    const nameResult = (a.personName || "").localeCompare(b.personName || "");
                    return nameResult;
                });
                setFilteredMemberships([...sorted]);
            } else if (currentSortOption === sortOptions.horse1) {
                const sorted = formattedMemberships.sort(function(a: FormattedMembership, b: FormattedMembership): number {
                    const nameResult = (b.horseName || "").localeCompare(a.horseName || "");
                    return nameResult;
                });
                setFilteredMemberships([...sorted]);
            } else if (currentSortOption === sortOptions.horse2) {
                const sorted = formattedMemberships.sort(function(a: FormattedMembership, b: FormattedMembership): number {
                    const nameResult = (a.horseName || "").localeCompare(b.horseName || "");
                    return nameResult;
                });
                setFilteredMemberships([...sorted]);
            } else if (currentSortOption === sortOptions.barn1) {
                const sorted = formattedMemberships.sort(function(a: FormattedMembership, b: FormattedMembership): number {
                    const nameResult = (b.barnName || "").localeCompare(a.barnName || "");
                    return nameResult;
                });
                setFilteredMemberships([...sorted]);
            } else if (currentSortOption === sortOptions.barn2) {
                const sorted = formattedMemberships.sort(function(a: FormattedMembership, b: FormattedMembership): number {
                    const nameResult = (a.barnName || "").localeCompare(b.barnName || "");
                    return nameResult;
                });
                setFilteredMemberships([...sorted]);
            } else if (currentSortOption === sortOptions.status1) {
                const sorted = formattedMemberships.sort(function(a: FormattedMembership, b: FormattedMembership): number {
                    const nameResult = (b.status || "").localeCompare(a.status || "");
                    return nameResult;
                });
                setFilteredMemberships([...sorted]);
            } else if (currentSortOption === sortOptions.status2) {
                const sorted = formattedMemberships.sort(function(a: FormattedMembership, b: FormattedMembership): number {
                    const nameResult = (a.status || "").localeCompare(b.status || "");
                    return nameResult;
                });
                setFilteredMemberships([...sorted]);
            } else if (currentSortOption === sortOptions.dateCreated1) {
                const sorted = formattedMemberships.sort(function(a: FormattedMembership, b: FormattedMembership): number {
                    const nameResult = (b.dateCreated || "").localeCompare(a.dateCreated || "");
                    return nameResult;
                });
                setFilteredMemberships([...sorted]);
            } else if (currentSortOption === sortOptions.dateCreated2) {
                const sorted = formattedMemberships.sort(function(a: FormattedMembership, b: FormattedMembership): number {
                    const nameResult = ((a.dateCreated )|| "").localeCompare(b.dateCreated || "");
                    return nameResult;
                });
                setFilteredMemberships([...sorted]);
            }
        }
    }

    const handleSearchInput = (value: any) => {
        value = value.toLocaleLowerCase();

        const isNumber = /^-?\d+$/.test(value);
        if (isNumber) {
            const items = formattedMemberships?.filter((i) => {
                return i.backNumber === value || i.membershipNumber === value;
            });
            setFilteredMemberships(items);
        } else {
            let result = formattedMemberships?.filter((membership) => {
                return (
                    membership.personName.toLocaleLowerCase().includes(value) ||
                    membership.barnName.toLocaleLowerCase().includes(value) ||
                    membership.horseName.toLocaleLowerCase().includes(value)
                );
            });
            if (result) {
                setFilteredMemberships(result);
            }
        }

        if (!value || value === "") {
            setFilteredMemberships(formattedMemberships);
        }
    };

    const handleNavigateToNewMembershipPage = () => {
        const path = "/index/staff/organization/" + organizationId + "/member/create";
        history.push(path);
    }

    const handleNavigateToBulkAddMembershipPage = () => {
        const path = "/index/staff/organization/" + organizationId + "/member/bulk-add";
        history.push(path);
    }

    const onSelectMembership = (c: Membership) => {
        const path = "/index/staff/organization/membership/edit/" + c.id;
        // do not navigate to edit membership page if isViewOnly is true
        if (!isViewOnly) {
            history.push(path);
        }
    }

    const handleInputChange = (updateType: ("backNumber" | "volunteerHours" | "meetingsAttended" | "showsAttended"), value: string, membership: FormattedMembership, filteredIndex: number) => {
        if (filteredMemberships) {
            let updated: FormattedMembership = {
                ...membership,
                hasBeenChanged: true
            };
            updated[updateType] = value;
            
            let updatedArray = [
                ...filteredMemberships.slice(0, filteredIndex),
                updated,
                ...filteredMemberships.slice(filteredIndex + 1)
            ];
            setFilteredMemberships(updatedArray);
        }

        if (formattedMemberships) {
            let index = formattedMemberships.findIndex((m: FormattedMembership) => m.id === membership.id);
            if (index !== -1) {
                const oldMembership = formattedMemberships[index];
                let updatedMembership: FormattedMembership = {
                    ...oldMembership,
                    hasBeenChanged: true
                }; 
                updatedMembership[updateType] = value;
                const updatedMembershipArray: FormattedMembership[] = [
                    ...formattedMemberships.slice(0, index),
                    updatedMembership,
                    ...formattedMemberships.slice(index + 1)
                ];
                setFormattedMemberships(updatedMembershipArray);
            }
        }
    }

    const handleSaveEdits = async () => {
        setIsLoading(true);
        if (formattedMemberships) {
            for (let i = 0; i < formattedMemberships.length; i++) {
                const current = formattedMemberships[i];
                if (current.hasBeenChanged) {
                    const updateInput: UpdateMembershipInput = {
                        id: current.id,
                        backNumber: current.backNumber ? parseInt(current.backNumber) : 0,
                        volunteerHours: current.volunteerHours ? parseInt(current.volunteerHours) : 0,
                        meetingsAttended: current.meetingsAttended ? parseInt(current.meetingsAttended) : 0,
                        showsAttended: current.showsAttended ? parseInt(current.showsAttended) : 0
                    };
                    await updateMembership(updateInput);
                }
            }
        }
        setIsEditing(false);
        setIsLoading(false);
    }

    const downloadCSVReport = async () => {
        if (formattedMemberships) {
            await generateOrgMemberReport(formattedMemberships, organizationId);
        }
    }

    const downloadContactCSVReport = async () => {
        if (formattedMemberships) {
            setIsLoading(true);
            await generateOrgMemberContactReport(formattedMemberships);
            setIsLoading(false);
        }
    }

    const columns = [{
        id: 1,
        name: (
            <>
                <IonText onClick={() => {setCurrentSortOption(currentSortOption === sortOptions.number1 ? sortOptions.number2 : sortOptions.number1); 
                    sortEntries(currentSortOption === sortOptions.number2 ? sortOptions.number1 : sortOptions.number2); setSortOption(columnHeaders.backNumber);}}
                >
                    {isAELOrganization ? "Arm" : "Back"} #
                </IonText>
                {sortOption === columnHeaders.backNumber ? <IonIcon icon={currentSortOption === sortOptions.number1 ? caretUpOutline : caretDownOutline }/> : ""}
                {!isViewOnly && <IonIcon icon={createOutline} size="small" className="m-1" onClick={() => setIsEditing(!isEditing)}/> }
            </>
        ),
        cell: (membership: FormattedMembership,index:any) =>
            isEditing ? (
                <Input
                    className="input-width"
                    type="text"
                    value={membership.backNumber}
                    onChange={(e: any) => {
                        handleInputChange("backNumber", e.target.value, membership, index);
                    }}
                />
                
            ) : (
                <IonText onClick={() => onSelectMembership(membership.membership)} className="description ion-text-wrap">
                    {membership.backNumber}
                </IonText>
            ),
        center: true,
        width: "8rem"
    }, {
        id: 2,
        name: (
            <>
                <IonText onClick={() => {setCurrentSortOption(currentSortOption === sortOptions.memberId1 ? sortOptions.memberId2 : sortOptions.memberId1); 
                    sortEntries(currentSortOption === sortOptions.memberId1 ? sortOptions.memberId2 : sortOptions.memberId1); setSortOption(columnHeaders.membershipId);}}
                >
                    Member Id
                </IonText>
                {sortOption === columnHeaders.membershipId ? <IonIcon icon={currentSortOption === sortOptions.memberId1 ? caretDownOutline : caretUpOutline}/> : ""}
            </>
        ),
        selector: (membership: FormattedMembership) => membership.membershipNumber,
        center: true,
        width: "8rem"
    }, {
        id: 3,
        name: (
            <>
                <IonText onClick={() => {setCurrentSortOption(currentSortOption === sortOptions.dateCreated1 ? sortOptions.dateCreated2 : sortOptions.dateCreated1); 
                    sortEntries(currentSortOption === sortOptions.dateCreated1 ? sortOptions.dateCreated2 : sortOptions.dateCreated1); setSortOption(columnHeaders.dateCreated);}}
                >
                    Date Created
                </IonText>
                {sortOption === columnHeaders.dateCreated ? <IonIcon icon={currentSortOption === sortOptions.dateCreated1 ? caretDownOutline : caretUpOutline}/> :""}
            </>
        ),
        selector: (membership: FormattedMembership) => membership.dateCreated,
        center: true
    }, {
        id: 4,
        name: (
            <>
                <IonText onClick={() => {setCurrentSortOption(currentSortOption === sortOptions.rider1 ? sortOptions.rider2 : sortOptions.rider1); 
                    sortEntries(currentSortOption === sortOptions.rider1 ? sortOptions.rider2 : sortOptions.rider1); setSortOption(columnHeaders.rider);}}
                >
                    Member Name
                </IonText>
                {sortOption === columnHeaders.rider ? <IonIcon icon={currentSortOption === sortOptions.rider1 ? caretDownOutline : caretUpOutline}/> :""}
            </>
        ),
        cell: (membership: FormattedMembership) => (
            <IonText onClick={() => onSelectMembership(membership.membership)} className="description ion-text-wrap">
                {membership.personName}
            </IonText>
        ),
        width: "11rem"
    }, {
        id: 5,
        omit: isAELOrganization,
        name: (
            <>
                <IonText onClick={() => {setCurrentSortOption(currentSortOption === sortOptions.horse1 ? sortOptions.horse2 : sortOptions.horse1); 
                    sortEntries(currentSortOption === sortOptions.horse1 ? sortOptions.horse2 : sortOptions.horse1); setSortOption(columnHeaders.horse);}}
                >
                    Horse
                </IonText>
                {sortOption === columnHeaders.horse ? <IonIcon icon={currentSortOption === sortOptions.horse1 ? caretDownOutline : caretUpOutline}/> :""}
            </>
        ),
        cell: (membership: FormattedMembership) => (
            <IonText onClick={() => onSelectMembership(membership.membership)} className="description ion-text-wrap">
                {membership.horseName}
            </IonText>
        )
    }, {
        id: 6,
        name: (
            <>
                <IonText onClick={() => {setCurrentSortOption(currentSortOption === sortOptions.barn1 ? sortOptions.barn2 : sortOptions.barn1); 
                    sortEntries(currentSortOption === sortOptions.barn1 ? sortOptions.barn2 : sortOptions.barn1); setSortOption(columnHeaders.barn);}}
                >
                    {isAELOrganization ? "Team" : "Barn"}
                </IonText>
                {sortOption === columnHeaders.barn ? <IonIcon icon={currentSortOption === sortOptions.barn1 ? caretDownOutline : caretUpOutline}/> :""}
            </>
        ),
        cell: (membership: FormattedMembership) => (
            <IonText onClick={() => onSelectMembership(membership.membership)} className="description ion-text-wrap">
                {membership.barnName}
            </IonText>
        )
    }, {
        id: 7,
        name: (
            <>
                <IonText onClick={() => {setCurrentSortOption(currentSortOption === sortOptions.type1 ? sortOptions.type2 : sortOptions.type1); 
                    sortEntries(currentSortOption === sortOptions.type1 ? sortOptions.type2 : sortOptions.type1); setSortOption(columnHeaders.type);}}
                >
                    Type
                </IonText>
                {sortOption === columnHeaders.type ? <IonIcon icon={currentSortOption === sortOptions.type1 ? caretDownOutline : caretUpOutline} /> : ""}
            </>
        ),
        cell: (membership: FormattedMembership) => (
            <IonText onClick={() => onSelectMembership(membership.membership)} className="description ion-text-wrap">
                {membership.type}
            </IonText>
        ),
        center: true,
        width:"15rem"
    }, {
        id: 8,
        name: (
            <>
                <IonText onClick={() => {setCurrentSortOption(currentSortOption === sortOptions.status1 ? sortOptions.status2 : sortOptions.status1); 
                    sortEntries(currentSortOption === sortOptions.status1 ? sortOptions.status2 : sortOptions.status1); setSortOption(columnHeaders.status);}}
                >
                    Status
                </IonText>
                {sortOption === columnHeaders.status ? <IonIcon icon={currentSortOption === sortOptions.status1 ? caretDownOutline : caretUpOutline}/> :""}
            </>
        ),
        cell: (membership: FormattedMembership) => (
            membership.status === CONSTANT.MEMBERSHIP.APPLICATION_STATUS.APPLICATION_INCOMPLETE ?
            <IonText onClick={() => onSelectMembership(membership.membership)} color="warning">{CONSTANT.MEMBERSHIP.APPLICATION_STATUS.APPLICATION_INCOMPLETE}</IonText> :
            membership.status === CONSTANT.MEMBERSHIP.APPLICATION_STATUS.ACTIVE ?
            <IonText onClick={() => onSelectMembership(membership.membership)} color="success">{CONSTANT.MEMBERSHIP.APPLICATION_STATUS.ACTIVE}</IonText> :
            <IonText onClick={() => onSelectMembership(membership.membership)}>{CONSTANT.MEMBERSHIP.APPLICATION_STATUS.EXPIRED}</IonText>
        )
    }, {
        id: 9,
        omit: isAELOrganization,
        name: (
            <>
                <IonText onClick={() => {setCurrentSortOption(currentSortOption === sortOptions.hours1 ? sortOptions.hours2 : sortOptions.hours1); 
                    sortEntries(currentSortOption === sortOptions.hours1 ? sortOptions.hours2 : sortOptions.hours1); setSortOption(columnHeaders.hours);}}
                >
                    Hours
                </IonText>
                {sortOption === columnHeaders.hours ? <IonIcon icon={currentSortOption === sortOptions.hours1 ? caretDownOutline : caretUpOutline}/> : ""}
                {!isViewOnly && <IonIcon icon={createOutline} size="small" className="m-1" onClick={() => setIsEditing(!isEditing)}/>}
            </>
        ),
        cell: (membership: FormattedMembership, index: any) =>
            isEditing ? (
                <Input
                    className="input-width"
                    type="text"
                    value={membership.volunteerHours}
                    onChange={(e: any) => {
                        handleInputChange("volunteerHours", e.target.value, membership, index);
                    }}
                />
            ) : (
                <IonText onClick={() => onSelectMembership(membership.membership)} className="description ion-text-wrap">
                    {membership.volunteerHours}
                </IonText>
            ),
        center: true
    }, {
        id: 10,
        omit: isAELOrganization,
        name: (
            <>
                <IonText onClick={() => {setCurrentSortOption(currentSortOption === sortOptions.meeting1 ? sortOptions.meeting2 : sortOptions.meeting1); 
                    sortEntries(currentSortOption === sortOptions.meeting1 ? sortOptions.meeting2 : sortOptions.meeting1); setSortOption(columnHeaders.meetings);}}
                >
                    Meetings
                </IonText>
                {sortOption === columnHeaders.meetings ? <IonIcon icon={currentSortOption === sortOptions.meeting1 ? caretDownOutline : caretUpOutline} /> : ""}
                {!isViewOnly && <IonIcon icon={createOutline} size="small" className="m-1" onClick={() => setIsEditing(!isEditing)}/>}
            </>
        ),
        cell: (membership: FormattedMembership, index: any) =>
            isEditing ? (
                <Input
                    className="input-width"
                    type="text"
                    value={membership.meetingsAttended}
                    onChange={(e: any) => {
                        handleInputChange("meetingsAttended", e.target.value, membership, index);
                    }}
                />
            ) : (
                <IonText onClick={() => onSelectMembership(membership.membership)} className="description ion-text-wrap">
                    {membership.meetingsAttended}
                </IonText>
            ),
        center: true
    }, {
        id: 11,
        omit: isAELOrganization,
        name: (
            <>
                <IonText  onClick={() => {setCurrentSortOption(currentSortOption === sortOptions.shows1 ? sortOptions.shows2 : sortOptions.shows1); 
                    sortEntries(currentSortOption === sortOptions.shows1 ? sortOptions.shows2 : sortOptions.shows1); setSortOption(columnHeaders.shows)}}
                >
                    Shows
                </IonText>
                {sortOption === columnHeaders.shows ? <IonIcon icon={currentSortOption === sortOptions.shows1 ? caretDownOutline : caretUpOutline} /> : ""}
                {!isViewOnly && <IonIcon icon={createOutline} size="small" className="m-1" onClick={() => setIsEditing(!isEditing)}/>}
            </>
        ),
        cell: (membership: FormattedMembership, index: any) =>
            isEditing ? (
                <Input
                    className="input-width"
                    type="text"
                    value={membership.showsAttended}
                    onChange={(e: any) => {
                        handleInputChange("showsAttended", e.target.value, membership, index);
                    }}
                />
            ) : (
                <IonText onClick={() => onSelectMembership(membership.membership)} className="description ion-text-wrap">
                    {membership.showsAttended}
                </IonText>
            ),
        center: true
    }];

    const tableCustomStyles = {
        headCells: {
            style: {
                width:"9rem",
                fontSize: "15px",
                fontWeight: "bolder",
            }
        }
    };

    return (
        <IonCard mode="md" className="ion-padding bg-white">
            <IonCardTitle>
                <IonRow className="ion-justify-content-between mb-3">
                    <IonCol sizeXs="12" sizeMd="6">
                        <h5>General Memberships</h5>
                    </IonCol>
                    {!isViewOnly && !isAELOrganization && 
                        <>
                            <IonCol sizeXs="12" sizeMd="3">
                                <IonButton
                                    id="general-memberships-table-bulk-add-membership-btn"
                                    onClick={handleNavigateToBulkAddMembershipPage}
                                >
                                    {(isWindows() || (isPlatform("desktop"))) ? "Bulk Add" : <IonIcon icon={add}/>}
                                </IonButton>
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="3">
                                <IonButton
                                    id="general-memberships-table-new-membership-btn"
                                    onClick={handleNavigateToNewMembershipPage}
                                >
                                    {(isWindows() || (isPlatform("desktop"))) ? "New Membership" : <IonIcon icon={add}/>}
                                </IonButton>
                            </IonCol>
                        </>
                    }
                </IonRow>
            </IonCardTitle>
            {!isViewOnly && <IonCardSubtitle>
                <p className="ion-text-wrap">
                    Click on a Membership to edit it.{" "}
                    <span className="link" onClick={() => downloadCSVReport()}>
                        Download CSV
                    </span>{" "}/{" "}
                    <span className="link" onClick={() => downloadContactCSVReport()}>
                        Download Contact Sheet
                    </span>
                </p>
            </IonCardSubtitle>}
            {isLoading ? (
                <Spinner />
            ) : (
                <>
                    <IonRow className="ion-justify-content-center">
                        {isEditing && (
                            <IonCol sizeXs="12" sizeMd="6" className="ion-text-center">
                                <IonButton color="success" onClick={() => handleSaveEdits()} expand="block">
                                    Save Changes
                                </IonButton>
                            </IonCol>
                        )}
                    </IonRow>
                    <IonRow className="ion-justify-content-between">
                        <IonCol sizeXs="12" sizeMd="3">
                            <Input
                                className="mt-2"
                                type="select"
                                value={membershipStatus}
                                onChange={(e) => {
                                    setMembershipStatus(e.target.value === "active" ? "active" : "expired");
                                    getMemberships(organizationId, e.target.value === "active" ? "active" : "expired");
                                }}
                            >
                                <option value="active">Active</option>
                                <option value="expired">Expired</option>
                            </Input>
                        </IonCol>
                        <IonCol sizeXs="12" sizeMd="6">
                            <IonSearchbar
                                color="white"  
                                placeholder={isAELOrganization ? "Search by Member Name, Team or Id" : "Search by Back#, MembershipId, Rider, Horse, Barn"}
                                value={filterText} 
                                onIonChange={e => {
                                    setFilterText(e.detail.value!)
                                    handleSearchInput(e.detail.value!)
                                }}
                            />
                        </IonCol>
                    </IonRow>
                    <DataTable
                        columns={columns}
                        data={filteredMemberships || []}
                        customStyles={tableCustomStyles}
                        paginationRowsPerPageOptions={[10, 20, 50, 100]}
                        paginationComponentOptions={{
                            selectAllRowsItem: true,
                            selectAllRowsItemText: "ALL",
                        }}
                        onRowClicked={(row: FormattedMembership) => {
                            onSelectMembership(row.membership);
                        }}
                        pagination
                        highlightOnHover
                    />
                </>
            )}
        </IonCard>
    );
};

export default OrganizationMembershipsTable;