import {
    IonButton,
    IonCol, 
    IonContent, 
    IonHeader,  
    IonPage, 
    IonRow,
    IonText
} from "@ionic/react";
import React from "react";
import { Card, CardBody, Container } from "reactstrap";
import WebNavbar from "../../components/Navbars/WebNavbar";
import {Link} from "react-router-dom";
import textAlertsImg from "../../assets/img/general/Alerts.png";
import textAlertsPricingImg from "../../assets/img/general/PricingBasicLandscape.png";
import fullServicePricingImg from "../../assets/img/general/PricingChartFullService.png";
import barnAlertsPricingImg from "../../assets/img/general/BarnAlertSubscriptionPackages.png";
import Footer from "../../components/Footers/Footer";
import { isWindows } from "../../utilities/platform/Platform";
import { isPlatform } from "@ionic/core";
import logo from "./../../assets/img/brand/LogoWithTextBlock.png";
import morgan from "../../assets/img/general/MorganWorkman.png";
import jessi from "../../assets/img/general/Jessi.jpeg";
import ashley from "../../assets/img/general/ashley.jpeg";
import Testimonial from "../../components/Testimonials/Testimonial";
import DropDownCard from "../../components/Cards/DropdownCard";

const AboutTextAlertsPage: React.FC = () => {

    return (
        <IonPage id="webAboutTextAlertsPage">
            <IonHeader>
                <WebNavbar />
            </IonHeader>
            <IonContent>
                <section id="online-entries" className="py-5 bg-light overflow-hidden">
                    <Container fluid>
                        <IonRow className="mt-5 ion-justify-content-center ion-align-items-center">
                            <IonCol sizeXs="12" sizeMd="6" className="text-center">
                                <IonRow className="ion-justify-content-center ion-align-items-center">
                                    <IonCol sizeXs="8" size="4" className="ion-text-center">
                                        <img
                                            className="img"
                                            alt="RingSide Pro"
                                            src={logo}
                                        />
                                    </IonCol>
                                </IonRow>
                                <IonRow className="justify-content-center mb-3">
                                    <IonCol size="12" className="text-center">
                                        <h1 className="text-primary pb-3">
                                            Text Alerts Service
                                        </h1>
                                        <h2 className="text-primary py-3">
                                            Communication is Key.
                                        </h2>
                                        <h3 className="text-primary pt-3">
                                            The most reliable way to get information
                                        </h3>
                                        <h3 className="text-primary pb-3">
                                            to the people who need it. 
                                        </h3>
                                    </IonCol>
                                </IonRow>
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="6" className="text-center">
                                <img className="shadow bg-white rounded-1 img" alt="Text Alerts" src={textAlertsImg}/>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-justify-content-center ion-align-items-center">
                            <IonCol size="12" className="ion-text-center">
                                <h4 className="text-primary">
                                    Available for communication at <a href="/text-alerts#horse-show-alerts" className="link">horse shows</a> as well as within <a href="/text-alerts#org-alerts" className="link">barns</a>.
                                </h4>
                            </IonCol>
                        </IonRow>
                    </Container>
                </section>
                <section id="org-alerts" className="py-5 bg-white">
                    <Container>
                        <IonRow className="mt-2 ion-justify-content-center ion-align-items-center">
                            <IonCol size="12" className="ion-text-center">
                                <IonText><h2 className="font-weight-bold text-primary">Now Available for Barns, Teams and Clubs!</h2></IonText>
                                <IonText><p className="text-primary">Learn how to make communication EASY - no matter how big your barn, team or club is!</p></IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow className="mt-2 ion-justify-content-center ion-align-items-center">
                            <IonCol sizeXs="12" sizeMd="10">
                                <Card className="shadow mt-2 border-0">
                                    <CardBody className="py-5">
                                        <h4 className="h3 text-info text-uppercase">
                                            Clear Communication
                                        </h4>
                                        <p className="description mt-3">
                                            Include as many "subscribers" as you like and categorize them based on your needs.
                                        </p>
                                        <p className="description mt-3">
                                            Examples: By owners, riders, parents, barn staff. By lessoner, boarder, attends local shows, attends rated shows. By IHSA team member, IEA team member.
                                        </p>
                                        <p className="description mt-3">
                                            When you need to send a message, simply select that categories of people that need the info.
                                        </p>
                                        <p className="description mt-3">
                                            Examples: All horse owners: the vet is coming to do spring shots on Wednesday this week. All IEA riders and parents: Practice today is cancelled due to the rain and will be rescheduled for Thursday.
                                        </p>
                                        <p className="description text-info mt-3">
                                            Save time by never needing to scroll through your contacts again. PLUS, you avoid starting a crazy group chat.
                                        </p>
                                    </CardBody>
                                </Card>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-justify-content-center">
                            <IonCol size="12" className="ion-text-center">
                                <a className="link" href="https://forms.gle/puRzRsgugrdmoibT9" target="_blank" rel="noopener noreferrer">
                                    <IonButton 
                                        color="success"
                                    >
                                        Fill Out the Interest Form!
                                    </IonButton>
                                </a>
                            </IonCol>
                        </IonRow>
                    </Container>
                </section>
                <section className="py-5 bg-light">
                    <Container>
                        <IonRow className="mt-5 ion-justify-content-center ion-align-items-center">
                            <IonCol size="12" className="ion-text-center">
                                <IonText><h2 className="font-weight-bold text-primary">Barn Managers - How to Use It</h2></IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow className="justify-content-center">
                            <IonCol sizeXs="12" sizeMd="4">
                                <Card className="card-lift--hover shadow border-0">
                                    <CardBody className="py-5">
                                        <h4 className="h3 text-primary text-uppercase">
                                            Easy Set Up
                                        </h4>
                                        <p className="description mt-3">
                                            Set up an organization on RingSide Pro. You can invite other admins to join your organization so they can help send alerts. Then add subscribers to your organization.
                                        </p>
                                    </CardBody>
                                </Card>
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="4">
                                <Card className="card-lift--hover shadow border-0">
                                    <CardBody className="py-5">
                                        <h4 className="h3 text-info text-uppercase">
                                            Add Subscribers
                                        </h4>
                                        <p className="description mt-3">
                                            Owners, riders and parents can sign up with a phone number. Your unique QR code will send them to the sign up form. Or have an admin bulk add subscribers with a spreadsheet.
                                        </p>
                                    </CardBody>
                                </Card>
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="4">
                                <Card className="card-lift--hover shadow border-0">
                                    <CardBody className="py-5">
                                        <h4 className="h3 text-primary text-uppercase">
                                            Send Alerts
                                        </h4>
                                        <p className="description mt-3">
                                            Send alerts from a computer or smart phone. It's just like sending a text message - type out the message and click send - without the chaotic group chat.
                                        </p>
                                    </CardBody>
                                </Card>
                            </IonCol>
                        </IonRow>
                    </Container>
                </section>
                <section className="py-5">
                    <Container>
                        <IonRow className="mt-5 ion-justify-content-center ion-align-items-center">
                            <IonCol size="12" className="ion-text-center">
                                <IonText><h2 className="font-weight-bold text-primary">Pricing for Barns</h2></IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow className="text-primary">
                            <IonCol size="12" className="ion-text-center">
                                <p className="text-primary">
                                    Choose a subcription package, which will come with credits to use each month. Credits allow you to send alerts. One credit sends an alert to one phone.
                                </p>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-justify-content-center pt-3">
                            <IonCol sizeXs="12" sizeMd="8" sizeLg="6" className="text-center">
                                <img className="shadow bg-white rounded-1 img" alt="Barn Alerts Pricing" src={barnAlertsPricingImg}/>
                            </IonCol>
                        </IonRow>
                        <IonRow className="text-primary pb-3">
                            <IonCol size="12" className="ion-text-center">
                                <p className="text-primary">
                                    We are always happy to consult with you about which package will work best for your barn.
                                </p>
                            </IonCol>
                        </IonRow>
                    </Container>
                </section>
                <section className="py-5 bg-light">
                    <Container>
                        <IonRow className="mt-5 ion-justify-content-center ion-align-items-center">
                            <IonCol size="12" className="ion-text-center">
                                <IonText><h2 className="font-weight-bold text-primary">Barn Manager Testimonial</h2></IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-justify-content-center">
                            <IonCol sizeXs="12" sizeMd="6">
                                <Testimonial 
                                    image={ashley} 
                                    name="Ashley Holsinger"
                                    label="Owner of Memory's Hill Stables" 
                                    quote="We LOVE the text alert system! It's so convenient for communicating within our barn!"
                                />
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="6">
                                <Card className="shadow mt-2 border-0">
                                    <CardBody className="py-5">
                                        <h4 className="h3 text-info text-uppercase">
                                            Interested?
                                        </h4>
                                        <p className="description mt-3">
                                           First, fill out some basic information about your barn and your needs: <a className="link" href="https://forms.gle/puRzRsgugrdmoibT9" target="_blank" rel="noopener noreferrer">click here</a>.
                                        </p>
                                        <p className="description mt-3">
                                           If you reach out to us, we'll help you get started!
                                        </p>
                                        <p className="description mt-3">
                                            Email: hello@ringsidepro.com
                                        </p>
                                        <p className="description mt-3">
                                            Phone: +1-757-812-0385
                                        </p>
                                    </CardBody>
                                </Card>
                            </IonCol>
                        </IonRow>
                    </Container>
                </section>
                <section id="horse-show-alerts" className="py-5 bg-white">
                    <Container>
                        <IonRow className="mt-5 ion-justify-content-center ion-align-items-center">
                            <IonCol size="12" className="ion-text-center">
                                <IonText><h2 className="font-weight-bold text-primary">Horse Show Managers - How to Use It</h2></IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow className="justify-content-center">
                            <IonCol sizeXs="12" sizeMd="4">
                                <Card className="card-lift--hover shadow border-0">
                                    <CardBody className="py-5">
                                        <h4 className="h3 text-primary text-uppercase">
                                            Quick Set Up
                                        </h4>
                                        <p className="description mt-3">
                                            It takes less than 5 minutes to set up your event. You can invite other event staff to join your organization. Then they can access your event and help send alerts.
                                        </p>
                                    </CardBody>
                                </Card>
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="4">
                                <Card className="card-lift--hover shadow border-0">
                                    <CardBody className="py-5">
                                        <h4 className="h3 text-info text-uppercase">
                                            Add Subscribers
                                        </h4>
                                        <p className="description mt-3">
                                            Exhibitors, trainers, show staff and spectators can sign up with just a phone number. Your unique QR code will send them straight to the sign up form.
                                        </p>
                                    </CardBody>
                                </Card>
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="4">
                                <Card className="card-lift--hover shadow border-0">
                                    <CardBody className="py-5">
                                        <h4 className="h3 text-primary text-uppercase">
                                            Send Alerts
                                        </h4>
                                        <p className="description mt-3">
                                            Send alerts from a computer or smart phone. It's just like sending a text message - type out the message and click send. Plus, you can include images in the alerts.
                                        </p>
                                    </CardBody>
                                </Card>
                            </IonCol>
                        </IonRow>
                    </Container>
                </section>
                <section className="py-5 bg-white">
                    <Container>
                        <IonRow className="mt-5 ion-justify-content-center ion-align-items-center">
                            <IonCol size="12" className="ion-text-center">
                                <IonText><h2 className="font-weight-bold text-primary">Exhibitors - How to Use It</h2></IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow className="justify-content-center">
                            <IonCol sizeXs="12" sizeMd="4">
                                <Card className="card-lift--hover shadow border-0">
                                    <CardBody className="py-5">
                                        <h4 className="h3 text-primary text-uppercase">
                                            Find the Event
                                        </h4>
                                        <p className="description mt-3">
                                            Use the unique link or QR code for each event to sign up for alerts. You can also find any events on www.ringsidepro.com. 
                                        </p>
                                    </CardBody>
                                </Card>
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="4">
                                <Card className="card-lift--hover shadow border-0">
                                    <CardBody className="py-5">
                                        <h4 className="h3 text-info text-uppercase">
                                            Quick Sign Up
                                        </h4>
                                        <p className="description mt-3">
                                            Get alerts by signing up with your name and phone number. Some events will also let you select filters such as days or rings.
                                        </p>
                                    </CardBody>
                                </Card>
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="4">
                                <Card className="card-lift--hover shadow border-0">
                                    <CardBody className="py-5">
                                        <h4 className="h3 text-primary text-uppercase">
                                            Pause Alerts
                                        </h4>
                                        <p className="description mt-3">
                                            If you leave the event early or just want a break from the alerts, you can reply PAUSE or STOP to end the text messages.
                                        </p>
                                    </CardBody>
                                </Card>
                            </IonCol>
                        </IonRow>
                    </Container>
                </section>
                <section className="py-5 bg-light">
                    <Container>
                        <IonRow className="mt-5 ion-justify-content-center ion-align-items-center">
                            <IonCol size="12" className="ion-text-center">
                                <IonText><h2 className="font-weight-bold text-primary">Benefits</h2></IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow className="justify-content-center">
                            <IonCol size="12">
                                <Card className="shadow border-0">
                                    <CardBody className="py-5">
                                        <h4 className="h3 text-primary text-uppercase text-center">
                                            Save Event Staff's Time
                                        </h4>
                                        <p className="description mt-3">
                                            "The number of times I’ve been asked about the schedule is down about <span className="font-weight-bold">90%</span>." - Stephen Bickers, in-gate and announcer
                                        </p>
                                        <p className="description mt-3">
                                            "Sending out the alerts is easy and effective. I'll see someone walking to my booth to ask a question, check their phone, and turn around." - Jamila Flowers, in-gate and announcer
                                        </p>
                                        <p className="description mt-3">
                                            "It was helpful to get the alerts so that I knew timings in the rings and could accurately direct my staff." - Matt Shepard, tractor crew
                                        </p>
                                    </CardBody>
                                </Card>
                            </IonCol>
                        </IonRow>
                        <IonRow className="justify-content-center">
                            <IonCol size="12">
                                <Card className="shadow border-0">
                                    <CardBody className="py-5">
                                        <h4 className="h3 text-primary text-uppercase text-center">
                                            Keep Exhibitors in the Know
                                        </h4>
                                        <p className="description mt-3">
                                            "<span className="font-weight-bold">I implore show management to please utilize this incredible resource.</span> It reduced both staff and exhibitors' anxiety." - Katie Cooper, professional and trainer
                                        </p>
                                        <p className="description mt-3">
                                            "I absolutely love this service! I don’t know how much it costs the horse show to have this available, but it almost can’t be too much. It has simplified our show day so very much!" - Betty Mills, exhibitor
                                        </p>
                                        <p className="description mt-3">
                                            "I liked not walking to the ring a thousand times and I could just relax at the barn. I literally sat outside for an hour drinking coffee and wasn’t rushed. So nice!" - Felicia Weinstein, exhibitor
                                        </p>
                                    </CardBody>
                                </Card>
                            </IonCol>
                        </IonRow>
                        <IonRow className="justify-content-center">
                            <IonCol size="12">
                                <Card className="shadow border-0">
                                    <CardBody className="py-5">
                                        <h4 className="h3 text-primary text-uppercase text-center">
                                            Testimonial
                                        </h4>
                                        <IonRow>
                                            <IonCol sizeXs="12" sizeMd="6">
                                                <IonRow className="ion-justify-content-center ion-align-items-center">
                                                    <IonCol sizeXs="8" size="4" className="ion-text-center">
                                                        <img
                                                            className="img"
                                                            alt="RingSide Pro"
                                                            src={morgan}
                                                        />
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className="ion-justify-content-center ion-align-items-center">
                                                    <IonCol sizeXs="8" size="4" className="ion-text-center">
                                                        <p className="description mt-3 ion-text-wrap">
                                                            Morgan Workman, Professional Rider at River’s Edge Farm
                                                        </p>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCol>
                                            <IonCol sizeXs="12" sizeMd="6">
                                                <p className="description mt-3 ion-text-wrap">
                                                    "RingSide Pro made my life so much easier at Upperville this year! Having both hunters and jumpers showing at the same time, it was so helpful not to have to run across the street 10 times a day to check on timing. <span className="font-weight-bold">I was able to fully focus on the ring we were currently showing in!</span>
                                                </p>
                                                <p className="description mt-3 ion-text-wrap">
                                                    With the text updates, I didn’t even have to stop riding, I could just look at my Apple Watch and never have to pull up! 
                                                </p>
                                                <p className="description mt-3 ion-text-wrap">
                                                    RingSide Pro was easy to set up on my phone and easy to cancel when I left the show. <span className="font-weight-bold">I wish every show had this tool!</span>”
                                                </p>
                                            </IonCol>
                                        </IonRow>
                                    </CardBody>
                                </Card>
                            </IonCol>
                        </IonRow>
                    </Container>
                </section>
                <section className="py-5">
                    <Container>
                        <IonRow className="mt-5 ion-justify-content-center ion-align-items-center">
                            <IonCol size="12" className="ion-text-center">
                                <IonText><h2 className="font-weight-bold text-primary">Pricing for Horse Shows</h2></IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow className="text-primary">
                            <IonCol size="12" className="ion-text-center">
                                <p className="text-primary">
                                    Pre-purchase credits to send alerts. One credit sends an alert to one phone.
                                </p>
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-justify-content-center pt-3">
                            <IonCol sizeXs="12" sizeMd="8" sizeLg="6" className="text-center">
                                <img className="shadow bg-white rounded-1 img" alt="Text Alerts Pricing" src={textAlertsPricingImg}/>
                            </IonCol>
                        </IonRow>
                        <IonRow className="text-primary pb-3">
                            <IonCol size="12" className="ion-text-center">
                                <p className="text-primary">
                                    Run a lot of shows? Contact us for bulk pricing options.
                                </p>
                            </IonCol>
                        </IonRow>
                        <IonRow className="text-primary pb-3">
                            <IonCol sizeXs="12" sizeMd="6" className="">
                                <ul>
                                    <li>
                                        <p className="text-primary">
                                            We can help you determine how many credits you need.
                                        </p>
                                    </li>
                                    <li>
                                        <p className="text-primary">
                                            <a href="mailto:hello@ringsidepro.com" className="link">Email us</a> with questions or to set up a consultation.
                                        </p>
                                    </li>
                                </ul>
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="6" className="">
                                <ul>
                                    <li>
                                        <p className="text-primary">
                                            It's <span className="font-weight-bold">free</span> for anyone to sign up to receive the alerts.
                                        </p>
                                    </li>
                                    <li>
                                        <p className="text-primary">
                                            Leftover credits stay in your account for future events.
                                        </p>
                                    </li>
                                </ul>
                            </IonCol>
                        </IonRow>
                        <IonRow className="text-primary pb-3">
                            <IonCol size="12" className="ion-text-center">
                                <p className="text-primary">
                                    Have sponsors? We can help you get set up to have sponsors featured in the alerts.
                                </p>
                            </IonCol>
                        </IonRow>
                    </Container>
                </section>
                <section className="py-5 bg-light">
                    <Container>
                        <IonRow className="mt-5 ion-justify-content-center ion-align-items-center">
                            <IonCol size="12" className="ion-text-center">
                                <IonText><h2 className="font-weight-bold text-primary">Full Service</h2></IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow className="justify-content-center">
                            <IonCol size="12">
                                <Card className="shadow border-0">
                                    <CardBody className="py-5">
                                        <IonRow className="ion-justify-content-center pt-3">
                                            <IonCol size="12" className="text-center">
                                                <p>
                                                    We offer a "Full Service" option where we will attend the event and send the alerts.
                                                </p>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow className="">
                                            <IonCol size="12">
                                                <ul className="description">
                                                    <li>
                                                        We set up the event for you on the RingSide Pro website.
                                                    </li>
                                                    <li>
                                                        We can add any contacts you have as subscibrers. 
                                                    </li>
                                                    <li>
                                                        We create social media posts for you to post explaining how people can sign up.
                                                    </li>
                                                    <li>
                                                        We hang flyers at the event.
                                                    </li>
                                                    <li>
                                                        We work with the horse show staff to determine what alerts need to be sent.
                                                    </li>
                                                    <li>
                                                        We train your staff so they are confident in how to run the service in the future.
                                                    </li>
                                                </ul>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow className="ion-justify-content-center pt-3">
                                            <IonCol size="12" className="text-center">
                                                <img className="img-fluid" alt="Full Service Pricing" src={fullServicePricingImg}/>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow className="ion-justify-content-center pt-3">
                                            <IonCol size="12" className="text-center">
                                                <p className="description mt-3">
                                                    The pricing is based on the size of your event as well as the number of days of the event.
                                                    Larger events may require more RingSide Pro staff members attending, which results in the higher prices for larger shows.
                                                    Please note the travel expenses are not included in the pricing.
                                                </p>
                                            </IonCol>
                                        </IonRow>
                                    </CardBody>
                                </Card>
                            </IonCol>
                        </IonRow>
                        <IonRow className="justify-content-center">
                            <IonCol sizeXs="12" sizeMd="6">
                                <Testimonial 
                                    image={jessi} 
                                    name="Jessica Lohman"
                                    label="Show Manager" 
                                    quote="The full service played such a huge role in the success of our show. With 5 rings and so many moving parts, I absolutely intend to use the full service option again next year!! That’s a no brainer. RingSide Pro made it all so easy!"
                                />
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="6">
                                <Card className="shadow mt-2 border-0">
                                    <CardBody className="py-5">
                                        <h4 className="h3 text-info text-uppercase">
                                            Get Scheduled
                                        </h4>
                                        <p className="description mt-3">
                                            If you would like to request the Full Service option, we ask that you email us the dates and details of your event.
                                        </p>
                                        <p className="description mt-3">
                                            We ask that you please give us at least 4 weeks notice.
                                        </p>
                                        <p className="description mt-3">
                                            The Full Service option is currently available in Maryland, Virginia and North Carolina.
                                        </p>
                                        <p className="description mt-3">
                                            If you are in a different state, email us to talk about options.
                                        </p>
                                        <p className="description mt-3">
                                            hello@ringsidepro.com
                                        </p>
                                    </CardBody>
                                </Card>
                            </IonCol>
                        </IonRow>
                    </Container>
                </section>
                <section className="py-5">
                    <Container>
                        <IonRow className="mt-5 ion-justify-content-center ion-align-items-center">
                            <IonCol size="12" className="ion-text-center">
                                <IonText><h2 className="font-weight-bold text-primary">Tutorial Videos</h2></IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow className="text-primary py-3 ion-align-items-space-between">
                            <IonCol size="12" className="ion-text-center py-3">
                                <p>
                                    Learn more about our Text Alerts system and what it can do: <a href="https://www.youtube.com/channel/UC62yedG12RVpiKifqZkM1fA/featured" className="link">RingSide Pro Youtube Channel.</a>
                                </p>
                            </IonCol>
                        </IonRow>
                        <IonRow className="text-primary py-3 ion-align-items-space-between">
                            <IonCol sizeXs="6" sizeMd="12" className="ion-text-center py-3">
                                {(isWindows() || isPlatform("desktop")) ?
                                    <div>
                                        <iframe width="560" height="315" title="Tutorial Video"
                                            src="https://youtube.com/embed/GOIv1ZYRKGk">
                                        </iframe>
                                    </div>
                                    :
                                    <div></div>
                                }
                            </IonCol>
                        </IonRow>
                    </Container>
                </section>
                <section className="py-5 bg-light">
                    <Container>
                        <IonRow className="mt-5 ion-justify-content-center ion-align-items-center">
                            <IonCol size="12" className="ion-text-center">
                                <IonText><h2 className="font-weight-bold text-primary">FAQ</h2></IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow className="text-primary pb-3">
                            <IonCol size="12" className="ion-text-center">
                                <DropDownCard 
                                    title="What types of alerts do I send?" 
                                    content="That's totally up to you! Some events like to send out reminders of entry deadlines, what to bring, etc before the event. During the event, some people like to send regular updates throughout the day while other people like to send morning and evening announcements. The system is flexible to fit your needs." 
                                />
                            </IonCol>
                        </IonRow>
                        <IonRow className="text-primary pb-3">
                            <IonCol size="12" className="ion-text-center">
                                <DropDownCard 
                                    title="How many credits should I buy?" 
                                    content="It depends on how you are using the system. If you plan to send regular announcements throughout the day, we suggest estimating 15 alerts per ring per day. You also want to take into account the size of the event and how many people will sign up to receive alerts. If you would like help figuring out how many credits to buy, contact us: hello@ringsidepro.com." 
                                />
                            </IonCol>
                        </IonRow>
                        <IonRow className="text-primary pb-3">
                            <IonCol size="12" className="ion-text-center">
                                <DropDownCard 
                                    title="What if I run out of credits?" 
                                    content="The system will start to send out warnings as you run low on credits. You can purchase more credits during the event with a secure online payment." 
                                />
                            </IonCol>
                        </IonRow>
                        <IonRow className="text-primary pb-3">
                            <IonCol size="12" className="ion-text-center">
                                <DropDownCard 
                                    title="What if I buy too many credits?" 
                                    content="All credits are valid for two years. They will stay within your account and roll over to your next event."
                                />
                            </IonCol>
                        </IonRow>
                        <IonRow className="text-primary pb-3">
                            <IonCol size="12" className="ion-text-center">
                                <DropDownCard 
                                    title="Who will send the alerts?" 
                                    content="That's up to you! Some shows find it best to have the in-gate starters or announcers send the alerts. Other shows have the secretary or someone working in hospitality. Some shows even have a volunteer send them! Plus, you are able to give more than one person access to send the alerts. It is easy to send alerts and does not present a large time burden, so pick people you know have access to the correct information and you trust to reliably send alerts on time."
                                />
                            </IonCol>
                        </IonRow>
                        <IonRow className="text-primary pb-3">
                            <IonCol size="12" className="ion-text-center">
                                <DropDownCard 
                                    title="Can I pick who receives certain alerts?" 
                                    content="Yes! We allow you to create 'filters'. These can be days, rings, or any other groupings. When you send the alerts, you can choose which groupings the alert should go to. This also helps you save your credits."
                                />
                            </IonCol>
                        </IonRow>
                        <IonRow className="text-primary pb-3">
                            <IonCol size="12" className="ion-text-center">
                                <DropDownCard 
                                    title="I have a list of exhibitors phone numbers. Can I add them?" 
                                    content="Yes! We have a Bulk Add tool that will let you import subscribers from a spread sheet. The system sends a text message to each person you add letting them know that they were added, they can update their settings and that they can opt out if they so wish."
                                />
                            </IonCol>
                        </IonRow>
                        <IonRow className="text-primary pb-3">
                            <IonCol size="12" className="ion-text-center">
                                <DropDownCard 
                                    title="Can exhibitors respond to the text message alerts?" 
                                    content="Yes! We allow you to enable two-way messaging in each event's settings. If you enable two-way messaging, your exhibitors can respond to the alerts with their questions. You'll see these responses in the Alerts Dashboard."
                                />
                            </IonCol>
                        </IonRow>
                        <IonRow className="text-primary pb-3">
                            <IonCol size="12" className="ion-text-center">
                                <DropDownCard 
                                    title="How do discounts work?" 
                                    content="We offer discounts on packages starting at 40,000 credits. We're always happy to work with horse show associations and other groups that run many shows each year. Contact us to find out how much you can save!"
                                />
                            </IonCol>
                        </IonRow>
                        <IonRow className="text-primary pb-3">
                            <IonCol size="12" className="ion-text-center">
                                <DropDownCard 
                                    title="An exhibitor signed up but isn't receiving messages. What should I do?" 
                                    content="If the exhibitor has signed up for previous events and responded STOP to the alerts, they will need to send a text message to (757)210-6404 that says START. If that was not causing the issue, they can adjust their settings by going throught the same steps that they used to sign up for the alerts. They will need to make sure that they either get all alerts or that they filter the alerts with at least one filter selected. Make sure to press submit within the sign up form. If you still need assistance, contact us at hello@ringsidepro.com or (757)812-0385."
                                />
                            </IonCol>
                        </IonRow>
                    </Container>
                </section>
                <section className="py-5 bg-white">
                    <Container>
                        <IonRow className="mt-5 ion-justify-content-center ion-align-items-center">
                            <IonCol size="12" className="ion-text-center">
                                <IonText><h2 className="font-weight-bold text-primary">We're Here to Help</h2></IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow className="text-primary py-3 ion-align-items-space-between">
                            <IonCol size="12" className="ion-text-center py-3">
                                <p>
                                    We're always happy to answer your questions and provide support to both you and your exhibitors.
                                </p>
                            </IonCol>
                        </IonRow>
                        <IonRow className="justify-content-center">
                            <IonCol sizeXs="12" sizeMd="6">
                                <Card className="shadow border-0">
                                    <CardBody className="py-5">
                                        <h4 className="h3 text-primary text-uppercase">
                                            Call
                                        </h4>
                                        <p className="description mt-3">
                                            757-812-0385
                                        </p>
                                    </CardBody>
                                </Card>
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="6">
                                <Card className="shadow border-0">
                                    <CardBody className="py-5">
                                        <h4 className="h3 text-info text-uppercase">
                                            Email
                                        </h4>
                                        <p className="description mt-3">
                                            hello@ringsidepro.com
                                        </p>
                                    </CardBody>
                                </Card>
                            </IonCol>
                        </IonRow>
                    </Container>
                </section>
                <section className="py-5">
                    <Container>
                        <IonRow className="justify-content-center">
                            <IonCol size="12">
                                <Card className="shadow border-0">
                                    <CardBody className="py-5">
                                        <h4 className="h3 text-primary text-center text-uppercase">
                                            Ready to get started?
                                        </h4>
                                        <p className="text-center">
                                            <Link to="/auth/register" className="link text-info">Create an Account</Link>
                                        </p>
                                    </CardBody>
                                </Card>
                            </IonCol>
                        </IonRow>
                    </Container>
                </section>
                <Footer />
            </IonContent>
        </IonPage>
    );
};

export default AboutTextAlertsPage;
